import * as React from 'react'

import { withTranslation } from 'react-i18next'

/* Import components here */
import {
  StyledRentalObjectAssignmentHistoryList,
  ListItem,
  CheckboxWrapper,
  MenuTitle
} from './RentalObjectAssignmentHistoryList.styles'

/* Import interfaces here */
import {
  IRentalObjectAssignmentHistoryListProps,
  IRentalObjectAssignmentHistoryListState
} from './RentalObjectAssignmentHistoryList.interfaces'
import { PageHeader, Button, Icon, Tag, Divider, Spin, Alert } from 'antd'
import * as ROUTES from '../../../constants/routes'

/* Import utilities here */
import { compose } from 'recompose'
import { withFirebase } from '../../../firebase'

import { Avatar, List, Layout, Badge } from 'antd'
import { Link, withRouter } from 'react-router-dom'
import { Checkbox } from 'antd'
import { rootStore } from 'stores'
import { observer } from 'mobx-react'
import { Select } from 'antd'

const { Option } = Select
const CheckboxGroup = Checkbox.Group
// const { Search } = Input

const defaultCheckedList = []
const { Content, Sider } = Layout
@observer
export class RentalObjectAssignmentHistoryList extends React.Component<
  IRentalObjectAssignmentHistoryListProps,
  IRentalObjectAssignmentHistoryListState
> {
  unsubscribeRentalObject: any
  unsubscribeAssignments: any
  constructor(props) {
    super(props)

    this.state = {
      rentalObjectLoading: false,
      assignmentsLoading: false,
      rentalObject: null,
      assignmentHistory: [],
      allAssignments: [],
      priorityCheckedList: defaultCheckedList,
      areaCheckedList: defaultCheckedList,
      sortBy: 'active'
    }
  }

  componentDidMount() {
    this.setState({ rentalObjectLoading: true, assignmentsLoading: true })

    this.unsubscribeRentalObject = this.props.firebase
      .rentalObject(this.props.match.params.rentalObjectID)
      .onSnapshot(snapshot => {
        this.setState({
          rentalObject: snapshot.data(),
          rentalObjectLoading: false
        })
      })

    this.unsubscribeAssignments = this.props.firebase
      .assignments(this.props.match.params.rentalObjectID)
      .onSnapshot(snapshot => {
        const assignments: any = []
        snapshot.forEach(doc => {
          assignments.push({ ...doc.data(), uid: doc.id })
        })
        this.setState({
          allAssignments: assignments,
          assignmentHistory: assignments.sort(this.sortBy(this.state.sortBy)),
          assignmentsLoading: false
        })
      })
  }

  componentWillUnmount() {
    this.unsubscribeRentalObject && this.unsubscribeRentalObject()
    this.unsubscribeAssignments && this.unsubscribeAssignments()
  }

  updateList = () => {
    const { allAssignments, sortBy, areaCheckedList, priorityCheckedList } = this.state
    //const checker = (arr, target) => target.every(v => arr.includes(v)) Om man vill att alla ska stämma in
    const checker = (arr, target) => target.some(v => arr.includes(v))

    let filteredAssignments
    if (priorityCheckedList.length > 0 && areaCheckedList.length > 0) {
      filteredAssignments = allAssignments
        .slice()
        .filter(ass => priorityCheckedList.includes(ass.priority))
        .filter(ass => checker(ass.areas, areaCheckedList))
        .sort(this.sortBy(sortBy))
    } else if (priorityCheckedList.length === 0 && areaCheckedList.length > 0) {
      filteredAssignments = allAssignments
        .slice()
        .filter(ass => checker(ass.areas, areaCheckedList))
        .sort(this.sortBy(sortBy))
    } else if (priorityCheckedList.length > 0 && areaCheckedList.length === 0) {
      filteredAssignments = allAssignments
        .slice()
        .filter(ass => priorityCheckedList.includes(ass.priority))
        .sort(this.sortBy(sortBy))
    } else {
      filteredAssignments = allAssignments.sort(this.sortBy(sortBy))
    }
    this.setState({
      assignmentHistory: filteredAssignments
    })
  }

  onChangePriority = priorityCheckedList => {
    this.setState(
      {
        priorityCheckedList
      },
      () => this.updateList()
    )
  }

  onChangeArea = areaCheckedList => {
    this.setState(
      {
        areaCheckedList
      },
      () => this.updateList()
    )
  }

  onSortChange = value => {
    const { assignmentHistory, sortBy } = this.state
    let sortedAssignments
    if (sortBy === 'old') {
      sortedAssignments = assignmentHistory.slice().sort(this.sortBy('new'))
      sortedAssignments.sort(this.sortBy(value))
    } else {
      sortedAssignments = assignmentHistory.slice().sort(this.sortBy(value))
    }
    this.setState({ assignmentHistory: sortedAssignments, sortBy: value })
  }

  sortBy = sortBy => {
    const priorities = rootStore.settingStore.settings.priorities
    switch (sortBy) {
      case 'active':
        return (a, b) => (!!a.dateFinished === !!b.dateFinished ? 0 : !!a.dateFinished ? 1 : -1)
      case 'new':
        return (a, b) => b.dateCreated.toMillis() - a.dateCreated.toMillis()
      case 'old':
        return (a, b) => a.dateCreated.toMillis() - b.dateCreated.toMillis()
      case 'high':
        return (a, b) =>
          priorities.findIndex(priority => priority === b.priority) -
          priorities.findIndex(priority => priority === a.priority)
      case 'low':
        return (a, b) =>
          priorities.findIndex(priority => priority === a.priority) -
          priorities.findIndex(priority => priority === b.priority)
      default:
        return (a, b) => a.priority - b.priority
    }
  }

  public render() {
    const { t, children, ...props } = this.props
    const {
      rentalObject,
      rentalObjectLoading,
      assignmentsLoading,
      assignmentHistory,
      priorityCheckedList,
      areaCheckedList
    } = this.state

    return (
      <StyledRentalObjectAssignmentHistoryList {...props}>
        {rentalObjectLoading && (
          <PageHeader
            backIcon={
              <Button type="primary">
                <Icon type="left" />
                Gå Tillbaka
              </Button>
            }
            onBack={() => window.history.back()}
            title={<Spin />}
          />
        )}
        {rentalObject && (
          <PageHeader
            backIcon={
              <Button type="primary">
                <Icon type="left" />
                Gå Tillbaka
              </Button>
            }
            onBack={() => window.history.back()}
            title={
              rentalObject.address.realEstateName +
              ' (' +
              rentalObject.address.rentalObjectNumber +
              ') '
            }
            subTitle={
              rentalObject.address.street +
              ' ' +
              rentalObject.address.zipCode +
              ' ' +
              rentalObject.address.city
            }
          />
        )}
        <Layout>
          <Layout>
            <Sider
              width={200}
              style={{
                background: '#fff',
                borderRight: '1px solid #e8e8e8',
                borderTop: '1px solid #e8e8e8'
              }}
            >
              <CheckboxWrapper>
                <MenuTitle>Sortera efter</MenuTitle>
                <Select defaultValue="active" style={{ width: 180 }} onChange={this.onSortChange}>
                  <Option value="active">Pågående</Option>
                  <Option value="new">Nyaste Först</Option>
                  <Option value="old">Äldsta Först</Option>
                  <Option value="high">Högst Prioritet</Option>
                  <Option value="low">Lägst Prioritet</Option>
                </Select>
                <Divider style={{ margin: '24px auto', minWidth: '80%', width: '90%' }} />
                <MenuTitle>Filtrera på Prioritering</MenuTitle>
                <CheckboxGroup
                  options={rootStore.settingStore.settings.priorities}
                  value={priorityCheckedList}
                  onChange={this.onChangePriority}
                />
                <Divider style={{ margin: '24px auto', minWidth: '80%', width: '90%' }} />
                <MenuTitle>Filtrera på Områden</MenuTitle>
                <CheckboxGroup
                  options={rootStore.settingStore.settings.areas}
                  value={areaCheckedList}
                  onChange={this.onChangeArea}
                />
              </CheckboxWrapper>
              <Divider style={{ margin: '24px auto', minWidth: '80%', width: '90%' }} />
              <Alert
                style={{ width: 180 }}
                message="Prioriteringar som tagits bort får prio 0, Områden som tagits bort kan ej filtreras"
                type="warning"
              />
            </Sider>
            <Content style={{ padding: '0 24px 12px', minHeight: 280, backgroundColor: 'white' }}>
              {/* <Search placeholder="Sök" /> */}

              <List
                style={{ paddingTop: 12 }}
                itemLayout="vertical"
                dataSource={assignmentHistory}
                loading={assignmentsLoading}
                renderItem={(assignment: any) => {
                  const priorityIndex = rootStore.settingStore.settings.priorities.findIndex(
                    priority => priority === assignment.priority
                  )
                  return (
                    <ListItem
                      onClick={() =>
                        this.props.history.push({
                          pathname: `${ROUTES.REAL_ESTATES}/${assignment.realEstateID}/${assignment.rentalObjectID}/${assignment.assignmentID}`,
                          state: { assignment }
                        })
                      }
                      // actions={[
                      //   <Tag color="blue">Pågående</Tag>,
                      //   <Tag color="red">Otilldelad</Tag>,
                      //   <Tag color="yellow">Ofakturerad</Tag>
                      // ]}
                      // extra={
                      //   <Link
                      //     to={{
                      //       pathname: `${ROUTES.SETTINGS_REAL_ESTATE_LIST}/${assignment.uid}`,
                      //       state: { assignment }
                      //     }}
                      //   >
                      //     Se Detaljer
                      //   </Link>
                      // }
                    >
                      <List.Item.Meta
                        avatar={
                          <Avatar
                            style={{
                              verticalAlign: 'middle'
                            }}
                          >
                            {priorityIndex + 1}
                          </Avatar>
                        }
                        title={
                          <Link
                            to={{
                              pathname: `${ROUTES.REAL_ESTATES}/${assignment.realEstateID}/${assignment.rentalObjectID}/${assignment.assignmentID}`,
                              state: { assignment }
                            }}
                          >
                            {assignment.dateCreated.toDate().toLocaleString()}
                            {!assignment.dateFinished && (
                              <Badge
                                style={{ marginLeft: '16px', backgroundColor: '#3f51b5' }}
                                count="Pågående Ärende"
                              />
                            )}
                          </Link>
                        }
                        description={
                          <div>
                            {assignment.areas.map((area, index) => {
                              return <Tag key={index}>{area}</Tag>
                            })}
                          </div>
                        }
                      />
                      {assignment.description}
                    </ListItem>
                  )
                }}
              />
            </Content>
          </Layout>
        </Layout>
      </StyledRentalObjectAssignmentHistoryList>
    )
  }
}

export default compose(
  withTranslation(),
  withRouter,
  withFirebase
)(RentalObjectAssignmentHistoryList)
