import * as React from 'react'

/* Import components here */
import { WithTranslation } from 'react-i18next'
import { compose } from 'recompose'

import { AuthUserContext, withAuthorization, withEmailVerification } from '../../contexts'
import { PasswordChangeForm } from '../../components'
import { Row, Col, Avatar, Typography, Icon, Divider, Tag } from 'antd'

const { Title, Text, Paragraph } = Typography

export const AccountPage: React.FC<WithTranslation> = ({ children, ...props }) => {
  //const { t } = useTranslation()
  return (
    <AuthUserContext.Consumer>
      {(authUser: any) => (
        <div>
          <Row gutter={[24, 24]}>
            <Col xl={8} span={24}>
              <div style={{ backgroundColor: 'white', padding: 24 }}>
                <div style={{ textAlign: 'center' }}>
                  <Avatar size={128} src={authUser.profilePicture} icon="user" />
                  <Title style={{ marginTop: 10 }} level={4}>
                    {authUser.firstName + ' ' + authUser.lastName}
                  </Title>
                </div>
                <Paragraph style={{ marginTop: 20 }}>
                  <Text strong style={{ marginRight: 8 }}>
                    <Icon type="mail" />
                  </Text>
                  <Text copyable>{authUser.email}</Text>
                </Paragraph>
                <Paragraph>
                  <Text strong style={{ marginRight: 8 }}>
                    <Icon type="phone" />
                  </Text>
                  <Text copyable>{authUser.phoneNumbers}</Text>
                </Paragraph>
                <Divider dashed />
                {authUser.isActive ? (
                  <Tag color="green">Aktiv</Tag>
                ) : (
                  <Tag color="red">Inaktiv</Tag>
                )}
                {!!authUser.roles && authUser.roles.ADMIN ? (
                  <Tag color="geekblue">Kontorspersonal</Tag>
                ) : null}
                {!!authUser.roles && authUser.roles.JANITOR ? (
                  <Tag color="volcano">Fastighetsskötare</Tag>
                ) : null}
                <Divider dashed />
                <Text strong style={{ marginRight: 8 }}>
                  <Icon
                    twoToneColor={authUser.emailVerified ? '#52c41a' : 'red'}
                    theme="twoTone"
                    type={authUser.emailVerified ? 'check-circle' : 'close-circle'}
                  />
                  {authUser.emailVerified ? ' Email verifierad' : ' Email är inte verifierad'}
                </Text>
                <PasswordChangeForm />
              </div>
            </Col>
            <Col xl={16} span={24}></Col>
          </Row>
        </div>
      )}
    </AuthUserContext.Consumer>
  )
}

const condition = authUser => !!authUser

export default compose(withEmailVerification, withAuthorization(condition))(AccountPage)
