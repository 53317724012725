import * as React from 'react'

import { withTranslation } from 'react-i18next'

/* Import components here */
import { Avatar, Badge, Button, Icon, Menu, Popconfirm, Popover, Typography } from 'antd'

/* Import interfaces here */
import { IAppLayoutAvatarPopoverProps } from './AppLayoutAvatarPopover.interfaces'

/* Import utilities here */
import { AuthUserContext } from 'contexts'
import { withFirebase } from '../../../firebase'
import { compose } from 'recompose'
import { Link } from 'react-router-dom'
import * as ROUTES from '../../../constants/routes'
import { rootStore } from 'stores'
import { observer } from 'mobx-react'
const { inboxStore } = rootStore

const { Text } = Typography

const UserAvatar = observer(({ user }) => (
  <Badge count={inboxStore.numberOfAssignments}>
    <Avatar style={{ cursor: 'pointer' }} size={42} icon="user" />
  </Badge>
))
const InboxBadge = observer(() => <Badge count={inboxStore.numberOfAssignments} />)

export const AppLayoutAvatarPopover: React.FC<IAppLayoutAvatarPopoverProps> = ({
  t,
  children,
  ...props
}) => {
  return (
    <AuthUserContext.Consumer>
      {(authUser: any) =>
        authUser ? (
          <>
            <Text strong style={{ marginRight: '16px' }}>
              {authUser.firstName + ' ' + authUser.lastName}
            </Text>
            <Popover
              overlayClassName="appLayoutAvatarPopover"
              placement="bottomRight"
              title={authUser.firstName + ' ' + authUser.lastName}
              style={{ padding: 0 }}
              content={
                <div>
                  <Menu selectedKeys={[]} mode="inline" style={{ width: 256 }}>
                    <Menu.Item key="1">
                      <Link to={ROUTES.ACCOUNT}>
                        <Icon type="user" />
                        <span className="nav-text">Min Profil</span>
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="2">
                      <Link to={ROUTES.INBOX}>
                        <Icon type="inbox" />
                        <span className="nav-text">Inkorg</span>
                        <span style={{ float: 'right' }}>
                          <InboxBadge />
                        </span>
                      </Link>
                    </Menu.Item>
                  </Menu>
                  <Popconfirm
                    placement="bottomRight"
                    title={'Är du säker på att du vill logga ut??'}
                    onConfirm={props.firebase.doSignOut}
                    okText="Ja"
                    cancelText="Nej"
                  >
                    <Button style={{ width: '100%' }}>Logga ut</Button>
                  </Popconfirm>
                </div>
              }
              trigger="click"
            >
              <span>
                <UserAvatar user={authUser} />
              </span>
            </Popover>
          </>
        ) : null
      }
    </AuthUserContext.Consumer>
  )
}

export default compose(withTranslation(), withFirebase)(AppLayoutAvatarPopover)
