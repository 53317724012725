import { styled } from 'theme'
import { Card } from 'antd'

export const StyledDashBoardCardStatisticGrid = styled.div``
export const StyledCard = styled(Card)`
  min-width: 400px;
  margin-top: 16px;

  :hover {
    cursor: pointer;
    background-color: #f9f9f9;
  }
`
