import React, { Component } from 'react'
import { Bar } from 'react-chartjs-2'

const data = {
  labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
  datasets: [
    {
      label: 'Sales',
      type: 'line',
      data: [51, 65, 40, 49, 60, 37, 40],
      fill: false,
      borderColor: '#EC932F',
      backgroundColor: '#EC932F',
      pointBorderColor: '#EC932F',
      pointBackgroundColor: '#EC932F',
      pointHoverBackgroundColor: '#EC932F',
      pointHoverBorderColor: '#EC932F'
    },
    {
      type: 'bar',
      label: 'Visitor',
      data: [200, 185, 590, 621, 250, 400, 95],
      fill: false,
      backgroundColor: '#71B37C',
      borderColor: '#71B37C',
      hoverBackgroundColor: '#71B37C',
      hoverBorderColor: '#71B37C'
    }
  ]
}

const plugins = [
  {
    // afterDraw: (chartInstance, easing) => {
    //   const ctx = chartInstance.chart.ctx
    //   ctx.fillText('This text drawn by a plugin', 100, 100)
    // }
    afterDraw: function(chart, easing) {
      const ctx = chart.ctx
      const value = 550
      const top = chart.scales['y-axis-0'].top
      const bottom = chart.scales['y-axis-0'].bottom
      const availableArea = bottom - top
      const max = chart.scales['y-axis-0'].max
      const x = ((max - value) / max) * availableArea + top
      const leftY = chart.scales['y-axis-0'].right
      const rightY = chart.width
      ctx.beginPath()
      ctx.moveTo(leftY, x)
      ctx.lineTo(rightY, x)
      ctx.lineWidth = 2
      ctx.strokeStyle = '#e23fa9'
      ctx.stroke()
    }
  }
]

export default class LineDemo extends Component {
  render() {
    return (
      <div>
        <h2>Line Example</h2>
        <Bar ref="chart" data={data} plugins={plugins} width={500} />
      </div>
    )
  }

  // componentDidMount() {
  //   const { datasets } = this.refs.chart.chartInstance.data
  //   console.log(datasets[0].data)
  // }
}
