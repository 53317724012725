import * as React from 'react'

import { withTranslation } from 'react-i18next'

/* Import components here */
import { StyledUserEditForm, StyledTitle } from './UserEditForm.styles'
/* Import interfaces here */
import { IUserEditFormProps, IUserEditFormState } from './UserEditForm.interfaces'

/* Import utilities here */
import { Button, Form, Icon, Input, Select, Tooltip } from 'antd'
import { withFirebase } from '../../../firebase'
import { compose } from 'recompose'
import axios from 'axios'
const { Option } = Select

export class UserEditForm extends React.Component<IUserEditFormProps, IUserEditFormState> {
  public state = {
    loading: false
  }

  public componentDidMount() {
    // To disabled submit button at the beginning.
    this.props.form.validateFields()
    //console.log(this.props.providerData)
    this.props.providerData.forEach(provider => {
      //console.log(provider.providerId)
    })
  }

  public handleSubmit = e => {
    e.preventDefault()
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({
          loading: true
        })
        // console.log(this.props.authUser)
        // console.log('Received values of form: ', values)
        const authBody = {}
        const userBody = {
          email: values.email,
          firstName: values.firstName,
          lastName: values.lastName,
          phoneNumber: values.prefix + values.phone,
          phoneNumbers: values.prefix + values.phone
        }
        this.props.providerData.forEach(provider => {
          //console.log(provider.providerId)
          if (provider.providerId === 'password') {
            Object.assign(authBody, { email: values.email })
            //Object.assign(authBody, { password: '123456' }) om man bara hade telefon innan
          } else if (provider.providerId === 'phone') {
            Object.assign(authBody, { phoneNumber: values.prefix + values.phone })
          }
        })
        //console.log(this.props.user.userID)
        //console.log(authBody)
        this.updateUser(this.props.user.userID, authBody, userBody)
      }
    })
  }

  public updateUser(uid: string, data: any, userBody: any) {
    const user = this.props.firebase.auth.currentUser
    if (user) {
      user
        .getIdToken(true)
        .then(idToken => {
          //console.log(idToken)
          axios({
            method: 'post',
            url: `${process.env.REACT_APP_FUNCTIONS_BASE_URL}/updateUser`,
            headers: { Authorization: idToken },
            params: {
              uid
            },
            data
          })
            .then(res => {
              //            .then(res => this.props.getWithUID(uid)) //this.setState({ postResponse: JSON.stringify(res.data) })
              //console.log(res.data)
              if (res.data !== 'Error') {
                this.props.firebase
                  .updateUser(uid)(userBody)
                  .then(() => {
                    //console.log('Document successfully Updated!')
                  })
                  .catch(error => {
                    //console.error('Error writing document: ', error)
                  })
              } else {
                //console.log('Error with postRequest')
              }
            })
            .catch(error => {
              //console.log('Axios error', error)
            })
        })
        .catch(error => {
          //console.log('getIDToken error', error)
        })
    } else {
      //console.log('No user lul')
    }
  }

  public hasErrors(fieldsError) {
    return Object.keys(fieldsError).some(field => fieldsError[field])
  }

  public render() {
    const {
      getFieldDecorator,
      getFieldsError,
      getFieldError,
      isFieldTouched,
      getFieldValue
    } = this.props.form
    const { loading } = this.state
    const { disabled, user } = this.props
    const firstNameError = isFieldTouched('firstName') && getFieldError('firstName')
    const lastNameError = isFieldTouched('lastName') && getFieldError('lastName')
    const emailError = isFieldTouched('email') && getFieldError('email')
    const phoneError = isFieldTouched('phone') && getFieldError('phone')

    const firstNameEdited = getFieldValue('firstName') === user.firstName ? false : true
    const lastNameEdited = getFieldValue('lastName') === user.lastName ? false : true
    const emailEdited = getFieldValue('email') === user.email ? false : true
    const phoneEdited = getFieldValue('phone') === user.phoneNumber.substring(3) ? false : true

    const fieldsEdited = firstNameEdited || lastNameEdited || emailEdited || phoneEdited

    const formItemLayout = {
      labelCol: { span: 5 },
      wrapperCol: { span: 14 }
    }
    const tailFormItemLayout = {
      wrapperCol: { span: 6, offset: 9 }
    }
    const prefixSelector = getFieldDecorator('prefix', {
      initialValue: '+46'
    })(
      <Select style={{ width: 70 }}>
        <Option value="+46">+46</Option>
      </Select>
    )

    return (
      <StyledUserEditForm>
        <StyledTitle style={{ textAlign: 'center' }}>
          {user.firstName + ' ' + user.lastName}
        </StyledTitle>

        <Form {...formItemLayout} onSubmit={this.handleSubmit}>
          <Form.Item
            {...formItemLayout}
            label="Förnamn"
            validateStatus={firstNameError ? 'error' : ''}
            help={firstNameError || ''}
          >
            {getFieldDecorator('firstName', {
              initialValue: user.firstName,
              rules: [{ required: true, message: 'Ange ett förnamn !' }]
            })(
              <Input
                disabled={disabled}
                prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                placeholder="Förnamn"
              />
            )}
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            label="Efternamn"
            validateStatus={lastNameError ? 'error' : ''}
            help={lastNameError || ''}
          >
            {getFieldDecorator('lastName', {
              initialValue: user.lastName,
              rules: [{ required: true, message: 'Ange ett efternamn !' }]
            })(
              <Input
                disabled={disabled}
                prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                placeholder="Efternamn"
              />
            )}
          </Form.Item>
          <Form.Item
            label="E-mail"
            validateStatus={emailError ? 'error' : ''}
            help={emailError || ''}
          >
            {getFieldDecorator('email', {
              initialValue: user.email,
              rules: [
                {
                  type: 'email',
                  message: 'Ogiltigt E-mail format !'
                },
                {
                  required: true,
                  message: 'Ange en E-mail!'
                }
              ]
            })(
              <Input
                disabled={disabled}
                prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />}
                placeholder="Email"
              />
            )}
          </Form.Item>
          <Form.Item
            label={
              <span>
                Telefonnummer&nbsp;
                <Tooltip title="E.164 Standard, börja inte abonnentnummert med 0, använd inte punkt, parantes, bindestreck eller mellanslag">
                  <Icon type="question-circle-o" />
                </Tooltip>
              </span>
            }
            extra="Exempel: +46 701239911"
            validateStatus={phoneError ? 'error' : ''}
            help={phoneError || ''}
          >
            {getFieldDecorator('phone', {
              initialValue: user.phoneNumber.substring(3),
              rules: [
                {
                  required: true,
                  message:
                    'Telefonnummret måste vara i rätt format! (Börja inte med 0, använd inte paranteser, punkt eller mellanslag)',
                  pattern: /^[1-9]\d{8}$/g
                }
              ]
            })(
              <Input
                disabled={disabled}
                addonBefore={prefixSelector}
                style={{ width: '100%' }}
                placeholder="720001122"
              />
            )}
          </Form.Item>
          <Form.Item {...tailFormItemLayout}>
            <Button
              block
              type="primary"
              htmlType="submit"
              disabled={this.hasErrors(getFieldsError()) || disabled || !fieldsEdited}
              loading={loading}
            >
              Uppdatera Användare
            </Button>
          </Form.Item>
        </Form>
      </StyledUserEditForm>
    )
  }
}

/** @component */
export default Form.create<IUserEditFormProps>({ name: 'UserEditForm' })(
  compose(withTranslation(), withFirebase)(UserEditForm)
)
