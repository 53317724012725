import * as React from 'react'
import {
  Button,
  Modal,
  Form,
  Input,
  Popconfirm,
  Typography,
  Icon,
  Tag,
  Row,
  Col,
  Select,
  Checkbox
} from 'antd'
import { rootStore } from '../../stores'

const { Title, Paragraph, Text } = Typography
const { Option } = Select

const CollectionCreateForm = Form.create({ name: 'form_in_modal' })(
  // eslint-disable-next-line
  class extends React.Component {
    render() {
      const { visible, onCancel, onCreate, form, deleteAssignment } = this.props
      const { getFieldDecorator } = form
      let { assignment } = this.props

      if (assignment) {
        const contactPerson = assignment.contactPersons[0]
        return (
          <Modal
            width={600}
            visible={visible}
            maskClosable={false}
            keyboard={false}
            closable={false}
            footer={[
              <Popconfirm
                key="deleteConfirm"
                placement="bottom"
                title={'Är du säker på att du vill slänga ärendet? Det försvinner permanent'}
                onConfirm={deleteAssignment}
                okText="Ja"
                cancelText="Nej"
              >
                <Button key="delete" style={{ float: 'left' }} type="danger">
                  Släng Ärende
                </Button>
              </Popconfirm>,
              <Button key="cancel" onClick={onCancel}>
                Avbryt
              </Button>,
              <Button key="submit" type="primary" onClick={onCreate}>
                Skapa Ärende
              </Button>
            ]}
          >
            <Title level={4}>
              {assignment.address.realEstateName +
                ' (' +
                assignment.address.rentalObjectNumber +
                ')'}
            </Title>
            <Paragraph>
              {assignment.address.street +
                ', ' +
                assignment.address.zipCode +
                ' ' +
                assignment.address.city}
            </Paragraph>
            <Text strong>Kontaktperson</Text>
            <Paragraph>
              {contactPerson.firstName} {contactPerson.lastName} ◦ {contactPerson.email} ◦{' '}
              {contactPerson.phoneNumbers}
            </Paragraph>
            <Paragraph>
              <Icon
                style={{
                  fontSize: 16,
                  verticalAlign: 'middle',
                  color: assignment.masterKeyAllowed ? '#5E934F' : '#F5222D'
                }}
                type="key"
              />{' '}
              {assignment.masterKeyAllowed
                ? 'Huvudnyckel får anvädas'
                : 'Huvudnyckel får EJ användas'}
            </Paragraph>
            <Text strong>Områden</Text>
            <Paragraph>
              {assignment.areas.map((area, index) => {
                return <Tag key={index}>{area}</Tag>
              })}
            </Paragraph>
            <Form layout="vertical">
              <Form.Item label="Åtgärdstext">
                {getFieldDecorator('description', {
                  initialValue: assignment.description,
                  rules: [
                    {
                      message: 'Skriv in en åtgärdstext',
                      required: true
                    }
                  ]
                })(
                  <Input.TextArea
                    style={{ resize: 'none' }}
                    autoSize={{ minRows: 2, maxRows: 10 }}
                    placeholder="Åtgärdstext"
                  />
                )}
              </Form.Item>
              <Row gutter={16}>
                <Col span={16}>
                  <Form.Item label="Uppdragstagare">
                    {getFieldDecorator('employee', {
                      initialValue: 'Otilldelad',
                      rules: [{ required: true, message: 'Välj en uppdragstagare' }]
                    })(
                      <Select
                        style={{ position: 'relative' }}
                        placeholder="Välj en uppdragstagare"
                        showSearch={true}
                        optionFilterProp="children"
                      >
                        <Option key="Otilldelad" value="Otilldelad">
                          Otilldelad
                        </Option>
                        {rootStore.employeeStore.allJanitors.map(employee => {
                          return (
                            <Option key={employee.userID} value={employee.userID}>
                              {employee.firstName} {employee.lastName} {employee.email}
                            </Option>
                          )
                        })}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="Prioritet">
                    {getFieldDecorator('priority', {
                      initialValue: rootStore.settingStore.settings.priorities[0],
                      rules: [{ required: true, message: 'Välj prioritet' }]
                    })(
                      <Select placeholder="Välj prioritet" style={{ position: 'relative' }}>
                        {rootStore.settingStore.settings.priorities.map(priority => {
                          return (
                            <Option key={priority} value={priority}>
                              {priority}
                            </Option>
                          )
                        })}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item>
                {getFieldDecorator(
                  'checkbox-group',
                  {}
                )(
                  <Checkbox.Group style={{ width: '100%' }}>
                    <Row>
                      <Col span={24}>
                        <Checkbox value="toBeInvoiced">Felanmälan ska faktureras</Checkbox>
                      </Col>
                    </Row>
                  </Checkbox.Group>
                )}
              </Form.Item>
            </Form>
          </Modal>
        )
      } else {
        return null
      }
    }
  }
)

export default class CollectionsPage extends React.Component {
  handleCreate = () => {
    const { form } = this.formRef.props
    const { assignment, authUser } = this.props
    form.validateFields((err, values) => {
      if (err) {
        return
      }

      console.log('Received values of form: ', values)
      let toBeInvoiced = false
      if (values['checkbox-group'] !== undefined) {
        for (const key of values['checkbox-group']) {
          if (key === 'toBeInvoiced') {
            toBeInvoiced = true
          }
        }
      }
      const assignedUser = rootStore.employeeStore.getEmployeeWithID(values.employee)
      const contactPerson = assignment.contactPersons[0]
      contactPerson.tenantID = 'inboxedAssignment'
      const assignmentToBeCreated = {
        address: {
          city: assignment.address.city,
          realEstateName: assignment.address.realEstateName,
          rentalObjectNumber: assignment.address.rentalObjectNumber,
          street: assignment.address.street,
          zipCode: assignment.address.zipCode
        },
        areas: assignment.areas,
        assignedDate:
          values.employee === 'Otilldelad'
            ? null
            : this.props.firebase.timestamp.fromDate(new Date(Date.now())),
        assignedUserEmail: assignedUser ? assignedUser.email : '',
        assignedUserID: assignedUser ? assignedUser.userID : '',
        assignedUserName: assignedUser ? assignedUser.firstName + ' ' + assignedUser.lastName : '',
        assignedUserPhoneNumbers:
          assignedUser && assignedUser.phoneNumbers ? assignedUser.phoneNumbers : '',
        assignmentID: assignment.assignmentID,
        billable: toBeInvoiced,
        billed: toBeInvoiced ? false : null,
        contactPersons: [contactPerson],
        creatorEmail: authUser.email,
        creatorID: authUser.userID,
        creatorName: authUser.firstName + ' ' + authUser.lastName,
        dateCreated: this.props.firebase.timestamp.fromDate(new Date(Date.now())),
        dateFinished: null,
        description: values.description,
        lastEditedDate: null,
        lastEditedEmail: '',
        lastEditedID: '',
        lastEditedName: '',
        masterKeyAllowed: assignment.masterKeyAllowed,
        priority: values.priority,
        realEstateID: assignment.realEstateID,
        rentalObjectID: assignment.rentalObjectID,
        sendSMS: assignedUser && assignedUser.allowSMS ? assignedUser.allowSMS : false
      }
      if (toBeInvoiced) {
        assignment.hoursToInvoice = 0
        assignment.materialUsed = ''
      }

      form.resetFields()
      this.props.firebase.addAssignment(assignment.assignmentID, assignmentToBeCreated)
      this.props.firebase.removeAssignmentFromInbox(assignment.assignmentID)
      this.props.handleCancel()
    })
  }

  saveFormRef = formRef => {
    this.formRef = formRef
  }

  deleteAssignment = () => {
    let { assignment } = this.props
    this.props.firebase.removeAssignmentFromInbox(assignment.assignmentID)
    this.props.handleCancel()
  }

  render() {
    return (
      <div>
        <CollectionCreateForm
          wrappedComponentRef={this.saveFormRef}
          visible={this.props.visible}
          onCancel={this.props.handleCancel}
          onCreate={this.handleCreate}
          assignment={this.props.assignment}
          deleteAssignment={this.deleteAssignment}
        />
      </div>
    )
  }
}
