import * as React from 'react'

import { useTranslation, WithTranslation } from 'react-i18next'
import { RouteComponentProps } from 'react-router-dom'

import { ErrorBoundary, ErrorComponent } from 'components'

export interface ISentryPageProps extends WithTranslation, RouteComponentProps {}

export const SentryPage: React.FC<ISentryPageProps> = () => {
  const { t } = useTranslation()
  return (
    <div>
      <h1>Sentry</h1>
      <ErrorBoundary>
        <ErrorComponent />
        <p>{t('error:everything-works')}</p>
      </ErrorBoundary>
    </div>
  )
}
