import { styled } from 'theme'

export const StyledMessage = styled.div`
  background-color: white;
  width: 90%;
  margin: 0 auto;
  padding: 32px 16px;

  .ant-comment-inner {
    padding: 0px;
  }
`
