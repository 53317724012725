import * as React from 'react'

import { withTranslation } from 'react-i18next'

/* Import components here */
import { Icon, Menu, Badge } from 'antd'

/* Import interfaces here */
import { INavigationProps } from './Navigation.interfaces'

/* Import utilities here */
import { NavLink } from 'react-router-dom'
import { AuthUserContext } from '../../contexts'
// import { SignOutButton } from '../../components'
import * as ROUTES from '../../constants/routes'
import * as ROLES from '../../constants/roles'
import { observer } from 'mobx-react'
import { rootStore } from 'stores'
const { inboxStore } = rootStore

const InboxBadge = observer(() => <Badge dot={inboxStore.numberOfAssignments > 0 ? true : false} />)

export const Navigation: React.FC<INavigationProps> = ({ t, children, ...props }) => {
  return (
    <AuthUserContext.Consumer>
      {(authUser: any) =>
        authUser && authUser.roles.ADMIN ? (
          <AntNavigationAuth authUser={authUser} />
        ) : (
          <NavigationNonAuth />
        )
      }
    </AuthUserContext.Consumer>
  )
}

const NavigationNonAuth = () => (
  <Menu theme="dark" mode="inline">
    <Menu.Item key="1">
      <NavLink to={ROUTES.LANDING} activeClassName="selectedNavLink">
        <Icon type="dashboard" />
        <span className="nav-text">Landing</span>
      </NavLink>
    </Menu.Item>
    <Menu.Item key="2">
      <NavLink to={ROUTES.SIGN_IN} activeClassName="selectedNavLink">
        <Icon type="home" />
        <span className="nav-text">Sign In</span>
      </NavLink>
    </Menu.Item>
  </Menu>
)

const AntNavigationAuth = ({ authUser }) => (
  <Menu theme="dark" mode="inline">
    <Menu.Item key="1">
      <NavLink to={ROUTES.DASHBOARD} activeClassName="selectedNavLink">
        <Icon type="dashboard" />
        <span className="nav-text">Dashboard</span>
      </NavLink>
    </Menu.Item>
    <Menu.Item key="8">
      <NavLink to={ROUTES.INBOX} activeClassName="selectedNavLink">
        <Icon type="inbox" />
        <span className="nav-text">Inkorg</span>
        <span style={{ float: 'right' }}>
          <InboxBadge />
        </span>
      </NavLink>
    </Menu.Item>
    <Menu.Item key="2">
      <NavLink to={ROUTES.REAL_ESTATES} activeClassName="selectedNavLink">
        <Icon type="home" />
        <span className="nav-text">Fastigheter</span>
      </NavLink>
    </Menu.Item>
    <Menu.Item key="3">
      <NavLink to={ROUTES.ASSIGNMENTS} activeClassName="selectedNavLink">
        <Icon type="profile" />
        <span className="nav-text">Ärenden</span>
      </NavLink>
    </Menu.Item>
    <Menu.Item key="4">
      <NavLink to={ROUTES.INVOICES} activeClassName="selectedNavLink">
        <Icon type="audit" />
        <span className="nav-text">Faktureringar</span>
      </NavLink>
    </Menu.Item>
    <Menu.Item key="5">
      <NavLink exact to={ROUTES.HOME} activeClassName="selectedNavLink">
        <Icon type="read" />
        <span className="nav-text">Nyheter & Hjälp</span>
      </NavLink>
    </Menu.Item>
    {!!authUser.roles[ROLES.ADMIN] && (
      <Menu.SubMenu
        key="sub1"
        title={
          <span>
            <Icon type="lock" />
            <span>Admin</span>
          </span>
        }
      >
        <Menu.Item key="6">
          <NavLink to={ROUTES.ADMIN_USERS} activeClassName="selectedNavLink">
            <Icon type="team" />
            <span className="nav-text">Användare</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="7">
          <NavLink to={ROUTES.SETTINGS} activeClassName="selectedNavLink">
            <Icon type="setting" />
            <span className="nav-text">Inställningar</span>
          </NavLink>
        </Menu.Item>
      </Menu.SubMenu>
    )}
  </Menu>
)

export default withTranslation()(Navigation)
