import * as React from 'react'

import { withTranslation } from 'react-i18next'

/* Import components here */
import { StyledRealEstateSettingsNav } from './RealEstateSettingsNav.styles'

/* Import interfaces here */
import {
  IRealEstateSettingsNavProps,
  IRealEstateSettingsNavState
} from './RealEstateSettingsNav.interfaces'

/* Import utilities here */
import { Icon, Layout, Menu } from 'antd'
import { Switch, Route, Link } from 'react-router-dom'
import * as ROUTES from '../../../../constants/routes'
import { RealEstateList, RealEstateSettingsHelp, RealEstateForm } from 'components'
const { Content, Sider } = Layout

export class RealEstateSettingsNav extends React.Component<
  IRealEstateSettingsNavProps,
  IRealEstateSettingsNavState
> {
  public render() {
    const { t, children, ...props } = this.props

    return (
      <StyledRealEstateSettingsNav {...props}>
        <Layout>
          <Layout>
            <Sider width={200} style={{ background: '#fff' }}>
              <Menu defaultSelectedKeys={['1']} mode="inline" style={{ height: '100%' }}>
                <Menu.Item key="1">
                  <Link to={ROUTES.SETTINGS_REAL_ESTATES}>
                    <Icon type="bars" />
                    <span>Fastigheter</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="2">
                  <Link to={ROUTES.SETTINGS_REAL_ESTATES_CREATE}>
                    <Icon type="plus-circle" />
                    <span>Ny Fastighet</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="3">
                  <Link to={ROUTES.SETTINGS_REAL_ESTATES_HELP}>
                    <Icon type="question-circle" />
                    <span>Hjälp</span>
                  </Link>
                </Menu.Item>
              </Menu>
            </Sider>
            <Content style={{ padding: '0 24px', minHeight: 280, backgroundColor: 'white' }}>
              <Switch>
                <Route exact path={ROUTES.SETTINGS_REAL_ESTATES} component={RealEstateList} />
                <Route
                  exact
                  path={ROUTES.SETTINGS_REAL_ESTATES_CREATE}
                  component={RealEstateForm}
                />
                <Route
                  exact
                  path={ROUTES.SETTINGS_REAL_ESTATES_HELP}
                  component={RealEstateSettingsHelp}
                />
              </Switch>
            </Content>
          </Layout>
        </Layout>
      </StyledRealEstateSettingsNav>
    )
  }
}

export default withTranslation()(RealEstateSettingsNav)
