import { styled } from 'theme'

export const StyledUserCreateForm = styled.div`
  background-color: white;
  padding: 5rem 1rem;
  max-width: 1000px;
  margin: 0 auto;
`

export const StyledTitle = styled.div`
  padding: 1rem;
  font-size: 18px;
  font-weight: bold;
`
