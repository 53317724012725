import * as React from 'react'

import { withTranslation } from 'react-i18next'

/* Import components here */
import {
  StyledDefaultSettingsBoundary,
  FlexContainer,
  FlexItem
} from './DefaultSettingsBoundary.styles'
//import { PriorityList, AreaList, CategoryList } from 'components'
import { PriorityList, AreaList } from 'components'

/* Import interfaces here */
import { IDefaultSettingsBoundaryProps } from './DefaultSettingsBoundary.interfaces'

/* Import utilities here */

export const DefaultSettingsBoundary: React.FC<IDefaultSettingsBoundaryProps> = ({
  t,
  children,
  ...props
}) => {
  return (
    <StyledDefaultSettingsBoundary {...props}>
      <FlexContainer>
        <FlexItem>
          <PriorityList />
        </FlexItem>
        {/* <FlexItem>
          <CategoryList />
        </FlexItem> */}
        <FlexItem>
          <AreaList />
        </FlexItem>
      </FlexContainer>
    </StyledDefaultSettingsBoundary>
  )
}

export default withTranslation()(DefaultSettingsBoundary)
