import * as React from 'react'

import { withTranslation } from 'react-i18next'

/* Import components here */
import {
  StyledImportUpload,
  BoldSpan,
  ProgressWrapper,
  UploadWrapper,
  StyledContent
} from './ImportUpload.styles'
import { Alert, Button, Icon, Modal, Progress, Typography, Upload, Menu } from 'antd'
import { ImportResult } from '../../..'

/* Import interfaces here */
import { IImportUploadProps, IImportUploadState } from './ImportUpload.interfaces'
import { compose } from 'recompose'
import { withFirebase } from '../../../../firebase'
import axios from 'axios'
import { Layout } from 'antd'

const { Sider } = Layout
/* Import utilities here */
const { confirm } = Modal
const { Title, Paragraph, Text } = Typography

export class ImportUpload extends React.Component<IImportUploadProps, IImportUploadState> {
  public state = {
    loading: false,
    progress: 0,
    fileList: [],
    importResult: null,
    importType: 'tenantImport'
  }

  public handleChange = info => {
    // To only show 1 selected file in history
    let fileList = [...info.fileList]
    fileList = fileList.slice(-1)
    this.setState({ fileList })
  }

  public beforeUpload = file => {
    // console.log("Before",file, fileList)
    const isSpreadsheet =
      file.type === 'application/vnd.ms-excel' ||
      file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    if (!isSpreadsheet) {
      file.status = 'error'
      file.response = 'Filen måste vara .xlsx eller .xls'
    }
    return isSpreadsheet
  }

  public customUpload = async ({ onError, onSuccess, file }) => {
    const storage = this.props.firebase.storage
    const fileExtension =
      file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        ? 'xlsx'
        : 'xls'

    const metadata = {
      contentType: fileExtension
    }
    const storageRef = await storage.ref()
    const fileId = this.props.firebase.getUniqueID()

    const fileLocation = storageRef.child(`Excel Files/${fileId}.${fileExtension}`)
    try {
      const uploadTask = fileLocation.put(file, metadata)
      uploadTask.on(
        this.props.firebase.app.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
        (snapshot: any) => {
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress = Number(
            ((snapshot.bytesTransferred / snapshot.totalBytes) * 100).toFixed(2)
          )
          console.log('Upload is ' + progress + '% done')
          this.setState({ progress })
          switch (snapshot.state) {
            case this.props.firebase.app.storage.TaskState.PAUSED: // or 'paused'
              console.log('Upload is paused')
              break
            case this.props.firebase.app.storage.TaskState.RUNNING: // or 'running'
              console.log('Upload is running')
              break
          }
        },
        (error: any) => {
          // A full list of error codes is available at
          // https://firebase.google.com/docs/storage/web/handle-errors
          switch (error.code) {
            case 'storage/unauthorized':
              // User doesn't have permission to access the object
              onError(error)
              break

            case 'storage/canceled':
              // User canceled the upload
              onError(error)
              break
            case 'storage/unknown':
              // Unknown error occurred, inspect error.serverResponse
              onError(error)
              break
          }
        },
        () => {
          // Upload completed successfully, now we can get the download URL
          console.log(file.name)
          console.log('REF', uploadTask.snapshot.ref.name)
          console.log('CustomerID', this.props.firebase.customerID)
          const fileName = uploadTask.snapshot.ref.name
          const customerID = this.props.firebase.customerID
          this.postURL(fileName, customerID, fileLocation)
          onSuccess(null, uploadTask)
          // FIXME: ta bort fil efter upload
          // this.showConfirm(file.name, uploadTask.snapshot.ref.fullPath, this.props.nextStep)
          // uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
          //   console.log('File available at', downloadURL)
          //   // this.setModalVisible(true)
          //   onSuccess(null, uploadTask)
          // })
        }
      )
    } catch (e) {
      onError(e)
    }
  }

  postURL = (fileName, customerID, fileRef) => {
    this.setState({ loading: true })
    const user = this.props.firebase.auth.currentUser
    const importActionURL = this.state.importType
    if (user) {
      user
        .getIdToken(true)
        .then(idToken => {
          axios({
            method: 'post',
            url: `${process.env.REACT_APP_FUNCTIONS_BASE_URL}/${importActionURL}`,
            headers: { Authorization: idToken, 'Content-Type': 'application/json' },
            data: {
              fileName,
              customerID
            }
          })
            .then(res => {
              // console.log(res)
              this.setState({ loading: false, importResult: res.data })
              fileRef
                .delete()
                .then(() => console.log('Deleted File'))
                .catch(error => console.log('Could not delete file', error))
            })
            .catch(error => {
              fileRef
                .delete()
                .then(() => console.log('Deleted File'))
                .catch(error => console.log('Could not delete file', error))
              console.log('Error', error)
              console.log('Error', error.response)
              if (error && error.response) {
                this.setState({ loading: false, importResult: error.response.data })
              } else {
                this.setState({
                  loading: false,
                  importResult: {
                    status: 'error',
                    errorMessage:
                      'Nätverksfel, kontrollera internetkoppling. Eller så beror felet på att servern inte kan nås.'
                  }
                })
              }
            })
        })
        .catch(error => {
          // Error Getting ID Token
        })
    } else {
      console.log('Error, No User')
    }
  }

  public showConfirm = (fileName, storagePath, callback) => {
    confirm({
      title: 'Bekräfta att rätt fil är vald',
      content: (
        <span>
          Vill du fortsätta importen av <BoldSpan>{fileName}</BoldSpan>?
        </span>
      ),
      cancelText: 'Avbryt',
      okText: 'Ja',
      onOk() {
        callback()
      },
      onCancel() {}
    })
  }

  public toggleLoading = () => {
    this.setState({ loading: !this.state.loading })
  }

  public goBack = () => {
    this.setState({ importResult: null, loading: false })
  }

  public setSuccess = () => {
    this.setState({
      loading: false,
      importResult: {
        newlyCreatedRentalObjects: [
          {
            id: 'liphtUuybXOCUcj8HAbo',
            address: {
              city: 'Sölvesborg',
              street: 'Bokvägen 11/Landsvägselyckagatan 20',
              realEstateName: 'Björken 1 o 5',
              zipCode: '29433',
              rentalObjectNumber: '13.11'
            },
            externalRentalObjectID: null,
            realEstateID: 'WfKmLVX1KajYN6YIwErD',
            rentalObjectID: 'liphtUuybXOCUcj8HAbo',
            category: '4 Rum och kök',
            type: 'Hyresrätt'
          },
          {
            id: 'liphtUuybXOCUcj8HAbo',
            address: {
              city: 'Sölvesborg',
              street: 'Bokvägen 11/Landsvägselyckagatan 20',
              realEstateName: 'Björken 1 o 5',
              zipCode: '29433',
              rentalObjectNumber: '13.11'
            },
            externalRentalObjectID: null,
            realEstateID: 'WfKmLVX1KajYN6YIwErD',
            rentalObjectID: 'liphtUuybXOCUcj8HAbo',
            category: '4 Rum och kök',
            type: 'Hyresrätt'
          },
          {
            id: 'liphtUuybXOCUcj8HAbo',
            address: {
              city: 'Sölvesborg',
              street: 'Bokvägen 11/Landsvägselyckagatan 20',
              realEstateName: 'Björken 1 o 5',
              zipCode: '29433',
              rentalObjectNumber: '13.11'
            },
            externalRentalObjectID: null,
            realEstateID: 'WfKmLVX1KajYN6YIwErD',
            rentalObjectID: 'liphtUuybXOCUcj8HAbo',
            category: '4 Rum och kök',
            type: 'Hyresrätt'
          }
        ],
        newlyCreatedRealEstates: [
          {
            address: {
              realEstateName: 'Kevin 12',
              city: '',
              zipCode: '',
              street: ''
            },
            isOwned: true,
            numberOfRentalObjects: 0,
            pictureURL: '',
            realEstateID: 'realraelrealID'
          },
          {
            address: {
              realEstateName: 'Follo 42',
              city: '',
              zipCode: '',
              street: ''
            },
            isOwned: true,
            numberOfRentalObjects: 0,
            pictureURL: '',
            realEstateID: 'realraelr23ealID'
          }
        ],
        // updatedTenants: [
        //   {
        //     dataBeforeUpdate: {
        //       address: {
        //         city: 'Sölvesborg',
        //         street: 'Bokvägen 11/Landsvägselyckagatan 20',
        //         realEstateName: 'Björken 1 o 5',
        //         zipCode: '29433',
        //         rentalObjectNumber: '13.11'
        //       },
        //       externalRentalObjectID: null,
        //       realEstateID: 'WfKmLVX1KajYN6YIwErD',
        //       rentalObjectID: 'liphtUuybXOCUcj8HAbo',
        //       category: '4 Rum och kök',
        //       type: 'Hyresrätt',
        //       tenantHistory: [
        //         {
        //           moveInDate: {
        //             _seconds: 1372636800,
        //             _nanoseconds: 0
        //           },
        //           moveOutDate: null,
        //           phoneNumbers: ['+46708509127'],
        //           firstName: 'Anastasia',
        //           lastName: 'Follo'
        //         }
        //       ]
        //     },
        //     newHistory: [
        //       {
        //         moveInDate: {
        //           _seconds: 1372636800,
        //           _nanoseconds: 0
        //         },
        //         moveOutDate: null,
        //         phoneNumbers: ['+46708509127'],
        //         firstName: 'Kevin',
        //         lastName: 'Follo'
        //       }
        //     ]
        //   },
        //   {
        //     dataBeforeUpdate: {
        //       address: {
        //         city: 'Sölvesborg',
        //         street: 'Bokvägen 11/Landsvägselyckagatan 20',
        //         realEstateName: 'Björken 1 o 5',
        //         zipCode: '29433',
        //         rentalObjectNumber: '13.11'
        //       },
        //       externalRentalObjectID: null,
        //       realEstateID: 'WfKmLVX1KajYN6YIwErD',
        //       rentalObjectID: 'liphtUuybXOCUcj8HAbo',
        //       category: '4 Rum och kök',
        //       type: 'Hyresrätt'
        //     },
        //     newHistory: [
        //       {
        //         moveInDate: {
        //           _seconds: 1372636800,
        //           _nanoseconds: 0
        //         },
        //         moveOutDate: null,
        //         phoneNumbers: ['+46708509127'],
        //         firstName: 'Kevin',
        //         lastName: 'Follo'
        //       },
        //       {
        //         moveInDate: {
        //           _seconds: 1372636800,
        //           _nanoseconds: 0
        //         },
        //         moveOutDate: null,
        //         phoneNumbers: ['+46708509127'],
        //         firstName: 'Anastasia',
        //         lastName: 'Follo'
        //       }
        //     ]
        //   },
        //   {
        //     dataBeforeUpdate: {
        //       address: {
        //         city: 'Sölvesborg',
        //         street: 'Bokvägen 11/Landsvägselyckagatan 20',
        //         realEstateName: 'Björken 1 o 5',
        //         zipCode: '29433',
        //         rentalObjectNumber: '13.11'
        //       },
        //       externalRentalObjectID: null,
        //       realEstateID: 'WfKmLVX1KajYN6YIwErD',
        //       rentalObjectID: 'liphtUuybXOCUcj8HAbo',
        //       category: '4 Rum och kök',
        //       type: 'Hyresrätt',
        //       tenantHistory: [
        //         {
        //           moveInDate: {
        //             _seconds: 1372636800,
        //             _nanoseconds: 0
        //           },
        //           moveOutDate: null,
        //           phoneNumbers: ['+46708509127'],
        //           firstName: 'Kevin',
        //           lastName: 'Follo'
        //         },
        //         {
        //           moveInDate: {
        //             _seconds: 1372636800,
        //             _nanoseconds: 0
        //           },
        //           moveOutDate: null,
        //           phoneNumbers: ['+46708509127'],
        //           firstName: 'Anastasia',
        //           lastName: 'Follo'
        //         }
        //       ]
        //     },
        //     newHistory: [
        //       {
        //         moveInDate: {
        //           _seconds: 1372636800,
        //           _nanoseconds: 0
        //         },
        //         moveOutDate: null,
        //         phoneNumbers: ['+46708509127'],
        //         firstName: 'Kevin',
        //         lastName: 'Follo'
        //       },
        //       {
        //         moveInDate: {
        //           _seconds: 1372636800,
        //           _nanoseconds: 0
        //         },
        //         moveOutDate: null,
        //         phoneNumbers: ['+46708509127'],
        //         firstName: 'Anastasia',
        //         lastName: 'Follo'
        //       }
        //     ]
        //   }
        // ],
        status: 'success'
      }
    })
    console.log(this.state.importResult)
  }

  public setError = () => {
    this.setState({
      loading: false,
      importResult: {
        status: 'error',
        errorMessage: 'Something went wrong'
      }
    })
  }

  public handleImportTypeChange = e => {
    this.setState({ importType: e.key })
  }

  public render() {
    const { t, children, ...props } = this.props
    const { progress, fileList, loading, importResult, importType } = this.state

    return (
      <StyledImportUpload {...props}>
        <Layout>
          <Sider
            width={256}
            style={{
              backgroundColor: '#FAFAFA',
              borderRight: '1px solid #e8e8e8'
            }}
          >
            <Title style={{ textAlign: 'center', paddingTop: 20 }} level={4}>
              Välj import typ
            </Title>
            <Menu
              onClick={this.handleImportTypeChange}
              style={{ width: 256, backgroundColor: '#FAFAFA' }}
              defaultSelectedKeys={['tenantImport']}
              defaultOpenKeys={['sub1']}
              mode="inline"
            >
              <Menu.Item key="tenantImport">
                <span>
                  <Icon type="team" />
                  <span>Hyresgäster</span>
                </span>
              </Menu.Item>
              <Menu.Item key="realEstateImport">
                <span>
                  <Icon type="home" />
                  <span>Fastigheter & Lägenheter</span>
                </span>
              </Menu.Item>
              <Menu.Item key="help">
                <span>
                  <Icon type="question-circle" />
                  <span>Hjälp</span>
                </span>
              </Menu.Item>
            </Menu>
            {/* <Button onClick={this.toggleLoading}>Toggle Loading</Button>
            <Button onClick={this.setSuccess}>Set Success</Button>
            <Button onClick={this.setError}>Set Error</Button> */}
          </Sider>
          <StyledContent>
            {(loading || importResult) && importType !== 'help' && (
              <ImportResult loading={loading} result={importResult} goBack={this.goBack} />
            )}

            {importType === 'help' && (
              <UploadWrapper>
                <Typography>
                  <Title level={2}>Import från Excel</Title>
                  <Paragraph>
                    För att kunna uppdatera alla hyresgäster regelbundet och även skapa upp nya
                    fastigheter och lägenheter när det behövs.
                  </Paragraph>
                  <Paragraph>
                    Importen använder excel-filer uttagna från REAL i ett specifikt format.
                    Exempelfiler finns längre ner, det här är för att alla information som behövs
                    ska finnas med.
                    <Text strong>
                      {` `}Viss information på nyskapade fastigheter kommer behöva kompliteras
                    </Text>
                    .
                  </Paragraph>
                  <Title level={3}>Riktlinjer och Resurser</Title>
                  <Paragraph>
                    Om nya fastigheter finns så börja alltid med att importera dem först. Importera
                    sedan hyrsesgästerna för att fylla inneboende i de nyskapde lägenheterna och
                    uppdatera existerande hyresgäster. Nyskapade Fastigheter kommer behöva
                    kompliteras med Adress, Zipkod och Stad. Bild är valfri.
                  </Paragraph>
                  <Paragraph>
                    <Text type="danger">Viktigt: </Text>Tänk på att all data i Excel-filen kommer
                    att läsas in. Så alla fastigheter kommer skapas, även om de är sålda. För att
                    fixa det här. Gå sedan in på Inställningar och Fastigheter. Där kan du söka fram
                    den nyskapade fastigheten och markera den som såld. Då kommer den inte att
                    visas.
                  </Paragraph>

                  <Paragraph>
                    <ul>
                      <li>
                        <a href="https://firebasestorage.googleapis.com/v0/b/cdab-fastighetsdagboken-9ce1a.appspot.com/o/utils%2FtenantsExampleFile.xlsx?alt=media&token=27fd0fc5-7184-49b4-b184-d3c980378c01">
                          Exempel Fil - Hyresgäster
                        </a>
                      </li>
                      <li>
                        <a href="https://firebasestorage.googleapis.com/v0/b/cdab-fastighetsdagboken-9ce1a.appspot.com/o/utils%2FrentalObjectsExampleFile.xlsx?alt=media&token=f0366287-3769-4a6b-85c3-dab9561c08be">
                          Exempel Fil - Fastigheter & Lägenheter
                        </a>
                      </li>
                    </ul>
                  </Paragraph>
                </Typography>
              </UploadWrapper>
            )}
            {!loading && !importResult && importType !== 'help' && (
              <>
                <UploadWrapper>
                  <Title style={{ textAlign: 'center' }} level={2}>
                    {importType === 'tenantImport'
                      ? 'Uppdatering av hyresgäster '
                      : ' Fastigheter & Lägenheter '}
                    <Icon type={importType === 'tenantImport' ? 'team' : 'home'} />
                  </Title>
                  <Paragraph>
                    <Alert
                      message="Innan du importerar"
                      description={
                        <ul>
                          <li>Kontrollera att rätt fil och senaste version av den väljs</li>
                          <li>Tänk på att importera nya fastigheter innan hyresgäster</li>
                        </ul>
                      }
                      type="info"
                      showIcon={true}
                    />
                  </Paragraph>

                  <Paragraph>
                    <Alert
                      message="Allt i filen kommer att läsas in"
                      //description="This is a warning notice about copywriting."
                      type="warning"
                      showIcon={true}
                    />
                  </Paragraph>

                  <Upload
                    name="importUpload"
                    beforeUpload={this.beforeUpload}
                    onChange={this.handleChange}
                    customRequest={this.customUpload}
                    accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    fileList={fileList}
                  >
                    <Button size="large" style={{ width: '500px', height: '150px' }}>
                      <Icon type="upload" /> Välj Fil
                    </Button>
                  </Upload>
                </UploadWrapper>
                <ProgressWrapper>
                  <Progress style={{ marginTop: '10px' }} percent={progress} />
                </ProgressWrapper>
              </>
            )}
          </StyledContent>
        </Layout>
      </StyledImportUpload>
    )
  }
}

export default compose(withTranslation(), withFirebase)(ImportUpload)
