import * as React from 'react'

import { withTranslation } from 'react-i18next'

/* Import components here */
import { StyledImportSteps, StepsContent, StepsWrapper } from './ImportSteps.styles'
import { PageHeader } from 'antd'
import { ImportUpload } from '../../..'

/* Import interfaces here */
import { IImportStepsProps } from './ImportSteps.interfaces'

/* Import utilities here */

export const ImportSteps: React.FC<IImportStepsProps> = ({ t, children, ...props }) => {
  return (
    <StyledImportSteps {...props}>
      <PageHeader title="Import från Excel" subTitle="För uppdatering av databas">
        <StepsWrapper>
          <StepsContent>
            <ImportUpload />
          </StepsContent>
        </StepsWrapper>
      </PageHeader>
    </StyledImportSteps>
  )
}

export default withTranslation()(ImportSteps)
