import React from 'react'

import { AuthUserContext } from '../'
import { withFirebase } from '../../firebase'
import {
  IWithEmailVerificationProps,
  IWithEmailVerificationState
} from './WithEmailVerification.interfaces'

import { Result, Icon, Button } from 'antd'

const needsEmailVerification = authUser =>
  authUser &&
  !authUser.emailVerified &&
  authUser.providerData.map(provider => provider.providerId).includes('password')

export const withEmailVerification = Component => {
  class WithEmailVerification extends React.Component<
    IWithEmailVerificationProps,
    IWithEmailVerificationState
  > {
    constructor(props) {
      super(props)

      this.state = { isSent: false }
    }

    onSendEmailVerification = () => {
      this.props.firebase.doSendEmailVerification().then(() => this.setState({ isSent: true }))
    }

    render() {
      const { isSent } = this.state
      return (
        <AuthUserContext.Consumer>
          {authUser =>
            needsEmailVerification(authUser) ? (
              <div style={{ backgroundColor: 'white' }}>
                <Result
                  icon={
                    <Icon
                      type="mail"
                      theme="twoTone"
                      twoToneColor={isSent ? '#52C423' : '#FAAD27'}
                    />
                  }
                  title={isSent ? 'Ett nytt mail har skickats till dig!' : 'Email EJ verifierad!'}
                  subTitle="Verifiera din email: Kolla igenom dina mail (Även Spam mappen) efter ett bekräftelse mail."
                  extra={
                    <Button
                      type="primary"
                      onClick={this.onSendEmailVerification}
                      disabled={this.state.isSent}
                    >
                      Skicka nytt mail
                    </Button>
                  }
                />
              </div>
            ) : (
              <Component {...this.props} />
            )
          }
        </AuthUserContext.Consumer>
      )
    }
  }

  return withFirebase(WithEmailVerification)
}
