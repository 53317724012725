import * as React from 'react'
import { Modal, Button, Select } from 'antd'
const { Option, OptGroup } = Select

export default class RoleModal extends React.Component {
  state = { visible: false }

  componentDidMount() {
    const { user } = this.props
    const value =
      !!user.roles && user.roles.JANITOR && user.roles.ADMIN
        ? ['admin', 'janitor']
        : !!user.roles && user.roles.ADMIN
        ? ['admin']
        : !!user.roles && user.roles.JANITOR
        ? ['janitor']
        : []
    this.setState({
      value,
      userRole: value
    })
  }

  showModal = () => {
    this.setState({
      visible: true
    })
  }

  handleOk = e => {
    const customClaims = {}
    this.state.value.forEach(role => {
      customClaims[role] = true
    })
    this.props.setPermission(customClaims)
    this.setState({
      visible: false
    })
  }

  handleCancel = e => {
    this.setState({
      visible: false
    })
  }

  handleChange = value => {
    console.log(`selected ${value}`)
    this.setState({
      value
    })
  }

  render() {
    const { value, userRole } = this.state
    const { isOwnAccount } = this.props
    return (
      <span>
        <Button onClick={this.showModal} disabled={isOwnAccount}>
          Välj Kontotyp
        </Button>
        <Modal
          width={400}
          title="Tilldela Roll"
          closable={false}
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          okText="Tilldela"
          cancelText="Avbryt"
          okButtonProps={{ disabled: value === userRole ? true : false }}
        >
          <Select
            mode="multiple"
            defaultValue={value}
            style={{ width: 352 }}
            onChange={this.handleChange}
          >
            <OptGroup label="Appen">
              <Option value="janitor">Fastighetsskötare</Option>
            </OptGroup>
            <OptGroup label="Hemsidan">
              <Option value="admin">Kontorspersonal</Option>
            </OptGroup>
          </Select>
        </Modal>
      </span>
    )
  }
}
