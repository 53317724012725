import * as React from 'react'

import { withTranslation } from 'react-i18next'

/* Import components here */
import {
  StyledRentalObjectAssignmentHistoryItemContent,
  Content,
  Extra,
  Wrap,
  PostImgGrid,
  EditorWrapper
} from './RentalObjectAssignmentHistoryItemContent.styles'

/* Import interfaces here */
import {
  IRentalObjectAssignmentHistoryItemContentProps,
  IRentalObjectAssignmentHistoryItemContentState
} from './RentalObjectAssignmentHistoryItemContent.interfaces'
import {
  Divider,
  Typography,
  Spin,
  Avatar,
  Comment,
  Icon,
  Upload,
  Input,
  Button,
  Form,
  Tag,
  Badge,
  Popconfirm
} from 'antd'

/* Import utilities here */
import { AssignUserButton } from '../../../utils/tables/AssignmentInfoList'
import { Timestamp } from '../../../firebase'
import Lightbox from 'react-image-lightbox'
import { compose } from 'recompose'
import { withFirebase } from '../../../firebase'
import { AuthUserContext } from 'contexts'
import { rootStore } from 'stores'
import { observer } from 'mobx-react'
const { Text, Title, Paragraph } = Typography
const ButtonGroup = Button.Group
const TextArea = Input.TextArea

function convertTime(num) {
  var hours = Math.floor(num)
  var minutes = Math.round((num % 1) * 60)
  return (
    <Text>
      {hours} timmar och {minutes} minuter
    </Text>
  )
}

const IconText = ({ type, text }) => (
  <Text strong>
    <Icon type={type} style={{ marginRight: 8 }} />
    {text}
  </Text>
)

const Editor = ({ onChange, onSubmit, submitting, uploadProps, value, fileArray }) => (
  <AuthUserContext.Consumer>
    {(authUser: any) => (
      <EditorWrapper>
        <Form.Item>
          <TextArea
            style={{ resize: 'none' }}
            autoSize={{ minRows: 2 }}
            onChange={onChange}
            value={value}
          />
        </Form.Item>
        <Form.Item
          style={{
            border: '1px dashed #e9e9e9',
            borderRadius: '6px',
            backgroundColor: '#fafafa'
          }}
        >
          <ButtonGroup
            style={{
              float: 'right'
            }}
          >
            <Button
              htmlType="submit"
              loading={submitting}
              type="primary"
              onClick={() => onSubmit(authUser)}
              disabled={!value && fileArray.length < 1}
            >
              Kommentera
            </Button>
            <Upload {...uploadProps}>
              <Button>
                <Icon type="upload" /> Välj Fil
              </Button>
            </Upload>
          </ButtonGroup>
        </Form.Item>
      </EditorWrapper>
    )}
  </AuthUserContext.Consumer>
)

const MarkAsDoneButton = ({ firebase, assignmentID, updateTableAfterChanges, ...restProps }) => (
  <AuthUserContext.Consumer>
    {(authUser: any) => (
      <Popconfirm
        placement="bottom"
        title={'Är du säker att du vill markera ärendet som färdigt?'}
        onConfirm={() =>
          firebase.updateAssignment(assignmentID, {
            dateFinished: firebase.timestamp.fromDate(new Date(Date.now())),
            finishedByEmail: authUser.email,
            finishedByID: authUser.userID,
            finishedByName: authUser.firstName + ' ' + authUser.lastName
          })(doc => updateTableAfterChanges(doc))
        }
        okText="Ja"
        cancelText="Nej"
      >
        <Button
          {...restProps}
          // tslint:disable-next-line: jsx-no-lambda
        >
          Markera som färdig
        </Button>
      </Popconfirm>
    )}
  </AuthUserContext.Consumer>
)
@observer
export class RentalObjectAssignmentHistoryItemContent extends React.Component<
  IRentalObjectAssignmentHistoryItemContentProps,
  IRentalObjectAssignmentHistoryItemContentState
> {
  constructor(props) {
    super(props)
    this.state = {
      fileArray: [],
      isOpen: false,
      pictureURL: '',
      submitting: false,
      inputValue: ''
    }
  }

  public updateTableAfterChanges = changedAssignment => {
    console.log(changedAssignment)
  }

  public onChange = e => {
    this.setState({
      inputValue: e.target.value
    })
  }

  public setPictureState = pictureURL => {
    this.setState({
      isOpen: true,
      pictureURL
    })
  }

  public getExtension(filename) {
    const parts = filename.split('/')
    return parts[parts.length - 1]
  }

  public handleSubmit = (authUser: any) => {
    if (!this.state.inputValue && this.state.fileArray === []) {
      return
    }

    this.setState({
      submitting: true
    })
    const assignment = this.props.assignment

    setTimeout(() => {
      const postRef = this.props.firebase.postRef()
      postRef.set({
        realEstateID: assignment.realEstateID,
        assignmentID: assignment.assignmentID,
        authorEmail: authUser.email,
        authorID: authUser.userID,
        authorName: authUser.firstName + ' ' + authUser.lastName,
        //authorProfilePicture: authUser.profilePicture,
        comment: this.state.inputValue,
        dateCreated: this.props.firebase.timestamp.fromDate(new Date(Date.now())),
        postID: postRef.id,
        rentalObjectID: assignment.rentalObjectID,
        urls: []
      })
      if (this.state.fileArray.length > 0) {
        this.state.fileArray.forEach((fileInArray: any) => {
          const file = fileInArray
          const id = this.props.firebase.getUniqueID()
          const ext = this.getExtension(file.type)
          const storageRef = this.props.firebase.rentalObjectStorageRef(
            assignment.rentalObjectID + '/' + (id + '.' + ext)
          )

          const metadata = {
            contentType: file.type
          }

          // Upload file and metadata to the object 'images/mountains.jpg'
          const uploadTask = storageRef.put(file, metadata)
          // Listen for state changes, errors, and completion of the upload.
          uploadTask.on(
            this.props.firebase.app.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
            (snapshot: any) => {
              // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
              const progress = Number(
                ((snapshot.bytesTransferred / snapshot.totalBytes) * 100).toFixed(2)
              )
              console.log('Upload is ' + progress + '% done')
              //that.setState({ progress })
              switch (snapshot.state) {
                case this.props.firebase.app.storage.TaskState.PAUSED: // or 'paused'
                  console.log('Upload is paused')
                  break
                case this.props.firebase.app.storage.TaskState.RUNNING: // or 'running'
                  console.log('Upload is running')
                  break
              }
            },
            (error: any) => {
              // A full list of error codes is available at
              // https://firebase.google.com/docs/storage/web/handle-errors
              switch (error.code) {
                case 'storage/unauthorized':
                  // User doesn't have permission to access the object
                  break

                case 'storage/canceled':
                  // User canceled the upload
                  break
                case 'storage/unknown':
                  // Unknown error occurred, inspect error.serverResponse
                  break
              }
            },
            () => {
              // Upload completed successfully, now we can get the download URL
              const fileArray: any[] = []
              uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
                // console.log('File available at', downloadURL)
                // console.log(id + '.' + ext)
                // console.log(file.type)
                const fileName = id + '.' + ext
                postRef.update({
                  fileNames: this.props.firebase.fieldValue.arrayUnion(fileName),
                  urls: this.props.firebase.fieldValue.arrayUnion(downloadURL)
                })
                this.setState({
                  fileArray,
                  submitting: false,
                  inputValue: ''
                })
              })
            }
          )
        })
      } else {
        this.setState({
          submitting: false,
          inputValue: ''
        })
      }
    }, 1000)
  }

  public render() {
    const { assignment, comments, commentLoading, t, children, ...props } = this.props
    const { fileArray, isOpen, pictureURL, submitting, inputValue } = this.state
    const uploadProps = {
      multiple: false, // true
      onRemove: file => {
        this.setState(() => {
          const index = fileArray.indexOf(file)
          const newFileList = fileArray.slice()
          newFileList.splice(index, 1)
          return {
            fileArray: newFileList
          }
        })
      },
      beforeUpload: (file, fileList) => {
        this.setState({
          fileArray: [file]
        })
        return false
      },
      // To support Multiple
      // beforeUpload: (file, fileList) => {
      //   this.setState({
      //     fileArray: [...fileArray, ...fileList],
      //   })
      //   return false
      // },
      fileList: fileArray
    }
    const priority = assignment.priority

    return (
      <StyledRentalObjectAssignmentHistoryItemContent {...props}>
        <Wrap>
          <Content>
            <Title level={4}>
              <Avatar style={{ verticalAlign: 'middle', marginRight: '1rem' }}>
                {rootStore.settingStore.settings.priorities.findIndex(
                  priority => priority === assignment.priority
                ) + 1}
              </Avatar>
              <Text type="secondary">Prioritering: </Text>
              {priority && priority}
              {!assignment.dateFinished && (
                <Badge
                  style={{ marginLeft: '32px', backgroundColor: '#3f51b5' }}
                  count="Pågående Ärende"
                />
              )}
            </Title>
            <div style={{ marginTop: '2rem', maxWidth: '800px' }}>
              <Paragraph>
                {assignment.areas.map((area, index) => {
                  return (
                    <Tag style={{ padding: '4px 8px', fontSize: '16px' }} key={index}>
                      {area}
                    </Tag>
                  )
                })}
              </Paragraph>
              {assignment.contactPersons.length > 0 &&
              !assignment.address.rentalObjectNumber.includes('00.00') ? (
                <Paragraph>
                  <Text strong>Kontaktuppgifter</Text>
                  <ul>
                    {assignment.contactPersons.map((person, index) => {
                      return (
                        <li key={index}>
                          <Text strong>Namn: </Text>
                          {person.firstName} {person.lastName}{' '}
                          {person.phoneNumbers ? (
                            <Text>
                              <Text strong>Telefonnummer: </Text>
                              {person.phoneNumbers.map((phoneNumber, phoneIndex) => {
                                return (
                                  <span key={phoneNumber + index + phoneIndex}>{phoneNumber} </span>
                                )
                              })}
                            </Text>
                          ) : null}
                          {person.email ? (
                            <Text>
                              <Text strong>Email: </Text>
                              {person.email}
                            </Text>
                          ) : null}
                        </li>
                      )
                    })}
                  </ul>
                </Paragraph>
              ) : null}
              <Text strong>Beskrivning</Text>
              <Paragraph>{assignment.description}</Paragraph>
              <Paragraph>
                <Text strong>Noteringar: </Text>
                <ul>
                  <li>
                    {assignment.masterKeyAllowed ? (
                      <Text style={{ color: '#5E934F' }}>Huvudnyckel får användas</Text>
                    ) : (
                      <Text type="danger">Huvudnyckel får EJ användas</Text>
                    )}
                    {!assignment.dateFinished && (
                      <Button
                        ghost
                        style={{ float: 'right' }}
                        onClick={() =>
                          this.props.firebase.updateAssignment(assignment.uid, {
                            masterKeyAllowed: assignment.masterKeyAllowed === true ? false : true
                          })(doc => this.updateTableAfterChanges(doc))
                        }
                        type={assignment.masterKeyAllowed === true ? 'danger' : 'primary'}
                        size="small"
                      >
                        {assignment.masterKeyAllowed === true
                          ? 'Tillåt EJ Huvudnyckel'
                          : 'Tillåt Huvudnyckel'}
                      </Button>
                    )}
                  </li>
                  {assignment.billable ? (
                    <li>
                      <Text type="warning">Skall faktureras</Text>
                    </li>
                  ) : (
                    ''
                  )}
                </ul>
              </Paragraph>

              {assignment.billable && (
                <Paragraph>
                  <Divider />

                  <Text strong>Faktureringsuppgifter</Text>
                  <br />
                  <Text strong>Beskrivning: </Text>
                  <Paragraph>{assignment.invoiceDescription}</Paragraph>
                  <Text strong>Material: </Text>
                  {assignment.materialUsed}
                  <br />
                  <Text strong>Tid: </Text>
                  {convertTime(assignment.hoursToInvoice)}
                </Paragraph>
              )}
            </div>
          </Content>
          <Extra>
            {assignment.dateCreated && (
              <>
                <Text strong>Ärende Skapat: </Text>
                {assignment.dateCreated instanceof Timestamp ? (
                  <Text>
                    {assignment.dateCreated.toDate().toLocaleString()}
                    <br />
                    <Text type="secondary">
                      Skapare: {assignment.creatorName}, {assignment.creatorEmail}
                    </Text>
                  </Text>
                ) : (
                  <Spin style={{ marginLeft: 40 }} size="small" />
                )}
              </>
            )}
            {assignment.assignedDate && (
              <>
                <Divider />
                <Text strong>Tilldelat: </Text>
                {assignment.assignedDate instanceof Timestamp ? (
                  <Text>
                    {assignment.assignedDate.toDate().toLocaleString()}
                    <br />
                    <Text type="secondary">
                      Fastighetsskötare: {assignment.assignedUserName},{' '}
                      {assignment.assignedUserEmail}
                    </Text>
                  </Text>
                ) : (
                  <Spin style={{ marginLeft: 40 }} size="small" />
                )}
              </>
            )}
            {assignment.dateFinished && (
              <>
                <Divider />
                <Text strong>Ärende Avslutat: </Text>
                {assignment.dateFinished instanceof Timestamp ? (
                  <Text>
                    {assignment.dateFinished.toDate().toLocaleString()}
                    <br />
                    <Text type="secondary">
                      Avslutat av: {assignment.finishedByName}, {assignment.finishedByEmail}
                    </Text>
                  </Text>
                ) : (
                  <Spin style={{ marginLeft: 40 }} size="small" />
                )}
              </>
            )}
            <br />
            {!assignment.dateFinished && (
              <AssignUserButton
                style={{ marginTop: '24px' }}
                assignmentID={assignment.uid}
                firebase={this.props.firebase}
                updateTableAfterChanges={this.updateTableAfterChanges}
                block
              />
            )}
            {!assignment.dateFinished && (
              <MarkAsDoneButton
                style={{ marginTop: '12px' }}
                assignmentID={assignment.uid}
                firebase={this.props.firebase}
                updateTableAfterChanges={this.updateTableAfterChanges}
                block
                type="primary"
              />
            )}
          </Extra>
        </Wrap>
        <Divider />
        <IconText
          type={commentLoading ? 'loading' : 'message'}
          text={`${comments.length} kommentarer`}
        />
        <div style={{ padding: '0px 0px 0px 40px', maxWidth: '700px' }}>
          {commentLoading && (
            <div style={{ padding: '40px 300px' }}>
              <Spin size="large" />
            </div>
          )}
          {comments
            .sort((a, b) => a.dateCreated.toDate() - b.dateCreated.toDate())
            .map(post => (
              <Comment
                key={post.postID}
                author={post.authorName + ' ' + post.authorEmail}
                avatar={
                  post.authorProfilePicture != null ? (
                    <Avatar src={post.authorProfilePicture} />
                  ) : (
                    <Avatar icon="user" />
                  )
                }
                content={
                  <div>
                    <p>{post.comment}</p>
                    <PostImgGrid className="postImgGrid">
                      {post.urls != null
                        ? post.urls.map((pic, index) => {
                            return (
                              <img
                                key={index}
                                alt="Not Found"
                                onClick={() => this.setPictureState(pic)}
                                src={pic}
                              />
                            )
                          })
                        : null}
                    </PostImgGrid>
                  </div>
                }
                datetime={post.dateCreated.toDate().toLocaleString()}
              />
            ))}
          <Comment
            avatar={<Avatar icon="user" />}
            content={
              <Editor
                onChange={this.onChange}
                onSubmit={this.handleSubmit}
                submitting={submitting}
                uploadProps={uploadProps}
                value={inputValue}
                fileArray={fileArray}
              />
            }
          />
        </div>
        {isOpen && (
          <Lightbox mainSrc={pictureURL} onCloseRequest={() => this.setState({ isOpen: false })} />
        )}
      </StyledRentalObjectAssignmentHistoryItemContent>
    )
  }
}

export default compose(withTranslation(), withFirebase)(RentalObjectAssignmentHistoryItemContent)
