import * as React from 'react'

import { withTranslation } from 'react-i18next'
import { withFirebase } from '../../../../firebase'
import { compose } from 'recompose'

/* Import components here */
import {
  StyledRealEstateListItemOverview,
  Content,
  Extra,
  Wrap,
  BoldSpan
} from './RealEstateListItemOverview.styles'

/* Import interfaces here */
import {
  IRealEstateListItemOverviewProps,
  IRealEstateListItemOverviewState
} from './RealEstateListItemOverview.interfaces'

/* Import utilities here */
import { Avatar, Typography, Divider, Modal, Tag, Button, Alert } from 'antd'

const { Paragraph, Title, Text } = Typography
const { confirm } = Modal

export class RealEstateListItemOverview extends React.Component<
  IRealEstateListItemOverviewProps,
  IRealEstateListItemOverviewState
> {
  showConfirm = () => {
    const { realEstate } = this.props
    const realEstateName = realEstate.address.realEstateName
    const callback = this.updateRealEstateName
    confirm({
      content: (
        <span>
          Är du säker på att du vill ta bort <BoldSpan>{realEstateName}</BoldSpan>?
        </span>
      ),
      okType: 'danger',
      okText: 'Ja',
      cancelText: 'Nej',
      onOk() {
        callback()
      },
      onCancel() {}
    })
  }

  updateRealEstateName = () => {
    const { realEstate } = this.props

    this.props.firebase
      .realEstate(realEstate.realEstateID)
      .update({
        isOwned: !realEstate.isOwned
      })
      .then(() => {})
  }
  public render() {
    const { realEstate, t, children, ...props } = this.props
    return (
      <StyledRealEstateListItemOverview {...props}>
        <div>
          <Wrap>
            <Content>
              <Title level={3}>{realEstate.address.realEstateName}</Title>
              <Paragraph>
                <Text strong>Adress: </Text>
                <Text>{realEstate.address.street}</Text>
              </Paragraph>
              <Paragraph>
                <Text strong>Zipkod: </Text>
                <Text>{realEstate.address.zipCode}</Text>
              </Paragraph>
              <Paragraph>
                <Text strong>Stad: </Text>
                <Text>{realEstate.address.city}</Text>
              </Paragraph>
              <Divider />
              {realEstate.isOwned ? (
                <Tag color="green">Status: Äger</Tag>
              ) : (
                <Tag color="red">Status: Såld</Tag>
              )}
              {/* <Tag color="blue">Antal hyresobjekt: {realEstate.numberOfRentalObjects}</Tag> */}
              <Divider />
              {realEstate.isOwned ? (
                <Button onClick={this.showConfirm} type="danger">
                  Ta bort fastighet
                </Button>
              ) : (
                <Alert
                  type="warning"
                  message="Har det blivit fel? Kontakta CDAB för att återställa fastigheten."
                />
              )}
            </Content>
            <Extra>
              <Avatar size={200} src={realEstate.pictureURL} />
            </Extra>
          </Wrap>
        </div>
      </StyledRealEstateListItemOverview>
    )
  }
}

export default compose(withTranslation(), withFirebase)(RealEstateListItemOverview)
