import * as React from 'react'

/* Import components here */
import { SignInForm, PasswordForgetLink } from 'components'
import { WithTranslation } from 'react-i18next'
import styled from 'styled-components'
import logo from '../../layouts/AppLayout/logo.png'
const SignInLogo = styled.div`
  width: 100%;
  height: 253px;
  position: relative;
  display: inline-block;
  overflow: hidden;
  margin: 0;
  background-color: 'red';
`
export const SignInPage: React.FC<WithTranslation> = ({ children, ...props }) => {
  // const { t } = useTranslation()

  return (
    <>
      <SignInLogo>
        <img src={logo} alt="logo" />
      </SignInLogo>
      <SignInForm />
      {/* <SignUpLink /> */}
      <PasswordForgetLink />
    </>
  )
}

export default SignInPage
