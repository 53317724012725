import { action, observable, computed } from 'mobx'

// interface IInbox {
//   areas: any[]
//   priorities: any[]
//   statusCodes: any[]
// }
export default class InboxStore {
  @observable public inbox: any[] = []

  @action.bound
  public pushToInbox(assignment) {
    this.inbox.push(assignment)
  }

  @action.bound
  public setInbox(assignmentList) {
    this.inbox = assignmentList
  }

  @computed get numberOfAssignments() {
    return this.inbox.length
  }
}
