import * as React from 'react'

/* Import components here */
import { Page } from 'components'
import { compose } from 'recompose'

import { AuthUserContext, withAuthorization, withEmailVerification } from '../../contexts'
import { AccountAssignmentList } from '../../components'
export const InboxPage: React.FC = ({ children, ...props }) => {
  return (
    <AuthUserContext.Consumer>
      {(authUser: any) => (
        <Page {...props}>
          <AccountAssignmentList authUser={authUser} />
        </Page>
      )}
    </AuthUserContext.Consumer>
  )
}

const condition = authUser => !!authUser

export default compose(withEmailVerification, withAuthorization(condition))(InboxPage)
