import * as React from 'react'

/* Import components here */
import { StyledPage } from './Page.styles'

export const Page: React.FunctionComponent = ({ children, ...props }) => (
  <StyledPage {...props}>
    <div className="page-content">{children}</div>
  </StyledPage>
)

export default Page
