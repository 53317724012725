import i18next from 'i18next'
import LngDetector, { I18nextBrowserLanguageDetector } from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

// English
import enCommon from 'i18n/en/common.json'
import enError from 'i18n/en/error.json'
import enStart from 'i18n/en/start.json'

// Swedish
import svCommon from 'i18n/sv/common.json'
import svError from 'i18n/sv/error.json'
import svStart from 'i18n/sv/start.json'
import svLanding from 'i18n/sv/landing.json'

const options: I18nextBrowserLanguageDetector.DetectorOptions = {
  // order and from where user language should be detected
  order: ['localStorage', 'navigator', 'htmlTag'],

  // keys or params to lookup language from
  lookupLocalStorage: 'i18nextLng',

  // cache user language on
  caches: ['localStorage'],

  // optional htmlTag with lang attribute, the default is:
  htmlTag: document.documentElement
}

i18next
  .use(initReactI18next)
  .use(LngDetector)
  .init(
    {
      debug: false,
      defaultNS: 'common',
      detection: options,
      fallbackLng: ['sv', 'en'],
      interpolation: { escapeValue: false },
      lng: 'sv',
      react: {
        wait: true
      },
      resources: {
        en: {
          common: enCommon,
          error: enError,
          start: enStart
        },
        sv: {
          common: svCommon,
          error: svError,
          start: svStart,
          landing: svLanding
        }
      }
    },
    err => {
      if (err) {
        // tslint:disable-next-line:no-console
        return console.log('something went wrong loading', err)
      }
    }
  )

export default i18next
