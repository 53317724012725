import * as React from 'react'

import { withTranslation } from 'react-i18next'

/* Import components here */
import {
  ActiveAssignments,
  EmployeePerformance,
  OverallPerformance,
  DashBoardMultipleYearSelect
} from 'components'
// import { StyledDashBoardChartTabPanel } from './DashBoardChartTabPanel.styles'
import { Card, Icon, Select, Tabs } from 'antd'

/* Import interfaces here */
import {
  IDashBoardChartTabPanelProps,
  IDashBoardChartTabPanelState
} from './DashBoardChartTabPanel.interfaces'
import { rootStore } from 'stores'
import { observer } from 'mobx-react'

/* Import utilities here */
const TabPane = Tabs.TabPane
const { Option } = Select

const Operations = ({ activeKey, onYearChange, onEmployeeChange, setYears }) => (
  <span>
    {activeKey === '2' && (
      <span>
        <Select
          showSearch={true}
          style={{ width: 200 }}
          placeholder="Välj arbetare"
          optionFilterProp="children"
          onChange={onEmployeeChange}
        >
          {rootStore.employeeStore.allJanitors.map(employee => {
            return (
              <Option key={employee.userID} value={employee.userID}>
                {employee.firstName} {employee.lastName}
              </Option>
            )
          })}
        </Select>
        <Select defaultValue="2020" style={{ width: 120 }} onChange={onYearChange}>
          <Option value="2020">2020</Option>
          <Option value="2019">2019</Option>
          <Option value="2018">2018</Option>
          <Option value="2017">2017</Option>
          <Option value="2016">2016</Option>
          <Option value="2015">2015</Option>
        </Select>
      </span>
    )}
    {activeKey === '3' && <DashBoardMultipleYearSelect setYears={setYears} />}
  </span>
)
@observer
export class DashBoardChartTabPanel extends React.Component<
  IDashBoardChartTabPanelProps,
  IDashBoardChartTabPanelState
> {
  public state = {
    activeKey: '1',
    year: '2020',
    employeeID: '',
    selectedYears: []
  }

  public onTabChange = activeKey => {
    this.setState({ activeKey })
  }

  public onYearChange = year => {
    this.setState({ year })
  }

  public onEmployeeChange = employeeID => {
    this.setState({ employeeID })
  }

  public setYears = selectedYears => {
    this.setState({ selectedYears })
  }

  public render() {
    const { activeKey, year, employeeID, selectedYears } = this.state

    return (
      <Card loading={rootStore.employeeStore.loading} size="default" style={{ minWidth: '600px' }}>
        <Tabs
          defaultActiveKey="1"
          activeKey={activeKey}
          onChange={this.onTabChange}
          size="large"
          tabBarExtraContent={
            <Operations
              activeKey={activeKey}
              onYearChange={this.onYearChange}
              onEmployeeChange={this.onEmployeeChange}
              setYears={this.setYears}
            />
          }
        >
          <TabPane
            tab={
              <span>
                <Icon type="bar-chart" />
                Pågående
              </span>
            }
            key="1"
          >
            <div>
              <ActiveAssignments />
            </div>
          </TabPane>
          <TabPane
            tab={
              <span>
                <Icon type="bar-chart" />
                Enskild Prestation
              </span>
            }
            key="2"
          >
            <div>
              <EmployeePerformance year={year} employeeID={employeeID} />
            </div>
          </TabPane>
          <TabPane
            tab={
              <span>
                <Icon type="bar-chart" />
                Total Prestation
              </span>
            }
            key="3"
          >
            <div>
              <OverallPerformance selectedYears={selectedYears} />
            </div>
          </TabPane>
        </Tabs>
      </Card>
    )
  }
}

export default withTranslation()(DashBoardChartTabPanel)
