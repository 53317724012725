import * as React from 'react'

import { withTranslation } from 'react-i18next'

/* Import components here */
import { StyledRealEstateSettingsHelp } from './RealEstateSettingsHelp.styles'

/* Import interfaces here */
import { IRealEstateSettingsHelpProps } from './RealEstateSettingsHelp.interfaces'

/* Import utilities here */
import { Typography, Divider } from 'antd'

const { Title, Paragraph, Text } = Typography
export const RealEstateSettingsHelp: React.FC<IRealEstateSettingsHelpProps> = ({
  t,
  children,
  ...props
}) => {
  return (
    <StyledRealEstateSettingsHelp {...props}>
      <Typography>
        <Title level={2}>Fastigheter</Title>
        <Paragraph>För att administrera fastigheter, här läser du hur du kan:</Paragraph>
        <Paragraph>
          <ul>
            <li>Hitta specifika Fastigheter och får en översikt av dem</li>
            <li>Kunna redigera Fastigheters information, byta bild och markera dem som sålda.</li>
            <li>Skapa nya Fastigheter</li>
          </ul>
        </Paragraph>
        <Title level={4}>Fastighetslista</Title>
        <Paragraph>
          Överst så kan du filtrera på vilka fastigheter du vill se i listan, standard är att se
          alla Ägda Fastigheter. Alternativt: (<Text code>Ägda</Text>,<Text code>Borttagna</Text>{' '}
          och <Text code>Alla</Text>). För att hjälpa dig att navigera till rätt Fastighet. Alla
          sålda fastigheter finns under Borttagna, dessa fastigheter kommer inte att visas bland
          Fastigheterna där man skapar ärenden. Om något har blivit fel kan man alltid få tillbaka
          Borttagna fastigheter. Ärenden och annat som hade skapats på den försvinner inte. Men för
          att få tillbaka en såld fastighet måste support kontaktas.
        </Paragraph>
        <Paragraph>
          I sökfältet kan du söka på alla atribut en fastighet har, till exempel Fastighetsnamn,
          adress, stad och postnummer. Om du sedan klickar på Fastighetens namn eller Insällningar
          så kommer du till en översikt av Fastigheten. Där ser du all aktuell information på den.
          Du ser också om det saknas något, till exempel postnummer.
        </Paragraph>
        <Divider />
        <Title level={4}>Redigera Fastigheter</Title>
        <Paragraph>
          Vid översikten av en Fastighet kan man markera den som såld. Gör detta på fastigheter ni
          inte längre äger och som inte ska synas längre. Den kommer då försvinna och inga ärenden
          kan skapas på den.
        </Paragraph>
        <Paragraph>
          Till vänster så ser du en meny, om du där väljer "Redigera", så kan du byta
          Fastighetsnamn, adress, stad, postnummer och även byta bild. Fastighetsnamn måste vara
          unika.
        </Paragraph>
        <Divider />
        <Title level={4}>Skapa ny Fastighet</Title>
        <Paragraph>
          Fastigheter kan skapas automatiskt vid Excel-import av Hyrobjekt. Men vid vissa tillfällen
          kan det vara bra att skapa en manuellt.
        </Paragraph>
        <Paragraph>
          Det gör du enkelt om du navigerar till "Ny Fastighet". Där ser du ett formulär där du
          fyller i Fastighetsnamnet, adress, stad, postnummer och en bild. Bilden är valfri och
          behövs inte.
        </Paragraph>
        <Paragraph>
          <Text strong>
            Fastigheten är nu skapad men har inga hyrobjekt eller hyresgäster. Importera dessa med
            en Excel-fil för hyresgäster. Då kommer alla aktiva kontrakt på hyresgäster att skapas.
            Lägenheterna skapas även i detta import. Tomma lägenheter skapas inte. För att få dessa
            så måste man importera Fastigheter & Lägenheter från Real
          </Text>
          .
        </Paragraph>
      </Typography>
    </StyledRealEstateSettingsHelp>
  )
}

export default withTranslation()(RealEstateSettingsHelp)
