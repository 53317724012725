import * as React from 'react'

import { withTranslation } from 'react-i18next'

/* Import components here */
import { StyledRentalObjectTable, StyledFileUpload, StyledForm } from './RentalObjectTable.styles'
import {
  PageHeader,
  Button,
  Icon,
  Table,
  Input,
  List,
  Popconfirm,
  Popover,
  Modal,
  Progress,
  Spin
} from 'antd'
/* Import interfaces here */
import { IRentalObjectTableProps, IRentalObjectTableState } from './RentalObjectTable.interfaces'

/* Import utilities here */
import { compose } from 'recompose'
import { withFirebase } from '../../../firebase'
import Highlighter from 'react-highlight-words'
import { search, sortByName } from '../../../utils/tables/TableActions'
import { Link, withRouter } from 'react-router-dom'
import { AssignmentCreatorDrawer } from 'components'

const defaultSortDirectionOrder: SortOrder = 'ascend'
const sortDirectionOrder: SortOrder[] = ['ascend', 'descend']
export declare type SortOrder = 'descend' | 'ascend'

function contentCreator(files, firebase, getRentalObjectIDWithFileName) {
  if (files) {
    if (files.length > 0) {
      return (
        <div>
          <List
            itemLayout="horizontal"
            dataSource={files}
            renderItem={(file: any) => (
              <List.Item
                actions={[
                  <a key={1} href={file.downloadURL} target="_blank" rel="noopener noreferrer">
                    Visa
                  </a>,
                  <Popconfirm
                    key={2}
                    placement="right"
                    title={'Är du säker på att du vill ta bort denna fil?'}
                    onConfirm={() =>
                      deleteFile(
                        file.downloadURL,
                        file.fileName,
                        file.title,
                        file.type,
                        firebase,
                        getRentalObjectIDWithFileName
                      )
                    }
                    okText="Ja"
                    cancelText="Nej"
                  >
                    <span style={{ color: 'red' }}>Delete</span>
                  </Popconfirm>
                ]}
              >
                <List.Item.Meta
                  title={
                    <span style={{ fontWeight: 'bold', fontSize: '14px' }}>{file.title} </span>
                  }
                />
              </List.Item>
            )}
          />
        </div>
      )
    } else {
      return <div>Inga filer</div>
    }
  } else {
    return <div>Inga filer</div>
  }
}

function deleteFile(downloadURL, fileName, title, type, firebase, getRentalObjectIDWithFileName) {
  const rentalObjectID = getRentalObjectIDWithFileName(fileName)
  firebase
    .rentalObjectStorageRef(rentalObjectID + '/' + fileName)
    .delete()
    .then(() => {
      firebase.rentalObject(rentalObjectID).update({
        files: firebase.fieldValue.arrayRemove({
          downloadURL,
          fileName,
          title,
          type
        })
      })
    })
}

function PopOverMenu(props) {
  const content = contentCreator(props.files, props.firebase, props.getRentalObjectIDWithFileName)
  return (
    <Popover
      content={content}
      title={<span style={{ fontWeight: 'bold', fontSize: '16px' }}>Filer</span>}
      trigger="click"
    >
      <Button className="button" style={{ width: '100%' }}>
        <Icon type="file-pdf" />
        Filer
      </Button>
    </Popover>
  )
}
export class RentalObjectTable extends React.Component<
  IRentalObjectTableProps,
  IRentalObjectTableState
> {
  unsubscribeRealEstate: any
  unsubscribeRentalObjects: any

  constructor(props) {
    super(props)

    this.state = {
      rentalObjects: [],
      realEstateLoading: false,
      rentalObjectsLoading: false,
      realEstate: null,
      searchText: '',

      confirmLoading: false,
      file: null,
      progress: 0,
      title: '',
      visible: false,
      drawerVisible: false,
      selectedRentalObjectID: null,
      rentalObjectID: '',
      ...props.location.state
    }
  }

  componentDidMount() {
    this.setState({ rentalObjectsLoading: true })
    this.unsubscribeRentalObjects = this.props.firebase
      .rentalObjects(this.props.match.params.realEstateID)
      .onSnapshot(snapshot => {
        const rentalObjects: any = []

        snapshot.forEach(doc => {
          rentalObjects.push({ ...doc.data(), uid: doc.id })
        })

        this.setState({
          rentalObjects,
          rentalObjectsLoading: false
        })
      })
    if (this.state.realEstate) {
      return
    }

    this.setState({ realEstateLoading: true })

    this.unsubscribeRealEstate = this.props.firebase
      .realEstate(this.props.match.params.realEstateID)
      .onSnapshot(snapshot => {
        this.setState({
          realEstate: snapshot.data(),
          realEstateLoading: false
        })
      })
  }

  componentWillUnmount() {
    this.unsubscribeRealEstate && this.unsubscribeRealEstate()
    this.unsubscribeRentalObjects && this.unsubscribeRentalObjects()
  }

  public getColumnSearchProps = (obj, dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            ;(this as any).searchInput = node
          }}
          placeholder={`Sök ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Sök
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Rensa
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) => search(value, record, obj, dataIndex),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => (this as any).searchInput.select())
      }
    },
    render: text => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape={true}
        textToHighlight={text.toString()}
      />
    )
  })

  public handleSearch = (selectedKeys, confirm) => {
    confirm()
    this.setState({ searchText: selectedKeys[0] })
  }

  public handleReset = clearFilters => {
    clearFilters()
    this.setState({ searchText: '' })
  }

  public getTenantsWithID = id => {
    const todayTimestamp = this.props.firebase.timestamp.fromDate(new Date())
    const ap = this.state.rentalObjects.find(apt => apt.rentalObjectID === id)
    if (ap && ap.tenantHistory) {
      return ap.tenantHistory
        .map(tenant => tenant)
        .filter(
          tenant =>
            tenant.moveInDate &&
            tenant.moveInDate.toMillis() < todayTimestamp.toMillis() &&
            (tenant.moveOutDate === null ||
              tenant.moveOutDate.toMillis() > todayTimestamp.toMillis())
        )
    }
  }

  public getRentalObjectNrWithID = id => {
    const ap = this.state.rentalObjects.find(apt => apt.rentalObjectID === id)
    if (ap) {
      return ap.address.rentalObjectNumber
    }
  }

  public getRentalObjectIDWithFileName = (fileName: string) => {
    const ap = this.state.rentalObjects.find(apt => {
      if (typeof apt.files !== 'undefined') {
        const file = apt.files.find(fileObject => fileObject.fileName === fileName)
        if (file) {
          return true
        }
      }
      return false
    })
    if (ap) {
      return ap.rentalObjectID
    }
  }

  public showModal = rentalObjectID => {
    this.setState({
      rentalObjectID,
      visible: true
    })
  }

  public handleCancel = () => {
    this.setState({
      file: null,
      progress: 0,
      title: '',
      visible: false
    })
  }

  public handleTitleChange = e => {
    this.setState({ title: e.target.value })
  }

  public handleFileChange = e => {
    if (e.target.files[0]) {
      const file = e.target.files[0]
      this.setState({ file })
    }
    e.target.value = null
  }

  public getExtension(filename) {
    const parts = filename.split('/')
    return parts[parts.length - 1]
  }

  public handleFileUpload = () => {
    this.setState({
      confirmLoading: true
    })
    if (this.state.file.name !== 'Ingen fil har valts' && this.state.title !== '') {
      const file = this.state.file
      const id = this.props.firebase.getUniqueID()
      const ext = this.getExtension(file.type)
      const storageRef = this.props.firebase.rentalObjectStorageRef(
        this.state.rentalObjectID + '/' + (id + '.' + ext)
      )
      const metadata = {
        contentType: file.type
      }

      // Upload file and metadata to the object 'images/mountains.jpg'
      const uploadTask = storageRef.put(file, metadata)
      const that = this
      // Listen for state changes, errors, and completion of the upload.
      uploadTask.on(
        this.props.firebase.app.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
        (snapshot: any) => {
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress = Number(
            ((snapshot.bytesTransferred / snapshot.totalBytes) * 100).toFixed(2)
          )
          console.log('Upload is ' + progress + '% done')
          that.setState({ progress })
          switch (snapshot.state) {
            case this.props.firebase.app.storage.TaskState.PAUSED: // or 'paused'
              console.log('Upload is paused')
              break
            case this.props.firebase.app.storage.TaskState.RUNNING: // or 'running'
              console.log('Upload is running')
              break
          }
        },
        (error: any) => {
          // A full list of error codes is available at
          // https://firebase.google.com/docs/storage/web/handle-errors
          switch (error.code) {
            case 'storage/unauthorized':
              // User doesn't have permission to access the object
              break

            case 'storage/canceled':
              // User canceled the upload
              break
            case 'storage/unknown':
              // Unknown error occurred, inspect error.serverResponse
              break
          }
        },
        () => {
          // Upload completed successfully, now we can get the download URL
          uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
            // console.log('File available at', downloadURL)
            // console.log(that.state.title)
            // console.log(id + '.' + ext)
            // console.log(file.type)
            this.props.firebase.rentalObject(this.state.rentalObjectID).update({
              files: this.props.firebase.fieldValue.arrayUnion({
                downloadURL,
                fileName: id + '.' + ext,
                title: that.state.title,
                type: file.type
              })
            })
            that.setState({
              confirmLoading: false,
              file: null,
              progress: 0,
              title: '',
              visible: false
            })
          })
        }
      )
    } else {
      this.setState({
        confirmLoading: false
      })
    }
  }

  public closeDrawer = () => {
    this.setState({ drawerVisible: false })
  }

  public openDrawer = selectedRentalObjectID => {
    this.setState({ drawerVisible: true, selectedRentalObjectID })
  }

  public render() {
    const { t, children, ...props } = this.props
    const { realEstate, realEstateLoading, rentalObjects, rentalObjectsLoading } = this.state
    const {
      visible,
      confirmLoading,
      file,
      title,
      progress,
      rentalObjectID,
      drawerVisible,
      selectedRentalObjectID
    } = this.state

    const columns = [
      {
        title: 'Objekt',
        children: [
          {
            title: 'LägenhetsNummer',
            dataIndex: 'address.rentalObjectNumber',
            key: 'lgh',
            sorter: (a, b) => sortByName(a, b, 'address', 'rentalObjectNumber'),
            sortDirections: sortDirectionOrder,
            defaultSortOrder: defaultSortDirectionOrder,
            ...this.getColumnSearchProps('address', 'rentalObjectNumber')
          },
          {
            title: 'Typ',
            dataIndex: 'type',
            key: 'typ',
            sorter: (a, b) => sortByName(a, b, null, 'type'),
            sortDirections: sortDirectionOrder,
            ...this.getColumnSearchProps(null, 'type')
          }
        ]
      },
      {
        title: 'Funktioner',
        children: [
          {
            title: 'Felanmälan',
            dataIndex: 'rentalObjectID',
            key: 'createReport',
            render: rentalObjectID => {
              return (
                <Button
                  onClick={() => this.openDrawer(rentalObjectID)}
                  className="button"
                  style={{ width: '100%' }}
                >
                  <Icon type="edit" />
                  Felanmälan
                </Button>
              )
            }
          },
          {
            title: 'Historik',
            dataIndex: 'rentalObjectID',
            key: 'history',
            render: rentalObjectID => {
              return (
                <Link
                  to={{
                    pathname: `${this.props.location.pathname}/${rentalObjectID}`
                  }}
                >
                  <Button className="button" style={{ width: '100%' }}>
                    <Icon type="ordered-list" />
                    Historik
                  </Button>
                </Link>
              )
            }
          },
          {
            title: 'Lägg till fil',
            dataIndex: 'rentalObjectID',
            key: 'upload',
            render: rentalObjectID => {
              return (
                <Button
                  onClick={() => this.showModal(rentalObjectID)}
                  className="button"
                  style={{ width: '100%' }}
                >
                  <Icon type="upload" />
                  Lägg till fil
                </Button>
              )
            }
          },
          {
            title: 'Arkiv',
            dataIndex: 'files',
            key: 'fileArchive',
            render: files => {
              return (
                <PopOverMenu
                  files={files}
                  firebase={this.props.firebase}
                  getRentalObjectIDWithFileName={this.getRentalObjectIDWithFileName}
                />
              )
            }
          }
        ]
      },
      {
        title: 'Kontaktperson',
        children: [
          {
            title: 'Namn',
            children: [
              {
                title: 'Förnamn',
                dataIndex: 'rentalObjectID',
                key: 'firstName',
                /* sorter: (a, b) => sortByNameContactPerson(a, b, 'firstName', CurrentOrder),
                sortDirections: sortDirectionOrder,
                ...this.getColumnSearchProps('tenantHistory', 'firstName'), */
                render: rentalObjectID => {
                  const contactPersons = this.getTenantsWithID(rentalObjectID)
                  if (contactPersons && contactPersons.length > 1) {
                    return (
                      <span>
                        {contactPersons.map((person, index) => {
                          return (
                            <div key={person.tenantID || index}>
                              <Highlighter
                                highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                                searchWords={[this.state.searchText]}
                                autoEscape={true}
                                textToHighlight={person.firstName}
                              />
                            </div>
                          )
                        })}
                      </span>
                    )
                  } else if (contactPersons && contactPersons.length === 1) {
                    return (
                      <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[this.state.searchText]}
                        autoEscape={true}
                        textToHighlight={contactPersons[0].firstName}
                      />
                    )
                  } else {
                    return <div />
                  }
                }
              },
              {
                title: 'Efternamn',
                dataIndex: 'rentalObjectID',
                key: 'lastName',
                /* sorter: (a, b) => sortByNameContactPerson(a, b, 'lastName', CurrentOrder),
                sortDirections: sortDirectionOrder,
                ...this.getColumnSearchProps('tenantHistory', 'lastName'), */
                render: rentalObjectID => {
                  const contactPersons = this.getTenantsWithID(rentalObjectID)
                  if (contactPersons && contactPersons.length > 1) {
                    if (
                      contactPersons.every(person => person.lastName === contactPersons[0].lastName)
                    ) {
                      return (
                        <Highlighter
                          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                          searchWords={[this.state.searchText]}
                          autoEscape={true}
                          textToHighlight={contactPersons[0].lastName}
                        />
                      )
                    }
                    return (
                      <span>
                        {contactPersons.map((person, index) => {
                          return (
                            <div key={person.tenantID || index}>
                              <Highlighter
                                highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                                searchWords={[this.state.searchText]}
                                autoEscape={true}
                                textToHighlight={person.lastName}
                              />
                            </div>
                          )
                        })}
                      </span>
                    )
                  } else if (contactPersons && contactPersons.length === 1) {
                    return (
                      <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[this.state.searchText]}
                        autoEscape={true}
                        textToHighlight={contactPersons[0].lastName}
                      />
                    )
                  } else {
                    return <div />
                  }
                }
              }
            ]
          },
          {
            title: 'Kontakt',
            children: [
              {
                title: 'Telefonnummer',
                dataIndex: 'rentalObjectID',
                key: 'phoneNumbers',
                // ...this.getColumnSearchProps('tenantHistory', 'phoneNumbers'),
                render: rentalObjectID => {
                  const contactPersons = this.getTenantsWithID(rentalObjectID)
                  if (contactPersons && contactPersons.length > 1) {
                    return (
                      <span>
                        {contactPersons.map((person, index) => {
                          if (person.phoneNumbers != null) {
                            return (
                              <div key={person.tenantID || index}>
                                <Highlighter
                                  highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                                  searchWords={[this.state.searchText]}
                                  autoEscape={true}
                                  textToHighlight={person.phoneNumbers.join(', ')}
                                />
                              </div>
                            )
                          } else {
                            return <div key={person.tenantID || index}>Telefonnummer saknas</div>
                          }
                        })}
                      </span>
                    )
                  } else {
                    if (
                      contactPersons &&
                      contactPersons.length === 1 &&
                      contactPersons[0].phoneNumbers != null
                    ) {
                      return (
                        <Highlighter
                          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                          searchWords={[this.state.searchText]}
                          autoEscape={true}
                          textToHighlight={contactPersons[0].phoneNumbers.join(', ')}
                        />
                      )
                    } else if (contactPersons && contactPersons.length === 1) {
                      return <div key={contactPersons[0].tenantID || '0'}>Telefonnummer saknas</div>
                    } else {
                      return <div />
                    }
                  }
                }
              }
            ]
          }
        ]
      }
    ]

    return (
      <StyledRentalObjectTable {...props}>
        {realEstateLoading && <Spin />}
        {/* {rentalObjectsLoading && <div>Loading RentalObject ...</div>} */}

        {realEstate && (
          <PageHeader
            style={{ padding: '40px 48px 24px' }}
            backIcon={
              <Button type="primary">
                <Icon type="left" />
                Gå Tillbaka
              </Button>
            }
            onBack={() => window.history.back()}
            title={realEstate.address.realEstateName}
            subTitle={
              realEstate.address.street +
              ' ' +
              realEstate.address.zipCode +
              ' ' +
              realEstate.address.city
            }
          />
        )}
        {rentalObjects && (
          <Table
            // rowSelection={rowSelection}
            rowKey="rentalObjectID"
            bordered={true}
            columns={columns}
            loading={rentalObjectsLoading}
            dataSource={rentalObjects}
            pagination={{ pageSize: 50 }}
            size="middle"
          />
        )}
        <Modal
          maskClosable={false}
          closable={false}
          title={
            <span style={{ fontWeight: 'bold' }}>
              LGH: {this.getRentalObjectNrWithID(rentalObjectID)}
            </span>
          }
          visible={visible}
          onCancel={this.handleCancel}
          footer={[
            <Button key="back" onClick={this.handleCancel}>
              Avbryt
            </Button>,
            <Button
              key="submit"
              type="primary"
              disabled={!file || title === ''}
              loading={confirmLoading}
              onClick={this.handleFileUpload}
            >
              Ladda upp
            </Button>
          ]}
        >
          <div>
            <h3>Titel på fil</h3>
            <Input
              prefix={<Icon type="form" style={{ color: 'rgba(0,0,0,.25)' }} />}
              style={{ width: '100%' }}
              placeholder="Titel"
              value={title}
              onChange={this.handleTitleChange}
            />
            <StyledForm style={{ marginTop: '10px' }}>
              <StyledFileUpload
                className="file-upload-wrapper-rentalobjecttable"
                data-text={file ? file.name : 'Ingen fil har valts'}
              >
                <input
                  name="file-upload-field"
                  type="file"
                  value=""
                  onChange={this.handleFileChange}
                />
              </StyledFileUpload>
            </StyledForm>
            <Progress style={{ marginTop: '10px' }} percent={progress} />
          </div>
        </Modal>
        {realEstate && rentalObjects.length > 0 && selectedRentalObjectID && (
          <AssignmentCreatorDrawer
            rentalObjects={rentalObjects}
            selectedRentalObjectID={selectedRentalObjectID}
            fromPage="rentalObjectTable"
            realEstate={realEstate}
            visible={drawerVisible}
            closeDrawer={this.closeDrawer}
          />
        )}
      </StyledRentalObjectTable>
    )
  }
}

export default compose(withTranslation(), withRouter, withFirebase)(RentalObjectTable)
