import { styled } from 'theme'
import { List } from 'antd'

export const StyledAccountAssignmentList = styled.div`
  /* background-color: white;
  padding: 24px; */
`

export const ListItem = styled(List.Item)`
  opacity: 0.9;

  :hover {
    opacity: 1;
    cursor: pointer;
    -webkit-transform: scale(1.01);
    -ms-transform: scale(1.01);
    transform: scale(1.01);
  }
`
export const CenterContainer = styled.div`
  width: 272px;
  height: 153px;
  position: relative;
`

export const CenteredDiv = styled.div`
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
`
