import { BackTop, Layout } from 'antd'
import React, { useState } from 'react'
import { Route } from 'react-router-dom'

import { AppLayoutAvatarPopover, AppLayoutBreadcrumb, Navigation } from '../../components'
import logo from './FDLogotext.svg'
import navLogo from './FDLogobook.png'

const { Header, Content, Sider, Footer } = Layout

// App layout
const AppLayout = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false)

  const onCollapse = (collapsedState, type) => {
    setCollapsed(collapsedState)
  }

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider
        breakpoint="lg"
        style={{
          height: '100vh',
          overflow: 'auto',
          position: 'fixed'
        }}
        collapsible={true}
        collapsed={collapsed}
        onCollapse={onCollapse}
      >
        <div
          style={{
            height: 100,
            marginTop: 32
          }}
        >
          <img
            style={{ margin: '0 auto', display: 'block', transition: 'all 0.2s' }}
            height={collapsed ? 50 : 100}
            src={navLogo}
            alt="logo"
          />
        </div>
        <Navigation />
      </Sider>
      <Layout style={{ marginLeft: collapsed ? 80 : 200 }}>
        <Header
          style={{
            background: '#fff',
            padding: 0,
            height: 100,
            minWidth: '803px',
            backgroundImage: `url(${logo})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'auto 120px'
          }}
        >
          <span style={{ marginRight: 50, float: 'right', padding: '1rem' }}>
            <AppLayoutAvatarPopover />
          </span>
        </Header>
        <Content style={{ margin: '32px 16px 0px' }}>
          <span style={{ display: 'none' }}>
            <AppLayoutBreadcrumb />
          </span>
          {children}
        </Content>
        <Footer
          style={{
            textAlign: 'center'
          }}
        >
          FastighetsDagboken ©2020 CDAB
        </Footer>
      </Layout>
      {/* <HomePageDrawer
          gridContent={this.state.gridContent}
          visible={this.state.visible}
          closeDrawer={this.closeDrawer}
        /> */}
      {/* <HomePageAssignmentDrawer /> */}
      <BackTop />
    </Layout>
  )
}

export const AppLayoutRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={matchProps => (
        <AppLayout>
          <Component {...matchProps} />
        </AppLayout>
      )}
    />
  )
}
