import * as React from 'react'

/* Import components here */
import { Page } from 'components'
import { Result, Button } from 'antd'

export const AccessDeniedPage: React.FC = ({ children, ...props }) => {
  return (
    <Page {...props}>
      <Result
        status="403"
        title="403"
        subTitle="Ledsen, du har inte behörighet för att se sidan."
        extra={
          <Button type="primary" onClick={() => window.history.go(-2)}>
            Gå tillbaka
          </Button>
        }
      />
    </Page>
  )
}

export default AccessDeniedPage
