export const LANDING = '/'
export const SIGN_UP = '/signup'
export const SIGN_IN = '/signin'
export const SIGN_IN_SIDE = '/signinSide'
export const HOME = '/home'
export const HOME_NEWS_DETAILS = '/home/:messageID'

export const ACCOUNT = '/account'
export const PASSWORD_FORGET = '/pw-forget'
export const ADMIN = '/admin'
// export const ADMIN_DETAILS = '/admin/:id'
export const ADMIN_USERS = '/admin/users'
export const ADMIN_USER_CREATE = '/admin/create-new-user'
export const ADMIN_USER_DETAILS = '/admin/users/:userId'
export const REAL_ESTATES = '/realestates'

export const DASHBOARD = '/dashboard'
export const ASSIGNMENTS = '/assignments'

export const INVOICES = '/invoices'

export const REAL_ESTATES_RENTAL_OBJECT = '/realestates/:realEstateID'
export const REAL_ESTATES_RENTAL_OBJECT_HISTORY = '/realestates/:realEstateID/:rentalObjectID'
export const REAL_ESTATES_RENTAL_OBJECT_HISTORY_ASSIGNMENT =
  '/realestates/:realEstateID/:rentalObjectID/:assignmentID'

export const SETTINGS = '/settings'
export const SETTINGS_ASSIGNMENTS = '/settings/assignments'

export const SETTINGS_IMPORT = '/settings/import'
export const SETTINGS_REAL_ESTATES = '/settings/realestates'
export const SETTINGS_REAL_ESTATE_LIST = '/settings/realestates/details'

export const SETTINGS_REAL_ESTATES_DETAILS = '/settings/realestates/details/:realEstateID'

export const SETTINGS_REAL_ESTATES_CREATE = '/settings/realestates/create'
export const SETTINGS_REAL_ESTATES_HELP = '/settings/realestates/help'

export const ACCESS_DENIED = '/access-denied'

export const INBOX = '/inbox'
