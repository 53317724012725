import * as React from 'react'

import { withTranslation } from 'react-i18next'
import { withFirebase } from '../../../../firebase'
import { compose } from 'recompose'

/* Import components here */
import { StyledRealEstateList, CenterContainer, CenteredDiv } from './RealEstateList.styles'

/* Import interfaces here */
import { IRealEstateListProps, IRealEstateListState } from './RealEstateList.interfaces'

/* Import utilities here */
import { Link } from 'react-router-dom'
import * as ROUTES from '../../../../constants/routes'
import { List, Avatar, Spin, Input, Radio } from 'antd'
const { Search } = Input
export class RealEstateList extends React.Component<IRealEstateListProps, IRealEstateListState> {
  unsubscribe: any
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      realEstates: [],
      allRealEstates: [],
      disabled: true,
      searchBarInput: '',
      selectedFilter: true
    }
  }

  componentDidMount() {
    this.setState({ loading: true })

    this.unsubscribe = this.props.firebase.realEstates().onSnapshot(snapshot => {
      let realEstates: any = []

      snapshot.forEach(doc => realEstates.push({ ...doc.data(), uid: doc.id }))
      realEstates.sort((a: any, b: any) =>
        a.address.realEstateName < b.address.realEstateName
          ? -1
          : a.address.realEstateName > b.address.realEstateName
          ? 1
          : 0
      )
      this.setState({
        realEstates: realEstates.filter(realEstate => realEstate.isOwned),
        allRealEstates: realEstates,
        loading: false
      })
    })
  }

  componentWillUnmount() {
    this.unsubscribe()
  }

  searchHandler = event => {
    const searcjQery = event.target.value.toLowerCase()
    const { selectedFilter, allRealEstates } = this.state
    const displayedContacts: any = allRealEstates
      .filter((realEstate: any) =>
        selectedFilter != null ? realEstate.isOwned === selectedFilter : true
      )
      .filter(obj => this.filterObject(obj, searcjQery))
    this.setState({
      realEstates: displayedContacts,
      searchBarInput: searcjQery
    })
  }

  filterObject(obj, query) {
    for (const key in obj) {
      // console.log(key)
      if (key === 'profilePicture') {
        return
      } else if (typeof obj[key] === 'object') {
        if (
          Object.values(obj[key])
            .toString()
            .toUpperCase()
            .includes(query.toUpperCase())
        ) {
          return obj
        }
      } else if (
        obj[key]
          .toString()
          .toUpperCase()
          .includes(query.toUpperCase())
      ) {
        return obj
      }
    }
  }

  public onChange = e => {
    const selectedFilter = e.target.value
    const { searchBarInput, allRealEstates } = this.state
    const realEstates: any = allRealEstates
      .filter((realEstate: any) =>
        selectedFilter != null ? realEstate.isOwned === selectedFilter : true
      )
      .filter(realEstate => this.filterObject(realEstate, searchBarInput))
      .map(realEstate => realEstate)
    this.setState({ realEstates, selectedFilter })
  }

  public render() {
    const { t, children, ...props } = this.props
    const { realEstates, loading } = this.state

    return (
      <StyledRealEstateList {...props}>
        <div style={{ marginBottom: 10 }}>
          <Radio.Group onChange={this.onChange} defaultValue={true}>
            <Radio.Button value={true}>Ägda</Radio.Button>
            <Radio.Button value={false}>Borttagna</Radio.Button>
            <Radio.Button value={undefined}>Alla</Radio.Button>
          </Radio.Group>
        </div>
        <Search placeholder="Sök" onChange={this.searchHandler} />
        {loading && (
          <CenterContainer>
            <CenteredDiv>
              <Spin size="large" />
            </CenteredDiv>
          </CenterContainer>
        )}
        {!loading && (
          <List
            style={{ paddingTop: 12 }}
            itemLayout="horizontal"
            dataSource={realEstates}
            renderItem={(realEstate: any) => (
              <List.Item
                actions={[
                  <Link
                    to={{
                      pathname: `${ROUTES.SETTINGS_REAL_ESTATE_LIST}/${realEstate.uid}`,
                      state: { realEstate }
                    }}
                  >
                    Inställningar
                  </Link>
                ]}
              >
                <List.Item.Meta
                  avatar={<Avatar size={64} src={realEstate.pictureURL} />}
                  title={
                    <Link
                      to={{
                        pathname: `${ROUTES.SETTINGS_REAL_ESTATE_LIST}/${realEstate.uid}`,
                        state: { realEstate }
                      }}
                    >
                      {realEstate.address.realEstateName}
                    </Link>
                  }
                  description={
                    <div>
                      {realEstate.address.street} {realEstate.address.zipCode}{' '}
                      {realEstate.address.city}
                    </div>
                  }
                />
                {/* <strong style={{ width: '150px', textAlign: 'left' }}>
                  Antal hyresobjekt: {realEstate.numberOfRentalObjects}
                </strong> */}
              </List.Item>
            )}
          />
        )}
      </StyledRealEstateList>
    )
  }
}
export default compose(withTranslation(), withFirebase)(RealEstateList)
