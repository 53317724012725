import * as React from 'react'

import { withTranslation } from 'react-i18next'

/* Import components here */
import { StyledMessageItem, CommentContainer, Paragraph } from './MessageItem.styles'

/* Import interfaces here */
import { IMessageItemProps, IMessageItemState } from './MessageItem.interfaces'

/* Import utilities here */
import { List, Avatar, Button, Input, Comment, Icon, Switch } from 'antd'

import Logo from '../../../layouts/AppLayout/FDLogobook.png'
import { compose } from 'recompose'
import { withRouter, Link } from 'react-router-dom'
import * as ROUTES from '../../../constants/routes'

const IconText = ({ type, text }) => (
  <span>
    <Icon type={type} style={{ marginRight: 8 }} />
    {text}
  </span>
)

const options = {
  year: '2-digit',
  month: '2-digit',
  day: '2-digit'
}
export class MessageItem extends React.Component<IMessageItemProps, IMessageItemState> {
  constructor(props) {
    super(props)

    this.state = {
      editMode: false,
      editText: this.props.message.text,
      titleText: this.props.message.title,
      descriptionText: this.props.message.description,
      favourite: this.props.message.favourite
    }
  }

  onToggleEditMode = () => {
    this.setState(state => ({
      editMode: !state.editMode,
      editText: this.props.message.text,
      titleText: this.props.message.title,
      descriptionText: this.props.message.description,
      favourite: this.props.message.favourite
    }))
  }

  onChangeEditText = event => {
    this.setState({ editText: event.target.value })
  }

  onChangeTitleText = event => {
    this.setState({ titleText: event.target.value })
  }

  onChangeDescriptionText = event => {
    this.setState({ descriptionText: event.target.value })
  }

  onToggleFavourite = favourite => this.setState({ favourite })

  onSaveEditText = () => {
    const { editText, titleText, descriptionText, favourite } = this.state
    this.props.onEditMessage(this.props.message, editText, titleText, descriptionText, favourite)

    this.setState({ editMode: false })
  }

  public render() {
    const { authUser, message, onRemoveMessage, onEditMessage, t, children, ...props } = this.props
    const { editMode, editText, titleText, descriptionText } = this.state
    console.log(message.uid, editText, titleText, descriptionText)
    const actions = [
      <IconText
        type="calendar"
        text={
          message.createdAt ? message.createdAt.toDate().toLocaleDateString(undefined, options) : ''
        }
        key="calendar"
      />,
      <Link
        key="link"
        to={{
          pathname: `${ROUTES.HOME}/${message.uid}`,
          state: { message }
        }}
      >
        Läs mer
      </Link>
    ]
    if (message.favourite) {
      actions.splice(
        1,
        0,
        <Icon type="star" theme="twoTone" key="list-vertical-star-o" twoToneColor="#DAA520" />
      )
    }

    return (
      <StyledMessageItem {...props}>
        <List.Item
          key={message.uid}
          actions={actions}
          // extra={
          //   <img
          //     width={272}
          //     alt="logo"
          //     src="https://gw.alipayobjects.com/zos/rmsportal/mqaQswcyDLcXyDKnZfES.png"
          //   />
          // }
        >
          <List.Item.Meta
            avatar={<Avatar size="large" shape="square" src={Logo} />}
            title={
              editMode ? (
                <Input type="text" value={titleText} onChange={this.onChangeTitleText} />
              ) : (
                <Link
                  to={{
                    pathname: `${ROUTES.HOME}/${message.uid}`,
                    state: { message }
                  }}
                >
                  {message.title}
                </Link>
              )
            }
            description={
              editMode ? (
                <Input
                  type="text"
                  value={descriptionText}
                  onChange={this.onChangeDescriptionText}
                />
              ) : (
                <span>{message.description}</span>
              )
            }
          />
          {editMode ? (
            <Input.TextArea
              style={{ resize: 'none' }}
              autoSize={{ minRows: 2 }}
              onChange={this.onChangeEditText}
              value={editText}
            />
          ) : (
            <CommentContainer
              onClick={() =>
                this.props.history.push({
                  pathname: `${ROUTES.HOME}/${message.uid}`,
                  state: { message }
                })
              }
            >
              <Comment
                content={<Paragraph dangerouslySetInnerHTML={{ __html: message.text }}></Paragraph>}
              />
            </CommentContainer>
          )}
        </List.Item>
        <div />

        {/* {editMode ? (
          <input type="text" value={editText} onChange={this.onChangeEditText} />
        ) : (
          <span>
            <strong>{message.userId}</strong> {message.text}
            {message.editedAt && <span>(Edited)</span>}
          </span>
        )} */}

        {authUser.uid === message.userId && (
          <span style={{ marginTop: '1rem' }}>
            {editMode ? (
              <span>
                <Button type="primary" icon="save" onClick={this.onSaveEditText}>
                  Spara
                </Button>
                <Button
                  style={{ margin: 10 }}
                  type="danger"
                  icon="undo"
                  onClick={this.onToggleEditMode}
                >
                  Avbryt
                </Button>
                <Switch
                  onChange={this.onToggleFavourite}
                  checkedChildren={<Icon type="star" />}
                  unCheckedChildren={<Icon type="star" />}
                  defaultChecked={message.favourite}
                />
              </span>
            ) : (
              <Button type="default" icon="edit" onClick={this.onToggleEditMode}>
                Edit
              </Button>
            )}

            {!editMode && (
              <Button
                style={{ margin: 10 }}
                type="danger"
                icon="delete"
                onClick={() => onRemoveMessage(message.uid)}
              >
                Delete
              </Button>
            )}
          </span>
        )}
      </StyledMessageItem>
    )
  }
}

export default compose(withTranslation(), withRouter)(MessageItem)
