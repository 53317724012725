import { styled } from 'theme'

export const StyledRentalObjectTable = styled.div`
  background-color: white;
  color: blue;
`

export const StyledForm = styled.form`
  width: 100%;
  border: 4px ridge #e8e8e8;
  border-width: 4px;
  border-style: ridge;
  border-color: #e8e8e8;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
`

export const StyledFileUpload = styled.div`
  position: relative;
  width: 100%;
  height: 40px;
  :after {
    content: attr(data-text);
    font-size: 18px;
    position: absolute;
    top: 0;
    left: 0;
    background: #fff;
    padding: 10px 15px;
    display: block;
    width: calc(100% - 40px);
    pointer-events: none;
    z-index: 20;
    height: 40px;
    line-height: 20px;
    color: #999;
    border-radius: 5px 10px 10px 5px;
    font-weight: 300;
  }
  :before {
    content: 'Välj Fil';
    position: absolute;
    top: 0;
    right: 0;
    display: inline-block;
    height: 40px;
    background: #4daf7c;
    color: #fff;
    font-weight: 700;
    z-index: 25;
    font-size: 16px;
    line-height: 40px;
    padding: 0 15px;
    text-transform: uppercase;
    pointer-events: none;
    border-radius: 0 5px 5px 0;
  }
  :hover:before {
    background: #3d8c63;
  }
  input {
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99;
    height: 40px;
    margin: 0;
    padding: 0;
    display: block;
    cursor: pointer;
    width: 100%;
  }
`
