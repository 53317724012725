import { styled } from 'theme'

export const StyledDefaultSettingsBoundary = styled.div`
  background-color: white;
  padding: 1rem;
`
export const FlexContainer = styled.div`
  padding: 0;
  margin: 0;
  list-style: none;

  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;

  -webkit-flex-flow: row wrap;
  justify-content: space-around;
`

export const FlexItem = styled.div`
  width: 400px;
  margin-top: 10px;
`
