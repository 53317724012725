import * as React from 'react'

import { withTranslation } from 'react-i18next'

/* Import components here */
// import { StyledPasswordChangeForm } from './PasswordChangeForm.styles'

/* Import interfaces here */
import { IPasswordChangeFormProps, IPasswordChangeFormState } from './PasswordChangeForm.interfaces'

/* Import utilities here */
import { withFirebase } from '../../firebase'
import { compose } from 'recompose'
import { Input, Button, Alert } from 'antd'

const INITIAL_STATE = {
  currentPassword: '',
  passwordOne: '',
  passwordTwo: '',
  errorMessage: null,
  visible: false,
  success: null
}
export class PasswordChangeFormBase extends React.Component<
  IPasswordChangeFormProps,
  IPasswordChangeFormState
> {
  constructor(props) {
    super(props)

    this.state = { ...INITIAL_STATE }
  }

  onSubmit = event => {
    const { currentPassword, passwordOne } = this.state
    event.preventDefault()

    this.props.firebase.doPasswordUpdate(currentPassword, passwordOne, this.next, this.error)
  }

  next = () => {
    this.setState({ ...INITIAL_STATE, success: 'Lösenordet har uppdaterats!' })
  }

  error = error => {
    const errorMessage = this.getErrorMessage(error.code)
    this.setState({ errorMessage })
  }

  public getErrorMessage = errorCode => {
    switch (errorCode) {
      case 'auth/user-not-found':
      case 'auth/wrong-password':
        return 'Det gamla/nuvarande lösenordet stämmer inte'
      case 'auth/user-disabled':
        return 'Användaren är avstängd av Admin'
      case 'auth/too-many-requests':
        return 'För många inloggningsförsök, testa igen senare'
      default:
        return 'Misslyckades med att uppdatera'
    }
  }

  show = () => {
    this.setState({ visible: true })
  }
  onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      [e.target.name]: e.target.value
    } as { [K in keyof IPasswordChangeFormState]: IPasswordChangeFormState[K] })
  }
  public render() {
    // const { t, children, ...props } = this.props
    const { currentPassword, passwordOne, passwordTwo, errorMessage, visible, success } = this.state

    const isInvalid =
      passwordOne !== passwordTwo ||
      passwordOne === '' ||
      currentPassword === '' ||
      passwordOne.length < 6
    return (
      <>
        {!visible && (
          <Button onClick={this.show} style={{ marginTop: 16 }} block>
            Byt lösenord
          </Button>
        )}
        {visible && (
          <form onSubmit={this.onSubmit}>
            <div style={{ marginTop: 16 }}>Nuvarande Lösenord</div>
            <Input
              name="currentPassword"
              value={currentPassword}
              onChange={this.onChange}
              type="password"
              placeholder="Nuvarande Lösenord"
            />
            <div style={{ marginTop: 16 }}>Nytt Lösenord</div>
            <Input
              name="passwordOne"
              value={passwordOne}
              onChange={this.onChange}
              type="password"
              placeholder="Nytt Lösenord"
            />
            <div style={{ marginTop: 16 }}>Bekräfta Nya Lösenordet</div>
            <Input
              name="passwordTwo"
              value={passwordTwo}
              onChange={this.onChange}
              type="password"
              placeholder="Bekräfta Nya Lösenordet"
            />
            <Button
              style={{ marginTop: 16 }}
              type="primary"
              block
              disabled={isInvalid}
              htmlType="submit"
            >
              Byt lösenord
            </Button>
            {errorMessage && (
              <Alert style={{ marginTop: 16 }} message={errorMessage} type="error" />
            )}
            {isInvalid && (
              <Alert
                style={{ marginTop: 16 }}
                message="• Det nya lösenordet måste vara minst 6 karaktärer"
                type="warning"
              />
            )}
          </form>
        )}
        {success && <Alert style={{ marginTop: 16 }} message={success} type="success" />}
      </>
    )
  }
}

const PasswordChangeForm = compose(withTranslation(), withFirebase)(PasswordChangeFormBase)

export default PasswordChangeForm
