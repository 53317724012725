import { styled } from 'theme'

export const StyledUserItem = styled.div`
  background-color: white;
`
export const CenterContainer = styled.div`
  min-height: 5em;
  position: relative;
`

export const CenteredDiv = styled.div`
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
`

export const Wrapper = styled.div`
  padding: 24px;
  display: flex;
`
export const MainContent = styled.div`
  flex: 1;
`
export const ExtraContent = styled.div`
  min-width: 300px;
  text-align: center;
`
