import * as React from 'react'
import { Router, Redirect, Route, RouteProps, Switch } from 'react-router-dom'

import {
  AboutPage,
  AccessDeniedPage,
  AssignmentsPage,
  DashboardPage,
  ErrorPage,
  SentryPage,
  StartPage,
  HomePage,
  AccountPage,
  AdminPage,
  //LandingPage,
  SignInPage,
  //SignUpPage,
  PasswordForgetPage,
  RealEstatesPage,
  SettingsPage,
  InvoicesPage,
  InboxPage
} from 'pages'
import * as ROUTES from '../constants/routes'
import { withAuthentication } from '../contexts'
import { AppLayoutRoute, LoginLayoutRoute } from 'layouts'
import { createBrowserHistory } from 'history'

export const history = createBrowserHistory()

const AppRouter: React.FC<RouteProps> = () => {
  const redirectToStart = () => {
    return <Redirect to="/home" />
  }

  return (
    <Router history={history}>
      <Switch>
        {/*         <AppLayoutRoute path={ROUTES.HOME} component={LandingPage} />*/}
        <Route exact path={ROUTES.LANDING} render={redirectToStart} />
        <AppLayoutRoute path={ROUTES.ACCOUNT} component={AccountPage} />
        <AppLayoutRoute path={ROUTES.INBOX} component={InboxPage} />

        <AppLayoutRoute path={ROUTES.ADMIN} component={AdminPage} />
        <AppLayoutRoute path={ROUTES.HOME} component={HomePage} />

        <AppLayoutRoute path={ROUTES.REAL_ESTATES} component={RealEstatesPage} />
        <AppLayoutRoute path={ROUTES.SETTINGS} component={SettingsPage} />
        <AppLayoutRoute path={ROUTES.DASHBOARD} component={DashboardPage} />
        <AppLayoutRoute path={ROUTES.ASSIGNMENTS} component={AssignmentsPage} />
        <AppLayoutRoute path={ROUTES.INVOICES} component={InvoicesPage} />

        <LoginLayoutRoute path={ROUTES.SIGN_IN} component={SignInPage} />
        {/* <LoginLayoutRoute path={ROUTES.SIGN_UP} component={SignUpPage} /> */}
        <LoginLayoutRoute path={ROUTES.PASSWORD_FORGET} component={PasswordForgetPage} />

        <Route exact={true} path="/" render={redirectToStart} />
        <AppLayoutRoute path="/about" component={AboutPage} />
        <Route exact={true} path="/start" component={StartPage} />
        <Route path={ROUTES.ACCESS_DENIED} component={AccessDeniedPage} />
        <Route path="/sentry" component={SentryPage} />
        <Route component={ErrorPage} />
      </Switch>
    </Router>
  )
}

export default withAuthentication(AppRouter)
