import * as React from 'react'

/* Import components here */
import { WithTranslation, useTranslation } from 'react-i18next'
import { Switch, Route, Link } from 'react-router-dom'
import { compose } from 'recompose'

import { AuthUserContext, withAuthorization, withEmailVerification } from '../../contexts'
import { UserItem, UserList, UserCreateForm } from '../../components'
import * as ROLES from '../../constants/roles'
import * as ROUTES from '../../constants/routes'
import { Menu, Icon } from 'antd'
export const AdminPage: React.FC<WithTranslation> = ({ children, ...props }) => {
  const { t } = useTranslation()
  return (
    <AuthUserContext.Consumer>
      {(authUser: any) => (
        <>
          <span style={{ display: 'none' }}>Admin {t('common:lorem')}</span>
          <Menu mode="horizontal">
            <Menu.Item key="1">
              <Link to={ROUTES.ADMIN_USERS}>
                <Icon type="team" />
                Användarlista
              </Link>
            </Menu.Item>
            <Menu.Item key="2">
              <Link to={ROUTES.ADMIN_USER_CREATE}>
                <Icon type="user-add" />
                Skapa ny användare
              </Link>
            </Menu.Item>
            <Menu.Item key="3">
              <Link to={`${ROUTES.ADMIN_USERS}/${authUser.uid}`}>
                <Icon type="user" />
                Min profil
              </Link>
            </Menu.Item>
          </Menu>
          <Switch>
            <Route
              exact
              path={ROUTES.ADMIN_USER_DETAILS}
              render={props => <UserItem {...props} authUser={authUser} />}
            />
            <Route exact path={ROUTES.ADMIN_USERS} component={UserList} />
            <Route
              exact
              path={ROUTES.ADMIN_USER_CREATE}
              render={props => <UserCreateForm {...props} authUser={authUser} />}
            />
          </Switch>
        </>
      )}
    </AuthUserContext.Consumer>
  )
}

const condition = authUser => authUser && !!authUser.roles[ROLES.ADMIN]

export default compose(withEmailVerification, withAuthorization(condition))(AdminPage)
