import * as React from 'react'

import { withTranslation, useTranslation } from 'react-i18next'

/* Import components here */
import { Form, EmailInput } from './PasswordForgetForm.styles'

/* Import interfaces here */
import { IPasswordForgetFormProps, IPasswordForgetFormState } from './PasswordForgetForm.interfaces'

/* Import utilities here */
import { Link } from 'react-router-dom'
import { compose } from 'recompose'

import { withFirebase } from '../../firebase'
import * as ROUTES from '../../constants/routes'
import { Button, Icon, Alert } from 'antd'

const INITIAL_STATE = {
  email: '',
  error: null,
  message: null
}
export class PasswordForgetFormBase extends React.Component<
  IPasswordForgetFormProps,
  IPasswordForgetFormState
> {
  constructor(props) {
    super(props)

    this.state = { ...INITIAL_STATE }
  }

  onSubmit = event => {
    const { email } = this.state

    this.props.firebase
      .doPasswordReset(email)
      .then(() => {
        this.setState({ ...INITIAL_STATE, message: 'Mail skickat till ' + email })
      })
      .catch(error => {
        this.setState({ error, message: null })
      })

    event.preventDefault()
  }

  onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      [e.target.name]: e.target.value
    } as { [K in keyof IPasswordForgetFormState]: IPasswordForgetFormState[K] })
  }

  public render() {
    //const { t, children, ...props } = this.props
    const { email, error, message } = this.state

    const isInvalid = email === ''

    return (
      <Form onSubmit={this.onSubmit}>
        <EmailInput
          prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />}
          placeholder="Email"
          value={email}
          onChange={this.onChange}
          name="email"
        />
        <Button style={{ marginTop: 10 }} disabled={isInvalid} htmlType="submit">
          Återställ Lösenord
        </Button>
        {message && <Alert style={{ marginTop: 15 }} message={message} type="success" />}
        {error && <Alert style={{ marginTop: 15 }} message={error.message} type="error" />}
      </Form>
    )
  }
}

const PasswordForgetLink = () => {
  const { t } = useTranslation()
  return (
    <p>
      <Link to={ROUTES.PASSWORD_FORGET}>{t('signin:Glömt lösenord?')}</Link>
    </p>
  )
}

const SignInLink = () => {
  const { t } = useTranslation()
  return (
    <p style={{ marginTop: 15 }}>
      <Link to={ROUTES.SIGN_IN}>{t('signin:Tillbaka till inloggning')}</Link>
    </p>
  )
}

const PasswordForgetForm = compose(withTranslation(), withFirebase)(PasswordForgetFormBase)

export default PasswordForgetForm

export { PasswordForgetLink, SignInLink }
