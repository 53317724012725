import * as React from 'react'

import { withTranslation } from 'react-i18next'

/* Import components here */
import { StyledRealEstateListItem, CenterContainer, CenteredDiv } from './RealEstateListItem.styles'

/* Import interfaces here */
import { IRealEstateListItemProps, IRealEstateListItemState } from './RealEstateListItem.interfaces'

/* Import utilities here */
import { withFirebase } from '../../../../firebase'
import { compose } from 'recompose'
import { Spin, PageHeader, Icon, Layout, Menu, Divider } from 'antd'
import { Link } from 'react-router-dom'
import * as ROUTES from '../../../../constants/routes'
import { RealEstateListItemOverview, RealEstateEditForm, RealEstateEditPicture } from 'components'
const { Content, Sider } = Layout
export class RealEstateListItem extends React.Component<
  IRealEstateListItemProps,
  IRealEstateListItemState
> {
  unsubscribe: any
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      realEstate: null,
      content: '1',
      ...props.location.state
    }
  }

  componentDidMount() {
    if (this.state.realEstate) {
      this.listenToRealEstateChanges()
      return
    }
    this.setState({ loading: true })
    this.listenToRealEstateChanges()
  }
  componentWillUnmount() {
    this.unsubscribe && this.unsubscribe()
  }

  listenToRealEstateChanges = () => {
    this.unsubscribe = this.props.firebase
      .realEstate(this.props.match.params.realEstateID)
      .onSnapshot(snapshot => {
        this.setState({
          realEstate: snapshot.data(),
          loading: false
        })
      })
  }

  handleClick = e => {
    this.setState({ content: e.key })
  }

  public render() {
    const { t, children, ...props } = this.props
    const { realEstate, loading, content } = this.state

    return (
      <StyledRealEstateListItem {...props}>
        {loading && (
          <CenterContainer style={{ minHeight: '701px' }}>
            <CenteredDiv>
              <Spin size="large" />
            </CenteredDiv>
          </CenterContainer>
        )}
        {realEstate && (
          <Layout>
            <PageHeader
              style={{ backgroundColor: 'white' }}
              backIcon={
                <Link to={ROUTES.SETTINGS_REAL_ESTATES}>
                  <Icon type="arrow-left" />
                </Link>
              }
              onBack={() => null}
              title={'Gå tillbaka'}
            />
            <Layout>
              <Sider width={200} style={{ background: '#fff' }}>
                <Menu
                  onClick={this.handleClick}
                  mode="inline"
                  defaultSelectedKeys={['1']}
                  style={{ height: '100%' }}
                >
                  <Menu.Item key="1">
                    <Icon type="file-search" />
                    <span>Översikt</span>
                  </Menu.Item>
                  <Menu.Item key="2" disabled={!realEstate.isOwned}>
                    <Icon type="edit" />
                    <span>Redigera</span>
                  </Menu.Item>
                  {/* <Menu.Item key="3">
                    <Icon type="setting" />
                    <span>Inställningar</span>
                  </Menu.Item> */}
                </Menu>
              </Sider>
              <Content style={{ padding: '0 24px', minHeight: 280, backgroundColor: 'white' }}>
                {content === '1' && <RealEstateListItemOverview realEstate={realEstate} />}
                {content === '2' && (
                  <div>
                    <RealEstateEditForm realEstate={realEstate} />
                    <Divider />
                    <RealEstateEditPicture realEstate={realEstate} />
                  </div>
                )}
              </Content>
            </Layout>
          </Layout>
        )}
      </StyledRealEstateListItem>
    )
  }
}

export default compose(withTranslation(), withFirebase)(RealEstateListItem)
