import * as React from 'react'

/* Import components here */
import { DashBoardCardStatisticGrid, DashBoardChartTabPanel } from 'components'
import { compose } from 'recompose'

import { withAuthorization, withEmailVerification } from '../../contexts'
export const DashboardPage: React.FC = ({ children, ...props }) => {
  return (
    <div>
      <DashBoardChartTabPanel />
      <DashBoardCardStatisticGrid />
    </div>
  )
}

const condition = authUser => !!authUser

export default compose(withEmailVerification, withAuthorization(condition))(DashboardPage)
