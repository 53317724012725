import * as React from 'react'

/* Import components here */
import { PasswordForgetForm, SignInLink } from 'components'
import { WithTranslation } from 'react-i18next'
import styled from 'styled-components'
import logo from '../../layouts/AppLayout/FDLogolong.png'

const PasswordForgetLogo = styled.div`
  width: 100%;
  height: 100px;
  position: relative;
  display: inline-block;
  overflow: hidden;
  margin: 0;
  background-color: 'blue';
`
export const PasswordForgetPage: React.FC<WithTranslation> = ({ children, ...props }) => {
  // const { t } = useTranslation()
  return (
    <>
      <PasswordForgetLogo>
        <img height="80" src={logo} alt="logo" />
      </PasswordForgetLogo>
      <PasswordForgetForm />
      <SignInLink />
    </>
  )
}

export default PasswordForgetPage
