import { styled } from 'theme'

export const StyledMessageItem = styled.div`
  background-color: white;
  width: 40rem;

  .ant-switch-checked {
    background-color: #daa520;
  }
`
export const CommentContainer = styled.div`
  opacity: 0.9;

  :hover {
    opacity: 1;
    cursor: pointer;
    -webkit-transform: scale(1.01);
    -ms-transform: scale(1.01);
    transform: scale(1.01);
  }
  .ant-comment-inner {
    padding: 0;
  }
`

export const Paragraph = styled.p`
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  -webkit-box-orient: vertical;
`
