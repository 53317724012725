import * as React from 'react'

/* Import components here */
import { Page } from 'components'
import { WithTranslation, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { observer } from 'mobx-react'
import { useStores } from '../../stores'

import { RootStoreContext } from '../../stores'

export const LandingPage: React.FC<WithTranslation> = ({ children, ...props }) => {
  const { t } = useTranslation()

  return (
    <RootStoreContext.Consumer>
      {rootStore => (
        <Page {...props}>
          {rootStore.rootStore.employeeStore.allEmployees.map(emp => (
            <div>Hej</div>
          ))}
          <h1>{t('landing:title')}</h1>
          <p>{t('landing:description')}</p>
          <Counter />
          <Link to="/home">Home</Link>
          {children}
        </Page>
      )}
    </RootStoreContext.Consumer>
  )
}

export default LandingPage

export const Counter = observer(() => {
  const { rootStore } = useStores()
  const { employeeStore } = rootStore

  return (
    <>
      <ul>
        {employeeStore.allEmployees.map(employee => (
          <li key={employee.userID}>{employee.firstName}</li>
        ))}
      </ul>
    </>
  )
})
