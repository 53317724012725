import * as React from 'react'

import { withTranslation } from 'react-i18next'
import { AuthUserContext } from '../../../contexts'

/* Import components here */
// import { StyledAssignmentCreatorDrawer } from './AssignmentCreatorDrawer.styles'
import { Button, Checkbox, Col, Divider, Drawer, Form, Icon, Input, Radio, Row, Select } from 'antd'

/* Import interfaces here */
import {
  IAssignmentCreatorDrawerProps,
  IProps,
  IAssignmentCreatorDrawerState
} from './AssignmentCreatorDrawer.interfaces'

/* Import utilities here */
import { compose } from 'recompose'
import { withFirebase } from '../../../firebase'
import { rootStore } from '../../../stores/'
const { Option } = Select
let id = 2

export class AssignmentCreatorDrawer extends React.Component<
  IAssignmentCreatorDrawerProps,
  IAssignmentCreatorDrawerState
> {
  constructor(props) {
    super(props)
    this.state = {
      selectedRentalObject: null
    }
  }

  public handleSubmit = (e, authUser) => {
    e.preventDefault()
    this.props.form.validateFields((err, values) => {
      if (!err) {
        // console.log(values)
        const assignmentId = this.props.firebase.getUniqueID()
        let toBeInvoiced = false
        if (values['checkbox-group'] !== undefined) {
          for (const key of values['checkbox-group']) {
            if (key === 'toBeInvoiced') {
              toBeInvoiced = true
            }
          }
        }
        const { fromPage, rentalObjects, selectedRentalObjectID } = this.props
        const selectedRentalObject =
          fromPage === 'realEstates'
            ? this.state.selectedRentalObject
            : rentalObjects.find(obj => obj.rentalObjectID === selectedRentalObjectID)
        const assignedUser = rootStore.employeeStore.getEmployeeWithID(values.employee)
        const currentTentants = this.getTenantsCurrentlyLivingInRentalObject(selectedRentalObject)

        let contactPersons = currentTentants.map((tenant, index) => {
          if (
            tenant.firstName === values['firstName' + index] &&
            tenant.lastName === values['lastName' + index]
          ) {
            return {
              firstName: values['firstName' + index],
              lastName: values['lastName' + index],
              phoneNumbers: values['keys' + tenant.tenantID].map(
                key => values['phoneNumber' + tenant.tenantID][key]
              ),
              tenantID: tenant.tenantID
            }
          } else {
            return {
              firstName: values['firstName' + index],
              lastName: values['lastName' + index],
              phoneNumbers: values['keys' + tenant.tenantID].map(
                key => values['phoneNumber' + tenant.tenantID][key]
              ),
              tenantID: 'tempTenant'
            }
          }
        })
        // if (currentTentants.length === 0) { Om man valde fastigheten blir det 0
        //   console.log('No CurrentTentants', values)
        // }

        const assignment: any = {
          address: {
            city: selectedRentalObject.address.city,
            realEstateName: selectedRentalObject.address.realEstateName,
            rentalObjectNumber: selectedRentalObject.address.rentalObjectNumber,
            street: selectedRentalObject.address.street,
            zipCode: selectedRentalObject.address.zipCode
          },
          areas: values.location,
          assignedDate:
            values.employee === 'Otilldelad'
              ? null
              : this.props.firebase.timestamp.fromDate(new Date(Date.now())),
          assignedUserEmail: assignedUser ? assignedUser.email : '',
          assignedUserID: assignedUser ? assignedUser.userID : '',
          assignedUserName: assignedUser
            ? assignedUser.firstName + ' ' + assignedUser.lastName
            : '',
          assignedUserPhoneNumbers:
            assignedUser && assignedUser.phoneNumbers ? assignedUser.phoneNumbers : '',
          assignmentID: assignmentId,
          billable: toBeInvoiced,
          billed: toBeInvoiced ? false : null,
          contactPersons,
          creatorEmail: authUser.email,
          creatorID: authUser.userID,
          creatorName: authUser.firstName + ' ' + authUser.lastName,
          dateCreated: this.props.firebase.timestamp.fromDate(new Date(Date.now())),
          dateFinished: null,
          description: values.description,
          lastEditedDate: null,
          lastEditedEmail: '',
          lastEditedID: '',
          lastEditedName: '',
          masterKeyAllowed: values.masterKeyAllowed === 'masterKeyAllowed' ? true : false,
          priority: values.priority,
          realEstateID: this.props.realEstate.realEstateID,
          rentalObjectID: values.apartmentFromStore
            ? values.apartmentFromStore
            : values.apartmentFromSelect,
          sendSMS: assignedUser && assignedUser.allowSMS ? assignedUser.allowSMS : false
        }
        if (toBeInvoiced) {
          assignment.hoursToInvoice = 0
          assignment.materialUsed = ''
        }
        // console.log(assignmentId, assignment)
        this.props.firebase.addAssignment(assignmentId, assignment)
        this.closeDrawer()
      }
    })
  }

  public handleChange = selectedRentalObjectID => {
    // console.log('Selected Ap ', selectedRentalObjectID)
    // console.log(this.props.rentalObjects)
    const { selectedRentalObject } = this.state
    // console.log('Old Selected', selectedRentalObject)
    const newlySelectedRentalObject = this.props.rentalObjects.find(
      rentalObject => rentalObject.rentalObjectID === selectedRentalObjectID
    )
    // console.log('New selected', newlySelectedRentalObject)
    if (selectedRentalObject) {
      const oldTenantLength = this.getTenantsCurrentlyLivingInRentalObject(selectedRentalObject)
        .length
      const newTenants = this.getTenantsCurrentlyLivingInRentalObject(newlySelectedRentalObject)

      for (let i = 0; i < oldTenantLength && i < newTenants.length; i++) {
        this.props.form.setFieldsValue({
          ['firstName' + i]: newTenants[i].firstName,
          ['lastName' + i]: newTenants[i].lastName
        })
      }
    }
    this.setState({ selectedRentalObject: newlySelectedRentalObject })
  }

  public remove = (k, tenant) => {
    const { form } = this.props
    // can use data-binding to get
    const keys = form.getFieldValue('keys' + tenant.tenantID)
    // We need at least one passenger
    if (keys.length === 1) {
      return
    }
    // can use data-binding to set
    form.setFieldsValue({
      ['keys' + tenant.tenantID]: keys.filter(key => key !== k)
    })
  }

  public add = tenant => {
    const { form } = this.props
    // can use data-binding to get
    const keys = form.getFieldValue('keys' + tenant.tenantID)
    const nextKeys = keys.concat(id++)
    // can use data-binding to set
    // important! notify form to detect changes
    form.setFieldsValue({
      ['keys' + tenant.tenantID]: nextKeys
    })
  }

  public getPhoneNumberForm = tenant => {
    const { getFieldDecorator, getFieldValue } = this.props.form
    getFieldDecorator('keys' + tenant.tenantID, {
      initialValue: tenant.phoneNumbers !== null ? tenant.phoneNumbers.map((x, index) => index) : []
    })
    const keys = getFieldValue('keys' + tenant.tenantID)
    const formItems = keys.map((k, index) => (
      <Col span={6} key={k}>
        <Form.Item label={index === 0 ? 'Telefonnummer' : 'Telefonnummer'} required={false}>
          {getFieldDecorator(`phoneNumber${tenant.tenantID}[${k}]`, {
            initialValue: tenant.phoneNumbers !== null ? tenant.phoneNumbers[k] : [],
            rules: [
              {
                message: 'Ange telefonnummer eller ta bort fält',
                required: true,
                whitespace: true
              }
            ],
            validateTrigger: ['onChange', 'onBlur']
          })(<Input placeholder="Telefonnummer" style={{ width: '80%', marginRight: 8 }} />)}
          {keys.length > 1 ? (
            <Icon
              className="dynamic-delete-button"
              type="minus-circle"
              theme="twoTone"
              twoToneColor="#eb2f2f"
              // tslint:disable-next-line: jsx-no-lambda
              onClick={() => this.remove(k, tenant)}
            />
          ) : null}
        </Form.Item>
      </Col>
    ))
    return formItems
  }

  public getTenantsCurrentlyLivingInRentalObject = rentalObject => {
    const todayTimestamp = this.props.firebase.timestamp.fromDate(new Date())
    return rentalObject.tenantHistory
      .map(tenant => tenant)
      .filter(
        tenant =>
          tenant.moveInDate &&
          tenant.moveInDate.toMillis() < todayTimestamp.toMillis() &&
          (tenant.moveOutDate === null || tenant.moveOutDate.toMillis() > todayTimestamp.toMillis())
      )
  }
  public getTenantsCurrentlyLivingInRentalObjectWithID = rentalObjectID => {
    const todayTimestamp = this.props.firebase.timestamp.fromDate(new Date())
    const ap = this.props.rentalObjects.find(apt => apt.rentalObjectID === rentalObjectID)
    if (ap) {
      return ap.tenantHistory
        .map(tenant => tenant)
        .filter(
          tenant =>
            tenant.moveInDate &&
            tenant.moveInDate.toMillis() < todayTimestamp.toMillis() &&
            (tenant.moveOutDate === null ||
              tenant.moveOutDate.toMillis() > todayTimestamp.toMillis())
        )
    }
  }

  public closeDrawer = () => {
    this.props.form.resetFields()
    this.setState({
      selectedRentalObject: null
    })
    this.props.closeDrawer()
  }

  public render() {
    const { getFieldDecorator, getFieldValue } = this.props.form
    const { visible, fromPage, realEstate, rentalObjects, selectedRentalObjectID } = this.props
    const selectedRentalObject =
      fromPage === 'realEstates'
        ? this.state.selectedRentalObject
        : rentalObjects.find(obj => obj.rentalObjectID === selectedRentalObjectID)

    return (
      <AuthUserContext.Consumer>
        {(authUser: any) => (
          <Drawer
            title="Skapa Felanmälan"
            maskClosable={false}
            closable={false}
            width={720}
            onClose={this.closeDrawer}
            visible={visible}
            // style={{
            //   height: 'calc(100% - 108px)',
            //   overflow: 'auto',
            //   paddingBottom: '108px',
            // }}
          >
            <Form layout="vertical" hideRequiredMark={true}>
              <Row gutter={16}>
                <Col span={24}>
                  {selectedRentalObject && fromPage === 'rentalObjectTable' && (
                    <Form.Item label="Lägenhet / Lokal">
                      {getFieldDecorator('apartmentFromStore', {
                        initialValue: selectedRentalObject.rentalObjectID,
                        rules: [{ required: true, message: 'Välj en lägenhet' }]
                      })(
                        <Select disabled={true}>
                          <Option
                            key={selectedRentalObject.rentalObjectID}
                            value={selectedRentalObject.rentalObjectID}
                          >
                            {selectedRentalObject.type === 'Fast' ? (
                              <span>{selectedRentalObject.address.rentalObjectNumber}</span>
                            ) : (
                              <span>
                                {!!selectedRentalObject.address.city && (
                                  <span>{selectedRentalObject.address.city} </span>
                                )}
                                {!!selectedRentalObject.address.street && (
                                  <span>{selectedRentalObject.address.street} </span>
                                )}
                                {!!selectedRentalObject.address.zipCode && (
                                  <span>{selectedRentalObject.address.zipCode} </span>
                                )}
                                {!!selectedRentalObject.address.rentalObjectNumber && (
                                  <span>{selectedRentalObject.address.rentalObjectNumber} </span>
                                )}
                              </span>
                            )}
                          </Option>
                        </Select>
                      )}
                    </Form.Item>
                  )}

                  {fromPage === 'realEstates' && (
                    <Form.Item label="Lägenhet / Lokal">
                      {getFieldDecorator('apartmentFromSelect', {
                        rules: [{ required: true, message: 'Välj en lägenhet' }]
                      })(
                        <Select
                          style={{ position: 'relative' }}
                          // tslint:disable-next-line: jsx-no-lambda
                          getPopupContainer={() =>
                            document.getElementById('real-estate_error_report_apartmentFromSelect')!
                          }
                          onChange={this.handleChange}
                          placeholder="Välj en lägenhet"
                          showSearch={true}
                          optionFilterProp="children"
                          // filterOption={(input, option) => {
                          //   option.props.children
                          //     ? option.props.children
                          //         .toString()
                          //         .toLowerCase()
                          //         .indexOf(input.toLowerCase()) >= 0
                          //     : null
                          // }}
                        >
                          {rentalObjects
                            .slice()
                            .sort((a, b) =>
                              a.address.rentalObjectNumber < b.address.rentalObjectNumber
                                ? -1
                                : a.address.rentalObjectNumber > b.address.rentalObjectNumber
                                ? 1
                                : 0
                            )
                            .map(apartment => {
                              return (
                                <Option
                                  key={apartment.rentalObjectID}
                                  value={apartment.rentalObjectID}
                                >
                                  {apartment.type === 'Fastighet'
                                    ? `(${apartment.address.rentalObjectNumber}) ◦ ${apartment.address.realEstateName}`
                                    : `${!!apartment.address.rentalObjectNumber &&
                                        `(${apartment.address.rentalObjectNumber}) ◦`}
                                      ${!!apartment.address.street && apartment.address.street}
                                      ${!!apartment.address.zipCode && apartment.address.zipCode}
                                      ◦
                                      ${this.getTenantsCurrentlyLivingInRentalObjectWithID(
                                        apartment.rentalObjectID
                                      ).map(tenant => {
                                        return tenant.firstName + ' ' + tenant.lastName
                                      })}
                                    `}
                                </Option>
                              )
                            })}
                        </Select>
                      )}
                    </Form.Item>
                  )}
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="Uppdragstagare">
                    {getFieldDecorator('employee', {
                      initialValue: 'Otilldelad',
                      rules: [{ required: true, message: 'Välj en uppdragstagare' }]
                    })(
                      <Select
                        style={{ position: 'relative' }}
                        // tslint:disable-next-line: jsx-no-lambda
                        getPopupContainer={() =>
                          document.getElementById('real-estate_error_report_employee')!
                        }
                        placeholder="Välj en uppdragstagare"
                        showSearch={true}
                        optionFilterProp="children"
                      >
                        <Option key="Otilldelad" value="Otilldelad">
                          Otilldelad
                        </Option>
                        {rootStore.employeeStore.allJanitors.map(employee => {
                          return (
                            <Option key={employee.userID} value={employee.userID}>
                              {employee.firstName} {employee.lastName} {employee.email}
                            </Option>
                          )
                        })}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Prioritet">
                    {getFieldDecorator('priority', {
                      initialValue: rootStore.settingStore.settings.priorities[0],
                      rules: [{ required: true, message: 'Välj prioritet' }]
                    })(
                      <Select
                        placeholder="Välj prioritet"
                        style={{ position: 'relative' }}
                        // tslint:disable-next-line: jsx-no-lambda
                        getPopupContainer={() =>
                          document.getElementById('real-estate_error_report_priority')!
                        }
                      >
                        {rootStore.settingStore.settings.priorities.map(setting => {
                          return (
                            <Option key={setting} value={setting}>
                              {setting}
                            </Option>
                          )
                        })}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item label="Utrymme">
                    {getFieldDecorator('location', {
                      rules: [{ required: true, message: 'Välj vilket eller vilka utrymmen' }]
                    })(
                      <Select
                        mode="multiple"
                        style={{ width: '100%', position: 'relative' }}
                        placeholder="Välj vilket eller vilka utrymmen"
                        // tslint:disable-next-line: jsx-no-lambda
                        getPopupContainer={() =>
                          document.getElementById('real-estate_error_report_location')!
                        }
                      >
                        {rootStore.settingStore.settings.areas.map(area => {
                          return (
                            <Option key={area} value={area}>
                              {area}
                            </Option>
                          )
                        })}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Divider />
              <h3>Kontaktperson</h3>
              {selectedRentalObject &&
                this.getTenantsCurrentlyLivingInRentalObject(selectedRentalObject).map(
                  (tenant, index) => {
                    return (
                      <Row key={index} gutter={16}>
                        <Col span={6}>
                          <Form.Item label="Förnamn">
                            {getFieldDecorator('firstName' + index, {
                              initialValue: tenant.firstName,
                              rules: [{ required: false, message: 'Skriv in förnamn' }]
                            })(<Input placeholder="Förnamn" />)}
                          </Form.Item>
                        </Col>
                        <Col span={6}>
                          <Form.Item label="Efternamn">
                            {getFieldDecorator('lastName' + index, {
                              initialValue: tenant.lastName,
                              rules: [{ required: true, message: 'Skriv in efternamn' }]
                            })(<Input placeholder="Efternamn" />)}
                          </Form.Item>
                        </Col>
                        {this.getPhoneNumberForm(tenant)}
                        {getFieldValue('keys' + tenant.tenantID).length < 2 ? (
                          <Col span={6}>
                            <Form.Item
                              label={<span style={{ visibility: 'hidden' }}>Titel</span>}
                              style={{ textAlign: 'center' }}
                            >
                              <Button
                                type="link"
                                // tslint:disable-next-line: jsx-no-lambda
                                /* onClick={() => this.add(tenant)} */
                                onClick={this.add.bind(this, tenant)}
                              >
                                <Icon
                                  className="dynamic-delete-button"
                                  type="plus-circle"
                                  theme="twoTone"
                                  twoToneColor="#52c41a"
                                />{' '}
                                Extra nummer
                              </Button>
                            </Form.Item>
                          </Col>
                        ) : null}
                      </Row>
                    )
                  }
                )}
              {/* {(!selectedRentalObject || // Om man vill kunna skriva in person när man väljer Hela fastigheten
                this.getTenantsCurrentlyLivingInRentalObject(selectedRentalObject).length < 1) && (
                <Row gutter={16}>
                  <Col span={6}>
                    <Form.Item label="Förnamn">
                      {getFieldDecorator('firstName', {
                        rules: [{ required: true, message: 'Please enter user name' }]
                      })(<Input placeholder="Förnamn" />)}
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item label="Efternamn">
                      {getFieldDecorator('lastName', {
                        rules: [{ required: true, message: 'Please enter user name' }]
                      })(<Input placeholder="Efternamn" />)}
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item label="Telefonnummer" required={false}>
                      {getFieldDecorator(`phoneNumber`, {
                        rules: [
                          {
                            message: 'Ange telefonnummer',
                            whitespace: true
                          }
                        ],
                        validateTrigger: ['onChange', 'onBlur']
                      })(
                        <Input
                          placeholder="Telefonnummer"
                          style={{ width: '80%', marginRight: 8 }}
                        />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              )} */}
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item label="Adress">
                    {getFieldDecorator('address', {
                      initialValue:
                        realEstate.address.city +
                        ' ' +
                        realEstate.address.street +
                        ' ' +
                        realEstate.address.zipCode,
                      rules: [{ required: true, message: 'Adress' }]
                    })(<Input disabled={true} placeholder="Adress" />)}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item label="Åtgärdstext">
                    {getFieldDecorator('description', {
                      rules: [
                        {
                          message: 'Skriv in en åtgärdstext',
                          required: true
                        }
                      ]
                    })(<Input.TextArea rows={4} placeholder="Åtgärdstext" />)}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item>
                    {getFieldDecorator('masterKeyAllowed', {
                      initialValue: 'masterNotKeyAllowed'
                    })(
                      <Radio.Group buttonStyle="solid">
                        <Row>
                          <Col span={24} className="masterNotKeyAllowed">
                            <Radio.Button value="masterNotKeyAllowed" style={{ width: '100%' }}>
                              Hyresgäst godkänner INTE att huvudnyckel används
                            </Radio.Button>
                          </Col>
                          <Col span={24} className="masterKeyAllowed">
                            <Radio.Button value="masterKeyAllowed" style={{ width: '100%' }}>
                              Hyresgäst godkänner att huvudnyckel används
                            </Radio.Button>
                          </Col>
                        </Row>
                      </Radio.Group>
                    )}
                  </Form.Item>
                  <Form.Item>
                    {getFieldDecorator(
                      'checkbox-group',
                      {}
                    )(
                      <Checkbox.Group style={{ width: '100%' }}>
                        <Row>
                          <Col span={24}>
                            <Checkbox value="toBeInvoiced">Felanmälan ska faktureras</Checkbox>
                          </Col>
                        </Row>
                      </Checkbox.Group>
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Form>
            <div
              style={{
                background: '#fff',
                borderTop: '1px solid #e9e9e9',
                bottom: 0,
                left: 0,
                padding: '10px 16px',
                position: 'absolute',
                textAlign: 'right',
                width: '100%'
              }}
            >
              <Button onClick={this.closeDrawer} style={{ marginRight: 8 }}>
                Avbryt
              </Button>
              <Button
                onClick={e => this.handleSubmit(e, authUser)}
                type="primary"
                htmlType="submit"
              >
                Skapa
              </Button>
            </div>
          </Drawer>
        )}
      </AuthUserContext.Consumer>
    )
  }
}

export default Form.create<IProps>({ name: 'real-estate_error_report' })(
  compose(withTranslation(), withFirebase)(AssignmentCreatorDrawer)
)
