import * as React from 'react'

import { withTranslation } from 'react-i18next'

/* Import components here */
//import { StyledSignInForm } from './SignInForm.styles'

/* Import interfaces here */
import { ISignInFormProps, ISignInFormState } from './SignInForm.interfaces'

/* Import utilities here */
import { withFirebase } from '../../firebase'
import * as ROUTES from '../../constants/routes'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'

import { Button, Form, Icon, Input } from 'antd'
import styled from 'styled-components'

const EmailInput = styled(Input)`
  height: 50px;
`
const PasswordInput = styled(Input.Password)`
  height: 50px;
`

const StyledForm = styled(Form)`
  .ant-btn,
  .ant-btn[disabled] {
    color: white;
    width: 100%;
    height: 40px;
    border-radius: 25px;
    margin-top: 5px;
    background-color: #60944f;
    border-color: rgb(74, 117, 70);
  }
  .ant-btn:hover,
  .ant-btn[disabled]:hover {
    color: white;
    background-color: rgb(137, 196, 119);
    border-color: rgb(118, 172, 101);
  }
  .ant-btn:focus {
    color: white;
    background-color: rgb(137, 196, 119);
    border-color: rgb(118, 172, 101);
  }
`

const INITIAL_STATE = {
  loading: false,
  error: null
}
export class SignInFormBase extends React.Component<ISignInFormProps, ISignInFormState> {
  constructor(props) {
    super(props)

    this.state = { ...INITIAL_STATE }
  }

  public componentDidMount() {
    // To disabled submit button at the beginning.
    this.props.form.validateFields()
  }

  public handleSubmit = e => {
    e.preventDefault()
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { email, password } = values

        this.setState({ loading: true })
        this.props.firebase
          .doSignInWithEmailAndPassword(email, password)
          .then(() => {
            this.props.history.push(ROUTES.REAL_ESTATES) //ROUTES.HOME
          })
          .catch(error => {
            const errorMessage = this.getErrorMessage(error.code)
            this.setFieldsErrors(errorMessage)
            this.setState({ error, loading: false })
          })
      }
    })
  }

  public getErrorMessage = errorCode => {
    switch (errorCode) {
      case 'auth/user-not-found':
      case 'auth/wrong-password':
        return 'Användarnamn eller Lösenord är felaktigt'
      case 'auth/user-disabled':
        return 'Användaren är avstängd av Admin'
      case 'auth/too-many-requests':
        return 'För många inloggningsförsök, testa igen senare'
      default:
        return 'Inloggningen misslyckades'
    }
  }

  public setFieldsErrors = errorMessage => {
    this.props.form.setFields({
      password: {
        errors: [new Error(errorMessage)],
        value: this.props.form.getFieldValue('password')
      }
    })
  }

  public hasErrors(fieldsError) {
    return Object.keys(fieldsError).some(field => fieldsError[field])
  }

  public render() {
    const { t } = this.props
    const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } = this.props.form
    const { loading, error } = this.state
    const emailError = isFieldTouched('email') && getFieldError('email')
    const passwordError = (isFieldTouched('password') || error) && getFieldError('password')

    return (
      <StyledForm onSubmit={this.handleSubmit}>
        <Form.Item validateStatus={emailError ? 'error' : ''} help={emailError || ''}>
          {getFieldDecorator('email', {
            rules: [
              {
                type: 'email',
                message: 'Ogiltigt E-mail format !'
              },
              {
                required: true,
                message: 'Ange en E-mail!'
              }
            ]
          })(
            <EmailInput
              prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Email"
            />
          )}
        </Form.Item>
        <Form.Item validateStatus={passwordError ? 'error' : ''} help={passwordError || ''}>
          {getFieldDecorator('password', {
            rules: [{ required: true, message: 'Skriv in ditt Lösenord!' }]
          })(
            <PasswordInput
              prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="password"
              placeholder="Lösenord"
            />
          )}
        </Form.Item>
        <Form.Item className="submitButtonFormItem">
          {/* {error && <p style={{ color: 'red' }}>{error.message}</p>} */}
          <Button htmlType="submit" disabled={this.hasErrors(getFieldsError())} loading={loading}>
            {t('signin:Logga in')}
          </Button>
        </Form.Item>
      </StyledForm>
    )
  }
}

const SignOutButtonBase = ({ firebase }) => (
  <button type="button" onClick={firebase.doSignOut}>
    Sign Out
  </button>
)

const SignOutButton = withFirebase(SignOutButtonBase)

export default Form.create({ name: 'SignInForm' })(
  compose(withTranslation(), withRouter, withFirebase)(SignInFormBase)
)

export { SignOutButton }
