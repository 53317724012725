import { styled } from 'theme'

export const StyledRentalObjectAssignmentHistoryItemContent = styled.div`
  background-color: white;
`

export const Wrap = styled.div`
  display: flex;
`
export const Extra = styled.div`
  /* min-width: 100px; */
  padding: 1rem;
`
export const Content = styled.div`
  flex: 1;
  padding-right: 3rem;
`

export const BoldSpan = styled.span`
  font-weight: bold;
`

export const PostImgGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
  align-items: stretch;
  justify-items: stretch;

  img {
    border: 1px solid #ccc;
    box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.3);
    width: 100px;
    height: 100px;
  }
`

export const EditorWrapper = styled.div`
  .ant-form-item {
    margin-bottom: 0px;
  }

  .ant-form-item-control {
    line-height: 0px;
  }
`
