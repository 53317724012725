import { styled } from 'theme'
import { List } from 'antd'

export const StyledRentalObjectAssignmentHistoryList = styled.div`
  background-color: white;
  padding: 24px;
`

export const MenuTitle = styled.div`
  margin-bottom: 5px;
  font-weight: bold;
`

export const ListItem = styled(List.Item)`
  opacity: 0.9;

  :hover {
    opacity: 1;
    cursor: pointer;
    -webkit-transform: scale(1.01);
    -ms-transform: scale(1.01);
    transform: scale(1.01);
  }
`

export const CheckboxWrapper = styled.div`
  margin-top: 24px;
  > .ant-checkbox-group > .ant-checkbox-group-item {
    display: block;
    margin-right: 0;
  }
`
