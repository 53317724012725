import * as React from 'react'

import { withTranslation } from 'react-i18next'

/* Import components here */
import {
  StyledAccountAssignmentList,
  ListItem,
  CenterContainer,
  CenteredDiv
} from './AccountAssignmentList.styles'
import { List, Tag, Typography, Avatar, Empty } from 'antd'

/* Import interfaces here */
import {
  IAccountAssignmentListProps,
  IAccountAssignmentListState
} from './AccountAssignmentList.interfaces'

/* Import utilities here */
import { compose } from 'recompose'
import { withFirebase } from '../../firebase'
import { withRouter } from 'react-router-dom'
import City from '../../pages/Home/city.jpg'
import { rootStore } from 'stores'
import { observer } from 'mobx-react'
import AssignmentModal from './AssignmentModal'
const { inboxStore } = rootStore
const { Title, Text } = Typography
@observer
export class AccountAssignmentList extends React.Component<
  IAccountAssignmentListProps,
  IAccountAssignmentListState
> {
  state = {
    visible: false,
    selectedAssignment: null
  }
  showModal = assignment => {
    this.setState({ visible: true, selectedAssignment: assignment })
  }

  handleCancel = () => {
    this.setState({ visible: false, selectedAssignment: null })
  }
  public render() {
    const { t, children, ...props } = this.props
    const { visible, selectedAssignment } = this.state
    return (
      <StyledAccountAssignmentList {...props}>
        <AssignmentModal
          firebase={this.props.firebase}
          authUser={this.props.authUser}
          visible={visible}
          assignment={selectedAssignment}
          handleCancel={this.handleCancel}
        />
        <Title
          style={{
            textAlign: 'center',
            backgroundColor: 'white',
            height: 50,
            backgroundSize: 200,
            padding: 0,
            backgroundImage: `url(${City})`
          }}
        >
          <span style={{ backgroundColor: 'rgba(255, 255, 255, 0.5)', padding: '0px 10px' }}>
            <Text>Ärende Inkorg</Text>
          </span>
        </Title>
        <List
          locale={{
            emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Inkorgen är tom!" />
          }}
          itemLayout="vertical"
          size="large"
          dataSource={inboxStore.inbox}
          renderItem={(assignment: any) => {
            const contactPerson = assignment.contactPersons[0]
            return (
              <ListItem
                onClick={() =>
                  // this.props.history.push({
                  //   pathname: `${ROUTES.REAL_ESTATES}/${assignment.realEstateID}/${assignment.rentalObjectID}/${assignment.assignmentID}`,
                  //   state: { assignment }
                  // })
                  this.showModal(assignment)
                }
                actions={[
                  <span>
                    Inskickat av: {contactPerson.firstName} {contactPerson.lastName}
                  </span>,
                  <span>{contactPerson.email}</span>,
                  <span>{contactPerson.phoneNumbers}</span>
                ]}
                extra={
                  <CenterContainer>
                    <CenteredDiv>
                      <img
                        height={153}
                        width={272}
                        src={assignment.pictureURL}
                        alt="kunde ej hämta bild"
                      />
                    </CenteredDiv>
                  </CenterContainer>
                }
              >
                <List.Item.Meta
                  avatar={
                    <Avatar
                      style={{
                        verticalAlign: 'middle',
                        backgroundColor: assignment.masterKeyAllowed ? '#5E934F' : '#F5222D'
                      }}
                      icon="key"
                    />
                  }
                  title={
                    <span>
                      {assignment.address.realEstateName +
                        ' (' +
                        assignment.address.rentalObjectNumber +
                        ')'}
                      <Tag style={{ marginLeft: 16 }} color="blue">
                        {assignment.dateCreated.toDate().toLocaleString()}
                      </Tag>
                    </span>
                  }
                  description={
                    assignment.address.street +
                    ', ' +
                    assignment.address.zipCode +
                    ' ' +
                    assignment.address.city
                  }
                />
                <p>
                  {assignment.areas.map((area, index) => {
                    return <Tag key={index}>{area}</Tag>
                  })}
                </p>
                {assignment.description}
              </ListItem>
            )
          }}
        />
      </StyledAccountAssignmentList>
    )
  }
}

export default compose(withTranslation(), withRouter, withFirebase)(AccountAssignmentList)
