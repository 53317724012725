import * as React from 'react'

import { withTranslation } from 'react-i18next'

/* Import components here */
import { StyledUserList, MenuTitle } from './UserList.styles'

/* Import interfaces here */
import { IUserListProps, IUserListState } from './UserList.interfaces'

/* Import utilities here */
import { Link } from 'react-router-dom'
import { compose } from 'recompose'
import { withFirebase } from '../../../firebase'
import * as ROUTES from '../../../constants/routes'
//import Highlighter from 'react-highlight-words'
import { Table, Tag, Avatar, Badge, Select, Input, Icon } from 'antd'

const { Option } = Select
const { Column } = Table
const { Search } = Input
export class UserList extends React.Component<IUserListProps, IUserListState> {
  unsubscribe: any
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      users: [],
      allUsers: [],
      disabled: false,
      searchText: '',
      sortBy: 'lastName',
      sortOrder: 'desc'
    }
  }

  componentDidMount() {
    this.setState({ loading: true })

    this.unsubscribe = this.props.firebase.users().onSnapshot(snapshot => {
      const users: any = []

      snapshot.forEach(doc => users.push({ ...doc.data(), uid: doc.id }))
      users.sort((a, b) => this.sortByField(a, b, this.state.sortBy, this.state.sortOrder))
      this.setState({
        users,
        allUsers: users,
        loading: false
      })
    })
  }

  componentWillUnmount() {
    this.unsubscribe()
  }

  searchHandler = event => {
    const searchText = event.target.value.toLowerCase(),
      displayedContacts: any = this.state.allUsers.filter(obj => this.filterObject(obj, searchText))
    this.setState({
      users: displayedContacts,
      searchText
    })
  }

  filterObject(obj, query) {
    for (const key in obj) {
      // console.log(key)
      if (key === 'profilePicture') {
        return
      } else if (typeof obj[key] === 'object') {
        if (
          Object.values(obj[key])
            .toString()
            .toUpperCase()
            .includes(query.toUpperCase())
        ) {
          return obj
        }
      } else if (
        obj[key]
          .toString()
          .toUpperCase()
          .includes(query.toUpperCase())
      ) {
        return obj
      }
    }
  }

  onSortOrderChange = sortOrder => {
    const { users, sortBy } = this.state
    const sortedUsers: any[] = users.slice().sort((a, b) => this.sortByField(a, b, sortBy, sortOrder))
    this.setState({ users: sortedUsers, sortOrder })
  }
  onSortChange = sortBy => {
    const { users, sortOrder } = this.state
    const sortedUsers: any[] = users.slice().sort((a, b) => this.sortByField(a, b, sortBy, sortOrder))
    this.setState({ users: sortedUsers, sortBy })
  }

  sortByField = (a, b, field, sortOrder) => {
    const nameA = a[field].toUpperCase() // ignore upper and lowercase
    const nameB = b[field].toUpperCase() // ignore upper and lowercase
    if (nameA < nameB) {
      return sortOrder === 'desc' ? -1 : 1
    }
    if (nameA > nameB) {
      return sortOrder === 'desc' ? 1 : -1
    }
    // names must be equal
    return 0
  }

  public render() {
    const { t, children, ...props } = this.props
    const { users, allUsers } = this.state

    return (
      <StyledUserList {...props}>
        <div
          style={{
            minWidth: '1000px',
            backgroundColor: 'white',
            marginBottom: '24px',
            padding: '24px',
            display: 'flex'
          }}
        >
          <div style={{ width: 200, fontWeight: 'bold', lineHeight: '32px' }}>
            <Icon style={{ marginRight: 8 }} type="team" />
            {users.length}/{allUsers.length} Användare
          </div>
          <div style={{ flex: 1, margin: 'auto', maxWidth: 500, minWidth: 370 }}>
            <MenuTitle>Sök:</MenuTitle>
            <Search style={{ width: '90%' }} placeholder="Sök" onChange={this.searchHandler} />
          </div>
          <div style={{ float: 'right' }}>
            <MenuTitle>Sortera efter:</MenuTitle>
            <Select
              defaultValue="lastName"
              style={{ width: 130, marginRight: 8 }}
              onChange={this.onSortChange}
            >
              <Option value="firstName">Förnamn</Option>
              <Option value="lastName">Efternamn</Option>
              <Option value="email">Email</Option>
            </Select>
            <Select defaultValue="desc" onChange={this.onSortOrderChange}>
              <Option value="desc">A-Ö</Option>
              <Option value="asc">Ö-A</Option>
            </Select>
          </div>
        </div>
        <Table
          pagination={false}
          style={{ backgroundColor: 'white' }}
          tableLayout="auto"
          rowKey="userID"
          dataSource={users}
          onRow={(record, rowIndex) => {
            return {
              onDoubleClick: event => {
                console.log(event, 'Clicked')
              }
            }
          }}
        >
          <Column
            dataIndex="profilePicture"
            key="profilePicture"
            render={profilePicture => <Avatar size="large" src={profilePicture} icon="user" />}
          />
          <Column
            title="Förnamn"
            dataIndex="firstName"
            key="firstName"
            render={firstName => (
              firstName
            )}
          />
          <Column
            title="Efternamn"
            dataIndex="lastName"
            key="lastName"
            render={lastName => (
              lastName
            )}
          />
          <Column
            title="Email"
            dataIndex="email"
            key="email"
            render={email => (
                            email
            )}
          />
          <Column
            title="Telefonnummer"
            dataIndex="phoneNumbers"
            key="phoneNumbers"
            render={number => (
              number
            )}
          />
          <Column
            title="Roller"
            dataIndex="roles"
            key="roles"
            render={tags => (
              <span>
                {tags &&
                  Object.keys(tags).map((key, index) => {
                    if (tags[key]) {
                      return (
                        <Tag color={key === 'JANITOR' ? 'volcano' : 'geekblue'} key={key}>
                          {key === 'JANITOR' ? 'Fastighetsskötare' : 'Kontorspersonal'}
                        </Tag>
                      )
                    }
                    return ''
                  })}
              </span>
            )}
          />
          <Column
            title="Status"
            dataIndex="isActive"
            key="isActive"
            render={isActive => (
              <Badge
                status={isActive ? 'success' : 'error'}
                text={isActive ? 'Aktiv' : 'Inaktiv'}
              />
            )}
          />
          <Column
            title="Action"
            key="action"
            render={(text, record: any) => (
              <Link
                to={{ pathname: `${ROUTES.ADMIN_USERS}/${record.userID}`, state: { user: record } }}
              >
                Detaljer
              </Link>
            )}
          />
        </Table>
      </StyledUserList>
    )
  }
}

export default compose(withTranslation(), withFirebase)(UserList)
