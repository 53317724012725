import * as React from 'react'

import { withTranslation } from 'react-i18next'

/* Import components here */
import { StyledImportFeedback } from './ImportFeedback.styles'
import { Icon, List, Skeleton, Spin } from 'antd'

/* Import interfaces here */
import { IImportFeedbackProps, IImportFeedbackState } from './ImportFeedback.interfaces'

/* Import utilities here */
const data: any[] = [
  // {
  //   loading: true,
  //   title: 'Ant Design Title 1'
  // },
  // {
  //   loading: false,
  //   title: 'Ant Design Title 2',
  //   description: 'A very nice describing description describing the problem',
  //   status: 'success'
  // },
  {
    loading: true,
    title: 'Granskar att Filen är i rätt format',
    description: 'Det här kan ta några minuter',
    status: 'warning'
  }
  // {
  //   loading: false,
  //   title: 'Ant Design Title 4',
  //   description: 'A very nice describing description describing the problem',
  //   status: 'error'
  // }
]

const loadingIcon = <Icon type="loading" style={{ fontSize: 24 }} spin={true} />
const successIcon = <Icon type="check-circle" style={{ fontSize: 24, color: 'green' }} />
const warningIcon = <Icon type="exclamation-circle" style={{ fontSize: 24, color: '#FAAD27' }} />
const errorIcon = <Icon type="close-circle" style={{ fontSize: 24, color: 'red' }} />
export class ImportFeedback extends React.Component<IImportFeedbackProps, IImportFeedbackState> {
  public getResultIcon = status => {
    switch (status) {
      case 'success':
        return successIcon
      case 'warning':
        return warningIcon
      case 'error':
        return errorIcon
      default:
        return errorIcon
    }
  }

  public render() {
    const { t, children, ...props } = this.props

    return (
      <StyledImportFeedback {...props}>
        <List
          itemLayout="horizontal"
          dataSource={data}
          renderItem={item => (
            <List.Item
            // actions={[
            //   <a href="/settings" key="list-loadmore-edit">
            //     Action 1
            //   </a>,
            //   <a href="/settings" key="list-loadmore-more">
            //     Action 2
            //   </a>
            // ]}
            >
              <List.Item.Meta
                avatar={
                  item.loading ? <Spin indicator={loadingIcon} /> : this.getResultIcon(item.status)
                }
                title={<a href="https://ant.design">{item.title}</a>}
                description={
                  <Skeleton avatar={false} title={false} loading={!item.loading} active={true}>
                    {item.description}
                  </Skeleton>
                }
              />
            </List.Item>
          )}
        />
        {/* <Button style={{ marginTop: '48px' }} type="primary" block={true}>
          Primary
        </Button> */}
      </StyledImportFeedback>
    )
  }
}

export default withTranslation()(ImportFeedback)
