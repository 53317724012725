import { styled } from 'theme'

export const StyledImportSteps = styled.div`
  background-color: white;
`
export const StyledSettings = styled.div`
  background: white;
`

export const StepsContent = styled.div`
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  /* text-align: center; */
  /* padding-top: 80px; */
`
export const StepsAction = styled.div`
  margin-top: 24px;
`

export const StepsWrapper = styled.div`
  padding: 0px 24px;
  min-width: 810px;
`
