import * as React from 'react'
import { useTranslation, WithTranslation } from 'react-i18next'
import { RouteComponentProps } from 'react-router-dom'
import { Link } from 'react-router-dom'

// Interfaces
interface IErrorPageProps extends WithTranslation, RouteComponentProps {}

export const ErrorPage: React.FunctionComponent<IErrorPageProps> = props => {
  const { t } = useTranslation()
  console.log(props)

  return (
    <div>
      <h1>{t('error:page.not-found')}</h1>
      <p>{t('error:page.not-found-text')}</p>
      <Link to="/">{t('error:go-home')}</Link>
    </div>
  )
}
