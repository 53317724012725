import * as React from 'react'

import { withTranslation } from 'react-i18next'

/* Import components here */
import { StyledRealEstateSearchBar } from './RealEstateSearchBar.styles'
import { Input } from 'antd'

/* Import interfaces here */
import {
  IRealEstateSearchBarProps,
  IRealEstateSearchBarState
} from './RealEstateSearchBar.interfaces'

/* Import utilities here */
const Search = Input.Search

export class RealEstateSearchBar extends React.Component<
  IRealEstateSearchBarProps,
  IRealEstateSearchBarState
> {
  public handleOnChange = e => {
    this.props.searchRealEstate(e.target.value)
  }

  public handleSearch = inputValue => {
    this.props.searchRealEstate(inputValue)
  }

  public render() {
    const { inputValue, t, children, ...props } = this.props

    return (
      <StyledRealEstateSearchBar {...props}>
        <Search
          placeholder="Sök på t.ex. Fastighetsnamn, Gata, Stad, Postnummer"
          enterButton="Sök"
          size="large"
          value={inputValue}
          onChange={this.handleOnChange}
          onSearch={this.handleSearch}
        />
      </StyledRealEstateSearchBar>
    )
  }
}

export default withTranslation()(RealEstateSearchBar)
