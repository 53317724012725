import * as React from 'react'

import { withTranslation } from 'react-i18next'

/* Import components here */
import {
  RealEstateSearchBar,
  RealEstateCitySelect,
  AssignmentCreatorDrawer
} from '../../../components'
import {
  StyledRealEstateGrid,
  StyledCard,
  CenterContainer,
  CenteredDiv
} from './RealEstateGrid.styles'
import { Card, Col, Row, Icon, Tooltip, Spin } from 'antd'

/* Import interfaces here */
import { IRealEstateGridProps, IRealEstateGridState } from './RealEstateGrid.interfaces'
import { Link } from 'react-router-dom'

/* Import utilities here */
import * as ROUTES from '../../../constants/routes'
import { compose } from 'recompose'
import { withFirebase } from '../../../firebase'
const { Meta } = Card

export class RealEstateGrid extends React.Component<IRealEstateGridProps, IRealEstateGridState> {
  unsubscribe: any
  constructor(props) {
    super(props)
    this.state = {
      allCities: [],
      filteredRealEstates: [],
      rentalObjects: [],
      loading: false,
      realEstates: [],
      realEstate: null,
      selectedCity: 'All',
      searchBarInput: '',
      visible: false
    }
  }

  componentDidMount() {
    this.setState({ loading: true })

    this.unsubscribe = this.props.firebase.realEstates().onSnapshot(snapshot => {
      const realEstates: any = []
      const uniqueCities: string[] = []

      snapshot.forEach(doc => {
        if (doc.data().isOwned) {
          realEstates.push({ ...doc.data(), uid: doc.id })
          if (uniqueCities.indexOf(doc.data().address.city) > -1) {
            // City already in array
          } else {
            uniqueCities.push(doc.data().address.city)
          }
        }
      })
      this.setState({
        allCities: uniqueCities,
        filteredRealEstates: realEstates,
        realEstates,
        loading: false
      })
    })
  }

  componentWillUnmount() {
    this.unsubscribe()
  }
  public handleClick = realEstate => {
    this.props.firebase
      .rentalObjects(realEstate.realEstateID)
      .get()
      .then(snapshot => {
        const rentalObjects: any = []
        snapshot.forEach(doc => {
          rentalObjects.push({ ...doc.data(), uid: doc.id })
        })
        this.setState({
          rentalObjects,
          realEstate,
          visible: true
        })
      })
  }

  public closeDrawer = () => {
    this.setState({ visible: false })
  }

  public setSelectedCity = selectedCity => {
    const { searchBarInput, realEstates } = this.state
    const filteredRealEstates = realEstates
      .filter(realEstate =>
        selectedCity === 'All' ? true : realEstate.address.city === selectedCity
      )
      .filter(realEstate => this.filterObject(realEstate, searchBarInput))
      .map(realEstate => realEstate)
    this.setState({ filteredRealEstates, selectedCity })
  }

  public searchRealEstate = inputValue => {
    const { selectedCity, realEstates } = this.state
    const filteredRealEstates = realEstates
      .filter(realEstate =>
        selectedCity === 'All' ? true : realEstate.address.city === this.state.selectedCity
      )
      .filter(realEstate => this.filterObject(realEstate, inputValue))
      .map(realEstate => realEstate)
    this.setState({ filteredRealEstates, searchBarInput: inputValue })
  }

  public filterObject(obj, query) {
    for (const key in obj) {
      if (key === 'pictureURL') {
        return
      } else if (typeof obj[key] === 'object') {
        if (
          Object.values(obj[key])
            .toString()
            .toUpperCase()
            .includes(query.toUpperCase())
        ) {
          return obj
        }
      } else if (
        obj[key]
          .toString()
          .toUpperCase()
          .includes(query.toUpperCase())
      ) {
        return obj
      }
    }
  }

  public render() {
    const { t, children, ...props } = this.props
    const {
      allCities,
      filteredRealEstates,
      loading,
      selectedCity,
      searchBarInput,
      rentalObjects,
      realEstate,
      visible
    } = this.state

    return (
      <StyledRealEstateGrid {...props}>
        <RealEstateSearchBar searchRealEstate={this.searchRealEstate} inputValue={searchBarInput} />
        <RealEstateCitySelect
          setSelectedCity={this.setSelectedCity}
          allCities={allCities}
          selectedCity={selectedCity}
        />
        {loading && (
          <CenterContainer>
            <CenteredDiv>
              <Spin size="large" />
            </CenteredDiv>
          </CenterContainer>
        )}
        <Row gutter={32}>
          {filteredRealEstates
            .slice()
            .sort((a: any, b: any) =>
              a.address.realEstateName < b.address.realEstateName
                ? -1
                : a.address.realEstateName > b.address.realEstateName
                ? 1
                : 0
            )
            .map((realEstate: any, index) => {
              return (
                <Col
                  xs={24}
                  md={12}
                  lg={12}
                  xl={8}
                  xxl={6}
                  key={index}
                  style={{ marginTop: '20px' }}
                >
                  <StyledCard
                    hoverable={true}
                    //onClick={() => console.log('Clicked')}
                    cover={
                      <Link
                        to={{
                          pathname: `${ROUTES.REAL_ESTATES}/${realEstate.uid}`,
                          state: { realEstate }
                        }}
                      >
                        <img alt="Kunde inte ladda" src={realEstate.pictureURL} />
                      </Link>
                    }
                    actions={[
                      <Tooltip key="1" placement="bottom" title="Felrapport">
                        <Icon type="edit" onClick={() => this.handleClick(realEstate)} />
                      </Tooltip>,
                      <Tooltip key="3" placement="bottom" title="Lägenheter">
                        <Link
                          to={{
                            pathname: `${ROUTES.REAL_ESTATES}/${realEstate.uid}`,
                            state: { realEstate }
                          }}
                        >
                          <Icon type="ordered-list" />
                        </Link>
                      </Tooltip>
                    ]}
                  >
                    <Meta
                      title={realEstate.address.realEstateName}
                      description={
                        <div className="RealEstateGridDescription">
                          <div>{realEstate.address.street}</div>
                          <div>
                            {realEstate.address.zipCode} {realEstate.address.city}
                          </div>
                          {/* <div>Antal Lägenheter: {realEstate.numberOfRentalObjects}</div> */}
                        </div>
                      }
                    />
                  </StyledCard>
                </Col>
              )
            })}
        </Row>
        {realEstate && (
          <AssignmentCreatorDrawer
            rentalObjects={rentalObjects}
            fromPage="realEstates"
            realEstate={realEstate}
            visible={visible}
            closeDrawer={this.closeDrawer}
          />
        )}
      </StyledRealEstateGrid>
    )
  }
}

export default compose(withTranslation(), withFirebase)(RealEstateGrid)
