import * as React from 'react'

import { withTranslation } from 'react-i18next'

/* Import components here */
import {
  StyledRentalObjectAssignmentHistoryItemEdit,
  StyledTitle
} from './RentalObjectAssignmentHistoryItemEdit.styles'
import { Button, Form, Icon, Input, Modal, Select, Row, Col, Spin } from 'antd' //InputNumber, Select

/* Import interfaces here */
import {
  IRentalObjectAssignmentHistoryItemEditProps,
  IRentalObjectAssignmentHistoryItemEditState,
  IFormProps
} from './RentalObjectAssignmentHistoryItemEdit.interfaces'

/* Import utilities here */
import { withFirebase } from '../../../firebase'
import { compose } from 'recompose'
import { rootStore } from '../../../stores/'
import { observer } from 'mobx-react'
const { Option } = Select
let id = 2
@observer
export class RentalObjectAssignmentHistoryItemEdit extends React.Component<
  IRentalObjectAssignmentHistoryItemEditProps,
  IRentalObjectAssignmentHistoryItemEditState
> {
  public state = {
    loading: false
  }

  public componentDidMount() {
    // To disabled submit button at the beginning.
    this.props.form.validateFields()
  }

  public componentDidUpdate(prevProps) {
    const assignmentChanged = prevProps.assignment === this.props.assignment ? false : true
    if (assignmentChanged) {
      this.props.form.resetFields()
    }
  }

  public handleSubmit = e => {
    e.preventDefault()
    this.setState({
      loading: true
    })
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        const assignmentID = this.props.assignment.assignmentID
        const uid = assignmentID.length < 5 ? `"${assignmentID}"` : assignmentID
        const assignmentReference = this.props.firebase.assignment(uid)

        const contactPersons = this.props.assignment.contactPersons
        contactPersons.forEach((tenant, index) => {
          tenant.firstName = values['firstName' + index]
          tenant.lastName = values['lastName' + index]
          tenant.phoneNumbers = values['keys' + tenant.tenantID].map(
            key => values['phoneNumber' + tenant.tenantID][key]
          )
        })

        assignmentReference
          .update({
            areas: values.location,
            description: values.description,
            priority: values.priority,
            contactPersons
          })
          .then(() => {
            this.setState({ loading: false })
            this.props.form.resetFields()
            this.props.redirectToContent()
          })
      } else {
        this.setState({ loading: false })
      }
    })
  }

  public successModal = title => {
    Modal.success({
      centered: true,
      title
    })
  }

  public errorModal = (title, content) => {
    Modal.error({
      title,
      content
    })
  }

  public hasErrors(fieldsError) {
    return Object.keys(fieldsError).some(field => fieldsError[field])
  }

  public remove = (k, tenant) => {
    const { form } = this.props
    // can use data-binding to get
    const keys = form.getFieldValue('keys' + tenant.tenantID)
    // We need at least one passenger
    if (keys.length === 1) {
      return
    }
    // can use data-binding to set
    form.setFieldsValue({
      ['keys' + tenant.tenantID]: keys.filter(key => key !== k)
    })
  }

  public add = tenant => {
    const { form } = this.props
    // can use data-binding to get
    const keys = form.getFieldValue('keys' + tenant.tenantID)
    const nextKeys = keys.concat(id++)
    // can use data-binding to set
    // important! notify form to detect changes
    form.setFieldsValue({
      ['keys' + tenant.tenantID]: nextKeys
    })
  }

  public getPhoneNumberForm = tenant => {
    const { getFieldDecorator, getFieldValue } = this.props.form
    getFieldDecorator('keys' + tenant.tenantID, {
      initialValue: tenant.phoneNumbers ? tenant.phoneNumbers.map((x, index) => index) : []
    })
    const keys = getFieldValue('keys' + tenant.tenantID)
    const formItems = keys.map((k, index) => (
      <Col span={6} key={k}>
        <Form.Item label={index === 0 ? 'Telefonnummer' : 'Telefonnummer'} required={false}>
          {getFieldDecorator(`phoneNumber${tenant.tenantID}[${k}]`, {
            initialValue: tenant.phoneNumbers ? tenant.phoneNumbers[k] : '',
            rules: [
              {
                message: 'Ange telefonnummer eller ta bort fält',
                required: true,
                whitespace: true
              }
            ],
            validateTrigger: ['onChange', 'onBlur']
          })(<Input placeholder="Telefonnummer" style={{ width: '80%', marginRight: 8 }} />)}
          {keys.length > 1 ? (
            <Icon
              className="dynamic-delete-button"
              type="minus-circle"
              theme="twoTone"
              twoToneColor="#eb2f2f"
              // tslint:disable-next-line: jsx-no-lambda
              onClick={() => this.remove(k, tenant)}
            />
          ) : null}
        </Form.Item>
      </Col>
    ))
    return formItems
  }

  public render() {
    const { assignment, t, children, ...props } = this.props
    const { loading } = this.state
    const {
      getFieldDecorator,
      //getFieldsError,
      getFieldError,
      isFieldTouched,
      getFieldValue,
      isFieldsTouched
    } = this.props.form
    const priorityError = isFieldTouched('priority') && getFieldError('priority')
    const areaError = isFieldTouched('location') && getFieldError('location')
    const descriptionError = isFieldTouched('description') && getFieldError('description')
    const rootStoreLoading = rootStore.settingStore.settings.priorities.length > 0

    return (
      <StyledRentalObjectAssignmentHistoryItemEdit {...props}>
        <StyledTitle style={{ textAlign: 'center' }}>Redigera Ärende</StyledTitle>

        {!rootStoreLoading && (
          <div style={{ margin: '0 auto', width: 20, marginTop: 100 }}>
            <Spin style={{ margin: '0 auto' }} />
          </div>
        )}
        {rootStoreLoading && (
          <Form layout="vertical" onSubmit={this.handleSubmit} hideRequiredMark={true}>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label="Prioritet"
                  validateStatus={priorityError ? 'error' : ''}
                  help={priorityError || ''}
                >
                  {getFieldDecorator('priority', {
                    initialValue: assignment.priority,
                    rules: [{ required: true, message: 'Välj prioritet' }]
                  })(
                    <Select
                      placeholder="Välj prioritet"
                      style={{ position: 'relative' }}
                      // tslint:disable-next-line: jsx-no-lambda
                      getPopupContainer={() =>
                        document.getElementById('AssignmentEditForm_priority')!
                      }
                    >
                      {rootStore.settingStore.settings.priorities.map((setting: any) => {
                        return (
                          <Option key={setting} value={setting}>
                            {setting}
                          </Option>
                        )
                      })}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Utrymme"
                  validateStatus={areaError ? 'error' : ''}
                  help={areaError || ''}
                >
                  {getFieldDecorator('location', {
                    initialValue: assignment.areas,

                    rules: [{ required: true, message: 'Välj vilket eller vilka utrymmen' }]
                  })(
                    <Select
                      mode="multiple"
                      style={{ width: '100%', position: 'relative' }}
                      placeholder="Välj vilket eller vilka utrymmen"
                      // tslint:disable-next-line: jsx-no-lambda
                      getPopupContainer={() =>
                        document.getElementById('AssignmentEditForm_location')!
                      }
                    >
                      {rootStore.settingStore.settings.areas.map(area => {
                        return (
                          <Option key={area} value={area}>
                            {area}
                          </Option>
                        )
                      })}
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Row>
            {assignment.contactPersons &&
              assignment.contactPersons.map((tenant, index) => {
                return (
                  <Row key={index} gutter={16}>
                    <Col span={6}>
                      <Form.Item label="Förnamn">
                        {getFieldDecorator('firstName' + index, {
                          initialValue: tenant.firstName,
                          rules: [{ required: false, message: 'Please enter user name' }]
                        })(<Input placeholder="Förnamn" />)}
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item label="Efternamn">
                        {getFieldDecorator('lastName' + index, {
                          initialValue: tenant.lastName,
                          rules: [{ required: true, message: 'Please enter user name' }]
                        })(<Input placeholder="Efternamn" />)}
                      </Form.Item>
                    </Col>
                    {this.getPhoneNumberForm(tenant)}
                    {getFieldValue('keys' + tenant.tenantID).length < 2 ? (
                      <Col span={6}>
                        <Form.Item
                          label={<span style={{ visibility: 'hidden' }}>Titel</span>}
                          style={{ textAlign: 'center' }}
                        >
                          <Button type="link" onClick={this.add.bind(this, tenant)}>
                            <Icon
                              className="dynamic-delete-button"
                              type="plus-circle"
                              theme="twoTone"
                              twoToneColor="#52c41a"
                            />{' '}
                            Extra nummer
                          </Button>
                        </Form.Item>
                      </Col>
                    ) : null}
                  </Row>
                )
              })}
            <Form.Item
              label="Åtgärdstext"
              validateStatus={descriptionError ? 'error' : ''}
              help={descriptionError || ''}
            >
              {getFieldDecorator('description', {
                initialValue: assignment.description,
                rules: [
                  {
                    message: 'skriv in en åtgärdstext',
                    required: true
                  }
                ]
              })(
                <Input.TextArea
                  style={{ resize: 'none' }}
                  autoSize={{ minRows: 2 }}
                  placeholder="Åtgärdstext"
                />
              )}
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                disabled={!isFieldsTouched()}
                loading={loading}
                style={{ width: '100%' }}
              >
                Bekräfta Ändringar
              </Button>
            </Form.Item>
          </Form>
        )}
      </StyledRentalObjectAssignmentHistoryItemEdit>
    )
  }
}

export default Form.create<IFormProps>({ name: 'AssignmentEditForm' })(
  compose(withTranslation(), withFirebase)(RentalObjectAssignmentHistoryItemEdit)
)
