import { styled } from 'theme'

export const StyledRentalObjectAssignmentHistoryItem = styled.div`
  background-color: white;
  padding: 24px;
`

export const CenterContainer = styled.div`
  min-height: 5em;
  position: relative;
`

export const CenteredDiv = styled.div`
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
`
