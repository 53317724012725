// src/contexts/index.tsx
import React from 'react'
import EmployeeStore from './EmployeeStore/EmployeeStore'
import SettingStore from './SettingStore/SettingStore'
import InboxStore from './InboxStore/InboxStore'
export class RootStore {
  employeeStore = new EmployeeStore()
  settingStore = new SettingStore()
  inboxStore = new InboxStore()
}

export const rootStore = new RootStore()
export const RootStoreContext = React.createContext({
  rootStore
})

export const useStores = () => React.useContext(RootStoreContext)
