import * as React from 'react'

import { withTranslation } from 'react-i18next'

/* Import components here */
import { StyledCard } from './DashBoardCardStatisticGrid.styles'
import { Col, Icon, Row, Statistic } from 'antd'

/* Import interfaces here */
import {
  IDashBoardCardStatisticGridProps,
  IDashBoardCardStatisticGridState
} from './DashBoardCardStatisticGrid.interfaces'

/* Import utilities here */
import { compose } from 'recompose'
import { withFirebase } from '../../../firebase'
export class DashBoardCardStatisticGrid extends React.Component<
  IDashBoardCardStatisticGridProps,
  IDashBoardCardStatisticGridState
> {
  unsubscribe: any
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      activeAssignments: [],
      unassignedAssignments: []
    }
  }

  componentDidMount() {
    this.setState({ loading: true })

    this.unsubscribe = this.props.firebase.activeAssignments().onSnapshot(snapshot => {
      const activeAssignments: any = []
      const unassignedAssignments: any = []

      snapshot.forEach(doc => {
        if (doc.data().assignedUserID === '') {
          unassignedAssignments.push(doc.data())
        }
        if (doc.data().dateFinished === null) {
          activeAssignments.push(doc.data())
        }
      })

      this.setState({
        activeAssignments,
        unassignedAssignments,
        loading: false
      })
    })
  }

  componentWillUnmount() {
    this.unsubscribe()
  }

  public render() {
    const { loading, activeAssignments, unassignedAssignments } = this.state

    return (
      <Row style={{ marginTop: 16 }} gutter={16}>
        <Col sm={{ span: 24 }} xl={{ span: 12 }}>
          <StyledCard loading={loading} onClick={() => console.log('Pågående')}>
            <Col span={12}>
              <Statistic
                title="Pågående Ärenden"
                value={activeAssignments.length}
                precision={0}
                valueStyle={{ color: '#36a2eb' }}
                prefix={<Icon type="sync" />}
                suffix="st"
              />
            </Col>
            <Col span={12}>
              <Statistic
                title="Äldsta Pågående"
                value={
                  activeAssignments.length > 0
                    ? activeAssignments[0].dateCreated.toDate().toLocaleString()
                    : 'Inga pågående'
                }
                precision={0}
                valueStyle={{ color: '#AAAAAA' }}
                prefix={<Icon type="calendar" />}
              />
            </Col>
          </StyledCard>
        </Col>
        <Col sm={{ span: 24 }} xl={{ span: 12 }}>
          <StyledCard loading={loading} onClick={() => console.log('Otilldelade')}>
            <Col span={12}>
              <Statistic
                title="Otilldelade Ärenden"
                value={unassignedAssignments.length}
                precision={0}
                valueStyle={{ color: '#cf1322' }}
                prefix={<Icon type="info-circle" />}
                suffix="st"
              />
            </Col>
            <Col span={12}>
              <Statistic
                title="Äldsta Otilldelade"
                value={
                  unassignedAssignments.length > 0
                    ? unassignedAssignments[0].dateCreated.toDate().toLocaleString()
                    : 'Inga Otilldelade'
                }
                precision={0}
                valueStyle={{ color: '#AAAAAA' }}
                prefix={<Icon type="calendar" />}
              />
            </Col>
          </StyledCard>
        </Col>
      </Row>
    )
  }
}
export default compose(withTranslation(), withFirebase)(DashBoardCardStatisticGrid)
