import * as React from 'react'

/* Import components here */
import { Bar } from 'react-chartjs-2'

/* Import interfaces here */
import { IActiveAssignmentsProps, IActiveAssignmentsState } from './ActiveAssignments.interfaces'
import { rootStore } from 'stores'
import { withFirebase } from '../../../firebase'
import { when } from 'mobx'

/* Import utilities here */
const legendOpts = {
  display: true,
  fullWidth: true,
  labels: {
    fontColor: '#AAAAAA'
  },
  position: 'bottom',
  reverse: false
}

const options = {
  maintainAspectRatio: false,
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
          stepSize: 5,
          userCallback(label, index, labels) {
            // when the floored value is the same as the value we have a whole number
            if (Math.floor(label) === label) {
              return label
            }
          }
        }
      }
    ]
  }
}

export class ActiveAssignments extends React.Component<
  IActiveAssignmentsProps,
  IActiveAssignmentsState
> {
  private unsubscribe: any

  constructor(props) {
    super(props)
    this.state = {
      barData: null,
      averageAssignments: 0
    }
  }

  private plugins = [
    {
      // afterDraw: (chartInstance, easing) => {
      //   const ctx = chartInstance.chart.ctx
      //   ctx.fillText('This text drawn by a plugin', 100, 100)
      // }
      afterDraw: (chart, easing) => {
        const ctx = chart.ctx
        const value = this.state.averageAssignments
        const top = chart.scales['y-axis-0'].top
        const bottom = chart.scales['y-axis-0'].bottom
        const availableArea = bottom - top
        const max = chart.scales['y-axis-0'].max
        const x = ((max - value) / max) * availableArea + top
        const leftY = chart.scales['y-axis-0'].right
        const rightY = chart.width
        ctx.beginPath()
        ctx.moveTo(leftY, x)
        ctx.lineTo(rightY, x)
        ctx.lineWidth = 2
        ctx.strokeStyle = '#e23fa9'
        ctx.stroke()
        ctx.fillStyle = '#e23fa9'
        ctx.fillText('Genomsnitt', rightY - 70, x - 10)
      }
    }
  ]

  public async componentDidMount() {
    await when(
      () => this.props.firebase && rootStore.employeeStore.employeesRecieved,
      () => {
        this.unsubscribe = this.props.firebase.activeAssignments().onSnapshot(snapshot => {
          const barData: any[] = []
          barData.push({
            data: 0,
            label: 'Otilldelade',
            uid: 'Otilldelade',
            backgroundColor: 'rgba(242, 38, 19, 0.1)',
            borderColor: 'rgba(242, 38, 19, 1)',
            hoverBackgroundColor: 'rgba(242, 38, 19, 0.4)',
            hoverBorderColor: 'rgba(242, 38, 19, 1)'
          })
          rootStore.employeeStore.allJanitors.forEach(janitor => {
            barData.unshift({
              data: 0,
              label: janitor.firstName + ' ' + janitor.lastName,
              uid: janitor.userID,
              backgroundColor: 'rgba(54,162,235,0.1)',
              borderColor: 'rgba(54,162,235,1)',
              hoverBackgroundColor: 'rgba(54,162,235,0.4)',
              hoverBorderColor: 'rgba(54,162,235,1)'
            })
          })

          const averageAssignments = snapshot.size / rootStore.employeeStore.totalJanitors
          snapshot.forEach(doc => {
            const found = rootStore.employeeStore.allJanitors.find(
              emp => emp.userID === doc.data().assignedUserID
            )
            if (found) {
              const index = barData.findIndex(obj => obj.uid === found.userID)
              barData[index].data++
            } else {
              const index = barData.findIndex(obj => obj.uid === 'Otilldelade')
              barData[index].data++
            }
          })
          const mockData = {
            datasets: [
              {
                backgroundColor: Array.from(barData, (x: any) => x.backgroundColor),
                borderColor: Array.from(barData, (x: any) => x.borderColor),
                borderWidth: 2,
                data: Array.from(barData, (x: any) => x.data),
                hoverBackgroundColor: Array.from(barData, (x: any) => x.hoverBackgroundColor),
                hoverBorderColor: Array.from(barData, (x: any) => x.hoverBorderColor),
                label: 'Antal Pågående Ärenden'
              }
            ],
            labels: Array.from(barData, (x: any) => x.label)
          }

          this.setState({
            barData: mockData,
            averageAssignments
          })
        })
      }
    )
  }

  public componentWillUnmount() {
    if (!!this.unsubscribe) {
      this.unsubscribe()
    }
  }
  public render() {
    const { barData } = this.state

    return (
      <div>
        {barData && (
          <Bar
            data={barData}
            legend={legendOpts}
            options={options}
            width={300}
            height={500}
            plugins={this.plugins}
          />
        )}
      </div>
    )
  }
}

export default withFirebase(ActiveAssignments)
