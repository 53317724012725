import { Colors } from './colors'

interface INotificationType {
  /** Background color of the notification */
  background: string
  /** Color of both title and description */
  color: string
}

export interface INotification {
  error: INotificationType
  /** Color of the icon */
  icon: string
  info: INotificationType
  success: INotificationType
  warning: INotificationType
}

export const Notification: INotification = {
  error: {
    background: Colors.red,
    color: Colors.white
  },
  icon: Colors.white,
  info: {
    background: Colors.blue,
    color: Colors.white
  },
  success: {
    background: Colors.green,
    color: Colors.white
  },
  warning: {
    background: Colors.orange,
    color: Colors.white
  }
}
