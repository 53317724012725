import * as React from 'react'

import { withTranslation } from 'react-i18next'

/* Import components here */
import { StyledPriorityList } from './PriorityList.styles'
import {
  StyledContainer,
  StyledList,
  StyledText,
  StyledWrapper,
  StyledCircleWrapper,
  StyledCircle,
  Content,
  Extra,
  Wrap
} from '../../../../utils/listSort/ListSort.styles'
/* Import interfaces here */
import { IPriorityListProps, IPriorityListState } from './PriorityList.interfaces'

/* Import utilities here */
import ListSort from '../../../../utils/listSort/ListSort'
import { Form, Input, Button, Icon } from 'antd'
import { withFirebase } from '../../../../firebase'
import { compose } from 'recompose'

export class PriorityList extends React.Component<IPriorityListProps, IPriorityListState> {
  constructor(props) {
    super(props)
    this.state = {
      editing: false,
      priorityList: []
    }
  }

  componentDidMount() {
    this.props.firebase
      .settings()
      .get()
      .then(doc => {
        if (doc.exists) {
          // console.log('Document data:', doc.data())
          this.setState({ priorityList: doc.data().priorityList })
        } else {
          // doc.data() will be undefined in this case
          console.log('No such document!')
        }
      })
      .catch(error => {
        console.log('Error getting document:', error)
      })
  }

  toggleEditing = () => {
    this.setState({ editing: !this.state.editing })
  }

  handleSubmit = e => {
    e.preventDefault()
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { keys, names } = values
        //console.log('Received values of form: ', values)
        //console.log('Merged values:',keys.map(key => names[key]))

        /* console.log(
          'SETSTATE',
          Array.from(keys.sort(), (key: any) => names[key])
        ) */
        //this.setState({ priorityList: Array.from(keys.sort(), (key: any) => names[key]) })
        // const namesField: any[] = []
        // keys.forEach(k => {
        //   namesField[k] = names[k]
        // })
        // console.log('NAMEFIELD', namesField)

        const priorities = keys.map(key => names[key])
        const priorityList: any[] = []
        keys.forEach(k => {
          priorityList.push({ value: k, label: names[k] })
        })
        // this.props.form.setFieldsValue({
        //   names: namesField
        // })
        this.setState({ priorityList })

        this.props.firebase
          .settings()
          .update({
            priorityList,
            priorities
          })
          .then(() => this.toggleEditing())
      }
    })
  }

  setKeys = objectArray => {
    const { form } = this.props
    const { priorityList, editing } = this.state
    if (Array.isArray(objectArray)) {
      form.setFieldsValue({
        keys: Array.from(objectArray, (x: any) => x.key)
      })
      if (!editing) {
        const currentOrderList: any[] = []
        const priorities: any[] = []
        objectArray.forEach(element => {
          currentOrderList.push(priorityList.find(p => p.value === element.key))
          priorities.push(priorityList.find(p => p.value === element.key).label)
        })
        this.props.firebase.settings().update({
          priorities,
          priorityList: currentOrderList
        })
      }
    }
  }

  add = () => {
    const { form, firebase } = this.props
    // can use data-binding to get
    const keys = form.getFieldValue('keys')
    const nextKeys = keys.concat(firebase.getUniqueID())
    // can use data-binding to set
    // important! notify form to detect changes
    form.setFieldsValue({
      keys: nextKeys
    })
  }

  remove = k => {
    const { form } = this.props
    // can use data-binding to get
    const keys = form.getFieldValue('keys')
    // We need at least one
    if (keys.length === 1) {
      return
    }
    setTimeout(() => {
      // can use data-binding to set
      form.setFieldsValue({
        keys: keys.filter(key => key !== k)
      })
    }, 500)
  }
  public render() {
    const { getFieldDecorator, getFieldValue } = this.props.form
    const { editing, priorityList } = this.state
    getFieldDecorator('keys', {
      initialValue: priorityList.map((x, index) => x.value)
    })
    const keys = getFieldValue('keys')
    const color = '#FF5500'
    const childrenToRender = keys.map((key, index) => {
      if (editing) {
        return (
          <StyledList key={key}>
            <StyledCircleWrapper>
              <StyledCircle>{index + 1}</StyledCircle>
            </StyledCircleWrapper>
            <StyledText>
              <Form.Item>
                {getFieldDecorator(`names[${key}]`, {
                  initialValue: priorityList.find(p => p.value === key)
                    ? priorityList.find(p => p.value === key).label
                    : '',
                  validateTrigger: ['onChange', 'onBlur'],
                  rules: [
                    {
                      required: true,
                      whitespace: true,
                      message: 'Vänligen skriv in en priortiet eller ta bort fält'
                    }
                  ]
                })(
                  <Input
                    placeholder="Prioritet"
                    style={{ textAlign: 'center', marginRight: '8px', width: '80%' }}
                  />
                )}
                {keys.length > 1 ? (
                  <Icon
                    className="dynamic-delete-button"
                    type="minus-circle-o"
                    style={{ color }}
                    onClick={() => this.remove(key)}
                  />
                ) : null}
              </Form.Item>
            </StyledText>
          </StyledList>
        )
      }
      return (
        <StyledList key={key}>
          <StyledCircleWrapper>
            <StyledCircle>{index + 1}</StyledCircle>
          </StyledCircleWrapper>
          <StyledText>
            <h1 style={{ textAlign: 'center', margin: '5px', width: '80%' }}>
              {priorityList.find(p => p.value === key).label}
            </h1>
          </StyledText>
        </StyledList>
      )
    })

    return (
      <StyledPriorityList>
        <Wrap>
          <Content>
            <h1>Ärende Prioriteringar</h1>
          </Content>
          <Extra>
            <Icon type="ordered-list" />
          </Extra>
        </Wrap>
        <StyledWrapper>
          <StyledContainer>
            <Form onSubmit={this.handleSubmit}>
              <ListSort
                onChange={this.setKeys}
                dragClassName="list-drag-selected"
                appearAnim={{ animConfig: { marginTop: [5, 30], opacity: [1, 0] } }}
              >
                {childrenToRender}
              </ListSort>
              {editing && (
                <>
                  <Form.Item style={{ marginBottom: '0px' }}>
                    <Button type="dashed" onClick={this.add} style={{ width: '100%' }}>
                      <Icon type="plus" /> Lägg till fält
                    </Button>
                  </Form.Item>
                  {/* <Button
                      disabled={keys.length < 2}
                      type="dashed"
                      onClick={this.remove}
                      style={{ width: '49%', marginLeft: '2%' }}
                    >
                      <Icon type="minus" /> Ta bort fält
                    </Button> */}
                  <Form.Item>
                    <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
                      Ändra
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form>
            {!editing && (
              <Button
                type="primary"
                style={{ width: '100%', marginTop: '20px' }}
                onClick={this.toggleEditing}
              >
                Editera
              </Button>
            )}
          </StyledContainer>
        </StyledWrapper>
      </StyledPriorityList>
    )
  }
}

const PriorityForm = Form.create({ name: 'Priorities' })(
  compose(withTranslation(), withFirebase)(PriorityList)
)

export default PriorityForm
