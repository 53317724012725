import { Colors } from './colors'

interface INotificationType {
  /** Background color of the notification */
  background: string
  /** Color of both title and description */
  color: string
}

export interface IAlert {
  error: INotificationType
  info: INotificationType
  success: INotificationType
  warning: INotificationType
}

export const Alert: IAlert = {
  error: {
    background: Colors.red,
    color: Colors.white
  },
  info: {
    background: Colors.blue,
    color: Colors.white
  },
  success: {
    background: Colors.green,
    color: Colors.white
  },
  warning: {
    background: Colors.orange,
    color: Colors.white
  }
}
