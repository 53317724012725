import * as React from 'react'

import { withTranslation } from 'react-i18next'

/* Import components here */
import { StyledImportResult, StyledDesc, StyledDescriptions } from './ImportResult.styles'

/* Import interfaces here */
import { IImportResultProps } from './ImportResult.interfaces'

/* Import utilities here */
import { Button, Icon, Result, Typography, Collapse, Descriptions, Divider } from 'antd'
import { Link } from 'react-router-dom'
import * as ROUTES from '../../../../constants/routes'

const { Paragraph, Text } = Typography
const { Panel } = Collapse

const customPanelStyle = {
  background: 'white',
  borderRadius: 4,
  marginBottom: 0,
  border: 1,
  overflow: 'hidden'
}
export const ImportResult: React.FC<IImportResultProps> = ({
  goBack,
  loading,
  result,
  t,
  children,
  ...props
}) => {
  // console.log('THIS IS THE RESULT', result)
  // console.log(typeof result)

  return (
    <StyledImportResult {...props}>
      <Result
        style={{ padding: '0px 32px' }}
        icon={loading ? <Icon type="loading" /> : null}
        status={loading ? 'info' : result.status || 'error'}
        title={
          loading
            ? 'Uppdaterar Databas...'
            : result.status === 'success'
            ? 'Importen Lyckades!'
            : 'Importen Misslyckades!'
        }
        subTitle={
          loading || result.status === 'success'
            ? ''
            : 'Vänligen kontrollera och ändra följande information innan du laddar upp igen'
        }
        extra={[
          <Button key="goback" onClick={() => goBack()} disabled={loading}>
            {loading ? 'Testa igen' : result.status === 'success' ? 'Gå tillbaka' : 'Testa igen'}
          </Button>
        ]}
      >
        <StyledDesc>
          <Paragraph>
            <Text
              strong={true}
              style={{
                fontSize: 16
              }}
            >
              {loading
                ? 'Väntar på resultat...'
                : result.status === 'success'
                ? 'Följande har uppdaterats: '
                : 'Excel filen du försökte importera har följande fel: '}
            </Text>
          </Paragraph>
          {result && result.errorMessage && (
            <Paragraph>
              <Icon style={{ color: 'red' }} type="close-circle" /> {result.errorMessage}
            </Paragraph>
          )}
          {result && typeof result === 'string' && (
            <Paragraph>
              <Icon style={{ color: 'red' }} type="close-circle" /> Kunde inte nå servern, försök
              igen eller kontakta support
            </Paragraph>
          )}
          {result && result.newlyCreatedRealEstates && (
            <Collapse
              bordered={false}
              defaultActiveKey={['1']}
              expandIcon={({ isActive }) => <Icon type="caret-right" rotate={isActive ? 90 : 0} />}
            >
              <Panel
                header={`Skapade ${result.newlyCreatedRealEstates.length} nya Fastigheter`}
                key="1"
                style={customPanelStyle}
              >
                {result.newlyCreatedRealEstates.map((realEstate, index) => (
                  <Paragraph key={index}>
                    <StyledDescriptions
                      size="small"
                      column={3}
                      title={
                        <span>
                          <Icon style={{ color: '#52c41a' }} type="plus-circle" /> Fastighet
                        </span>
                      }
                    >
                      <Descriptions.Item label="Namn">
                        {realEstate.address.realEstateName}
                      </Descriptions.Item>
                      <Descriptions.Item
                        label={
                          <span style={{ color: 'red' }}>
                            <Icon type="warning" /> Fastigheten behöver kompliteras
                          </span>
                        }
                      >
                        <Link
                          to={`${ROUTES.SETTINGS_REAL_ESTATE_LIST}/${realEstate.realEstateID}`}
                          target="_blank"
                        >
                          Editera
                        </Link>
                      </Descriptions.Item>
                    </StyledDescriptions>
                    <Divider style={{ margin: 0 }} />
                  </Paragraph>
                ))}
              </Panel>
            </Collapse>
          )}
          {result && result.newlyCreatedRentalObjects && (
            <Collapse
              bordered={false}
              defaultActiveKey={['0']}
              expandIcon={({ isActive }) => <Icon type="caret-right" rotate={isActive ? 90 : 0} />}
            >
              <Panel
                header={`Skapade ${result.newlyCreatedRentalObjects.length} nya Objekt`}
                key="1"
                style={customPanelStyle}
              >
                {result.newlyCreatedRentalObjects.map((rentalObject, index) => (
                  <Paragraph key={index}>
                    <StyledDescriptions
                      size="small"
                      column={3}
                      title={
                        <span>
                          <Icon style={{ color: '#52c41a' }} type="plus-circle" />{' '}
                          {rentalObject.type}
                        </span>
                      }
                    >
                      <Descriptions.Item label="Fastighet">
                        {rentalObject.address.realEstateName}
                      </Descriptions.Item>
                      <Descriptions.Item label="LägenhetsNummer">
                        {rentalObject.address.rentalObjectNumber}
                      </Descriptions.Item>
                      <Descriptions.Item label="Adress">
                        {rentalObject.address.street}
                      </Descriptions.Item>
                      <Descriptions.Item label="Stad">
                        {rentalObject.address.city}
                      </Descriptions.Item>
                      <Descriptions.Item label="Zip">
                        {rentalObject.address.zipCode}
                      </Descriptions.Item>
                      <Descriptions.Item label="Typ">{rentalObject.category}</Descriptions.Item>
                    </StyledDescriptions>
                    <Divider style={{ margin: 0 }} />
                  </Paragraph>
                ))}
              </Panel>
            </Collapse>
          )}
          {result && result.updatedRentalObjects && (
            <Collapse
              bordered={false}
              defaultActiveKey={['0']}
              expandIcon={({ isActive }) => <Icon type="caret-right" rotate={isActive ? 90 : 0} />}
            >
              <Panel
                header={`Uppdaterade ${result.updatedRentalObjects.length} Objekt`}
                key="1"
                style={customPanelStyle}
              >
                {result.updatedRentalObjects.map((rentalObject, index) => (
                  <Paragraph key={index}>
                    <StyledDescriptions
                      size="small"
                      column={3}
                      title={
                        <span>
                          <Icon style={{ color: '#1990FC' }} type="info-circle" />{' '}
                          {rentalObject.updatedRentalObject.type}
                        </span>
                      }
                    >
                      <Descriptions.Item label="Fastighet">
                        {rentalObject.updatedRentalObject.address.realEstateName}
                      </Descriptions.Item>
                      <Descriptions.Item label="LägenhetsNummer">
                        {rentalObject.updatedRentalObject.address.rentalObjectNumber}
                      </Descriptions.Item>
                      <Descriptions.Item label="Adress">
                        {rentalObject.updatedRentalObject.address.street}
                      </Descriptions.Item>
                      <Descriptions.Item label="Stad">
                        {rentalObject.updatedRentalObject.address.city}
                      </Descriptions.Item>
                      <Descriptions.Item label="Zip">
                        {rentalObject.updatedRentalObject.address.zipCode}
                      </Descriptions.Item>
                      <Descriptions.Item label="Typ">
                        {rentalObject.updatedRentalObject.category}
                      </Descriptions.Item>
                    </StyledDescriptions>
                    <Divider style={{ margin: 0 }} />
                  </Paragraph>
                ))}
              </Panel>
            </Collapse>
          )}
          {result && result.updatedTenants && (
            <Collapse
              bordered={false}
              defaultActiveKey={['0']}
              expandIcon={({ isActive }) => <Icon type="caret-right" rotate={isActive ? 90 : 0} />}
            >
              <Panel
                header={`Uppdaterade hyresgäster i
                ${result.updatedTenants.length} lägenheter`}
                key="1"
                style={customPanelStyle}
              >
                {result.updatedTenants.map((rentalObject, index) => (
                  <Paragraph key={index}>
                    <StyledDescriptions
                      size="small"
                      column={2}
                      title={
                        <span>
                          <Icon style={{ color: '#1990FC' }} type="info-circle" /> Nya inneboende
                        </span>
                      }
                    >
                      <Descriptions.Item label="Fastighet">
                        {rentalObject.dataBeforeUpdate.address.realEstateName}
                      </Descriptions.Item>
                      <Descriptions.Item label="LägenhetsNummer">
                        {rentalObject.dataBeforeUpdate.address.rentalObjectNumber}
                      </Descriptions.Item>
                      <Descriptions.Item label="Borttagna Hyresgäster">
                        {rentalObject.dataBeforeUpdate.tenantHistory &&
                          rentalObject.dataBeforeUpdate.tenantHistory.map((tenant, index) => (
                            <span key={index} style={{ marginRight: 8 }}>
                              <Icon style={{ color: 'red' }} type="minus-circle" />{' '}
                              {tenant.firstName} {tenant.lastName}
                            </span>
                          ))}
                      </Descriptions.Item>
                      <Descriptions.Item label="Nya Hyresgäster">
                        {rentalObject.newHistory.map((tenant, index) => (
                          <span key={index} style={{ marginRight: 8 }}>
                            <Icon style={{ color: '#52c41a' }} type="plus-circle" />{' '}
                            {tenant.firstName} {tenant.lastName}
                          </span>
                        ))}
                      </Descriptions.Item>
                    </StyledDescriptions>
                    <Divider style={{ margin: 0 }} />
                  </Paragraph>
                ))}
              </Panel>
            </Collapse>
          )}
        </StyledDesc>
      </Result>
    </StyledImportResult>
  )
}

export default withTranslation()(ImportResult)
