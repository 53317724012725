import * as React from 'react'

import { withTranslation } from 'react-i18next'

/* Import components here */
import { StyledMessages } from './Messages.styles'

/* Import interfaces here */
import { IMessagesProps, IMessagesState, IMessage } from './Messages.interfaces'

/* Import utilities here */
import { AuthUserContext } from '../../../contexts'
import { withFirebase } from '../../../firebase'
import { MessageList } from '../../../components'
import { compose } from 'recompose'
import { Empty, Button, Input } from 'antd'
export class Messages extends React.Component<IMessagesProps, IMessagesState> {
  unsubscribe: any
  constructor(props) {
    super(props)

    this.state = {
      description: '',
      text: '',
      title: '',
      loading: false,
      messages: [],
      limit: 3
    }
  }

  componentDidMount() {
    this.onListenForMessages()
  }

  onListenForMessages = () => {
    this.setState({ loading: true })

    this.unsubscribe = this.props.firebase
      .messages()
      .orderBy('createdAt', 'desc')
      .limit(this.state.limit)
      .onSnapshot(snapshot => {
        if (snapshot.size) {
          let messages: IMessage[] = []
          snapshot.forEach((doc: any) => messages.push({ ...doc.data(), uid: doc.id }))

          this.setState({
            messages: messages,
            loading: false
          })
        } else {
          this.setState({ messages: [], loading: false })
        }
      })
  }

  componentWillUnmount() {
    this.unsubscribe()
  }

  onChangeText = event => {
    this.setState({ text: event.target.value })
  }

  onChangeTitle = event => {
    this.setState({ title: event.target.value })
  }

  onChangeDescription = event => {
    this.setState({ description: event.target.value })
  }

  onCreateMessage = (event, authUser) => {
    event.preventDefault()
    this.props.firebase.messages().add({
      text: this.state.text,
      title: this.state.title,
      description: this.state.description,
      userId: authUser.uid,
      createdAt: this.props.firebase.fieldValue.serverTimestamp(),
      favourite: false
    })

    this.setState({ text: '', title: '', description: '' })
  }

  onEditMessage = (message, text, title, description, favourite) => {
    const { uid, ...messageSnapshot } = message

    this.props.firebase.message(message.uid).set({
      ...messageSnapshot,
      text,
      title,
      description,
      favourite,
      editedAt: this.props.firebase.fieldValue.serverTimestamp()
    })
  }

  onRemoveMessage = uid => {
    this.props.firebase.message(uid).delete()
  }

  onNextPage = () => {
    this.setState(state => ({ limit: state.limit + 5 }), this.onListenForMessages)
  }

  public render() {
    const { t, children, ...props } = this.props
    const { description, text, title, messages, loading } = this.state

    return (
      <AuthUserContext.Consumer>
        {(authUser: any) => (
          <StyledMessages {...props}>
            {messages && (
              <div>
                <MessageList
                  loading={loading}
                  authUser={authUser}
                  messages={messages}
                  onEditMessage={this.onEditMessage}
                  onRemoveMessage={this.onRemoveMessage}
                />
              </div>
            )}
            {messages && messages.length > 0 && (
              <div style={{ width: 200, margin: '0 auto' }}>
                <Button block onClick={this.onNextPage} disabled={loading}>
                  Se äldre inägg
                </Button>
              </div>
            )}

            {!messages && (
              <div style={{ width: 200, margin: '0 auto', marginTop: 150 }}>
                <Empty description="Inga inlägg hittades" />
              </div>
            )}
            {(authUser.uid === 'UbkOIsYFSdbX8ReWmhPjzbH8R0G2' ||
              authUser.uid === 'ixJHQrxaLWTeuzScM6DfvKlYZsq2') && (
              <form onSubmit={event => this.onCreateMessage(event, authUser)}>
                <div style={{ marginTop: 10 }}>Titel</div>
                <Input type="text" value={title} onChange={this.onChangeTitle} />
                <div style={{ marginTop: 10 }}>Version</div>
                <Input type="text" value={description} onChange={this.onChangeDescription} />
                <div style={{ marginTop: 10 }}>Beskrivning</div>
                <Input.TextArea
                  style={{ resize: 'none' }}
                  autoSize={{ minRows: 2 }}
                  onChange={this.onChangeText}
                  value={text}
                />
                <Button type="primary" block htmlType="submit">
                  Skicka
                </Button>
              </form>
            )}
          </StyledMessages>
        )}
      </AuthUserContext.Consumer>
    )
  }
}

export default compose(withTranslation(), withFirebase)(Messages)
