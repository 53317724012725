// // import axios from 'axios'
import * as React from 'react'

import { withTranslation } from 'react-i18next'

/* Import components here */
import { StyledUserCreateForm, StyledTitle } from './UserCreateForm.styles'

import { Button, Form, Icon, Input, Select, Tooltip, Alert } from 'antd'
/* Import interfaces here */
import { IUserCreateFormProps, IUserCreateFormState } from './UserCreateForm.interfaces'

/* Import utilities here */
import { withFirebase } from '../../../firebase'
import { withRouter } from 'react-router-dom'

import { compose } from 'recompose'
import axios from 'axios'
import * as ROUTES from '../../../constants/routes'

const { Option } = Select

export class UserCreateForm extends React.Component<IUserCreateFormProps, IUserCreateFormState> {
  public state = {
    loading: false,
    error: null
  }

  public componentDidMount() {
    // To disabled submit button at the beginning.
    this.props.form.validateFields()
  }

  public handleSubmit = e => {
    e.preventDefault()
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({
          loading: true
        })
        var user = this.props.firebase.auth.currentUser
        if (user) {
          const userID = this.props.firebase.getUniqueID()

          const requestBody = {
            uid: userID,
            email: values.email,
            phoneNumber: values.prefix + values.phone,
            password: '123456',
            displayName: values.firstName + ' ' + values.lastName
          }
          user
            .getIdToken(true)
            .then(idToken => {
              //console.log(idToken)
              axios({
                method: 'post',
                url: `${process.env.REACT_APP_FUNCTIONS_BASE_URL}/createUser`,
                headers: { Authorization: idToken },
                data: requestBody
              })
                .then(res => {
                  // console.log(res.data)
                  if (res.data !== 'Error') {
                    this.props.firebase
                      .addUser(userID)({
                        allowSMS: true,
                        userID: userID,
                        email: values.email,
                        customerID: this.props.authUser.customerID,
                        firstName: values.firstName,
                        lastName: values.lastName,
                        isActive: true,
                        phoneNumbers: values.prefix + values.phone,
                        profilePicture: null
                      })
                      .then(() => {
                        console.log('Användare skapad i databas!')
                        axios({
                          method: 'get',
                          url: `${process.env.REACT_APP_FUNCTIONS_BASE_URL}/setPermissions`,
                          headers: { Authorization: idToken },
                          params: {
                            email: values.email,
                            role: values.accountRole,
                            permission: true
                          }
                        })
                          .then(() => {
                            this.props.firebase.doPasswordReset(values.email)
                            this.props.history.push(`${ROUTES.ADMIN_USERS}/${userID}`)
                          })
                          .catch(error => console.error(error))
                      })
                      .catch(error => {
                        console.error('Error writing document: ', error)
                        this.setState({
                          loading: false,
                          error: 'Problem med att nå databasen'
                        })
                      })
                  } else {
                    this.setState({
                      loading: false,
                      error: 'Ogiltig data, email eller telefonnummer kan redan vara i bruk'
                    })
                  }
                })
                .catch(error => {
                  this.setState({
                    loading: false,
                    error: 'Kan inte kontakta servern'
                  })
                  console.error(error)
                })
            })
            .catch(() => {
              console.error('Kunde inte få IDToken')
            })
        }
      }
    })
  }

  public hasErrors(fieldsError) {
    return Object.keys(fieldsError).some(field => fieldsError[field])
  }

  public render() {
    const { children, ...props } = this.props
    const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } = this.props.form
    const { loading, error } = this.state
    const firstNameError = isFieldTouched('firstName') && getFieldError('firstName')
    const lastNameError = isFieldTouched('lastName') && getFieldError('lastName')
    const emailError = isFieldTouched('email') && getFieldError('email')
    const phoneError = isFieldTouched('phone') && getFieldError('phone')

    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 12 }
    }
    const tailFormItemLayout = {
      wrapperCol: { span: 6, offset: 9 }
    }
    const prefixSelector = getFieldDecorator('prefix', {
      initialValue: '+46'
    })(
      <Select style={{ width: 70 }}>
        <Option value="+46">+46</Option>
      </Select>
    )
    return (
      <div style={{ backgroundColor: 'white' }}>
        <StyledUserCreateForm {...props}>
          <StyledTitle style={{ textAlign: 'center' }}>Ny Användare</StyledTitle>
          <Form {...formItemLayout} onSubmit={this.handleSubmit}>
            <Form.Item
              label="Kontotyp"
              hasFeedback
              extra="Vilken eller vilka roller ska kontot ha. Vart personen kommer kunna logga in."
            >
              {getFieldDecorator('accountRole', {
                initialValue: 'janitor',
                rules: [{ required: true, message: 'Välj en roll' }]
              })(
                <Select>
                  <Option value="janitor">Fastighetsskötare</Option>
                  <Option value="admin">Kontorspersonal</Option>
                </Select>
              )}
            </Form.Item>
            <Form.Item
              label="Förnamn"
              validateStatus={firstNameError ? 'error' : ''}
              help={firstNameError || ''}
            >
              {getFieldDecorator('firstName', {
                rules: [{ required: true, message: 'Ange ett förnamn !' }]
              })(
                <Input
                  prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                  placeholder="Förnamn"
                />
              )}
            </Form.Item>
            <Form.Item
              label="Efternamn"
              validateStatus={lastNameError ? 'error' : ''}
              help={lastNameError || ''}
            >
              {getFieldDecorator('lastName', {
                rules: [{ required: true, message: 'Ange ett efternamn !' }]
              })(
                <Input
                  prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                  placeholder="Efternamn"
                />
              )}
            </Form.Item>
            <Form.Item
              label="E-mail"
              validateStatus={emailError ? 'error' : ''}
              help={emailError || ''}
            >
              {getFieldDecorator('email', {
                rules: [
                  {
                    type: 'email',
                    message: 'Ogiltigt E-mail format !'
                  },
                  {
                    required: true,
                    message: 'Ange en E-mail!'
                  }
                ]
              })(
                <Input
                  prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />}
                  placeholder="Email"
                />
              )}
            </Form.Item>
            <Form.Item
              label={
                <span>
                  Telefonnummer&nbsp;
                  <Tooltip title="E.164 Standard, börja inte abonnentnummert med 0, använd inte punkt, parantes, bindestreck eller mellanslag">
                    <Icon type="question-circle-o" />
                  </Tooltip>
                </span>
              }
              extra="Exempel: +46 701239911"
              validateStatus={phoneError ? 'error' : ''}
              help={phoneError || ''}
            >
              {getFieldDecorator('phone', {
                rules: [
                  {
                    required: true,
                    message:
                      'Telefonnummret måste vara i rätt format! (Börja inte med 0, använd inte paranteser, punkt eller mellanslag)',
                    pattern: /^[1-9]\d{8}$/g
                  }
                ]
              })(
                <Input
                  addonBefore={prefixSelector}
                  style={{ width: '100%' }}
                  placeholder="720001122"
                />
              )}
            </Form.Item>
            <Form.Item {...tailFormItemLayout}>
              <Button
                block
                type="primary"
                htmlType="submit"
                disabled={this.hasErrors(getFieldsError())}
                loading={loading}
              >
                Skapa
              </Button>
            </Form.Item>
          </Form>
          {error && (
            <Alert
              style={{ width: '50%', margin: '0 auto' }}
              message="Kunde inte skapa användaren!"
              description={error}
              type="error"
              showIcon
            />
          )}
        </StyledUserCreateForm>
      </div>
    )
  }
}

/** @component */
export default Form.create<IUserCreateFormProps>({ name: 'UserCreateForm' })(
  compose(withTranslation(), withRouter, withFirebase)(UserCreateForm)
)
