import * as React from 'react'
import { useTranslation } from 'react-i18next'
import Chart from './Chart'
export const AboutPage: React.FunctionComponent = () => {
  const { t } = useTranslation()

  return (
    <div>
      <h1>{t('common:about')}</h1>
      <p>{t('common:lorem')}</p>
      <Chart />
    </div>
  )
}
