import * as React from 'react'

import { withTranslation } from 'react-i18next'

/* Import components here */
import { StyledDefaultSettingsLanding, Main, Sider, Target } from './DefaultSettingsLanding.styles'

/* Import interfaces here */
import { IDefaultSettingsLandingProps } from './DefaultSettingsLanding.interfaces'

/* Import utilities here */
import { Typography, Divider, Anchor, Icon, Alert } from 'antd'
import City from '../../../../pages/Home/city.jpg'

const { Link } = Anchor

const { Title, Paragraph, Text } = Typography
export const DefaultSettingsLanding: React.FC<IDefaultSettingsLandingProps> = ({
  t,
  children,
  ...props
}) => {
  return (
    <StyledDefaultSettingsLanding {...props}>
      <Main>
        <Typography style={{ width: '50em', margin: '0 auto', padding: '2rem' }}>
          <Title id="settings" level={2}>
            Inställningar
          </Title>
          <Target id="support">
            <Title level={4}>Support</Title>
            <Paragraph>Vid problem eller oklarheter, tveka inte att kontakta oss</Paragraph>
            <Paragraph>
              <ul>
                <li>
                  <Text strong>
                    <Icon type={'mail'} style={{ marginRight: 8 }} />
                    fastighetsdagboken@support.cdab.eu
                  </Text>
                </li>
              </ul>
            </Paragraph>
          </Target>
          <Divider />
          <Target id="priority-area">
            <Title level={3}>Ärenden - Prioritering & Områden</Title>
            <Paragraph>
              We supply a series of design principles, practical patterns and high quality design
              resources (<Text code>Sketch</Text> and <Text code>Axure</Text>), to help people
              create their product prototypes beautifully and efficiently.
            </Paragraph>
            <Paragraph>
              <ul>
                <li>
                  <a href="settings/assignments">Ärende Prioritet & Områden</a>
                </li>
              </ul>
            </Paragraph>
          </Target>
          <Divider />
          <Target id="excel-import">
            <Title level={3}>Excel Import</Title>
            <Paragraph>
              För att kunna uppdatera alla hyresgäster regelbundet och även skapa upp nya
              fastigheter och lägenheter när det behövs.
            </Paragraph>
            <Paragraph>
              Importen använder excel-filer uttagna från REAL i ett specifikt format. Exempelfiler
              finns på importsidan.
              <Text strong>
                {` `}Viss information på nyskapade fastigheter kommer behöva kompliteras
              </Text>
              .
            </Paragraph>
            <Paragraph>
              <Alert
                message="Tänk på att all data i Excel-filen kommer
                    att läsas in. Så alla fastigheter kommer skapas, även om de är sålda. För att
                    fixa det här. Gå sedan in på Inställningar och Fastigheter. Där kan du söka fram
                    den nyskapade fastigheten och markera den som såld. Då kommer den inte att
                    visas."
                type="warning"
              />
            </Paragraph>
            <Target id="tenants-import">
              <Title level={4}>Hyresgäster</Title>
              <Paragraph>
                Uppdaterar alla hyresgäster i existerande fastigheter. Nya lägenheter i existerande
                fastigheter skapas.
              </Paragraph>
              <Paragraph>
                Finns det nya fastigheter i filen, skapa dessa manuellt innan för att sedan
                importera hyresgästerna. OBS. Endast lägenheter med hyresgäster kommer skapas då. Så
                om en lägenhet är tom så kommer den inte att skapas och inga ärenden kan skapas på
                den. Om du vill att alla Hyrobjekt ska skapas (även tomma) importera Fastigheter &
                LGH
              </Paragraph>
            </Target>
            <Target id="realEstate-import">
              <Title level={4}>Fastigheter & LGH</Title>
              <Paragraph>
                Skapar nya fastigheter och alla hyresobjekt i den. Även Lägenheter som inte har
                inneboende.
              </Paragraph>
              <Paragraph>
                Om nya fastigheter finns så börja alltid med att importera dem först. Eller skapa
                fastigheterna manuellt och importera bara hyresgästerna. Importera sedan
                hyrsesgästerna för att fylla inneboende i de nyskapde lägenheterna och uppdatera
                existerande hyresgäster. Nyskapade Fastigheter kommer behöva kompliteras med Adress,
                Zipkod och Stad. Bild är valfri.
              </Paragraph>
            </Target>

            <Paragraph>
              <ul>
                <li>
                  <a href="settings/import">Excel-Import</a>
                </li>
              </ul>
            </Paragraph>
          </Target>
          <Divider />
          <Target id="realEstate-settings">
            <Title level={3}>Fastigheter</Title>
            <Paragraph>För att administrera fastigheter, här läser du hur du kan:</Paragraph>
            <Paragraph>
              <ul>
                <li>Hitta specifika Fastigheter och får en översikt av dem</li>
                <li>
                  Kunna redigera Fastigheters information, byta bild och markera dem som sålda.
                </li>
                <li>Skapa nya Fastigheter</li>
              </ul>
            </Paragraph>
            <Target id="realEstateList">
              <Title level={4}>Fastighetslista</Title>
              <Paragraph>
                Överst så kan du filtrera på vilka fastigheter du vill se i listan, standard är att
                se alla Ägda Fastigheter. Alternativt: (<Text code>Ägda</Text>,
                <Text code>Borttagna</Text> och <Text code>Alla</Text>). För att hjälpa dig att
                navigera till rätt Fastighet. Alla sålda fastigheter finns under Borttagna, dessa
                fastigheter kommer inte att visas bland Fastigheterna där man skapar ärenden. Om
                något har blivit fel kan man alltid få tillbaka Borttagna fastigheter. Ärenden och
                annat som hade skapats på den försvinner inte. Men för att få tillbaka en såld
                fastighet måste support kontaktas.
              </Paragraph>
              <Paragraph>
                I sökfältet kan du söka på alla atribut en fastighet har, till exempel
                Fastighetsnamn, adress, stad och postnummer. Om du sedan klickar på Fastighetens
                namn eller Insällningar så kommer du till en översikt av Fastigheten. Där ser du all
                aktuell information på den. Du ser också om det saknas något, till exempel
                postnummer.
              </Paragraph>
            </Target>
            <Divider />

            <Target id="realEstate-edit">
              <Title level={4}>Redigera Fastigheter</Title>
              <Paragraph>
                Vid översikten av en Fastighet kan man markera den som såld. Gör detta på
                fastigheter ni inte längre äger och som inte ska synas längre. Den kommer då
                försvinna och inga ärenden kan skapas på den.
              </Paragraph>
              <Paragraph>
                Till vänster så ser du en meny, om du där väljer "Redigera", så kan du byta
                Fastighetsnamn, adress, stad, postnummer och även byta bild. Fastighetsnamn måste
                vara unika.
              </Paragraph>
            </Target>
            <Divider />

            <Target id="realEstate-create">
              <Title level={4}>Skapa ny Fastighet</Title>
              <Paragraph>
                Fastigheter kan skapas automatiskt vid Excel-import av Hyrobjekt. Men vid vissa
                tillfällen kan det vara bra att skapa en manuellt.
              </Paragraph>
              <Paragraph>
                Det gör du enkelt om du navigerar till "Ny Fastighet". Där ser du ett formulär där
                du fyller i Fastighetsnamnet, adress, stad, postnummer och en bild. Bilden är valfri
                och behövs inte.
              </Paragraph>
              <Paragraph>
                <Text strong>
                  Fastigheten är nu skapad men har inga hyrobjekt eller hyresgäster. Importera dessa
                  med en Excel-fil för hyresgäster. Då kommer alla aktiva kontrakt på hyresgäster
                  att skapas. Lägenheterna skapas även i detta import. Tomma lägenheter skapas inte.
                  För att få dessa så måste man importera Fastigheter & Lägenheter från Real
                </Text>
                .
              </Paragraph>
            </Target>

            <Paragraph>
              <ul>
                <li>
                  <a href="settings/realestates">Fastighetslista</a>
                </li>
                <li>
                  <a href="settings/realestates/create">Skapa ny fastighet</a>
                </li>
              </ul>
            </Paragraph>
          </Target>
        </Typography>
        <div
          style={{
            backgroundColor: 'white',
            height: 200,
            padding: 0,
            backgroundImage: `url(${City})`
          }}
        />
      </Main>
      <Sider style={{ borderLeft: '2px solid rgb(232, 232, 232)' }}>
        <Anchor style={{ marginLeft: '-6px' }} offsetTop={100}>
          <Link href="#settings" title="Inställningar">
            <Link href="#support" title="Support" />
          </Link>
          <Link href="#priority-area" title="Ärenden"></Link>
          <Link href="#excel-import" title="Excel Import">
            <Link href="#tenants-import" title="Hyresgäst" />
            <Link href="#realEstate-import" title="Fastigheter & LGH" />
          </Link>
          <Link href="#realEstate-settings" title="Fastigheter">
            <Link href="#realEstateList" title="Fastighetslista" />
            <Link href="#realEstate-edit" title="Redigera" />
            <Link href="#realEstate-create" title="Skapa Ny" />
          </Link>
        </Anchor>
      </Sider>
    </StyledDefaultSettingsLanding>
  )
}

export default withTranslation()(DefaultSettingsLanding)
