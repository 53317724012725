import React from 'react'

import { AuthUserContext } from '../'
import { withFirebase } from '../../firebase'
import { IWithAuthenticationProps, IWithAuthenticationState } from './WithAuthentication.interfaces'

export const withAuthentication = Component => {
  class WithAuthentication extends React.Component<
    IWithAuthenticationProps,
    IWithAuthenticationState
  > {
    listener: any

    constructor(props) {
      super(props)
      const user = localStorage.getItem('authUser')
      this.state = {
        authUser: !!user ? JSON.parse(user) : null
      }
    }

    componentDidMount() {
      this.listener = this.props.firebase.onAuthUserListener(
        authUser => {
          this.props.firebase.setUpRootStore(authUser)
          localStorage.setItem('authUser', JSON.stringify(authUser))
          this.setState({ authUser })
        },
        () => {
          localStorage.removeItem('authUser')
          this.setState({ authUser: null })
        }
      )
    }

    componentWillUnmount() {
      this.listener()
    }

    render() {
      return (
        <AuthUserContext.Provider value={this.state.authUser}>
          <Component {...this.props} />
        </AuthUserContext.Provider>
      )
    }
  }

  return withFirebase(WithAuthentication)
}
