import * as React from 'react'

/* Import components here */
import { Bar } from 'react-chartjs-2'

/* Import interfaces here */
import { IOverallPerformanceProps, IOverallPerformanceState } from './OverallPerformance.interfaces'

/* Import utilities here */
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { withFirebase } from '../../../firebase'

let chartReference: any = {}
const plugins = [ChartDataLabels]

const legendOpts = {
  display: true,
  fullWidth: true,
  labels: {
    fontColor: '#AAAAAA',
    usePointStyle: true
  },
  position: 'bottom',
  reverse: false
}

const data = {
  datasets: [],
  labels: [
    'Januari',
    'Februari',
    'Mars',
    'April',
    'Maj',
    'Juni',
    'Juli',
    'Augusti',
    'September',
    'Oktober',
    'November',
    'December'
  ]
}

const barColors = [
  {
    backgroundColor: 'rgba(113, 179, 124, 0.1)',
    borderColor: 'rgba(113, 179, 124, 1)',
    hoverBackgroundColor: 'rgba(113, 179, 124, 0.4)',
    hoverBorderColor: 'rgba(113, 179, 124, 1)'
  },
  {
    backgroundColor: 'rgba(0, 100, 0, 0.1)',
    borderColor: 'rgba(0, 100, 0, 1)',
    hoverBackgroundColor: 'rgba(0, 100, 0, 0.4)',
    hoverBorderColor: 'rgba(0, 100, 0, 1)'
  },
  {
    backgroundColor: 'rgba(30, 86, 49, 0.1)',
    borderColor: 'rgba(30, 86, 49,1)',
    hoverBackgroundColor: 'rgba(30, 86, 49,0.4)',
    hoverBorderColor: 'rgba(30, 86, 49,1)'
  }
]

const lineColors = [
  {
    borderColor: '#EC932F',
    backgroundColor: '#EC932F',
    pointBorderColor: '#EC932F',
    pointBackgroundColor: '#EC932F',
    pointHoverBackgroundColor: '#EC932F',
    pointHoverBorderColor: '#EC932F'
  },
  {
    borderColor: '#F31EB4',
    backgroundColor: '#F31EB4',
    pointBorderColor: '#F31EB4',
    pointBackgroundColor: '#F31EB4',
    pointHoverBackgroundColor: '#F31EB4',
    pointHoverBorderColor: '#F31EB4'
  },
  {
    borderColor: '#00C5CD',
    backgroundColor: '#00C5CD',
    pointBorderColor: '#00C5CD',
    pointBackgroundColor: '#00C5CD',
    pointHoverBackgroundColor: '#00C5CD',
    pointHoverBorderColor: '#00C5CD'
  }
]

const options = {
  plugins: {
    datalabels: {
      color: context => {
        return context.dataset.borderColor
      },
      formatter: (value, context) => {
        return context.dataset.type !== 'line' ? value : ''
      },
      labels: {
        title: {
          font: {
            weight: 'bold'
          },
          display: context => {
            // console.log(context)
            // console.log(context.dataset.data[context.dataIndex] >=(context.chart.scales["y-axis-0"].end/10))
            // return context.dataset.data[context.dataIndex] > 0 // If val is 0 dont show year
            return (
              context.dataset.data[context.dataIndex] >= context.chart.scales['y-axis-0'].end / 10
            )
          }
        },
        value: {
          align: 'start',
          formatter: (value, context) => {
            return context.dataset.type !== 'line'
              ? context.dataset.label.substring(context.dataset.label.length - 4)
              : null
          },
          display: context => {
            // console.log(context)
            // console.log(context.dataset.data[context.dataIndex] >=(context.chart.scales["y-axis-0"].end/5))
            // return context.dataset.data[context.dataIndex] > 0 // If val is 0 dont show year
            return (
              context.dataset.data[context.dataIndex] >= context.chart.scales['y-axis-0'].end / 5
            )
          }
        }
      }
    }
  },
  maintainAspectRatio: false,
  scales: {
    xAxes: [
      {
        categoryPercentage: 0.8,
        barPercentage: 0.9
      }
    ],
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
          stepSize: 50,
          userCallback(label, index, labels) {
            // when the floored value is the same as the value we have a whole number
            if (Math.floor(label) === label) {
              return label
            }
          }
        }
      }
    ]
  }
}

export class OverallPerformance extends React.Component<
  IOverallPerformanceProps,
  IOverallPerformanceState
> {
  private unsubscribe: any

  public componentDidUpdate(prevProps) {
    if (this.props.selectedYears !== prevProps.selectedYears) {
      if (this.unsubscribe) {
        this.unsubscribe()
      }
      const ci = chartReference
      this.emptyDataset(ci.chartInstance)
      const pickedYears = this.props.selectedYears
      this.unsubscribe = this.props.firebase.statistics().onSnapshot(doc => {
        const numberOfCompletedAssignments = doc.data().numberOfCompletedAssignments
        const numberOfCreatedAssignments = doc.data().numberOfCreatedAssignments
        for (const year in numberOfCompletedAssignments) {
          if (numberOfCompletedAssignments.hasOwnProperty(year)) {
            if (pickedYears.includes(year)) {
              const temp = []
              Object.keys(numberOfCompletedAssignments[year]).forEach(month => {
                temp[month] = numberOfCompletedAssignments[year][month]
              })
              this.addBarDataset(
                ci.chartInstance,
                `Avklarade ${year}`,
                temp,
                pickedYears
                  .sort()
                  .reverse()
                  .indexOf(year)
              )
            }
          }
        }
        for (const year in numberOfCreatedAssignments) {
          if (numberOfCreatedAssignments.hasOwnProperty(year)) {
            if (pickedYears.includes(year)) {
              const temp = []
              Object.keys(numberOfCreatedAssignments[year]).forEach(month => {
                temp[month] = numberOfCreatedAssignments[year][month]
              })
              this.addLineDataset(
                ci.chartInstance,
                `Skapade ${year}`,
                temp,
                pickedYears
                  .sort()
                  .reverse()
                  .indexOf(year)
              )
            }
          }
        }
      })
    }
  }
  public componentDidMount() {
    // How to disable datasets when didMount
    // const defaultDisabled = [0, 1, 3, 4]
    // for (const index of defaultDisabled) {
    //   const ci = chartReference
    //   const meta = ci.chartInstance.getDatasetMeta(index)
    //   // See controller.isDatasetVisible comment
    //   meta.hidden = meta.hidden === null ? !ci.chartInstance.data.datasets[index].hidden : null
    //   // We hid a dataset ... rerender the chart
    //   ci.chartInstance.update()
    // }
  }

  public addBarDataset(chart, label, data, colorIndex) {
    const found = chart.data.datasets.find(dataset => dataset.label === label)
    if (found) {
      found.data = data
      chart.update()
    } else {
      chart.data.datasets.push({
        label,
        data,
        backgroundColor: barColors[colorIndex].backgroundColor,
        borderColor: barColors[colorIndex].borderColor,
        hoverBackgroundColor: barColors[colorIndex].hoverBackgroundColor,
        hoverBorderColor: barColors[colorIndex].hoverBorderColor,
        borderWidth: 2,
        pointStyle: 'rect'
      })
      chart.update()
    }
  }

  public addLineDataset(chart, label, data, colorIndex) {
    const found = chart.data.datasets.find(dataset => dataset.label === label)
    if (found) {
      found.data = data
      chart.update()
    } else {
      chart.data.datasets.push({
        label,
        data,
        type: 'line',
        fill: false,
        borderColor: lineColors[colorIndex].borderColor,
        backgroundColor: lineColors[colorIndex].backgroundColor,
        pointBorderColor: lineColors[colorIndex].pointBorderColor,
        pointBackgroundColor: lineColors[colorIndex].pointBackgroundColor,
        pointHoverBackgroundColor: lineColors[colorIndex].pointHoverBackgroundColor,
        pointHoverBorderColor: lineColors[colorIndex].pointHoverBorderColor,
        lineTension: 0.2
      })
      chart.update()
    }
  }

  public emptyDataset(chart) {
    chart.data.datasets = []
    chart.update()
  }

  public render() {
    return (
      <Bar
        data={data}
        legend={legendOpts}
        options={options}
        width={300}
        height={500}
        plugins={plugins}
        ref={reference => (chartReference = reference)}
      />
    )
  }
}

export default withFirebase(OverallPerformance)
