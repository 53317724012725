import * as React from 'react'

/* Import components here */

/* Import interfaces here */
import {
  IEmployeePerformanceProps,
  IEmployeePerformanceState
} from './EmployeePerformance.interfaces'

/* Import utilities here */
import { Bar } from 'react-chartjs-2'
import { withFirebase } from '../../../firebase'
import { rootStore } from 'stores'

let chartReference: any = {}
const legendOpts = {
  display: true,
  fullWidth: true,
  labels: {
    fontColor: '#AAAAAA',
    usePointStyle: true
  },
  position: 'bottom',
  reverse: false
}

const options = {
  title: {
    display: true
  },
  maintainAspectRatio: false,
  responsive: true,
  tooltips: {
    mode: 'label'
  },
  elements: {
    line: {
      fill: false
    }
  },
  scales: {
    xAxes: [
      {
        display: true,
        gridLines: {
          display: true
        },
        labels: [
          'Januari',
          'Februari',
          'Mars',
          'April',
          'Maj',
          'Juni',
          'Juli',
          'Augusti',
          'September',
          'Oktober',
          'November',
          'December'
        ]
      }
    ],
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
          stepSize: 10
        },
        type: 'linear',
        display: true,
        position: 'left',
        id: 'y-axis-1',
        gridLines: {
          display: true
        },
        labels: {
          show: true
        }
      }
    ]
  }
}

const dataSettings = {
  datasets: [
    {
      label: 'Tilldelade Ärenden',
      type: 'line',
      data: [],
      fill: false,
      borderColor: '#EC932F',
      backgroundColor: '#EC932F',
      pointBorderColor: '#EC932F',
      pointBackgroundColor: '#EC932F',
      pointHoverBackgroundColor: '#EC932F',
      pointHoverBorderColor: '#EC932F',
      yAxisID: 'y-axis-1'
    },
    {
      type: 'bar',
      label: 'Avklarade Ärenden',
      data: [],
      fill: false,
      backgroundColor: 'rgba(113, 179, 124, 0.1)',
      borderColor: 'rgba(113, 179, 124, 1)',
      hoverBackgroundColor: 'rgba(113, 179, 124, 0.4)',
      hoverBorderColor: 'rgba(113, 179, 124, 1)',
      borderWidth: 2,
      yAxisID: 'y-axis-1',
      pointStyle: 'rect'
    }
  ]
}
export class EmployeePerformance extends React.Component<
  IEmployeePerformanceProps,
  IEmployeePerformanceState
> {
  private unsubscribe: any

  constructor(props) {
    super(props)
    this.state = { assignedData: [], completedData: [] }
  }

  public componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (
      this.props.employeeID !== '' &&
      (this.props.year !== prevProps.year || this.props.employeeID !== prevProps.employeeID)
    ) {
      if (this.unsubscribe) {
        this.unsubscribe()
      }
      const ci = chartReference
      const emp = rootStore.employeeStore.getEmployeeWithID(this.props.employeeID)
      if (emp) {
        this.updateChartTitle(ci.chartInstance, emp.firstName + ' ' + emp.lastName, this.props.year)
      }
      const ref = this.props.firebase.userStatistic(this.props.employeeID)
      this.unsubscribe = ref.onSnapshot(doc => {
        const data = doc.data()
        const year = this.props.year
        const assignedData: number[] = []
        const completedData: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]

        if (data) {
          if (data.numberOfAssigned && data.numberOfAssigned[year]) {
            const latestMonth = parseInt(
              Object.keys(data.numberOfAssigned[year]).sort((a, b) => {
                return +b - +a
              })[0],
              10
            )
            for (let i = 0; i < latestMonth; i++) {
              assignedData[i] = 0
            }
            Object.keys(data.numberOfAssigned[year]).forEach(month => {
              assignedData.splice(parseInt(month, 10), 1, data.numberOfAssigned[year][month])
            })
          }
          if (data.numberOfCompletedAssignments && data.numberOfCompletedAssignments[year]) {
            Object.keys(data.numberOfCompletedAssignments[year]).forEach(month => {
              completedData.splice(
                parseInt(month, 10),
                1,
                data.numberOfCompletedAssignments[year][month]
              )
            })
          }
        }
        this.setState({ assignedData, completedData })
      })
    }
  }

  public updateChartTitle(chart, title, year) {
    chart.options.title.text = [title, year]
    chart.update()
  }

  public render() {
    const { assignedData, completedData } = this.state
    const newData: any = dataSettings
    newData.datasets[0].data = assignedData
    newData.datasets[1].data = completedData
    return (
      <Bar
        data={newData}
        options={options}
        legend={legendOpts}
        width={300}
        height={500}
        ref={reference => (chartReference = reference)}
      />
    )
  }
}

export default withFirebase(EmployeePerformance)
