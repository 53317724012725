import * as React from 'react'

import { Button, Icon, notification, Avatar, Badge } from 'antd'
import * as ROUTES from '../../constants/routes'
import { history } from '../../pages/AppRouter'

const close = assignment => {
  history.push(`${ROUTES.INBOX}`)
  notification.close(assignment.assignmentID)
}
const openNotification = assignment => {
  console.log(assignment)
  const contactPerson = assignment.contactPersons[0]
  contactPerson.email = 'mail@mail.com'
  notification.open({
    key: assignment.assignmentID,
    message: `Nytt ärende ◦ ${assignment.dateCreated
      .toDate()
      .toLocaleString([], { hour: '2-digit', minute: '2-digit' })}`,
    description: (
      <div style={{ display: 'flex' }}>
        <div style={{ flex: 1, maxWidth: 50 }}>
          <Badge dot>
            <Avatar icon="inbox" />
          </Badge>
        </div>
        <div style={{ flex: 1 }}>
          <div style={{ fontWeight: 'bold' }}>
            {contactPerson.firstName} {contactPerson.lastName}
          </div>
          <div>{contactPerson.email}</div>
        </div>
        <div style={{ minWidth: 32 }}>
          <Button
            onClick={() => close(assignment)}
            style={{ position: 'absolute', bottom: 16 }}
            shape="circle"
            icon="right"
          />
        </div>
      </div>
    ),
    icon: <Icon type="mail" style={{ color: '#108ee9' }} />,
    duration: 0
  })
}

export default openNotification
