import * as React from 'react'

import { withTranslation } from 'react-i18next'

/* Import components here */
import { StyledRealEstateEditPicture, StyledTitle, Frame } from './RealEstateEditPicture.styles'

/* Import interfaces here */
import {
  IRealEstateEditPictureProps,
  IRealEstateEditPictureState
} from './RealEstateEditPicture.interfaces'

/* Import utilities here */
import { withFirebase } from '../../../../firebase'
import { compose } from 'recompose'
import { Upload, Icon, message } from 'antd'

function beforeUpload(file) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!')
  }
  const isLt2M = file.size / 1024 / 1024 < 2
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!')
  }
  return isJpgOrPng && isLt2M
}

export class RealEstateEditPicture extends React.Component<
  IRealEstateEditPictureProps,
  IRealEstateEditPictureState
> {
  public state = {
    loading: false
  }

  public getExtension(filename) {
    const parts = filename.split('/')
    return parts[parts.length - 1]
  }

  public customUpload = async ({ onError, onSuccess, file }) => {
    this.setState({ loading: true })
    const realEstateID = this.props.realEstate.realEstateID
    const id = this.props.firebase.getUniqueID()
    const ext = this.getExtension(file.type)
    const fileLocation = await this.props.firebase.realEstateStorageRef(
      realEstateID + '/' + (id + '.' + ext)
    )
    const metadata = {
      contentType: file.type
    }

    try {
      const uploadTask = fileLocation.put(file, metadata)
      uploadTask.on(
        this.props.firebase.app.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
        (snapshot: any) => {
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress = Number(
            ((snapshot.bytesTransferred / snapshot.totalBytes) * 100).toFixed(2)
          )
          console.log('Upload is ' + progress + '% done')
          //this.setState({ progress })
          switch (snapshot.state) {
            case this.props.firebase.app.storage.TaskState.PAUSED: // or 'paused'
              console.log('Upload is paused')
              break
            case this.props.firebase.app.storage.TaskState.RUNNING: // or 'running'
              console.log('Upload is running')
              break
          }
        },
        (error: any) => {
          // A full list of error codes is available at
          // https://firebase.google.com/docs/storage/web/handle-errors
          switch (error.code) {
            case 'storage/unauthorized':
              // User doesn't have permission to access the object
              onError(error)
              break

            case 'storage/canceled':
              // User canceled the upload
              onError(error)
              break
            case 'storage/unknown':
              // Unknown error occurred, inspect error.serverResponse
              onError(error)
              break
          }
        },
        () => {
          // Upload completed successfully, now we can get the download URL
          // console.log(file.name)
          // console.log('Snapshot', uploadTask.snapshot.ref.fullPath)
          uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
            // console.log('File available at', downloadURL)
            const realEstateReference = this.props.firebase.realEstate(
              this.props.realEstate.realEstateID
            )

            realEstateReference
              .update({
                pictureURL: downloadURL
              })
              .then(() => {
                this.setState({ loading: false })
              })
            onSuccess(null, uploadTask)
          })
        }
      )
    } catch (e) {
      onError(e)
    }
  }

  public render() {
    const { realEstate, t, children, ...props } = this.props

    const uploadButton = (
      <div
        style={{
          height: 200,
          width: 356,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <div>
          <Icon type={this.state.loading ? 'loading' : 'plus'} />
          <div className="ant-upload-text">Upload</div>
        </div>
      </div>
    )
    const imageUrl = realEstate.pictureURL
    return (
      <StyledRealEstateEditPicture {...props}>
        <StyledTitle style={{ textAlign: 'center' }}>Byt Bild</StyledTitle>
        <Frame>
          <Upload
            name="avatar"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            customRequest={this.customUpload}
            beforeUpload={beforeUpload}
          >
            {imageUrl ? (
              <img
                src={imageUrl}
                alt="avatar"
                style={{ height: 200, width: 356, objectFit: 'cover', borderRadius: 6 }}
              />
            ) : (
              uploadButton
            )}
          </Upload>
        </Frame>
      </StyledRealEstateEditPicture>
    )
  }
}

export default compose(withTranslation(), withFirebase)(RealEstateEditPicture)
