import * as React from 'react'

import { withTranslation } from 'react-i18next'

/* Import components here */
import {
  StyledUserItem,
  CenterContainer,
  CenteredDiv,
  ExtraContent,
  MainContent,
  Wrapper
} from './UserItem.styles'

/* Import interfaces here */
import { IUserItemProps, IUserItemState } from './UserItem.interfaces'

/* Import utilities here */
import { compose } from 'recompose'
import { withFirebase } from '../../../firebase'
import {
  Spin,
  PageHeader,
  Icon,
  Layout,
  Menu,
  Divider,
  Button,
  Result,
  Alert,
  Typography,
  Avatar,
  Tag,
  Popconfirm
} from 'antd'

import Modal from './Modal'
import axios from 'axios'

import { Link, withRouter } from 'react-router-dom'
import * as ROUTES from '../../../constants/routes'
import { UserEditForm } from 'components'
import * as ROLES from '../../../constants/roles'
const { Paragraph, Title, Text } = Typography
const { Content, Sider } = Layout
export class UserItem extends React.Component<IUserItemProps, IUserItemState> {
  unsubscribe: any
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      user: null,
      apiResponse: null,
      apiError: null,
      content: '1',
      buttonLoading: false,
      alert: null,
      ...props.location.state
    }
  }

  componentDidUpdate(prevProps) {
    // Om man är på en annan användares sida och sen går därifrån till sin egna
    if (prevProps.match.params.userId !== this.props.match.params.userId) {
      this.unsubscribe()
      this.setState({ loading: true, user: null })
      this.listenToUserChanges()
    }
  }

  getWithUID = uid => {
    //console.log('UID', uid)
    this.setState({ apiResponse: null, apiError: null })
    const user = this.props.firebase.auth.currentUser

    if (user) {
      user
        .getIdToken(true)
        .then(idToken => {
          axios({
            method: 'get',
            url: `${process.env.REACT_APP_FUNCTIONS_BASE_URL}/getUserByUID`,
            headers: { Authorization: idToken },
            params: {
              uid
            }
          })
            .then(res => this.setState({ apiResponse: res.data, apiError: null }))
            .catch(error => {
              console.log('Error could not get data', error)
              this.setState({ apiError: true })
            })
        })
        .catch(function(error) {
          // Handle error
        })
    } else {
      console.log('No user lul')
    }
  }
  componentDidMount() {
    if (this.state.user) {
      this.listenToUserChanges()
      return
    }
    this.setState({ loading: true })
    this.listenToUserChanges()
  }
  componentWillUnmount() {
    this.unsubscribe && this.unsubscribe()
  }

  listenToUserChanges = () => {
    this.unsubscribe = this.props.firebase
      .user(this.props.match.params.userId)
      .onSnapshot(snapshot => {
        console.log(snapshot.data())
        this.setState(
          {
            user: snapshot.data(),
            loading: false
          },
          () => {
            this.getWithUID(this.props.match.params.userId)
          }
        )
      })
  }

  deleteUser = uid => {
    const user = this.props.firebase.auth.currentUser
    if (user) {
      user
        .getIdToken(true)
        .then(idToken => {
          axios({
            method: 'post',
            url: `${process.env.REACT_APP_FUNCTIONS_BASE_URL}/deleteUser`,
            headers: { Authorization: idToken },
            params: {
              uid
            }
          })
            .then(res => {
              if (res.data !== 'Error') {
                this.unsubscribe()
                this.props.firebase
                  .deleteUser(uid)
                  .then(() => {
                    this.props.history.push(`${ROUTES.ADMIN_USERS}`)
                  })
                  .catch(error => console.error(error))
              }
            })
            .catch(error => {
              console.log('Kunde ej ta bort användare', error)
            })
        })
        .catch(error => {
          console.error('ingen ID Token', error)
        })
    } else {
      console.log('Ej inloggad')
    }
  }

  onSendPasswordResetEmail = () => {
    //console.log('EMAIL SENT')
    this.setState({ buttonLoading: true })
    this.props.firebase
      .doPasswordReset(this.state.user.email)
      .then(() => {
        //console.log('Mail sent')
        this.setState({
          buttonLoading: false,
          alert: {
            message: 'Återställningsmail har skickats till: ' + this.state.user.email,
            type: 'success'
          }
        })
      })
      .catch(error => {
        //console.log(error)
        this.setState({
          buttonLoading: false,
          alert: {
            message: 'Misslyckades med att skicka mail',
            type: 'error'
          }
        })
      })
  }

  toggleAccountIsActive = user => {
    const admin = this.props.firebase.auth.currentUser
    if (admin) {
      this.setState({ buttonLoading: true })
      admin
        .getIdToken(true)
        .then(idToken => {
          axios({
            method: 'post',
            url: `${process.env.REACT_APP_FUNCTIONS_BASE_URL}/toggleAccountActivity`,
            headers: { Authorization: idToken },
            params: {
              uid: user.userID,
              status: user.isActive
            }
          })
            .then(res => this.setState({ buttonLoading: false }))
            .catch(error => {
              //console.log(error)
            })
        })
        .catch(error => {
          // Handle error
        })
    } else {
      //console.log('No user')
    }
  }

  setPermission = role => {
    const { user } = this.state
    const currentUser = this.props.firebase.auth.currentUser
    if (currentUser) {
      currentUser.getIdToken(true).then(idToken => {
        axios({
          method: 'get',
          url: `${process.env.REACT_APP_FUNCTIONS_BASE_URL}/setPermissions`,
          headers: { Authorization: idToken },
          params: {
            email: user.email,
            role
          }
        })
          .then(() => {
            console.log('Userrole added')
          })
          .catch(error => console.error(error))
      })
    }
  }

  handleClick = e => {
    //console.log('click ', e)
    this.setState({ content: e.key })
  }

  public render() {
    const { authUser, t, children, ...props } = this.props
    const { user, loading, apiResponse, content, buttonLoading, alert, apiError } = this.state

    const disabled =
      authUser &&
      authUser.roles &&
      authUser.roles[ROLES.ADMIN] &&
      user &&
      user.roles &&
      user.roles[ROLES.ADMIN] &&
      user.userID !== authUser.userID
        ? true
        : false

    const isOwnAccount =
      (user && user.roles && user.roles[ROLES.ADMIN]) || (user && user.userID === authUser.userID)
        ? true
        : false
    return (
      <StyledUserItem {...props}>
        {loading && (
          <CenterContainer style={{ height: '344px' }}>
            <CenteredDiv>
              <Spin size="large" />
            </CenteredDiv>
          </CenterContainer>
        )}

        {user && (
          <Layout>
            <PageHeader
              style={{ backgroundColor: 'white' }}
              backIcon={
                <Link to={ROUTES.ADMIN_USERS}>
                  <Icon type="arrow-left" />
                </Link>
              }
              onBack={() => null}
              title="Tillbaka"
            />
            <Layout style={{ padding: '0px 0', background: '#fff' }}>
              <Sider width={200} style={{ background: '#fff' }}>
                <Menu
                  onClick={this.handleClick}
                  mode="inline"
                  defaultSelectedKeys={['1']}
                  style={{ height: '100%' }}
                >
                  <Menu.Item key="1">
                    <Icon type="user" />
                    <span>Översikt</span>
                  </Menu.Item>
                  <Menu.Item key="2">
                    <Icon type="setting" />
                    <span>Inställningar</span>
                  </Menu.Item>
                </Menu>
              </Sider>
              <Content style={{ padding: '0 24px', minHeight: 280 }}>
                {content === '1' && (
                  <Wrapper>
                    <MainContent>
                      <Title level={3}>{user.firstName + ' ' + user.lastName}</Title>
                      <Paragraph>
                        <Text strong style={{ marginRight: 8 }}>
                          <Icon type="mail" />
                        </Text>
                        <Text copyable>{user.email}</Text>
                      </Paragraph>
                      <Paragraph>
                        <Text strong style={{ marginRight: 8 }}>
                          <Icon type="phone" />
                        </Text>
                        <Text copyable>{user.phoneNumbers}</Text>
                      </Paragraph>
                      <Divider />
                      {user.isActive ? (
                        <Tag color="green">Aktiv</Tag>
                      ) : (
                        <Tag color="red">Inaktiv</Tag>
                      )}
                      {!!user.roles && user.roles.ADMIN ? (
                        <Tag color="geekblue">Kontorspersonal</Tag>
                      ) : null}
                      {!!user.roles && user.roles.JANITOR ? (
                        <Tag color="volcano">Fastighetsskötare</Tag>
                      ) : null}
                      <Divider />
                      {!apiError && apiResponse !== 'No user found' && (
                        <>
                          <Paragraph>
                            {!apiResponse && <Spin size="small" />}
                            {apiResponse && apiResponse.email && (
                              <Text strong style={{ marginRight: 8 }}>
                                <Icon
                                  twoToneColor={apiResponse.emailVerified ? '#52c41a' : 'red'}
                                  theme="twoTone"
                                  type={apiResponse.emailVerified ? 'check-circle' : 'close-circle'}
                                />
                                {apiResponse.emailVerified
                                  ? ' Email verifierad'
                                  : ' Email är inte verifierad'}
                              </Text>
                            )}
                          </Paragraph>
                          <Paragraph>
                            <Text strong style={{ marginRight: 8 }}>
                              <Icon type="calendar" /> Användare skapad:
                            </Text>
                            <Text>
                              {!apiResponse && <Spin size="small" />}
                              {apiResponse &&
                                new Date(apiResponse.metadata.creationTime).toLocaleString()}
                            </Text>
                          </Paragraph>
                          <Paragraph>
                            <Text strong style={{ marginRight: 8 }}>
                              <Icon type="calendar" /> Senaste inloggningen:
                            </Text>
                            <Text>
                              {!apiResponse && <Spin size="small" />}
                              {apiResponse &&
                                apiResponse.metadata.lastSignInTime &&
                                new Date(apiResponse.metadata.lastSignInTime).toLocaleString()}
                            </Text>
                          </Paragraph>
                        </>
                      )}
                      {(apiError || apiResponse === 'No user found') && (
                        <div>Kunde inte hämta data</div>
                      )}
                    </MainContent>
                    <ExtraContent>
                      <Avatar size={128} src={user.profilePicture} icon="user" />
                      {/* <div>
                        <Text strong>{user.firstName + ' ' + user.lastName}</Text>
                      </div>
                      <div>
                        <Text type="secondary">Roll</Text>
                      </div> */}
                    </ExtraContent>
                  </Wrapper>
                )}

                {content === '2' && (
                  <>
                    {!apiResponse && !apiError && (
                      <CenterContainer style={{ height: '321px' }}>
                        <CenteredDiv>
                          <Spin size="large" />
                        </CenteredDiv>
                      </CenterContainer>
                    )}
                    {!apiError && apiResponse !== 'No user found' && (
                      <>
                        {apiResponse && apiResponse.providerData && (
                          <>
                            <UserEditForm
                              user={user}
                              providerData={apiResponse.providerData}
                              disabled={disabled}
                            />
                            <Divider />
                            <Paragraph>
                              <Button
                                loading={buttonLoading}
                                onClick={this.onSendPasswordResetEmail}
                                style={{ marginRight: 20 }}
                              >
                                Skicka Lösenord Återställningsmail
                              </Button>
                              <Button
                                disabled={isOwnAccount}
                                loading={buttonLoading}
                                onClick={() => this.toggleAccountIsActive(user)}
                                style={{ marginRight: 20 }}
                              >
                                {user.isActive ? 'Inaktivera Konto' : 'Aktivera Konto'}
                              </Button>
                              <Popconfirm
                                placement="bottom"
                                title={'Är du säker på att du vill radera denna användaren?'}
                                onConfirm={() => this.deleteUser(user.userID)}
                                okText="Ja"
                                cancelText="Nej"
                              >
                                <Button
                                  type="danger"
                                  disabled={isOwnAccount}
                                  loading={buttonLoading}
                                  style={{ float: 'right' }}
                                >
                                  Radera Användare
                                </Button>
                              </Popconfirm>

                              <Modal
                                user={user}
                                setPermission={this.setPermission}
                                isOwnAccount={isOwnAccount}
                              />
                              {!!user.roles && user.roles.ADMIN ? (
                                <Tag
                                  style={{
                                    height: '32px',
                                    fontSize: '14px',
                                    lineHeight: '30px',
                                    marginLeft: '20px'
                                  }}
                                  color="geekblue"
                                >
                                  Kontorspersonal
                                </Tag>
                              ) : null}
                              {!!user.roles && user.roles.JANITOR ? (
                                <Tag
                                  style={{
                                    height: '32px',
                                    fontSize: '14px',
                                    lineHeight: '30px',
                                    marginLeft: '20px'
                                  }}
                                  color="volcano"
                                >
                                  Fastighetsskötare
                                </Tag>
                              ) : null}

                              {alert && (
                                <Alert
                                  style={{ marginTop: 24 }}
                                  message={alert.message}
                                  type={alert.type}
                                />
                              )}
                            </Paragraph>
                          </>
                        )}
                      </>
                    )}
                    {(apiError || apiResponse === 'No user found') && (
                      <Result
                        status="warning"
                        title="Problem med att hämta data"
                        subTitle="Kontakta CDAB om problem kvarstår"
                        extra={
                          <Button
                            type="primary"
                            key="console"
                            onClick={() => this.getWithUID(this.props.match.params.userId)}
                          >
                            Hämta igen
                          </Button>
                        }
                      />
                    )}
                  </>
                )}
              </Content>
            </Layout>
          </Layout>
        )}
      </StyledUserItem>
    )
  }
}

export default compose(withTranslation(), withRouter, withFirebase)(UserItem)
