import * as React from 'react'

import { withTranslation } from 'react-i18next'

/* Import components here */
import { StyledRealEstateForm, StyledTitle } from './RealEstateForm.styles'
import { Button, Form, Icon, Input, Upload, Modal } from 'antd' //InputNumber, Select

/* Import interfaces here */
import { IRealEstateFormProps, IRealEstateFormState } from './RealEstateForm.interfaces'

/* Import utilities here */
import { withFirebase } from '../../../../firebase'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import * as ROUTES from '../../../../constants/routes'

export class RealEstateForm extends React.Component<IRealEstateFormProps, IRealEstateFormState> {
  public state = {
    loading: false
  }

  public normFile = e => {
    // console.log('Upload event:', e)
    if (Array.isArray(e)) {
      //console.log('IsArray')
      return e
    }
    // console.log(e.fileList)
    e.fileList.length > 1 ? e.fileList.shift() : e.fileList.sort()
    return e && e.fileList
  }

  public async componentDidMount() {
    // To disabled submit button at the beginning.
    // console.log(await this.checkIfUniqueName('Fotografen 9'))
    this.props.form.validateFields()
  }

  public checkIfUniqueName = async realEstateName => {
    let unique = false
    await this.props.firebase
      .realEstates()
      .get()
      .then(querySnapshot => {
        let found = false
        querySnapshot.forEach(doc => {
          if (realEstateName === doc.get('address.realEstateName')) {
            found = true
          }
        })
        unique = !found
      })
      .catch(error => {
        console.log('Error getting documents: ', error)
      })
    return unique
  }
  public handleSubmit = e => {
    e.preventDefault()
    this.setState({
      loading: true
    })
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        if (await this.checkIfUniqueName(values.realEstateName)) {
          const realEstateID = this.props.firebase.getUniqueID()
          const realEstateReference = this.props.firebase.realEstate(realEstateID)

          realEstateReference
            .set({
              address: {
                city: values.city,
                realEstateName: values.realEstateName,
                street: values.street,
                zipCode: values.zip
              },
              isOwned: true,
              numberOfRentalObjects: 0,
              realEstateID,
              pictureURL: ''
            })
            .then(() => {
              // console.log('Document successfully written!')
              if (values.upload) {
                this.customUpload(values.upload[0].originFileObj, realEstateID, realEstateReference)
              } else {
                this.props.history.push(`${ROUTES.SETTINGS_REAL_ESTATE_LIST}/${realEstateID}`)
                // this.successModal('Fastigheten skapades!')
                // this.props.form.resetFields()
                // this.setState({ loading: false })
              }
            })
            .catch(error => {
              console.error('Error writing document: ', error)
            })
        } else {
          this.errorModal(
            'Misslyckades med att skapa Fastighet',
            'En Fastighet med det här namnet existerar redan.'
          )
          this.setState({
            loading: false
          })
        }
      }
    })
  }

  public hasErrors(fieldsError) {
    return Object.keys(fieldsError).some(field => fieldsError[field])
  }

  public getExtension(filename) {
    const parts = filename.split('/')
    return parts[parts.length - 1]
  }

  public customUpload = async (file, realEstateID, realEstateReference) => {
    const id = this.props.firebase.getUniqueID()
    const ext = this.getExtension(file.type)
    const fileLocation = await this.props.firebase.realEstateStorageRef(
      realEstateID + '/' + (id + '.' + ext)
    )
    const metadata = {
      contentType: file.type
    }

    try {
      const uploadTask = fileLocation.put(file, metadata)
      uploadTask.on(
        this.props.firebase.app.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
        (snapshot: any) => {
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          // const progress = Number(
          //   ((snapshot.bytesTransferred / snapshot.totalBytes) * 100).toFixed(2)
          // )
          // console.log('Upload is ' + progress + '% done')
          switch (snapshot.state) {
            case this.props.firebase.app.storage.TaskState.PAUSED: // or 'paused'
              // console.log('Upload is paused')
              break
            case this.props.firebase.app.storage.TaskState.RUNNING: // or 'running'
              // console.log('Upload is running')
              break
          }
        },
        (error: any) => {
          // A full list of error codes is available at
          // https://firebase.google.com/docs/storage/web/handle-errors
          switch (error.code) {
            case 'storage/unauthorized':
              // User doesn't have permission to access the object
              this.errorModal('Error', 'Misslyckades med att ladda upp bild till fastigheten')
              this.setState({ loading: false })
              break

            case 'storage/canceled':
              // User canceled the upload
              this.errorModal('Error', 'Misslyckades med att ladda upp bild till fastigheten')
              this.setState({ loading: false })
              break
            case 'storage/unknown':
              // Unknown error occurred, inspect error.serverResponse
              this.errorModal('Error', 'Misslyckades med att ladda upp bild till fastigheten')
              this.setState({ loading: false })
              break
          }
        },
        () => {
          // Upload completed successfully, now we can get the download URL
          // console.log(file.name)
          // console.log('Snapshot', uploadTask.snapshot.ref.fullPath)
          uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
            //console.log('File available at', downloadURL)
            realEstateReference
              .update({
                pictureURL: downloadURL
              })
              .then(() => {
                this.props.history.push(`${ROUTES.SETTINGS_REAL_ESTATE_LIST}/${realEstateID}`)
                // this.successModal('Fastigheten skapades!')
                // this.props.form.resetFields()
                // this.setState({ loading: false })
              })
          })
        }
      )
    } catch (e) {
      console.log('Error uploading', e)
      this.errorModal('Error', 'Misslyckades med att ladda upp bild till fastigheten')
      this.setState({ loading: false })
    }
  }

  public successModal = title => {
    Modal.success({
      title
    })
  }

  public errorModal = (title, content) => {
    Modal.error({
      title,
      content
    })
  }
  public render() {
    const { t, children, ...props } = this.props
    const { loading } = this.state
    const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } = this.props.form
    const realEstateNameError = isFieldTouched('realEstateName') && getFieldError('realEstateName')
    const streetError = isFieldTouched('street') && getFieldError('street')
    const cityError = isFieldTouched('city') && getFieldError('city')
    const zipError = isFieldTouched('zip') && getFieldError('zip')

    const formItemLayout = {
      labelCol: { span: 3 },
      wrapperCol: { span: 18 }
    }

    const buttonItemLayout = {
      wrapperCol: { span: 18, offset: 3 }
    }
    return (
      <StyledRealEstateForm {...props}>
        <StyledTitle style={{ textAlign: 'center' }}>Ny Fastighet</StyledTitle>
        <Form layout="horizontal" onSubmit={this.handleSubmit}>
          <Form.Item
            {...formItemLayout}
            label="Beteckning"
            validateStatus={realEstateNameError ? 'error' : ''}
            help={realEstateNameError || ''}
          >
            {getFieldDecorator('realEstateName', {
              rules: [{ required: true, message: 'Ange fastighetensbeteckning !' }]
            })(
              <Input
                prefix={<Icon type="edit" style={{ color: 'rgba(0,0,0,.25)' }} />}
                placeholder="Fastighetsbeteckning"
              />
            )}
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            label="Adress"
            validateStatus={streetError ? 'error' : ''}
            help={streetError || ''}
          >
            {getFieldDecorator('street', {
              rules: [{ required: true, message: 'Ange adress!' }]
            })(
              <Input
                prefix={<Icon type="home" style={{ color: 'rgba(0,0,0,.25)' }} />}
                placeholder="Adress"
              />
            )}
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            label="Stad"
            validateStatus={cityError ? 'error' : ''}
            help={cityError || ''}
          >
            {getFieldDecorator('city', {
              rules: [{ required: true, message: 'Ange stad!' }]
            })(
              <Input
                prefix={<Icon type="shop" style={{ color: 'rgba(0,0,0,.25)' }} />}
                placeholder="Stad"
              />
            )}
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            label="Zip"
            validateStatus={zipError ? 'error' : ''}
            help={zipError || ''}
          >
            {getFieldDecorator('zip', {
              rules: [{ required: true, message: 'Ange zipkod!' }]
            })(
              <Input
                prefix={<Icon type="environment" style={{ color: 'rgba(0,0,0,.25)' }} />}
                placeholder="Zip"
              />
            )}
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            label="Upload"
            extra="Välj bild som ska representera fastigheten"
          >
            {getFieldDecorator('upload', {
              valuePropName: 'fileList',
              getValueFromEvent: this.normFile
            })(
              <Upload name="logo" beforeUpload={() => false} listType="picture">
                <Button style={{ width: '100%' }}>
                  <Icon type="upload" /> Välj Bild
                </Button>
              </Upload>
            )}
          </Form.Item>
          <Form.Item {...buttonItemLayout}>
            <Button
              type="primary"
              htmlType="submit"
              disabled={this.hasErrors(getFieldsError())}
              loading={loading}
              style={{ width: '100%' }}
            >
              Lägg till Fastighet
            </Button>
          </Form.Item>
        </Form>
      </StyledRealEstateForm>
    )
  }
}

/** @component */
export default Form.create({ name: 'RealEstateForm' })(
  compose(withTranslation(), withRouter, withFirebase)(RealEstateForm)
)
