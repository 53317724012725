import { styled } from 'theme'
import { Input } from 'antd'

export const EmailInput = styled(Input)`
  height: 50px;
`
export const Form = styled.form`
  .ant-btn {
    color: white;
    width: 100%;
    height: 40px;
    border-radius: 25px;
    margin-top: 5px;
    background-color: #60944f;
    border-color: rgb(74, 117, 70);
  }
  .ant-btn:hover {
    color: white;
    background-color: rgb(137, 196, 119);
    border-color: rgb(118, 172, 101);
  }
  .ant-btn:focus {
    color: white;
    background-color: rgb(137, 196, 119);
    border-color: rgb(118, 172, 101);
  }

  .ant-btn[disabled] {
    color: white;
    background-color: #60944f;
    border-color: rgb(74, 117, 70);
  }
`
