import { styled } from 'theme'

export const StyledRealEstateList = styled.div`
  background-color: white;
  color: blue;
  padding: 1rem;
`
export const CenterContainer = styled.div`
  margin-top: 100px;
  position: relative;
`

export const CenteredDiv = styled.div`
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
`
