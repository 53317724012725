import { styled } from 'theme'
import { Collapse } from 'antd'

export const StyledRentalObjectAssignmentHistory = styled.div`
  background-color: white;
  padding: 24px;
`

export const BoldSpan = styled.span`
  font-weight: bold;
`

export const EditorWrapper = styled.div`
  .ant-form-item {
    margin-bottom: 0px;
  }

  .ant-form-item-control {
    line-height: 0px;
  }
`

export const StyledCollapse = styled(Collapse)`
  > div > div.ant-collapse-content.ant-collapse-content-active > div {
    padding: 0px 16px 0px;
  }
`

export const PostImgGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
  align-items: stretch;
  justify-items: stretch;

  img {
    border: 1px solid #ccc;
    box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.3);
    width: 100px;
    height: 100px;
  }
`
