import * as React from 'react'
import { Card, Icon } from 'antd'
import { Link } from 'react-router-dom'
import * as ROUTES from '../../constants/routes'
import { withFirebase } from '../../firebase'
import { styled } from 'theme'

const Container = styled.div`
  .ant-card-body {
    padding: 10px 0px 0px 24px;
  }
`

export interface IMessageProps {
  firebase: any
}

export interface IMessageState {
  latestNews: any[]
  importantNews: any[]
}

class SideNavigation extends React.Component<IMessageProps, IMessageState> {
  constructor(props) {
    super(props)

    this.state = {
      latestNews: [],
      importantNews: []
    }
  }

  componentDidMount() {
    this.getLatestNews()
    this.getImportantNews()
  }

  getLatestNews = () => {
    this.props.firebase
      .latestestNews()
      .get()
      .then(snapshot => {
        const latestNews: any = []
        snapshot.forEach(doc => {
          latestNews.push({ ...doc.data(), uid: doc.id })
        })
        this.setState({
          latestNews
        })
      })
  }

  getImportantNews = () => {
    this.props.firebase
      .importantNews()
      .get()
      .then(snapshot => {
        const importantNews: any = []
        snapshot.forEach(doc => {
          importantNews.push({ ...doc.data(), uid: doc.id })
        })
        this.setState({
          importantNews
        })
      })
  }
  render() {
    const { latestNews, importantNews } = this.state
    return (
      <Container>
        <Card bordered={false} title="Senaste Nyheter">
          {latestNews.map((news: any) => (
            <div key={news.uid}>
              {news.favourite && (
                <Icon
                  type="star"
                  theme="twoTone"
                  style={{ marginRight: 8 }}
                  twoToneColor="#DAA520"
                />
              )}
              <Link
                style={{ marginLeft: news.favourite ? '0px' : '22px' }}
                to={`${ROUTES.HOME}/${news.uid}`}
              >
                {news.title}
              </Link>
            </div>
          ))}
        </Card>
        <Card bordered={false} title="Viktiga Nyheter">
          {importantNews.map((news: any) => (
            <div key={news.uid}>
              <Icon type="star" theme="twoTone" style={{ marginRight: 8 }} twoToneColor="#DAA520" />
              <Link to={`${ROUTES.HOME}/${news.uid}`}>{news.title}</Link>
            </div>
          ))}
        </Card>
        {/* <Card bordered={false} title="Guider">
          <Link to="/home">Link To Post</Link>
        </Card> */}
      </Container>
    )
  }
}

export default withFirebase(SideNavigation)
