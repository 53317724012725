import * as React from 'react'

import { withTranslation, useTranslation } from 'react-i18next'

/* Import components here */
//import { StyledSignUpForm } from './SignUpForm.styles'

/* Import interfaces here */
import { ISignUpFormProps, ISignUpFormState } from './SignUpForm.interfaces'

/* Import utilities here */
import { Link, withRouter } from 'react-router-dom'
import { compose } from 'recompose'

import { withFirebase } from '../../firebase'
import * as ROUTES from '../../constants/routes'
import * as ROLES from '../../constants/roles'

const INITIAL_STATE = {
  username: '',
  email: '',
  passwordOne: '',
  passwordTwo: '',
  isAdmin: false,
  error: null
}

const ERROR_CODE_ACCOUNT_EXISTS = 'auth/email-already-in-use'

const ERROR_MSG_ACCOUNT_EXISTS = `
  An account with this E-Mail address already exists.
  Try to login with this account instead. If you think the
  account is already used from one of the social logins, try
  to sign in with one of them. Afterward, associate your accounts
  on your personal account page.
`

export class SignUpFormBase extends React.Component<ISignUpFormProps, ISignUpFormState> {
  constructor(props) {
    super(props)

    this.state = { ...INITIAL_STATE }
  }

  onSubmit = event => {
    const { username, email, passwordOne, isAdmin } = this.state
    const roles = {}

    if (isAdmin) {
      roles[ROLES.ADMIN] = ROLES.ADMIN
    }

    this.props.firebase
      .doCreateUserWithEmailAndPassword(email, passwordOne)
      .then(authUser => {
        // Create a user in your Firebase realtime database
        return this.props.firebase.user(authUser.user.uid).set(
          {
            username,
            email,
            roles
          },
          { merge: true }
        )
      })
      .then(() => {
        return this.props.firebase.doSendEmailVerification()
      })
      .then(() => {
        this.setState({ ...INITIAL_STATE })
        this.props.history.push(ROUTES.HOME)
      })
      .catch(error => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS
        }

        this.setState({ error })
      })

    event.preventDefault()
  }

  // onChange = event => {
  //   this.setState({ [event.target.name]: event.target.value });
  // };

  onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      [e.target.name]: e.target.value
    } as { [K in keyof ISignUpFormState]: ISignUpFormState[K] })
  }

  // onChangeCheckbox = event => {
  //   this.setState({ [event.target.name]: event.target.checked });
  // };

  onChangeCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      [e.target.name]: e.target.value
    } as { [K in keyof ISignUpFormState]: ISignUpFormState[K] })
  }

  public render() {
    // const { t, children, ...props } = this.props
    const { username, email, passwordOne, passwordTwo, isAdmin, error } = this.state

    const isInvalid =
      passwordOne !== passwordTwo || passwordOne === '' || email === '' || username === ''

    return (
      <form onSubmit={this.onSubmit}>
        <input
          name="username"
          value={username}
          onChange={this.onChange}
          type="text"
          placeholder="Full Name"
        />
        <input
          name="email"
          value={email}
          onChange={this.onChange}
          type="text"
          placeholder="Email Address"
        />
        <input
          name="passwordOne"
          value={passwordOne}
          onChange={this.onChange}
          type="password"
          placeholder="Password"
        />
        <input
          name="passwordTwo"
          value={passwordTwo}
          onChange={this.onChange}
          type="password"
          placeholder="Confirm Password"
        />
        <label>
          Admin:
          <input
            name="isAdmin"
            type="checkbox"
            checked={isAdmin}
            onChange={this.onChangeCheckbox}
          />
        </label>
        <button disabled={isInvalid} type="submit">
          Sign Up
        </button>

        {error && <p>{error.message}</p>}
      </form>
    )
  }
}
const SignUpLink = () => {
  const { t } = useTranslation()
  return (
    <p>
      Don't have an account? {t('landing:title')} <Link to={ROUTES.SIGN_UP}>Sign Up</Link>
    </p>
  )
}

const SignUpForm = compose(withTranslation(), withRouter, withFirebase)(SignUpFormBase)

export default SignUpForm

export { SignUpLink }
