import { styled } from 'theme'

export const StyledRealEstateForm = styled.div`
  background-color: white;
  padding: 5rem 0rem;
  max-width: 700px;
  margin: 0 auto;

  .ant-upload.ant-upload-select.ant-upload-select-picture {
    width: 100%;
  }
`

export const StyledTitle = styled.div`
  padding: 1rem;
  font-size: 18px;
  font-weight: bold;
`
