import * as React from 'react'

import { withTranslation } from 'react-i18next'

/* Import components here */
import { StyledDefaultSettingsNavigation } from './DefaultSettingsNavigation.styles'

/* Import interfaces here */
import { IDefaultSettingsNavigationProps } from './DefaultSettingsNavigation.interfaces'

/* Import utilities here */
import { Icon, Layout, Menu } from 'antd'
import { Switch, Route, Link } from 'react-router-dom'
import * as ROUTES from '../../../../constants/routes'
import { DefaultSettingsLanding, DefaultSettingsBoundary } from 'components'
const { Content, Sider } = Layout
export const DefaultSettingsNavigation: React.FC<IDefaultSettingsNavigationProps> = ({
  t,
  children,
  ...props
}) => {
  return (
    <StyledDefaultSettingsNavigation {...props}>
      <Layout>
        <Layout>
          <Sider width={200} style={{ background: '#fff' }}>
            <Menu mode="inline" style={{ height: '100%' }}>
              <Menu.Item key="1">
                <Link to={ROUTES.SETTINGS}>
                  <Icon type="question-circle" />
                  <span>Info</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="2">
                <Link to={ROUTES.SETTINGS_ASSIGNMENTS}>
                  <Icon type="bars" />
                  <span>Ärenden</span>
                </Link>
              </Menu.Item>
              {/* <Menu.Item key="3">
                <Link to={ROUTES.SETTINGS_PRIORITIES}>
                  <Icon type="plus-circle" />
                  <span>Prioriteringar</span>
                </Link>
              </Menu.Item> */}
            </Menu>
          </Sider>
          <Content style={{ minHeight: 280, backgroundColor: 'white' }}>
            <Switch>
              <Route exact path={ROUTES.SETTINGS} component={DefaultSettingsLanding} />
              <Route exact path={ROUTES.SETTINGS_ASSIGNMENTS} component={DefaultSettingsBoundary} />
              {/* <Route exact path={ROUTES.SETTINGS_PRIORITIES} render={() => <div>Prio</div>} /> */}
            </Switch>
          </Content>
        </Layout>
      </Layout>
    </StyledDefaultSettingsNavigation>
  )
}

export default withTranslation()(DefaultSettingsNavigation)
