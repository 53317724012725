import * as React from 'react'

/* Import components here */
import { AssignmentTable } from 'components'
import { compose } from 'recompose'

import { withAuthorization, withEmailVerification } from '../../contexts'
export const AssignmentsPage: React.FC = ({ children, ...props }) => {
  return (
    <div style={{ backgroundColor: 'white' }}>
      <AssignmentTable />
    </div>
  )
}

const condition = authUser => !!authUser

export default compose(withEmailVerification, withAuthorization(condition))(AssignmentsPage)
