import { observable, action, computed } from 'mobx'

export default class EmployeeStore {
  @observable
  public allEmployees: any[] = []
  @observable loading: boolean = true

  @action.bound
  public setAllEmployees(array) {
    this.allEmployees = array
  }

  @action.bound
  public setLoading(bool) {
    this.loading = bool
  }

  @computed get employeesRecieved() {
    return !this.loading
  }

  @computed get totalJanitors() {
    return this.allEmployees.filter(employee => employee.roles && employee.roles.JANITOR).length
  }

  @computed get allJanitors() {
    return this.allEmployees.filter(employee => employee.roles && employee.roles.JANITOR)
  }

  @action.bound
  public pushToAllEmployees(employee) {
    this.allEmployees.push(employee)
  }

  @action.bound
  public getEmployeeWithID(id) {
    return this.allEmployees.find(employee => employee.userID === id)
  }
}
