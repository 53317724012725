import * as React from 'react'

import { withTranslation } from 'react-i18next'

/* Import components here */
import { StyledAssignmentTable } from './AssignmentTable.styles'
import { Button, DatePicker, Icon, Input, Radio, Table } from 'antd'

/* Import interfaces here */
import { IAssignmentTableProps, IAssignmentTableState } from './AssignmentTable.interfaces'

/* Import utilities here */
import { search, sortByName, sortByNameContactPerson } from '../../utils/tables/TableActions'
import moment from 'moment'
import Highlighter from 'react-highlight-words'
import { AssignmentInfoList } from '../../utils/tables/AssignmentInfoList'
import { compose } from 'recompose'
import { withFirebase } from '../../firebase'

const ButtonGroup = Button.Group
const RangePicker = DatePicker.RangePicker

const defaultSortDirectionOrder: SortOrder = 'descend'
const sortDirectionOrder: SortOrder[] = ['ascend', 'descend']
export declare type SortOrder = 'descend' | 'ascend'
let CurrentOrder = 'descend'

const TableView = ({
  columns,
  assignmentList,
  rowSelection,
  loading,
  firebase,
  updateTableAfterChanges
}) => (
  <Table
    bordered={true}
    loading={loading}
    // rowSelection={rowSelection}
    columns={columns}
    dataSource={assignmentList}
    onChange={onChange}
    expandedRowRender={(record: any) => (
      <AssignmentInfoList
        obj={record}
        firebase={firebase}
        updateTableAfterChanges={updateTableAfterChanges}
      />
    )}
    pagination={{ pageSize: 50 }}
    size="middle"
    rowKey="assignmentID"
  />
)

function onChange(pagination, filters, sorter) {
  // tslint:disable-next-line: no-console
  console.log('params', pagination, filters, sorter)
  if (sorter.order) {
    CurrentOrder = sorter.order
  }
}

export class AssignmentTable extends React.Component<IAssignmentTableProps, IAssignmentTableState> {
  unsubscribe: any
  constructor(props) {
    super(props)
    this.state = {
      assignments: [],
      loading: false,
      searchText: '',
      selectedRowKeys: [], // Check here to configure the default column
      startDate: moment().set('month', moment().get('month') - 3),
      endDate: moment(),
      radio: 'Active'
    }
  }

  componentDidMount() {
    this.handleGetClick()
  }

  public onDateChange = (dates, dateStrings) => {
    // console.log('From: ', dates[0], ', to: ', dates[1])
    // console.log('From: ', dateStrings[0], ', to: ', dateStrings[1])
    this.setState({ startDate: dates[0], endDate: dates[1] })
  }

  public getColumnSearchProps = (obj, dataIndex, type?) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            ;(this as any).searchInput = node
          }}
          placeholder={`Sök ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Sök
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Rensa
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) => search(value, record, obj, dataIndex, type),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => (this as any).searchInput.select())
      }
    },
    render: text => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape={true}
        textToHighlight={text.toString()}
      />
    )
  })

  public handleSearch = (selectedKeys, confirm) => {
    confirm()
    this.setState({ searchText: selectedKeys[0] })
  }

  public handleReset = clearFilters => {
    clearFilters()
    this.setState({ searchText: '' })
  }

  public handleGetClick = () => {
    const { startDate, endDate, radio } = this.state
    this.setState({ loading: true })
    this.props.firebase
      .getAssignmentList(startDate, endDate, radio)
      .get()
      .then(querySnapshot => {
        const assignments: any = []

        querySnapshot.forEach(doc => {
          // console.log(doc.id, ' => ', doc.data())
          assignments.push(doc.data())
        })
        this.setState({ loading: false, assignments })
      })
      .catch(error => {
        console.log('Error getting documents: ', error)
      })
  }

  public handleRadio = e => {
    this.setState({ radio: e.target.value })
  }

  public updateTableAfterChanges = changedAssignment => {
    const { assignments } = this.state
    const newAssignments = assignments
    const index = assignments.findIndex(
      assignment => assignment.assignmentID === changedAssignment.assignmentID
    )
    newAssignments.splice(index, 1, changedAssignment)
    this.setState({ assignments: newAssignments })
  }

  public onSelectChange = selectedRowKeys => {
    this.setState({ selectedRowKeys })
  }
  public render() {
    const { assignments, selectedRowKeys, loading, startDate, endDate, radio } = this.state

    const columns = [
      {
        title: 'Adress',
        key: 'addressHeader',
        children: [
          {
            title: 'Stad',
            dataIndex: 'address.city',
            key: 'city',
            sorter: (a, b) => sortByName(a, b, 'address', 'city'),
            sortDirections: sortDirectionOrder,
            ...this.getColumnSearchProps('address', 'city')
          },
          {
            title: 'Zip',
            dataIndex: 'address.zipCode',
            key: 'zip',
            sorter: (a, b) => sortByName(a, b, 'address', 'zipCode'),
            sortDirections: sortDirectionOrder,
            ...this.getColumnSearchProps('address', 'zipCode')
          },
          {
            title: 'Fastighets Namn',
            dataIndex: 'address.realEstateName',
            key: 'realEstateName',
            sorter: (a, b) => sortByName(a, b, 'address', 'realEstateName'),
            sortDirections: sortDirectionOrder,
            ...this.getColumnSearchProps('address', 'realEstateName')
          },
          {
            title: 'Gata',
            children: [
              {
                title: 'Gata',
                dataIndex: 'address.street',
                key: 'street',
                sorter: (a, b) => sortByName(a, b, 'address', 'street'),
                sortDirections: sortDirectionOrder,
                ...this.getColumnSearchProps('address', 'street')
              },
              {
                title: 'Lgh Nr.',
                dataIndex: 'address.rentalObjectNumber',
                key: 'number',
                sorter: (a, b) => sortByName(a, b, 'address', 'rentalObjectNumber'),
                sortDirections: sortDirectionOrder,
                ...this.getColumnSearchProps('address', 'rentalObjectNumber')
              }
            ]
          }
        ]
      },
      {
        title: 'Datum',
        key: 'dateHeader',
        children: [
          {
            title: 'Ärande skapat',
            dataIndex: 'dateCreated',
            key: 'dateCreated',
            sorter: (a, b) => sortByName(a, b, null, 'dateCreated', 'DATE'),
            sortDirections: sortDirectionOrder,
            defaultSortOrder: defaultSortDirectionOrder,
            ...this.getColumnSearchProps(null, 'dateCreated', 'DATE'),
            render: dateCreated => {
              return (
                <Highlighter
                  highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                  searchWords={[this.state.searchText]}
                  autoEscape={true}
                  textToHighlight={dateCreated.toDate().toLocaleString()}
                />
              )
            }
          },
          {
            title: 'Ärande Klart',
            dataIndex: 'dateFinished',
            key: 'dateFinished',
            sorter: (a, b) => sortByName(a, b, null, 'dateFinished', 'DATE'),
            sortDirections: sortDirectionOrder,
            ...this.getColumnSearchProps(null, 'dateFinished', 'DATE'),
            render: dateFinished => {
              if (dateFinished != null) {
                return (
                  <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape={true}
                    textToHighlight={dateFinished.toDate().toLocaleString()}
                  />
                )
              } else {
                return <div>Pågående</div>
              }
            }
          }
        ]
      },
      {
        title: 'Kontaktperson',
        key: 'contactPersonHeader',
        children: [
          {
            title: 'Namn',
            children: [
              {
                title: 'Förnamn',
                dataIndex: 'contactPersons',
                key: 'firstName',
                sorter: (a, b) => sortByNameContactPerson(a, b, 'firstName', CurrentOrder),
                sortDirections: sortDirectionOrder,
                ...this.getColumnSearchProps('contactPersons', 'firstName'),
                render: contactPersons => {
                  if (contactPersons.length > 1) {
                    return (
                      <span>
                        {contactPersons.map((person, index) => {
                          return (
                            <div key={person.tenantID + index}>
                              <Highlighter
                                highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                                searchWords={[this.state.searchText]}
                                autoEscape={true}
                                textToHighlight={person.firstName}
                              />
                            </div>
                          )
                        })}
                      </span>
                    )
                  } else if (contactPersons.length === 1) {
                    return (
                      <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[this.state.searchText]}
                        autoEscape={true}
                        textToHighlight={contactPersons[0].firstName}
                      />
                    )
                  } else {
                    return
                  }
                }
              },
              {
                title: 'Efternamn',
                dataIndex: 'contactPersons',
                key: 'lastName',
                sorter: (a, b) => sortByNameContactPerson(a, b, 'lastName', CurrentOrder),
                sortDirections: sortDirectionOrder,
                ...this.getColumnSearchProps('contactPersons', 'lastName'),
                render: contactPersons => {
                  if (contactPersons.length > 1) {
                    if (
                      contactPersons.every(person => person.lastName === contactPersons[0].lastName)
                    ) {
                      return (
                        <Highlighter
                          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                          searchWords={[this.state.searchText]}
                          autoEscape={true}
                          textToHighlight={contactPersons[0].lastName}
                        />
                      )
                    }
                    return (
                      <span>
                        {contactPersons.map((person, index) => {
                          return (
                            <div key={person.tenantID + index}>
                              <Highlighter
                                highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                                searchWords={[this.state.searchText]}
                                autoEscape={true}
                                textToHighlight={person.lastName}
                              />
                            </div>
                          )
                        })}
                      </span>
                    )
                  } else if (contactPersons.length === 1) {
                    return (
                      <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[this.state.searchText]}
                        autoEscape={true}
                        textToHighlight={contactPersons[0].lastName}
                      />
                    )
                  } else {
                    return
                  }
                }
              }
            ]
          },
          {
            title: 'Kontakt',
            children: [
              /* {
                title: 'Email',
                dataIndex: 'contactPersons',
                key: 'email',
                ...this.getColumnSearchProps('contactPersons', 'email'),
                render: contactPersons => {
                  if (contactPersons.length > 1) {
                    return (
                      <span>
                        {contactPersons.map(person => {
                          if (person.email != null) {
                            return (
                              <div key={person.tenantID}>
                                <Highlighter
                                  highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                                  searchWords={[this.state.searchText]}
                                  autoEscape={true}
                                  textToHighlight={person.email}
                                />
                              </div>
                            )
                          } else {
                            return <div key={person.tenantID}>Saknas</div>
                          }
                        })}
                      </span>
                    )
                  } else if (contactPersons.length === 1) {
                    if (contactPersons[0].email != null) {
                      return (
                        <Highlighter
                          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                          searchWords={[this.state.searchText]}
                          autoEscape={true}
                          textToHighlight={contactPersons[0].email}
                        />
                      )
                    } else {
                      return <div>Saknas</div>
                    }
                  } else {
                    return
                  }
                },
              }, */
              {
                title: 'Telefonnummer',
                dataIndex: 'contactPersons',
                key: 'phoneNumbers',
                ...this.getColumnSearchProps('contactPersons', 'phoneNumbers'),
                render: contactPersons => {
                  if (contactPersons.length > 1) {
                    return (
                      <span>
                        {contactPersons.map((person, index) => {
                          if (person.phoneNumbers != null) {
                            return (
                              <div key={person.tenantID + index}>
                                <Highlighter
                                  highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                                  searchWords={[this.state.searchText]}
                                  autoEscape={true}
                                  textToHighlight={person.phoneNumbers.join(', ')}
                                />
                              </div>
                            )
                          } else {
                            return <div key={person.tenantID}>Saknas</div>
                          }
                        })}
                      </span>
                    )
                  } else if (contactPersons.length === 1) {
                    if (contactPersons[0].phoneNumbers != null) {
                      return (
                        <Highlighter
                          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                          searchWords={[this.state.searchText]}
                          autoEscape={true}
                          textToHighlight={contactPersons[0].phoneNumbers.join(', ')}
                        />
                      )
                    } else {
                      return <div>Saknas</div>
                    }
                  } else {
                    return
                  }
                }
              }
            ]
          }
        ]
      }
    ]

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      hideDefaultSelections: true,
      selections: [
        {
          key: 'unBilled',
          text: 'Välj Ofakturerade',
          onSelect: changableRowKeys => {
            let newSelectedRowKeys = []
            /* let newData = data.filter(assignment => assignment.billed === true)
            newData.filter((key, index) => {
            }) */
            // Row ID = Assignment ID
            // Kolla igenom state med assignments på ID om de är billed
            // samla och sedan filtrera jämfört med changableRow och sedan gör en ny och sedan sätt state
            newSelectedRowKeys = changableRowKeys.filter((key, index) => {
              if (index % 2 !== 0) {
                return false
              }
              return true
            })
            this.setState({ selectedRowKeys: newSelectedRowKeys })
          }
        },
        {
          key: 'billed',
          text: 'Välj Fakturerade',
          onSelect: changableRowKeys => {
            let newSelectedRowKeys = []
            newSelectedRowKeys = changableRowKeys.filter((key, index) => {
              /* console.log(key)
              console.log(changableRowKeys[key])
              console.log(changableRowKeys) */
              if (key.value === true) {
                return true
              }
              return false
            })
            this.setState({ selectedRowKeys: newSelectedRowKeys })
          }
        }
      ]
    }
    // const hasSelected = selectedRowKeys.length > 0

    return (
      <StyledAssignmentTable>
        <ButtonGroup style={{ display: 'flex' }}>
          <Radio.Group defaultValue={radio} size="large">
            <Radio.Button onClick={this.handleRadio} value="All">
              Alla
            </Radio.Button>
            <Radio.Button onClick={this.handleRadio} value="Active">
              Pågående
            </Radio.Button>
            <Radio.Button onClick={this.handleRadio} value="Unassigned">
              Otilldelade
            </Radio.Button>
          </Radio.Group>
          <RangePicker
            size="large"
            defaultValue={[startDate, endDate]}
            ranges={{
              Idag: [moment(), moment()],
              'Denna Vecka': [moment().startOf('week'), moment().endOf('week')],
              'Denna Månad': [moment().startOf('month'), moment().endOf('month')],
              'Senaste 3 Månaderna': [moment().set('month', moment().get('month') - 3), moment()],
              'Detta året': [moment().startOf('year'), moment().endOf('year')],
              'Senaste 3 år': [moment().set('year', moment().get('year') - 3), moment()],
              Alla: [moment().set('year', moment().get('year') - 10), moment()]
            }}
            onChange={this.onDateChange}
          />
          <Button
            size="large"
            onClick={this.handleGetClick}
            style={{ float: 'left', position: 'relative', backgroundColor: '#fafafa' }}
          >
            <Icon style={{ color: 'gray' }} type="sync" />
            Hämta Felanmälningar
          </Button>
          <Button size="large" disabled={true} style={{ overflow: 'hidden', flex: '1 0 0' }} />
        </ButtonGroup>
        <TableView
          columns={columns}
          assignmentList={assignments}
          rowSelection={rowSelection}
          loading={loading}
          firebase={this.props.firebase}
          updateTableAfterChanges={this.updateTableAfterChanges}
        />
      </StyledAssignmentTable>
    )
  }
}

export default compose(withTranslation(), withFirebase)(AssignmentTable)
