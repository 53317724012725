import { styled } from 'theme'
import { Layout } from 'antd'
const { Content } = Layout
export const StyledImportUpload = styled.div`
  /* padding: 50px 0px 48px;
  margin: 0 auto; */
`

export const UploadWrapper = styled.div`
  width: 500px;
  margin: 0 auto;
`
export const ProgressWrapper = styled.div`
  width: 80%;
  margin: 0 auto;
  padding-top: 24px;
`
export const BoldSpan = styled.span`
  font-weight: bold;
`

export const StyledContent = styled(Content)`
  background-position: bottom;
  background-repeat: repeat-x;
  overflow-x: inherit;
  background-color: #fafafa;
  margin: 0 auto;
  padding: 50px 0px 90px;
`
