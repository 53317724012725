import React, { useState } from 'react'
import { AuthUserContext } from '../../contexts'
import { Badge, Button, Col, Descriptions, Dropdown, Icon, Row, Select, Tag } from 'antd'
import { Link } from 'react-router-dom'
import { rootStore } from 'stores'
import { styled } from 'theme'
import * as ROUTES from '../../constants/routes'

const Wrapper = styled.div`
  .ant-descriptions .ant-descriptions-view {
    border: 0px solid;
  }
`

const BoldSpan = styled.span`
  font-weight: bold;
`

const ButtonGroup = Button.Group
const { Option } = Select

export const AssignmentInfoList = props => {
  const { obj, firebase, updateTableAfterChanges } = props
  const priority = obj.priority
  if (obj.billable) {
    return (
      <AuthUserContext.Consumer>
        {(authUser: any) => (
          <Wrapper>
            <Descriptions bordered={true} size="small" column={{ xl: 4, md: 2, sm: 1, xs: 1 }}>
              <Descriptions.Item label={<BoldSpan>Områden</BoldSpan>} span={2}>
                {obj.areas.map((area, index) => {
                  return <Tag key={index}>{area}</Tag>
                })}
              </Descriptions.Item>
              <Descriptions.Item label={<BoldSpan>Huvudnyckel</BoldSpan>}>
                <span>
                  <Badge
                    status={obj.masterKeyAllowed === true ? 'success' : 'error'}
                    text={obj.masterKeyAllowed === true ? 'Får användas' : 'Får EJ användas'}
                  />
                  <Button
                    onClick={() =>
                      firebase.updateAssignment(obj.assignmentID, {
                        masterKeyAllowed: obj.masterKeyAllowed === true ? false : true
                      })(doc => updateTableAfterChanges(doc))
                    }
                    type={obj.masterKeyAllowed === true ? 'danger' : 'primary'}
                    size="small"
                    style={{ float: 'right', marginRight: '20px' }}
                  >
                    {obj.masterKeyAllowed === true ? 'Tillåt EJ' : 'Tillåt'}
                  </Button>
                </span>
              </Descriptions.Item>
              <Descriptions.Item label={<BoldSpan>Prioritet</BoldSpan>}>
                {priority && priority}
              </Descriptions.Item>

              <Descriptions.Item label={<BoldSpan>Beskrivning</BoldSpan>} span={4}>
                {obj.description}
              </Descriptions.Item>

              <Descriptions.Item label={<BoldSpan>Fakturerings Text</BoldSpan>} span={4}>
                {obj.invoiceDescription}
              </Descriptions.Item>
              <Descriptions.Item label={<BoldSpan>Material</BoldSpan>} span={2}>
                {obj.materialUsed}
              </Descriptions.Item>
              <Descriptions.Item label={<BoldSpan>Tid</BoldSpan>} span={2}>
                {obj.hoursToInvoice}
              </Descriptions.Item>

              <Descriptions.Item label={<BoldSpan>Ärende Info</BoldSpan>} span={4}>
                <Row style={{ width: '1000px' }}>
                  {obj.dateCreated != null && (
                    <>
                      <Col span={4}>
                        <BoldSpan>Inlagd av:</BoldSpan>
                      </Col>
                      <Col span={20}>
                        {obj.creatorName}, {obj.creatorEmail}{' '}
                        {obj.dateCreated.toDate().toLocaleString()}
                      </Col>
                    </>
                  )}

                  {obj.lastEditedDate != null && (
                    <>
                      <Col span={4}>
                        <BoldSpan>Senast uppdaterad av:</BoldSpan>
                      </Col>
                      <Col span={20}>
                        {obj.lastEditedAuthor}, {obj.lastEditedEmail}{' '}
                        {obj.lastEditedDate.toDate().toLocaleString()}
                      </Col>
                    </>
                  )}

                  {obj.assignedDate != null && (
                    <>
                      <Col span={4}>
                        <BoldSpan>Tilldelad:</BoldSpan>
                      </Col>
                      <Col span={20}>
                        {obj.assignedUserName}, {obj.assignedUserEmail}{' '}
                        {obj.assignedDate.toDate().toLocaleString()}
                      </Col>
                    </>
                  )}

                  {obj.dateFinished != null && (
                    <>
                      <Col span={4}>
                        <BoldSpan>Avslutad av:</BoldSpan>
                      </Col>
                      <Col span={20}>
                        {obj.finishedByName}, {obj.finishedByEmail}{' '}
                        {obj.dateFinished.toDate().toLocaleString()}
                      </Col>
                    </>
                  )}
                </Row>
              </Descriptions.Item>
            </Descriptions>
            <ButtonGroup style={{ marginTop: '12px' }}>
              {!obj.dateFinished && (
                <Button
                  // tslint:disable-next-line: jsx-no-lambda
                  onClick={() =>
                    firebase.updateAssignment(obj.assignmentID, {
                      dateFinished: firebase.timestamp.fromDate(new Date(Date.now())),
                      finishedByEmail: authUser.email,
                      finishedByID: authUser.userID,
                      finishedByName: authUser.firstName + ' ' + authUser.lastName
                    })(doc => updateTableAfterChanges(doc))
                  }
                >
                  Markera som färdig
                </Button>
              )}
              {!obj.dateFinished && (
                <AssignUserButton
                  firebase={firebase}
                  assignmentID={obj.assignmentID}
                  updateTableAfterChanges={updateTableAfterChanges}
                />
              )}
              <Link
                to={`${ROUTES.REAL_ESTATES}/${obj.realEstateID}/${obj.rentalObjectID}/${obj.assignmentID}`}
              >
                <Button type="primary">
                  <span>Gå till Ärende</span>
                </Button>
              </Link>
            </ButtonGroup>
          </Wrapper>
        )}
      </AuthUserContext.Consumer>
    )
  } else {
    return (
      <AuthUserContext.Consumer>
        {(authUser: any) => (
          <Wrapper>
            <Descriptions bordered={true} size="small" column={{ xl: 4, md: 2, sm: 1, xs: 1 }}>
              <Descriptions.Item label={<BoldSpan>Områden</BoldSpan>} span={2}>
                {obj.areas.map((area, index) => {
                  return <Tag key={index}>{area}</Tag>
                })}
              </Descriptions.Item>
              <Descriptions.Item label={<BoldSpan>Huvudnyckel</BoldSpan>}>
                <span>
                  <Badge
                    status={obj.masterKeyAllowed === true ? 'success' : 'error'}
                    text={obj.masterKeyAllowed === true ? 'Får användas' : 'Får EJ användas'}
                  />
                  <Button
                    onClick={() =>
                      firebase.updateAssignment(obj.assignmentID, {
                        masterKeyAllowed: obj.masterKeyAllowed === true ? false : true
                      })(doc => updateTableAfterChanges(doc))
                    }
                    type={obj.masterKeyAllowed === true ? 'danger' : 'primary'}
                    size="small"
                    style={{ float: 'right', marginRight: '20px' }}
                  >
                    {obj.masterKeyAllowed === true ? 'Tillåt EJ' : 'Tillåt'}
                  </Button>
                </span>
              </Descriptions.Item>
              <Descriptions.Item label={<BoldSpan>Prioritet</BoldSpan>}>
                {priority && priority}
              </Descriptions.Item>

              <Descriptions.Item label={<BoldSpan>Beskrivning</BoldSpan>} span={4}>
                {obj.description}
              </Descriptions.Item>
              <Descriptions.Item label={<BoldSpan>Ärende Info</BoldSpan>} span={4}>
                <Row style={{ width: '1000px' }}>
                  {obj.dateCreated != null && (
                    <>
                      <Col span={4}>
                        <BoldSpan>Inlagd av:</BoldSpan>
                      </Col>
                      <Col span={20}>
                        {obj.creatorName}, {obj.creatorEmail}{' '}
                        {obj.dateCreated.toDate().toLocaleString()}
                      </Col>
                    </>
                  )}

                  {obj.lastEditedDate != null && (
                    <>
                      <Col span={4}>
                        <BoldSpan>Senast uppdaterad av:</BoldSpan>
                      </Col>
                      <Col span={20}>
                        {obj.lastEditedAuthor}, {obj.lastEditedEmail}{' '}
                        {obj.lastEditedDate.toDate().toLocaleString()}
                      </Col>
                    </>
                  )}

                  {obj.assignedDate != null && (
                    <>
                      <Col span={4}>
                        <BoldSpan>Tilldelad:</BoldSpan>
                      </Col>
                      <Col span={20}>
                        {obj.assignedUserName}, {obj.assignedUserEmail}{' '}
                        {obj.assignedDate.toDate().toLocaleString()}
                      </Col>
                    </>
                  )}

                  {obj.dateFinished != null && (
                    <>
                      <Col span={4}>
                        <BoldSpan>Avslutad av:</BoldSpan>
                      </Col>
                      <Col span={20}>
                        {obj.finishedByName}, {obj.finishedByEmail}{' '}
                        {obj.dateFinished.toDate().toLocaleString()}
                      </Col>
                    </>
                  )}
                </Row>
              </Descriptions.Item>
            </Descriptions>
            <ButtonGroup style={{ marginTop: '12px' }}>
              {!obj.dateFinished && (
                <Button
                  // tslint:disable-next-line: jsx-no-lambda
                  onClick={() =>
                    firebase.updateAssignment(obj.assignmentID, {
                      dateFinished: firebase.timestamp.fromDate(new Date(Date.now())),
                      finishedByEmail: authUser.email,
                      finishedByID: authUser.userID,
                      finishedByName: authUser.firstName + ' ' + authUser.lastName
                    })(doc => updateTableAfterChanges(doc))
                  }
                >
                  Markera som färdig
                </Button>
              )}
              {!obj.dateFinished && (
                <AssignUserButton
                  firebase={firebase}
                  assignmentID={obj.assignmentID}
                  updateTableAfterChanges={updateTableAfterChanges}
                />
              )}
              <Link
                to={`${ROUTES.REAL_ESTATES}/${obj.realEstateID}/${obj.rentalObjectID}/${obj.assignmentID}`}
              >
                <Button type="primary">
                  <span>Gå till Ärende</span>
                </Button>
              </Link>
            </ButtonGroup>
          </Wrapper>
        )}
      </AuthUserContext.Consumer>
    )
  }
}

export const AssignUserButton = props => {
  const { assignmentID, firebase, updateTableAfterChanges, ...restProps } = props
  const [value, setValue] = useState(null)
  const [visible, setVisible] = useState(false)

  // tslint:disable-next-line: no-shadowed-variable
  function onChange(value) {
    setValue(value)
  }

  function handleConfirm() {
    const assignedUser = rootStore.employeeStore.getEmployeeWithID(value)
    const fields = {
      assignedDate: firebase.timestamp.fromDate(new Date(Date.now())),
      assignedUserEmail: assignedUser ? assignedUser.email : '',
      assignedUserID: assignedUser ? assignedUser.userID : '',
      assignedUserName: assignedUser ? assignedUser.firstName + ' ' + assignedUser.lastName : ''
    }
    firebase.updateAssignment(assignmentID, fields)(doc => updateTableAfterChanges(doc))
    setValue(null)
    setVisible(false)
  }

  function handleVisibleChange(flag) {
    setVisible(flag)
  }

  return (
    <Dropdown
      overlay={
        <div style={{ backgroundColor: 'white', padding: '5px', borderRadius: '5px' }}>
          <Select
            showSearch={true}
            style={{ width: 300 }}
            placeholder="Välj uppdragstagare"
            optionFilterProp="children"
            onChange={onChange}
            // tslint:disable-next-line: jsx-no-lambda
            // filterOption={(input: any, option: any) =>
            //   option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            // }
          >
            {rootStore.employeeStore.allJanitors.map(employee => {
              return (
                <Option key={employee.userID} value={employee.userID}>
                  {employee.firstName} {employee.lastName} {employee.email}
                </Option>
              )
            })}
          </Select>
          <Button disabled={!value ? true : false} onClick={handleConfirm}>
            Tilldela
          </Button>
        </div>
      }
      trigger={['click']}
      onVisibleChange={handleVisibleChange}
      visible={visible}
    >
      <Button {...restProps}>
        Tilldela
        <Icon type="down" />
      </Button>
    </Dropdown>
  )
}
