import * as React from 'react'

import { withTranslation } from 'react-i18next'

/* Import components here */
import {
  StyledRentalObjectAssignmentHistoryItem,
  CenterContainer,
  CenteredDiv
} from './RentalObjectAssignmentHistoryItem.styles'
import {
  RentalObjectAssignmentHistoryItemContent,
  RentalObjectAssignmentHistoryItemEdit
} from 'components'
/* Import interfaces here */
import {
  IRentalObjectAssignmentHistoryItemProps,
  IRentalObjectAssignmentHistoryItemState
} from './RentalObjectAssignmentHistoryItem.interfaces'

/* Import utilities here */
import { withFirebase } from '../../../firebase'
import { compose } from 'recompose'
import { Spin, PageHeader, Icon, Layout, Menu, Button, Result } from 'antd'
const { Content, Sider } = Layout
export class RentalObjectAssignmentHistoryItem extends React.Component<
  IRentalObjectAssignmentHistoryItemProps,
  IRentalObjectAssignmentHistoryItemState
> {
  unsubscribe: any
  unsubscribeComments: any
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      commentLoading: true,
      assignment: null,
      comments: [],
      content: '1',
      ...props.location.state
    }
  }

  componentDidMount() {
    this.listenToComments()
    if (this.state.assignment) {
      this.listenToAssignmentChanges()
      return
    }
    this.setState({ loading: true })
    this.listenToAssignmentChanges()
  }
  componentWillUnmount() {
    this.unsubscribe && this.unsubscribe()
    this.unsubscribeComments && this.unsubscribeComments()
  }

  componentDidUpdate(prevProps) {
    // Om man är på en annan användares sida och sen går därifrån till sin egna
    if (prevProps.match.params.assignmentID !== this.props.match.params.assignmentID) {
      this.unsubscribe()
      this.unsubscribeComments()
      this.setState({ loading: true, assignment: null })
      this.listenToAssignmentChanges()
      this.listenToComments()
    }
  }

  listenToComments = () => {
    this.unsubscribeComments = this.props.firebase
      .postsByAssignmentID(this.props.match.params.assignmentID)
      .onSnapshot(snapshot => {
        const comments: any = []
        snapshot.forEach(doc => {
          comments.push({ ...doc.data(), uid: doc.id })
        })
        this.setState({
          comments,
          commentLoading: false
        })
      })
  }

  listenToAssignmentChanges = () => {
    const assignmentID = this.props.match.params.assignmentID
    const uid = assignmentID.length < 5 ? `"${assignmentID}"` : assignmentID
    this.unsubscribe = this.props.firebase.assignment(uid).onSnapshot(snapshot => {
      this.setState({
        assignment: { ...snapshot.data(), uid: snapshot.id },
        loading: false
      })
    })
  }

  handleClick = e => {
    this.setState({ content: e.key })
  }

  redirectToContent = () => {
    this.setState({ content: '1' })
  }

  public render() {
    const { t, children, ...props } = this.props
    const { assignment, comments, loading, content, commentLoading } = this.state

    return (
      <StyledRentalObjectAssignmentHistoryItem {...props}>
        {loading && (
          <CenterContainer style={{ minHeight: '400px' }}>
            <CenteredDiv>
              <Spin size="large" />
            </CenteredDiv>
          </CenterContainer>
        )}
        {!loading && !assignment && (
          <Result
            status="404"
            title="404"
            subTitle="Ledsen, ärendet hittades inte eller finns inte."
            extra={
              <Button onClick={() => window.history.back()} type="primary">
                Tillbaka
              </Button>
            }
          />
        )}
        {assignment && (
          <Layout>
            <PageHeader
              style={{ backgroundColor: 'white' }}
              backIcon={
                <Button type="primary">
                  <Icon type="left" />
                  Gå Tillbaka
                </Button>
              }
              onBack={() => window.history.back()}
              title={
                assignment.address.realEstateName +
                ' (' +
                assignment.address.rentalObjectNumber +
                ') '
              }
              subTitle={
                assignment.address.street +
                ' ' +
                assignment.address.zipCode +
                ' ' +
                assignment.address.city
              }
            />
            <Layout>
              <Sider width={200} style={{ background: '#fff' }}>
                <Menu
                  onClick={this.handleClick}
                  mode="inline"
                  defaultSelectedKeys={['1']}
                  selectedKeys={[this.state.content]}
                  style={{ height: '100%' }}
                >
                  <Menu.Item key="1">
                    <Icon type="file-search" />
                    <span>Översikt</span>
                  </Menu.Item>
                  <Menu.Item key="2" disabled={assignment.dateFinished}>
                    <Icon type="edit" />
                    <span>Redigera</span>
                  </Menu.Item>
                </Menu>
              </Sider>
              <Content style={{ padding: '0 24px', minHeight: 280, backgroundColor: 'white' }}>
                {content === '1' && (
                  <RentalObjectAssignmentHistoryItemContent
                    assignment={assignment}
                    comments={comments}
                    commentLoading={commentLoading}
                  />
                )}
                {content === '2' && (
                  <RentalObjectAssignmentHistoryItemEdit
                    assignment={assignment}
                    redirectToContent={this.redirectToContent}
                  />
                )}
              </Content>
            </Layout>
          </Layout>
        )}
      </StyledRentalObjectAssignmentHistoryItem>
    )
  }
}

export default compose(withTranslation(), withFirebase)(RentalObjectAssignmentHistoryItem)
