import { action, observable } from 'mobx'

interface ISettings {
  areas: any[]
  priorities: any[]
  statusCodes: any[]
}
export default class SettingStore {
  @observable public settings: ISettings = {
    areas: [],
    priorities: [],
    statusCodes: []
  }

  @action.bound
  public setSettings(obj) {
    this.settings = obj
  }
}
