import { createGlobalStyle } from 'styled-components'
import { theme } from './theme'

export const GlobalStyle = createGlobalStyle`
  /* Reset */
  * {
    margin: 0;
    padding: 0;
  }

  *, *::before, *::after {
    box-sizing: border-box;
  }

  html, body {
    /* font-family: Arial, sans-serif; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  body {
    /* font-size: 16px;
    color: white;
    background: ${theme.colors.primary}; */
  }

  a {
    /* color: inherit; */
    font-weight: 500;
    text-decoration: none;
    /* border-bottom: 1px solid currentColor; */
  }

  input:focus {
    outline: none;
  }

  /* Layout */

  .right {
      text-align: right;
    }

  .container {
    width: ${theme.sizes.container};

    margin: 0 auto;
    padding: 0 1rem;

    @media screen and (min-width: ${theme.sizes.mdContainer}) {
      width: ${theme.sizes.mdContainer};
    }

    @media screen and (min-width: ${theme.sizes.xlContainer}) {
      width: ${theme.sizes.xlContainer};
    }
  }

/* Overrides AntD styles*/
.appLayoutAvatarPopover .ant-popover-content .ant-popover-inner-content {
  padding: 0;
}
.ant-layout {
  transition: all 0.2s;
}

/* Applayout Menu Navlinks, style when active*/
.selectedNavLink span{
color:#1990FC;
}

/* Felanmälan drawer, Radio knapparna för om huvudnyckel får användas */
.masterNotKeyAllowed .ant-radio-button-wrapper:hover {
  position: relative;
  color: #cf1322;
  border-color: #cf1322;
}

.ant-radio-group-solid
  .masterNotKeyAllowed
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #fff;
  background: #cf1322;
  border-color: #cf1322;
}

.ant-radio-group-solid
  .masterNotKeyAllowed
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: #fff;
  background: rgb(230, 76, 89);
  border-color: #cf1322;
}

.masterKeyAllowed .ant-radio-button-wrapper:hover {
  position: relative;
  color: #5e955a;
  border-color: #5e955a;
}

.ant-radio-group-solid
  .masterKeyAllowed
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #fff;
  background: #5e955a;
  border-color: #5e955a;
}

.ant-radio-group-solid
  .masterKeyAllowed
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: #fff;
  background: rgb(104, 194, 132);
  border-color: #5e955a;
}
`
