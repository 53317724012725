import * as React from 'react'

/* Import components here */
//import { Page } from 'components'
import { Switch, Route, NavLink } from 'react-router-dom'
import { compose } from 'recompose'

import { withAuthorization, withEmailVerification } from '../../contexts'
import {
  RealEstateSettingsNav,
  RealEstateListItem,
  ImportSteps,
  DefaultSettingsNavigation
} from '../../components'
import * as ROLES from '../../constants/roles'
import * as ROUTES from '../../constants/routes'
import { Menu, Icon } from 'antd'
export const SettingsPage: React.FC = ({ children, ...props }) => {
  return (
    <div>
      <Menu
        // theme="dark"
        mode="horizontal"
        style={{ lineHeight: '64px' }}
      >
        <Menu.Item key="1">
          <NavLink to={ROUTES.SETTINGS} activeClassName="selectedNavLink">
            <Icon type="setting" />
            Översikt
          </NavLink>
        </Menu.Item>
        <Menu.Item key="2">
          <NavLink to={ROUTES.SETTINGS_IMPORT} activeClassName="selectedNavLink">
            <Icon type="import" />
            Excel Import
          </NavLink>
        </Menu.Item>
        <Menu.Item key="3">
          <NavLink to={ROUTES.SETTINGS_REAL_ESTATES} activeClassName="selectedNavLink">
            <Icon type="home" />
            Fastigheter
          </NavLink>
        </Menu.Item>
      </Menu>
      <Switch>
        <Route exact path={ROUTES.SETTINGS_IMPORT} component={ImportSteps} />
        <Route exact path={ROUTES.SETTINGS_REAL_ESTATES_DETAILS} component={RealEstateListItem} />
        <Route path={ROUTES.SETTINGS_REAL_ESTATES} component={RealEstateSettingsNav} />
        <Route path={ROUTES.SETTINGS} component={DefaultSettingsNavigation} />
      </Switch>
    </div>
  )
}

const condition = authUser => authUser && !!authUser.roles[ROLES.ADMIN]

export default compose(withEmailVerification, withAuthorization(condition))(SettingsPage)
