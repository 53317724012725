import { styled } from 'theme'
import { Card } from 'antd'

export const StyledRealEstateGrid = styled.div`
  padding: 24px;
`

export const StyledCard = styled(Card)`
  width: 100%;
  .ant-card-cover {
    height: 200px;
  }

  .ant-card-cover a {
    width: 100%;
    height: 100%;
  }

  .ant-card-cover img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .RealEstateGridDescription {
    text-overflow: ellipsis;
    /* Required for text-overflow to do anything */
    white-space: nowrap;
    overflow: hidden;
  }
  .ant-card-body {
    -webkit-box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.1);
  }
`

export const CenterContainer = styled.div`
  height: 500px;
  position: relative;
`

export const CenteredDiv = styled.div`
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
`
