import * as React from 'react'

import { withTranslation } from 'react-i18next'

/* Import components here */
import { StyledMessage } from './Message.styles'

/* Import interfaces here */
import { IMessageProps, IMessageState } from './Message.interfaces'

/* Import utilities here */
import { withFirebase, Timestamp } from '../../../firebase'
import { compose } from 'recompose'
import Logo from '../../../layouts/AppLayout/FDLogobook.png'

import { PageHeader, Row, Icon, Comment } from 'antd'
import * as ROUTES from '../../../constants/routes'

const Content = ({ children, extraContent, message }) => {
  return (
    <Row className="content" type="flex">
      <div className="main" style={{ flex: 1 }}>
        <Comment
          author={new Timestamp(message.createdAt.seconds, message.createdAt.nanoseconds)
            .toDate()
            .toLocaleDateString()}
          content={<p dangerouslySetInnerHTML={{ __html: children }} />}
        />
      </div>
      <div
        className="extra"
        style={{
          marginLeft: 80
        }}
      >
        {extraContent}
      </div>
    </Row>
  )
}

export class Message extends React.Component<IMessageProps, IMessageState> {
  unsubscribe: any
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      message: null,
      date: null,
      ...props.location.state
    }
  }

  componentDidMount() {
    if (this.state.message) {
      this.listenToArticleChanges()
      return
    }
    this.setState({ loading: true })
    this.listenToArticleChanges()
  }
  componentWillUnmount() {
    this.unsubscribe && this.unsubscribe()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.messageID !== this.props.match.params.messageID) {
      this.unsubscribe()
      this.setState({ loading: true })
      this.listenToArticleChanges()
    }
  }

  listenToArticleChanges = () => {
    const messageID = this.props.match.params.messageID
    this.unsubscribe = this.props.firebase.message(messageID).onSnapshot(snapshot => {
      this.setState({
        message: { ...snapshot.data(), uid: snapshot.id },
        loading: false,
        date: snapshot.data().createdAt
      })
    })
  }
  public render() {
    const { t, children, ...props } = this.props
    const { message } = this.state

    return (
      <StyledMessage {...props}>
        <PageHeader
          style={{ padding: 0 }}
          onBack={() =>
            this.props.history.push({
              pathname: `${ROUTES.HOME}`,
              state: { message }
            })
          }
          title=""
          subTitle="Gå Tillbaka"
        />
        {message && (
          <PageHeader
            title={message.title}
            subTitle={message.description}
            tags={
              <Icon
                style={{ display: message.favourite ? 'block' : 'none' }}
                type="star"
                theme="twoTone"
                twoToneColor="#DAA520"
              />
            }
            avatar={{
              src: Logo,
              size: 'large',
              shape: 'square'
            }}
          >
            <Content
              extraContent={
                <img
                  src="https://gw.alipayobjects.com/mdn/mpaas_user/afts/img/A*KsfVQbuLRlYAAAAAAAAAAABjAQAAAQ/original"
                  alt="content"
                />
              }
              message={message}
            >
              {message && message.text}
            </Content>
          </PageHeader>
        )}
      </StyledMessage>
    )
  }
}

export default compose(withTranslation(), withFirebase)(Message)
