export const search = (value, record, obj, dataIndex, type?) => {
  if (obj === 'contactPersons') {
    let bool
    record[obj].forEach(a => {
      if (a[dataIndex] != null && typeof a[dataIndex] !== 'undefined') {
        if (
          a[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        ) {
          bool = true
        }
      }
    })
    if (bool) {
      return record
    }
  } else if (obj === null && type === 'DATE') {
    if (record[dataIndex] != null && typeof record[dataIndex] !== 'undefined') {
      return record[dataIndex]
        .toDate()
        .toLocaleString()
        .toLowerCase()
        .includes(value.toLowerCase())
    }
  } else if (obj === null) {
    if (record[dataIndex] != null && typeof record[dataIndex] !== 'undefined') {
      return record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase())
    }
  } else {
    if (record[obj][dataIndex] != null && typeof record[obj][dataIndex] !== 'undefined') {
      return record[obj][dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase())
    }
  }
}

export const sortByName = (a, b, obj, field, type?) => {
  if (obj != null) {
    switch (type) {
      case 'DATE':
        if (a[obj][field].toDate().toLocaleString() < b[obj][field].toDate().toLocaleString()) {
          return -1
        }
        if (a[obj][field].toDate().toLocaleString() > b[obj][field].toDate().toLocaleString()) {
          return 1
        }
        return 0
      case 'INT':
        if (a[obj][field] < b[obj][field]) {
          return -1
        }
        if (a[obj][field] > b[obj][field]) {
          return 1
        }
        return 0
      default:
        if (a[obj][field].toUpperCase() < b[obj][field].toUpperCase()) {
          return -1
        }
        if (a[obj][field].toUpperCase() > b[obj][field].toUpperCase()) {
          return 1
        }
        return 0
    }
  } else {
    switch (type) {
      case 'DATE':
        if (a[field] === null) {
          return 1
        }
        if (b[field] === null) {
          return -1
        }
        if (a[field].toDate().toLocaleString() < b[field].toDate().toLocaleString()) {
          return -1
        }
        if (a[field].toDate().toLocaleString() > b[field].toDate().toLocaleString()) {
          return 1
        }
        return 0
      case 'INT':
        if (a[field] < b[field]) {
          return -1
        }
        if (a[field] > b[field]) {
          return 1
        }
        return 0
      default:
        if (a[field].toUpperCase() < b[field].toUpperCase()) {
          return -1
        }
        if (a[field].toUpperCase() > b[field].toUpperCase()) {
          return 1
        }
        return 0
    }
  }
}

export const sortByNameContactPerson = (a, b, field, order) => {
  if (order === 'ascend') {
    if (a.contactPersons.length > 1 || b.contactPersons.length > 1) {
      const newA = a.contactPersons.sort((a, b) => {
        if (a[field] < b[field]) {
          return -1
        }
        if (a[field] > b[field]) {
          return 1
        }
        return 0
      })
      const newB = b.contactPersons.sort((a, b) => {
        if (a[field].toUpperCase() < b[field].toUpperCase()) {
          return -1
        }
        if (a[field].toUpperCase() > b[field].toUpperCase()) {
          return 1
        }
        return 0
      })

      if (newA[0][field].toUpperCase() < newB[0][field].toUpperCase()) {
        return -1
      }
      if (newA[0][field].toUpperCase() > newB[0][field].toUpperCase()) {
        return 1
      }
      return 0
    } else {
      if (a.contactPersons[0][field].toUpperCase() < b.contactPersons[0][field].toUpperCase()) {
        return -1
      }
      if (a.contactPersons[0][field].toUpperCase() > b.contactPersons[0][field].toUpperCase()) {
        return 1
      }
      return 0
    }
  } else if (order === 'descend') {
    if (a.contactPersons.length > 1 || b.contactPersons.length > 1) {
      const newA = a.contactPersons.sort((a, b) => {
        if (a[field] < b[field]) {
          return 1
        }
        if (a[field] > b[field]) {
          return -1
        }
        return 0
      })
      const newB = b.contactPersons.sort((a, b) => {
        if (a[field].toUpperCase() < b[field].toUpperCase()) {
          return 1
        }
        if (a[field].toUpperCase() > b[field].toUpperCase()) {
          return -1
        }
        return 0
      })

      if (newA[0][field].toUpperCase() < newB[0][field].toUpperCase()) {
        return -1
      }
      if (newA[0][field].toUpperCase() > newB[0][field].toUpperCase()) {
        return 1
      }
      return 0
    } else {
      if (a.contactPersons[0][field].toUpperCase() < b.contactPersons[0][field].toUpperCase()) {
        return -1
      }
      if (a.contactPersons[0][field].toUpperCase() > b.contactPersons[0][field].toUpperCase()) {
        return 1
      }
      return 0
    }
  }
  return 0
}
