import * as React from 'react'

import { withTranslation } from 'react-i18next'

/* Import components here */
import { StyledAppLayoutBreadcrumb } from './AppLayoutBreadcrumb.styles'

/* Import interfaces here */
import { IAppLayoutBreadcrumbProps } from './AppLayoutBreadcrumb.interfaces'

/* Import utilities here */

export const AppLayoutBreadcrumb: React.FC<IAppLayoutBreadcrumbProps> = ({
  t,
  children,
  ...props
}) => {
  return (
    <StyledAppLayoutBreadcrumb {...props}>
      <h1>AppLayoutBreadcrumb</h1>
      <p>{props.title}</p>
      {children}
    </StyledAppLayoutBreadcrumb>
  )
}

export default withTranslation()(AppLayoutBreadcrumb)
