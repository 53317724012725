import * as React from 'react'
import { I18nextProvider } from 'react-i18next'
import { RouteProps } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'

import { GlobalStyle, theme } from 'theme'
import { AppRouter } from 'pages'
import i18nConfig from 'config/i18n'

export const App: React.FC<RouteProps> = () => {
  return (
    <ThemeProvider theme={theme}>
      <I18nextProvider i18n={i18nConfig}>
        <GlobalStyle />
        <AppRouter />
      </I18nextProvider>
    </ThemeProvider>
  )
}
