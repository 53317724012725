import { styled } from 'theme'

export const StyledRealEstateEditPicture = styled.div`
  background-color: white;
  padding: 1rem 0rem;
  max-width: 700px;
  margin: 0 auto;
`

export const StyledTitle = styled.div`
  padding: 1rem;
  font-size: 18px;
  font-weight: bold;
`
export const Frame = styled.div`
  margin: 0 auto;
  width: 555px;

  > .avatar-uploader > .ant-upload {
    width: 557px;
    margin: 0px;
  }

  > .avatar-uploader > .ant-upload > .ant-upload {
    padding: 4vmin;
    background-color: #ddc;
    border: solid 5vmin #eee;
    border-bottom-color: #fff;
    border-left-color: #eee;
    border-radius: 2px;
    border-right-color: #eee;
    border-top-color: #ddd;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25) inset, 0 5px 10px 5px rgba(0, 0, 0, 0.25);
    box-sizing: border-box;
    display: inline-block;
    position: relative;
    text-align: center;
    &:before {
      border-radius: 2px;
      bottom: -2vmin;
      box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.25) inset;
      content: '';
      left: -2vmin;
      position: absolute;
      right: -2vmin;
      top: -2vmin;
    }
    &:after {
      border-radius: 2px;
      bottom: -2.5vmin;
      box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.25);
      content: '';
      left: -2.5vmin;
      position: absolute;
      right: -2.5vmin;
      top: -2.5vmin;
      cursor: pointer;
    }
  }
`
