import { styled } from 'theme'

export const StyledRealEstateListItemOverview = styled.div`
  background-color: white;
`
export const Wrap = styled.div`
  display: flex;
  padding: 1rem;
`
export const Extra = styled.div`
  min-width: 300px;
  text-align: center;
  padding: 1rem;
`
export const Content = styled.div`
  flex: 1;
`

export const BoldSpan = styled.span`
  font-weight: bold;
`
