import * as React from 'react'

import { withTranslation } from 'react-i18next'

/* Import components here */
import {
  StyledRentalObjectAssignmentHistory,
  BoldSpan,
  EditorWrapper,
  StyledCollapse,
  PostImgGrid
} from './RentalObjectAssignmentHistory.styles'
import { Button, Icon, PageHeader } from 'antd'
/* Import interfaces here */
import {
  IRentalObjectAssignmentHistoryProps,
  IRentalObjectAssignmentHistoryState
} from './RentalObjectAssignmentHistory.interfaces'

/* Import utilities here */
import { compose } from 'recompose'
import { withFirebase } from '../../../firebase'
import { AuthUserContext } from '../../../contexts'

import { Avatar, Collapse, Comment, Form, Input, List, Tabs, Upload } from 'antd'

import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'
const ButtonGroup = Button.Group
const Panel = Collapse.Panel
const TextArea = Input.TextArea
const TabPane = Tabs.TabPane
const customPanelStyle = {
  border: 0
}

const Editor = ({ assignment, onChange, onSubmit, state, submitting, value, uploadProps }) => (
  <EditorWrapper>
    <Form.Item>
      <TextArea
        rows={4}
        onChange={e => onChange(e, assignment.assignmentID)}
        value={state[assignment.assignmentID]}
      />
    </Form.Item>
    <Form.Item>
      <ButtonGroup>
        <Button
          htmlType="submit"
          loading={submitting}
          type="primary"
          onClick={e => onSubmit()}
          disabled={!state[assignment.assignmentID] && state.fileArray.length < 1}
        >
          Kommentera
        </Button>
        <Upload {...uploadProps}>
          <Button type="primary">
            <Icon type="upload" /> Välj Fil
          </Button>
        </Upload>
      </ButtonGroup>
    </Form.Item>
  </EditorWrapper>
)

const IconText = ({ type, text }) => (
  <span>
    <Icon type={type} style={{ marginRight: 8 }} />
    {text}
  </span>
)

const CollapseView = ({
  assignment,
  postList,
  handleChange,
  handleSubmit,
  submitting,
  value,
  state,
  setState,
  uploadProps
}) => (
  <AuthUserContext.Consumer>
    {(authUser: any) => (
      <StyledCollapse bordered={false}>
        <Panel
          showArrow={false}
          header={
            <IconText
              type="message"
              text={`${
                postList.filter(post => post.assignmentID === assignment.assignmentID).length
              } kommentarer`}
            />
          }
          key="1"
          style={customPanelStyle}
        >
          <PostListView assignment={assignment} postList={postList} setState={setState} />
          <Comment
            avatar={<Avatar icon="user" />}
            content={
              <Editor
                state={state}
                assignment={assignment}
                onChange={handleChange}
                onSubmit={() => handleSubmit(assignment, authUser)}
                submitting={submitting}
                value={value}
                uploadProps={uploadProps}
              />
            }
          />
        </Panel>
      </StyledCollapse>
    )}
  </AuthUserContext.Consumer>
)
const PostListView = ({ assignment, postList, setState }) => (
  <ul>
    {postList
      .filter((post: any) => post.assignmentID === assignment.assignmentID)
      .sort((a, b) => a.dateCreated.toDate() - b.dateCreated.toDate())
      .map((post: any) => {
        return (
          <Comment
            key={post.postID}
            author={post.authorName + ' ' + post.authorEmail}
            avatar={
              post.authorProfilePicture != null ? (
                <Avatar src={post.authorProfilePicture} />
              ) : (
                <Avatar icon="user" />
              )
            }
            content={
              <div>
                <p>{post.comment}</p>
                <PostImgGrid className="postImgGrid">
                  {post.urls != null
                    ? post.urls.map((pic, index) => {
                        return (
                          <img
                            key={index}
                            alt="Not Found"
                            onClick={() => setState(pic)}
                            src={pic}
                          />
                        )
                      })
                    : null}
                </PostImgGrid>
              </div>
            }
            datetime={post.dateCreated.toDate().toLocaleString()}
          />
        )
      })}
  </ul>
)

export class RentalObjectAssignmentHistory extends React.Component<
  IRentalObjectAssignmentHistoryProps,
  IRentalObjectAssignmentHistoryState
> {
  unsubscribeRentalObject: any
  unsubscribeAssignments: any
  unsubscribeComments: any
  constructor(props) {
    super(props)

    this.state = {
      rentalObjectLoading: false,
      assignmentsLoading: false,
      rentalObject: null,
      assignmentHistory: [],
      postList: [],
      fileArray: [],
      isOpen: false,
      picture: '',
      submitting: false,
      value: ''
    }
  }

  componentDidMount() {
    // console.log(this.props)
    this.setState({ rentalObjectLoading: true, assignmentsLoading: true })

    this.unsubscribeRentalObject = this.props.firebase
      .rentalObject(this.props.match.params.rentalObjectID)
      .onSnapshot(snapshot => {
        this.setState({
          rentalObject: snapshot.data(),
          rentalObjectLoading: false
        })
      })

    this.unsubscribeAssignments = this.props.firebase
      .assignments(this.props.match.params.rentalObjectID)
      .onSnapshot(snapshot => {
        const assignments: any = []
        snapshot.forEach(doc => {
          assignments.push({ ...doc.data(), uid: doc.id })
        })
        this.setState({
          assignmentHistory: assignments,
          assignmentsLoading: false
        })
      })

    this.unsubscribeComments = this.props.firebase
      .posts(this.props.match.params.rentalObjectID)
      .onSnapshot(snapshot => {
        const posts: any = []
        snapshot.forEach(doc => {
          posts.push({ ...doc.data(), uid: doc.id })
        })
        this.setState({
          postList: posts
        })
      })
  }

  componentWillUnmount() {
    this.unsubscribeRentalObject && this.unsubscribeRentalObject()
    this.unsubscribeAssignments && this.unsubscribeAssignments()
  }

  public getExtension(filename) {
    const parts = filename.split('/')
    return parts[parts.length - 1]
  }

  public handleChange = (e: React.ChangeEvent<HTMLInputElement>, id) => {
    this.setState({
      [id]: e.target.value
    } as { [K in keyof IRentalObjectAssignmentHistoryState]: IRentalObjectAssignmentHistoryState[K] })
  }

  public setPictureState = url => {
    this.setState({
      isOpen: true,
      picture: url
    })
  }

  // When Tabs Change, probably nothing needed
  public callback = key => {
    // console.log(key)
  }

  public handleSubmit = (assignment: any, authUser: any) => {
    if (!this.state[assignment.assignmentID] && this.state.fileArray === []) {
      return
    }

    this.setState({
      submitting: true
    })

    setTimeout(() => {
      const postRef = this.props.firebase.postRef()
      postRef.set({
        realEstateID: assignment.realEstateID,
        assignmentID: assignment.assignmentID,
        authorEmail: authUser.email,
        authorID: authUser.userID,
        authorName: authUser.firstName + ' ' + authUser.lastName,
        //authorProfilePicture: authUser.profilePicture,
        comment: this.state[assignment.assignmentID] ? this.state[assignment.assignmentID] : '',
        dateCreated: this.props.firebase.timestamp.fromDate(new Date(Date.now())),
        postID: postRef.id,
        rentalObjectID: assignment.rentalObjectID,
        urls: []
      })
      if (this.state.fileArray.length > 0) {
        this.state.fileArray.forEach((fileInArray: any) => {
          const file = fileInArray
          const id = this.props.firebase.getUniqueID()
          const ext = this.getExtension(file.type)
          const storageRef = this.props.firebase.rentalObjectStorageRef(
            assignment.rentalObjectID + '/' + (id + '.' + ext)
          )

          const metadata = {
            contentType: file.type
          }

          // Upload file and metadata to the object 'images/mountains.jpg'
          const uploadTask = storageRef.put(file, metadata)
          // Listen for state changes, errors, and completion of the upload.
          uploadTask.on(
            this.props.firebase.app.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
            (snapshot: any) => {
              // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
              const progress = Number(
                ((snapshot.bytesTransferred / snapshot.totalBytes) * 100).toFixed(2)
              )
              console.log('Upload is ' + progress + '% done')
              // that.setState({ progress })
              switch (snapshot.state) {
                case this.props.firebase.app.storage.TaskState.PAUSED: // or 'paused'
                  console.log('Upload is paused')
                  break
                case this.props.firebase.app.storage.TaskState.RUNNING: // or 'running'
                  console.log('Upload is running')
                  break
              }
            },
            (error: any) => {
              // A full list of error codes is available at
              // https://firebase.google.com/docs/storage/web/handle-errors
              switch (error.code) {
                case 'storage/unauthorized':
                  // User doesn't have permission to access the object
                  break

                case 'storage/canceled':
                  // User canceled the upload
                  break
                case 'storage/unknown':
                  // Unknown error occurred, inspect error.serverResponse
                  break
              }
            },
            () => {
              // Upload completed successfully, now we can get the download URL
              const fileArray: any[] = []
              uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
                // console.log('File available at', downloadURL)
                // console.log(id + '.' + ext)
                // console.log(file.type)
                const fileName = id + '.' + ext
                postRef.update({
                  fileNames: this.props.firebase.fieldValue.arrayUnion(fileName),
                  urls: this.props.firebase.fieldValue.arrayUnion(downloadURL)
                })
                this.setState({
                  fileArray,
                  submitting: false,
                  [assignment.assignmentID]: ''
                } as { [K in keyof IRentalObjectAssignmentHistoryState]: IRentalObjectAssignmentHistoryState[K] })
              })
            }
          )
        })
      } else {
        this.setState({
          submitting: false,
          [assignment.assignmentID]: ''
        } as { [K in keyof IRentalObjectAssignmentHistoryState]: IRentalObjectAssignmentHistoryState[K] })
      }
    }, 1000)
  }

  public render() {
    const { t, children, ...props } = this.props
    const {
      rentalObject,
      rentalObjectLoading,
      assignmentsLoading,
      assignmentHistory,
      postList
    } = this.state
    const { submitting, value, isOpen, fileArray } = this.state
    const uploadProps = {
      multiple: false, // true
      onRemove: file => {
        this.setState(() => {
          const index = fileArray.indexOf(file)
          const newFileList = fileArray.slice()
          newFileList.splice(index, 1)
          return {
            fileArray: newFileList
          }
        })
      },
      beforeUpload: (file, fileList) => {
        this.setState({
          fileArray: [file]
        })
        return false
      },
      // To support Multiple
      // beforeUpload: (file, fileList) => {
      //   this.setState({
      //     fileArray: [...fileArray, ...fileList],
      //   })
      //   return false
      // },
      fileList: fileArray
    }
    return (
      <StyledRentalObjectAssignmentHistory {...props}>
        {rentalObjectLoading && <div>Loading ...</div>}
        {assignmentsLoading && <div>Loading Assignments...</div>}
        {rentalObject && (
          <PageHeader
            backIcon={
              <Button type="primary">
                <Icon type="left" />
                Gå Tillbaka
              </Button>
            }
            onBack={() => window.history.back()}
            title={
              rentalObject.address.realEstateName +
              ' (' +
              rentalObject.address.rentalObjectNumber +
              ') '
            }
            subTitle={
              rentalObject.address.street +
              ' ' +
              rentalObject.address.zipCode +
              ' ' +
              rentalObject.address.city
            }
          />
        )}
        <List
          itemLayout="vertical"
          size="large"
          dataSource={assignmentHistory
            .slice()
            .sort((a, b) => b.dateCreated.toDate() - a.dateCreated.toDate())}
          // tslint:disable-next-line: jsx-no-lambda
          renderItem={(assignment: any) => (
            <List.Item key={assignment.assignmentID}>
              <List.Item.Meta
                avatar={<Avatar icon="user" />}
                title={
                  <a href={assignment.assignmentID}>
                    {assignment.areas.map((area, index) => {
                      return <span key={index}>{area}, </span>
                    })}
                  </a>
                }
                description={<div>Prioritet: {assignment.priority}</div>}
              />
              <Tabs defaultActiveKey="1" onChange={this.callback}>
                <TabPane tab="Huvudtext" key="1">
                  <div>
                    {assignment.contactPersons.length > 0 &&
                    !assignment.address.rentalObjectNumber.includes('00.00') ? (
                      <div>
                        <BoldSpan>Kontaktuppgifter</BoldSpan>
                        {assignment.contactPersons.map((person, index) => {
                          return (
                            <div key={index}>
                              <span>
                                <BoldSpan>Namn: </BoldSpan>
                                {person.firstName} {person.lastName}{' '}
                              </span>
                              {person.phoneNumbers ? (
                                <span>
                                  <BoldSpan>Telefonnummer: </BoldSpan>
                                  {person.phoneNumbers.map((phoneNumber, phoneIndex) => {
                                    return (
                                      <span key={phoneNumber + index + phoneIndex}>
                                        {phoneNumber}{' '}
                                      </span>
                                    )
                                  })}
                                </span>
                              ) : null}
                              {person.email ? (
                                <span>
                                  <BoldSpan>Email: </BoldSpan> {person.email}
                                </span>
                              ) : null}
                            </div>
                          )
                        })}
                        <br />
                      </div>
                    ) : null}
                    <div>
                      <BoldSpan>Adress</BoldSpan>
                    </div>
                    <div>
                      {assignment.address.realEstateName}, {assignment.address.street} (
                      {assignment.address.rentalObjectNumber}), {assignment.address.zipCode}{' '}
                      {assignment.address.city}
                    </div>
                    <br />
                    {assignment.masterKeyAllowed || assignment.billable ? (
                      <div>
                        <div>
                          <BoldSpan>Noteringar</BoldSpan>
                        </div>
                        {assignment.masterKeyAllowed ? <div>Huvudnyckel får användas</div> : ''}
                        {assignment.billable ? <div>Skall faktureras</div> : ''}
                        <br />
                      </div>
                    ) : null}
                    {assignment.description && (
                      <div>
                        <div>
                          <BoldSpan>Åtgärdstext</BoldSpan>
                        </div>
                        <div style={{ wordWrap: 'break-word', width: '100%' }}>
                          {assignment.description}
                        </div>
                      </div>
                    )}

                    {assignment.billable && (
                      <div>
                        <br />
                        <div>
                          <BoldSpan>Faktureringsuppgifter</BoldSpan>
                        </div>
                        <div style={{ wordWrap: 'break-word', width: '100%' }}>
                          <BoldSpan>Beskrivning: </BoldSpan>
                          {assignment.invoiceDescription}
                        </div>
                        <div>
                          <BoldSpan>Material: </BoldSpan>
                          {assignment.materialUsed}
                        </div>
                        <div>
                          <BoldSpan>Tid: </BoldSpan>
                          {assignment.hoursToInvoice}
                        </div>
                      </div>
                    )}
                  </div>
                </TabPane>
                <TabPane tab="Information" key="2">
                  <div>
                    {assignment.dateCreated != null && (
                      <div>
                        <BoldSpan>Inlagd av: </BoldSpan>
                        {assignment.creatorName}, {assignment.creatorEmail}{' '}
                        {assignment.dateCreated.toDate().toLocaleString()}
                      </div>
                    )}

                    {assignment.lastEditedDate != null && (
                      <div>
                        <BoldSpan>Senast uppdaterad av: </BoldSpan>
                        {assignment.lastEditedName}, {assignment.lastEditedEmail}{' '}
                        {assignment.lastEditedDate.toDate().toLocaleString()}
                      </div>
                    )}

                    {assignment.assignedDate != null && (
                      <div>
                        <BoldSpan>Tilldelad: </BoldSpan>
                        {assignment.assignedUserName}, {assignment.assignedUserEmail}{' '}
                        {assignment.assignedDate.toDate().toLocaleString()}
                      </div>
                    )}

                    {assignment.dateFinished != null && (
                      <div>
                        <BoldSpan>Avslutad av: </BoldSpan>
                        {assignment.finishedByName}, {assignment.finishedByEmail}{' '}
                        {assignment.dateFinished.toDate().toLocaleString()}
                      </div>
                    )}
                  </div>
                </TabPane>
              </Tabs>
              {/* CollapseView with Postlist */}
              <CollapseView
                state={this.state}
                assignment={assignment}
                postList={postList}
                handleChange={this.handleChange}
                handleSubmit={this.handleSubmit}
                submitting={submitting}
                value={value}
                setState={this.setPictureState}
                uploadProps={uploadProps}
              />
            </List.Item>
          )}
        />
        {isOpen && (
          <Lightbox
            mainSrc={this.state.picture}
            onCloseRequest={() => this.setState({ isOpen: false })}
          />
        )}
      </StyledRentalObjectAssignmentHistory>
    )
  }
}

export default compose(withTranslation(), withFirebase)(RentalObjectAssignmentHistory)
