import * as React from 'react'

import { withTranslation } from 'react-i18next'

/* Import components here */
import { StyledRealEstateEditForm, StyledTitle } from './RealEstateEditForm.styles'
import { Button, Form, Icon, Input, Modal } from 'antd' //InputNumber, Select

/* Import interfaces here */
import {
  IRealEstateEditFormProps,
  IRealEstateEditFormState,
  IFormProps
} from './RealEstateEditForm.interfaces'

/* Import utilities here */
import { withFirebase } from '../../../../firebase'
import { compose } from 'recompose'
export class RealEstateEditForm extends React.Component<
  IRealEstateEditFormProps,
  IRealEstateEditFormState
> {
  public state = {
    loading: false
  }

  public componentDidMount() {
    // To disabled submit button at the beginning.
    this.props.form.validateFields()
  }

  public componentDidUpdate(prevProps) {
    const realEstateChanged = prevProps.realEstate === this.props.realEstate ? false : true
    if (realEstateChanged) {
      this.props.form.resetFields()
    }
  }

  public handleSubmit = e => {
    e.preventDefault()
    this.setState({
      loading: true
    })
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        if (await this.checkIfUniqueName(values.realEstateName)) {
          const realEstateReference = this.props.firebase.realEstate(
            this.props.realEstate.realEstateID
          )

          realEstateReference
            .update({
              'address.city': values.city,
              'address.realEstateName': values.realEstateName,
              'address.street': values.street,
              'address.zipCode': values.zip
            })
            .then(() => {
              this.setState({ loading: false })
              this.props.form.resetFields()
              this.successModal('Uppdatering av Fastighet lyckades')
            })
        } else {
          this.errorModal(
            'Misslyckades med att redigera Fastighet',
            'En Fastighet med det här namnet existerar redan.'
          )
          this.setState({
            loading: false
          })
        }
      }
    })
  }

  public checkIfUniqueName = async realEstateName => {
    const realEstateID = this.props.realEstate.realEstateID

    let unique = false
    await this.props.firebase
      .realEstates()
      .get()
      .then(querySnapshot => {
        let found = false
        querySnapshot.forEach(doc => {
          if (realEstateName === doc.get('address.realEstateName') && doc.id !== realEstateID) {
            found = true
          }
        })
        unique = !found
      })
      .catch(error => {
        console.log('Error getting documents: ', error)
      })
    return unique
  }

  public successModal = title => {
    Modal.success({
      centered: true,
      title
    })
  }

  public errorModal = (title, content) => {
    Modal.error({
      title,
      content
    })
  }

  public hasErrors(fieldsError) {
    // console.log(fieldsError)
    // console.log(Object.keys(fieldsError).some(field => fieldsError[field]))
    return Object.keys(fieldsError).some(field => fieldsError[field])
  }
  public render() {
    const { realEstate, t, children, ...props } = this.props
    const { loading } = this.state
    const {
      getFieldDecorator,
      getFieldsError,
      getFieldError,
      isFieldTouched,
      getFieldValue
    } = this.props.form
    const realEstateNameError = isFieldTouched('realEstateName') && getFieldError('realEstateName')
    const streetError = isFieldTouched('street') && getFieldError('street')
    const cityError = isFieldTouched('city') && getFieldError('city')
    const zipError = isFieldTouched('zip') && getFieldError('zip')

    const realEstateNameEdited =
      getFieldValue('realEstateName') === realEstate.address.realEstateName ? false : true
    const streetEdited = getFieldValue('street') === realEstate.address.street ? false : true
    const cityEdited = getFieldValue('city') === realEstate.address.city ? false : true
    const zipEdited = getFieldValue('zip') === realEstate.address.zipCode ? false : true

    const fieldsEdited = realEstateNameEdited || streetEdited || cityEdited || zipEdited

    const formItemLayout = {
      labelCol: { span: 3 },
      wrapperCol: { span: 18 }
    }

    const buttonItemLayout = {
      wrapperCol: { span: 18, offset: 3 }
    }

    return (
      <StyledRealEstateEditForm {...props}>
        <StyledTitle style={{ textAlign: 'center' }}>Redigera Fastighet</StyledTitle>
        <Form layout="horizontal" onSubmit={this.handleSubmit}>
          <Form.Item
            {...formItemLayout}
            label="Beteckning"
            validateStatus={realEstateNameError ? 'error' : ''}
            help={realEstateNameError || ''}
          >
            {getFieldDecorator('realEstateName', {
              initialValue: realEstate.address.realEstateName,
              rules: [{ required: true, message: 'Ange fastighetensbeteckning !' }]
            })(
              <Input
                prefix={<Icon type="edit" style={{ color: 'rgba(0,0,0,.25)' }} />}
                placeholder="Fastighetsbeteckning"
              />
            )}
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            label="Adress"
            validateStatus={streetError ? 'error' : ''}
            help={streetError || ''}
          >
            {getFieldDecorator('street', {
              initialValue: realEstate.address.street,
              rules: [{ required: true, message: 'Ange adress!' }]
            })(
              <Input
                prefix={<Icon type="home" style={{ color: 'rgba(0,0,0,.25)' }} />}
                placeholder="Adress"
              />
            )}
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            label="Stad"
            validateStatus={cityError ? 'error' : ''}
            help={cityError || ''}
          >
            {getFieldDecorator('city', {
              initialValue: realEstate.address.city,
              rules: [{ required: true, message: 'Ange stad!' }]
            })(
              <Input
                prefix={<Icon type="shop" style={{ color: 'rgba(0,0,0,.25)' }} />}
                placeholder="Stad"
              />
            )}
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            label="Zip"
            validateStatus={zipError ? 'error' : ''}
            help={zipError || ''}
          >
            {getFieldDecorator('zip', {
              initialValue: realEstate.address.zipCode,
              rules: [{ required: true, message: 'Ange zipkod!' }]
            })(
              <Input
                prefix={<Icon type="environment" style={{ color: 'rgba(0,0,0,.25)' }} />}
                placeholder="Zip"
              />
            )}
          </Form.Item>
          <Form.Item {...buttonItemLayout}>
            <Button
              type="primary"
              htmlType="submit"
              disabled={this.hasErrors(getFieldsError()) || !fieldsEdited}
              loading={loading}
              style={{ width: '100%' }}
            >
              Bekräfta Ändringar
            </Button>
          </Form.Item>
        </Form>
      </StyledRealEstateEditForm>
    )
  }
}

/** @component */
export default Form.create<IFormProps>({ name: 'RealEstateEditForm' })(
  compose(withTranslation(), withFirebase)(RealEstateEditForm)
)
