import * as React from 'react'

/* Import components here */
import { Page } from 'components'
import { WithTranslation } from 'react-i18next'
import { compose } from 'recompose'

import { withAuthorization, withEmailVerification } from '../../contexts'
import { Messages, Message } from '../../components'

import { Layout, Card, Icon, Typography } from 'antd'
import { Switch, Route } from 'react-router-dom'
import * as ROUTES from '../../constants/routes'
import SideNav from './SideNav'
import City from './city.jpg'
import CDABLogo from './cdab.svg'
const { Text } = Typography

const IconText = ({ type, text }) => (
  <Text strong>
    <Icon type={type} style={{ marginRight: 8 }} />
    {text}
  </Text>
)
const { Header, Sider, Content } = Layout

export const HomePage: React.FC<WithTranslation> = ({ children, ...props }) => {
  return (
    <Page {...props}>
      <Layout>
        <Header
          style={{
            backgroundColor: 'white',
            height: 200,
            padding: 0,
            backgroundImage: `url(${City})`
          }}
        />
        <Layout>
          <Sider
            width={320}
            style={{
              backgroundColor: 'white'
            }}
          >
            <Card bordered={false} title="Support">
              <p>
                Behöver du hjälp? Något som inte fungerar som det ska? Önskemål på ny funktionalitet
                eller annat, skicka gärna ett mail till oss!
              </p>
              <p>
                <IconText type={'mail'} text={`fastighetsdagboken@support.cdab.eu`} />
              </p>
            </Card>
            <Card bordered={false} title="CDAB">
              <div style={{ margin: '0 auto', width: 150, textAlign: 'center' }}>
                <img src={CDABLogo} width={150} alt="Not found" />
                <a href="http://cdab.eu/">Länk till Hemsida</a>
              </div>
            </Card>
          </Sider>
          <Content style={{ backgroundColor: 'white' }}>
            <Switch>
              <Route exact path={ROUTES.HOME} component={Messages} />
              <Route path={ROUTES.HOME_NEWS_DETAILS} component={Message} />
            </Switch>
          </Content>
          <Sider width={320} style={{ backgroundColor: 'white' }}>
            <SideNav />
          </Sider>
        </Layout>
        {/* <Footer style={{ backgroundColor: '#7dbcea', color: 'white' }}>Footer</Footer> */}
      </Layout>
    </Page>
  )
}

const condition = authUser => !!authUser

export default compose(withEmailVerification, withAuthorization(condition))(HomePage)
