import * as app from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'
import { rootStore } from '../stores'
import moment from 'moment'
import { PostNotification, InboxNotification } from 'components'
const { employeeStore, settingStore, inboxStore } = rootStore
const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID
}

export const Timestamp = app.firestore.Timestamp

export interface IFirebase {
  fieldValue: any
  auth: any
  db: any
}

class Firebase implements IFirebase {
  app: any
  auth: any
  db: any
  fieldValue: any
  messaging: any
  emailAuthProvider: any
  customerID: string
  timestamp: any
  storage: any
  settingsListener: any
  inboxListener: any
  inboxNotificationListener: any
  postNotificationListener: any
  constructor() {
    app.initializeApp(config)
    /* Helper */
    this.app = app

    this.fieldValue = app.firestore.FieldValue
    this.emailAuthProvider = app.auth.EmailAuthProvider
    this.timestamp = app.firestore.Timestamp

    /* Firebase APIs */

    this.auth = app.auth()
    this.db = app.firestore()
    this.storage = app.storage()
    // this.db.settings({ timestampsInSnapshots: true })

    const authUser = localStorage.getItem('authUser')
    const user = !!authUser ? JSON.parse(authUser) : null
    if (user) {
      this.customerID = user.customerID
    } else {
      this.customerID = ''
    }
  }

  // *** Auth API ***

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password)

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password)

  doSignOut = () => {
    this.settingsListener() // Stop listening to Settings, can also stop listening to other stuff here
    this.inboxListener()
    this.inboxNotificationListener()
    this.postNotificationListener()
    this.auth.signOut()
  }

  doPasswordReset = email => this.auth.sendPasswordResetEmail(email)

  doSendEmailVerification = () =>
    this.auth.currentUser.sendEmailVerification({
      url: process.env.REACT_APP_CONFIRMATION_EMAIL_REDIRECT
    })

  doPasswordUpdate = (currentPassword, newPassword, nextCallback, errorCallback) => {
    const user = app.auth().currentUser
    if (user && user.email) {
      const credential = app.auth.EmailAuthProvider.credential(user.email, currentPassword)
      user
        .reauthenticateWithCredential(credential)
        .then(() => {
          user.updatePassword(newPassword).then(nextCallback())
        })
        .catch(error => errorCallback(error))
    }
  }

  // *** Merge Auth and DB User API *** //

  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged(authUser => {
      if (authUser) {
        this.user(authUser.uid)
          .get()
          .then(snapshot => {
            const dbUser = snapshot.data()
            // default empty roles
            if (!dbUser.roles) {
              dbUser.roles = {}
            }
            this.customerID = dbUser.customerID
            // merge auth and db user
            authUser = {
              uid: authUser.uid,
              email: authUser.email,
              emailVerified: authUser.emailVerified,
              providerData: authUser.providerData,
              ...dbUser
            }

            next(authUser)
          })
      } else {
        fallback()
      }
    })

  setUpRootStore = authUser => {
    employeeStore.setAllEmployees([])
    // Get all Employees
    this.users()
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          // doc.data() is never undefined for query doc snapshots
          //console.log(doc.id, ' => ', doc.data())
          employeeStore.pushToAllEmployees(doc.data())
        })
        employeeStore.setLoading(false)
      })
      .catch(error => {
        console.log('Error getting documents: ', error)
      })
    // Settings
    this.settingsListener = this.settings().onSnapshot(doc => {
      settingStore.setSettings(doc.data())
    })
    this.inboxListener = this.inboxedAssignments().onSnapshot(async snapshot => {
      const assignments: any = []
      for (const doc of snapshot.docs) {
        const pictureURL = await this.realEstate(doc.data().realEstateID)
          .get()
          .then(realD => realD.data().pictureURL)
        assignments.push({
          ...doc.data(),
          pictureURL
        })
      }
      inboxStore.setInbox(assignments)
    })

    this.listenToNewPosts(authUser)
    this.listenToInboxAssignments()
  }

  getUniqueID = () => this.db.collection('users').doc().id

  // *** User API ***
  user = uid => this.db.doc(`users/${uid}`)

  users = () => this.db.collection('users').where('customerID', '==', this.customerID)

  addUser = userID => object =>
    this.db
      .collection('users')
      .doc(userID)
      .set(object)

  updateUser = userID => object =>
    this.db
      .collection('users')
      .doc(userID)
      .update(object)

  deleteUser = userID =>
    this.db
      .collection('users')
      .doc(userID)
      .delete()

  // *** RealEstates API ***
  realEstate = uid => this.db.doc(`customers/${this.customerID}/realEstates/${uid}`)

  realEstates = () => this.db.collection(`customers/${this.customerID}/realEstates`)

  // *** RentalObjects API ***

  rentalObjects = uid =>
    this.db
      .collection(`customers/${this.customerID}/rentalObjects`)
      .where('realEstateID', '==', uid)

  rentalObject = uid => this.db.doc(`customers/${this.customerID}/rentalObjects/${uid}`)

  // *** Assignments API ***
  // assignments2 = () =>
  //   this.db
  //     .collection(`customers/${this.customerID}/assignments`)
  //     .where('areas', 'array-contains', 'Sovrum 1')

  assignments = uid =>
    this.db
      .collection(`customers/${this.customerID}/assignments`)
      .where('rentalObjectID', '==', uid)
      .orderBy('dateCreated', 'desc')

  assignment = uid => this.db.doc(`customers/${this.customerID}/assignments/${uid}`)

  addAssignment(id, assignment) {
    this.db
      .collection('customers')
      .doc(this.customerID)
      .collection('assignments')
      .doc(id)
      .set(assignment)
  }

  // addAssignment(id, assignment) {
  //   assignment.customerID = "TYSo3fR7cvSrQk5Vz77L"
  //   this.db
  //     .collection('assignmentInbox')
  //     .doc(id)
  //     .set(assignment)
  // }
  updateAssignment = (id, fields) => callback => {
    const ref = this.db
      .collection('customers')
      .doc(this.customerID)
      .collection('assignments')
      .doc(id)

    ref
      .update({
        ...fields
      })
      .then(() => {
        ref.get().then((doc: any) => {
          if (doc.exists) {
            callback(doc.data())
          }
        })
      })
  }

  inboxedAssignments = () =>
    this.db
      .collection(`assignmentInbox`)
      .where('customerID', '==', this.customerID)
      .orderBy('dateCreated', 'desc')

  removeAssignmentFromInbox(id) {
    this.db
      .collection('assignmentInbox')
      .doc(id)
      .delete()
  }

  listenToInboxAssignments = () => {
    const date = this.timestamp.fromDate(new Date())
    this.inboxNotificationListener = this.db
      .collection(`assignmentInbox`)
      .where('customerID', '==', this.customerID)
      .where('dateCreated', '>=', date)
      .onSnapshot(snapshot => {
        snapshot.docChanges().forEach(change => {
          if (change.type === 'added') {
            InboxNotification(change.doc.data())
          }
        })
      })
  }

  getAssignmentList = (start, end, radio) => {
    const startDate = this.timestamp.fromDate(new Date(start.format('YYYY-MM-DD')))
    const endDate = this.timestamp.fromDate(
      new Date(
        moment(end)
          .add(1, 'd')
          .format('YYYY-MM-DD')
      )
    )
    switch (radio) {
      case 'Active':
        return this.db
          .collection('customers')
          .doc(this.customerID)
          .collection('assignments')
          .where('dateFinished', '==', null)
          .where('dateCreated', '>=', startDate)
          .where('dateCreated', '<=', endDate)
      case 'All':
        return this.db
          .collection('customers')
          .doc(this.customerID)
          .collection('assignments')
          .where('dateCreated', '>=', startDate)
          .where('dateCreated', '<=', endDate)
      case 'Unassigned':
        return this.db
          .collection('customers')
          .doc(this.customerID)
          .collection('assignments')
          .where('assignedUserID', '==', '')
          .where('dateFinished', '==', null)
          .where('dateCreated', '>=', startDate)
          .where('dateCreated', '<=', endDate)
      case 'Billable':
        return this.db
          .collection('customers')
          .doc(this.customerID)
          .collection('assignments')
          .where('billable', '==', true)
          .where('dateCreated', '>=', startDate)
          .where('dateCreated', '<=', endDate)
          .orderBy('dateCreated', 'desc')
      default:
    }
  }

  // *** Statistics API ***

  activeAssignments = () =>
    this.db
      .collection(`customers/${this.customerID}/assignments`)
      .where('dateFinished', '==', null)
      .orderBy('dateCreated')

  statistics = () => this.db.doc(`customers/${this.customerID}/statistics/general`)

  userStatistic = uid => this.db.doc(`users/${uid}/dynamicData/assignmentStatistic`)

  // *** Assignments API ***

  settings = () => this.db.doc(`customers/${this.customerID}/settings/enums`)

  // *** Post API ***
  posts = uid =>
    this.db.collection(`customers/${this.customerID}/posts`).where('rentalObjectID', '==', uid)

  postsByAssignmentID = uid =>
    this.db.collection(`customers/${this.customerID}/posts`).where('assignmentID', '==', uid)

  post = uid => this.db.doc(`customers/${this.customerID}/posts/${uid}`)

  postRef = () => this.db.collection(`customers/${this.customerID}/posts`).doc()

  listenToNewPosts = authUser => {
    const date = this.timestamp.fromDate(new Date())
    this.postNotificationListener = this.db
      .collection(`customers/${this.customerID}/posts`)
      .where('dateCreated', '>=', date)
      .onSnapshot(snapshot => {
        snapshot.docChanges().forEach(change => {
          if (change.type === 'added') {
            if (change.doc.data().authorID === authUser.userID) {
              //FIXME:
              PostNotification(change.doc.data())
            }
          }
        })
      })
  }

  // *** Message API ***

  message = uid => this.db.doc(`messages/${uid}`)

  messages = () => this.db.collection('messages')

  latestestNews = () =>
    this.db
      .collection('messages')
      .orderBy('createdAt', 'desc')
      .limit(5)

  importantNews = () =>
    this.db
      .collection('messages')
      .where('favourite', '==', true)
      .orderBy('createdAt')

  // *** Storage API ***
  rentalObjectStorageRef = file =>
    this.storage
      .ref()
      .child('customers')
      .child(this.customerID)
      .child('rentalObjects')
      .child(file)

  realEstateStorageRef = file =>
    this.storage
      .ref()
      .child('customers')
      .child(this.customerID)
      .child('realEstates')
      .child(file)
}

export default Firebase
