import * as React from 'react'

/* Import components here */
import { Switch, Route } from 'react-router-dom'
import { compose } from 'recompose'

import { withAuthorization, withEmailVerification } from '../../contexts'
import {
  RealEstateGrid,
  RentalObjectTable,
  RentalObjectAssignmentHistoryList,
  RentalObjectAssignmentHistoryItem
} from '../../components'
import * as ROUTES from '../../constants/routes'
export const RealEstatesPage: React.FC = ({ children, ...props }) => {
  return (
    <div style={{ backgroundColor: 'white' }}>
      <Switch>
        <Route exact path={ROUTES.REAL_ESTATES_RENTAL_OBJECT} component={RentalObjectTable} />
        <Route
          exact
          path={ROUTES.REAL_ESTATES_RENTAL_OBJECT_HISTORY}
          component={RentalObjectAssignmentHistoryList}
        />
        <Route
          exact
          path={ROUTES.REAL_ESTATES_RENTAL_OBJECT_HISTORY_ASSIGNMENT}
          component={RentalObjectAssignmentHistoryItem}
        />
        <Route exact path={ROUTES.REAL_ESTATES} component={RealEstateGrid} />
      </Switch>
    </div>
  )
}

const condition = authUser => authUser && !!authUser

export default compose(withEmailVerification, withAuthorization(condition))(RealEstatesPage)
