import * as React from 'react'

import { useTranslation, WithTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import styled, { keyframes, css } from 'styled-components'

import { logo } from './'

import { GlobalStyle } from 'theme'

const AppWrapper = styled.div`
  text-align: center;
`

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`
interface TitleProps {
  readonly primary?: boolean
}

const StyledButton = styled.button<TitleProps>`
  background: transparent;
  border-radius: 3px;
  border: 2px solid palevioletred;
  color: palevioletred;
  margin: 0 1em;
  padding: 0.25em 1em;

  ${props =>
    props.primary &&
    css`
      background: palevioletred;
      color: white;
    `};
`

const AppHeader = styled.div`
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
`

const AppLink = styled.a`
  color: #61dafb;
`

const AppLogo = styled.img`
  height: 80px;

  &:hover {
    animation: ${rotate360} infinite 1.5s linear;
  }
`

export const StartPage: React.FC<WithTranslation> = () => {
  const { t } = useTranslation()

  return (
    <AppWrapper>
      <GlobalStyle />
      <AppHeader>
        <AppLogo src={logo} alt="logo" />
        <p>{t('common:description')}</p>
        <StyledButton>Styled Button</StyledButton>
        <StyledButton primary>{t('start:click-me')}</StyledButton>
        <AppLink href="https://reactjs.org" target="_blank" rel="noopener noreferrer">
          {t('common:applink')}
        </AppLink>
        <NavLink to="/about">{t('start:about-this-boilerplate')}</NavLink>
        <NavLink to="/Sentry">{t('start:play-with-sentry')}</NavLink>
        <NavLink to="/nonexistingpage">{t('start:non-existing-page')}</NavLink>
      </AppHeader>
    </AppWrapper>
  )
}
