import * as React from 'react'

import { withTranslation } from 'react-i18next'

/* Import components here */
import { StyledMessageList } from './MessageList.styles'
import { MessageItem } from '../MessageItem'
/* Import interfaces here */
import { IMessageListProps, IMessage } from './MessageList.interfaces'

/* Import utilities here */
import { List } from 'antd'

export const MessageList: React.FC<IMessageListProps> = ({
  authUser,
  loading,
  messages,
  onEditMessage,
  onRemoveMessage,
  t,
  children,
  ...props
}) => {
  return (
    <StyledMessageList {...props}>
      <List
        loading={loading}
        itemLayout="vertical"
        size="large"
        dataSource={messages}
        renderItem={(message: IMessage) => (
          <MessageItem
            authUser={authUser}
            key={message.uid}
            message={message}
            onEditMessage={onEditMessage}
            onRemoveMessage={onRemoveMessage}
          />
        )}
      />
    </StyledMessageList>
  )
}

export default withTranslation()(MessageList)
