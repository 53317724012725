import { styled } from 'theme'

export const StyledDefaultSettingsLanding = styled.div`
  background-color: white;
  display: flex;
`
export const Main = styled.div`
  flex: 1;
  :target {
    border: 1px solid #1890ff;
  }
`
export const Sider = styled.div`
  min-width: 200px;
  padding-right: 24px;
`

export const Target = styled.div`
  :target {
    padding: 16px;
    border: 1px solid #1890ff;
  }
`
