import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'

import { AuthUserContext } from '../'
import { withFirebase } from '../../firebase'
import * as ROUTES from '../../constants/routes'
import { IWithAuthorizationProps } from './WithAuthorization.interfaces'

export const withAuthorization = condition => Component => {
  class WithAuthorization extends React.Component<IWithAuthorizationProps> {
    listener: any
    componentDidMount() {
      this.listener = this.props.firebase.onAuthUserListener(
        authUser => {
          if (!condition(authUser) || !authUser.roles.ADMIN) {
            this.props.history.push(ROUTES.ACCESS_DENIED) // Om man inte har behörighet
          }
        },
        () => this.props.history.push(ROUTES.SIGN_IN) // Om man inte är inloggad
      )
    }

    componentWillUnmount() {
      this.listener()
    }

    render() {
      return (
        <AuthUserContext.Consumer>
          {(authUser: any) =>
            condition(authUser) && authUser.roles.ADMIN ? <Component {...this.props} /> : null
          }
        </AuthUserContext.Consumer>
      )
    }
  }

  return compose(withRouter, withFirebase)(WithAuthorization)
}
