import * as React from 'react'

import { withTranslation } from 'react-i18next'

/* Import components here */
import { StyledRealEstateCitySelect } from './RealEstateCitySelect.styles'
import { Radio } from 'antd'

/* Import interfaces here */
import {
  IRealEstateCitySelectProps,
  IRealEstateCitySelectState
} from './RealEstateCitySelect.interfaces'

/* Import utilities here */
const RadioButton = Radio.Button
const RadioGroup = Radio.Group
export class RealEstateCitySelect extends React.Component<
  IRealEstateCitySelectProps,
  IRealEstateCitySelectState
> {
  public onChange = e => {
    // console.log(`radio checked:${e.target.value}`)

    // rootStore.appStateStore.setSearchBarValue('')
    this.props.setSelectedCity(e.target.value)
  }

  public render() {
    const { allCities, selectedCity, t, children, ...props } = this.props

    return (
      <StyledRealEstateCitySelect {...props}>
        <RadioGroup onChange={this.onChange} defaultValue={selectedCity}>
          <RadioButton key={0} value="All">
            Alla
          </RadioButton>
          {allCities.map((city, index) => {
            return (
              <RadioButton key={index} value={city}>
                {city}
              </RadioButton>
            )
          })}
        </RadioGroup>
      </StyledRealEstateCitySelect>
    )
  }
}

export default withTranslation()(RealEstateCitySelect)
