import React from 'react'
import { Route } from 'react-router-dom'
import styled from 'styled-components'
import * as ROUTES from '../../constants/routes'

const LoginContainer = styled.div`
  text-align: center;
  width: 444px;
  margin: auto;
  background-color: #eceae7;
  -webkit-box-shadow: inset 0px 0px 10px -1px rgba(0, 0, 0, 0.85);
  -moz-box-shadow: inset 0px 0px 10px -1px rgba(0, 0, 0, 0.85);
  box-shadow: inset 0px 0px 10px -1px rgba(0, 0, 0, 0.85);
  -webkit-box-shadow: 0px 0px 10px -1px rgba(0, 0, 0, 0.85);
  -moz-box-shadow: 0px 0px 10px -1px rgba(0, 0, 0, 0.85);
  box-shadow: 0px 0px 10px -1px rgba(0, 0, 0, 0.85);
  padding: 40px;
  border-radius: 25px;
`
// App layout
const LoginLayout = ({ children, match }) => {
  return (
    <div style={match.path === ROUTES.SIGN_IN ? { paddingTop: '10vh' } : { paddingTop: '30vh' }}>
      <LoginContainer>{children}</LoginContainer>
    </div>
  )
}

export const LoginLayoutRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={matchProps => (
        <LoginLayout {...matchProps}>
          <Component {...matchProps} />
        </LoginLayout>
      )}
    />
  )
}
