import * as React from 'react'

/* Import components here */
import { Button, Form, Select } from 'antd'

/* Import interfaces here */
import {
  IDashBoardMultipleYearSelectProps,
  IDashBoardMultipleYearSelectState
} from './DashBoardMultipleYearSelect.interfaces'

/* Import utilities here */
const { Option } = Select

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field])
}
export class DashBoardMultipleYearSelect extends React.Component<
  IDashBoardMultipleYearSelectProps,
  IDashBoardMultipleYearSelectState
> {
  public componentDidMount() {
    // To disabled submit button at the beginning.
    this.props.form.validateFields()
  }

  public handleSubmit = e => {
    e.preventDefault()
    this.props.form.validateFields((err, values) => {
      if (!err) {
        // console.log('Received values of form: ', values)
        this.props.setYears(values.tags)
      }
    })
  }
  public render() {
    const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } = this.props.form
    const selectError = isFieldTouched('tags') && getFieldError('tags')

    return (
      <Form layout="inline" onSubmit={this.handleSubmit}>
        <Form.Item
          validateStatus={selectError ? 'error' : ''}
          help={selectError || ''}
          // label="Tags (up to 5)"
        >
          {getFieldDecorator('tags', {
            rules: [
              {
                message: 'Välj minst 1 år',
                required: true
              },
              {
                validator: (rule, value, callback) => {
                  if (value) {
                    if (value.length > 3) {
                      callback('Max 3 år')
                    } else if (value.length <= 3) {
                      callback()
                    }
                  }
                  callback()
                }
              }
            ]
          })(
            <Select mode="multiple" placeholder="Välj år" style={{ minWidth: 200 }}>
              <Option value="2020">2020</Option>
              <Option value="2019">2019</Option>
              <Option value="2018">2018</Option>
              <Option value="2017">2017</Option>
              <Option value="2016">2016</Option>
              <Option value="2015">2015</Option>
              <Option value="2014">2014</Option>
              <Option value="2013">2013</Option>
            </Select>
          )}
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" disabled={hasErrors(getFieldsError())}>
            Hämta statistik
          </Button>
        </Form.Item>
      </Form>
    )
  }
}
/** @component */
export default Form.create<IDashBoardMultipleYearSelectProps>({ name: 'multipleYearSelect' })(
  DashBoardMultipleYearSelect
)
