import { styled } from 'theme'
import { Descriptions } from 'antd'

export const StyledImportResult = styled.div``
export const StyledDesc = styled.div`
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #ffff;
  padding: 24px;
`

export const StyledDescriptions = styled(Descriptions)`
  .ant-descriptions-title {
    margin-bottom: 4px;
    font-size: 14px;
  }
  .ant-descriptions-item-label {
    font-weight: 500;
  }
`
