import { styled } from 'theme'

export const StyledListSortDemo = styled.div`
  background-color: white;
`

export const StyledWrapper = styled.div`
  position: relative;
  background: #f0f2f5;
  overflow: hidden;
  width: 400px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
`
export const StyledList = styled.div`
  background: #fff;
  border-radius: 6px;
  margin: 5px auto;
  /* padding: 10px; Big Size */
  height: 40px; /* 70px  Big Size*/
  transition: box-shadow 0.5s, transform 0.5s;
`
export const StyledContainer = styled.div`
  margin: 24px auto;
  max-width: 350px;
  width: 90%;
  cursor: url('http://gtms02.alicdn.com/tps/i2/T1_PMSFLBaXXcu5FDa-20-20.png') 10 10, pointer !important;
  position: relative;
  > div {
    overflow: hidden;
  }
  .list-drag-selected {
    box-shadow: 0 8px 20px #e6e6e6;
    transform: scale(1.1) !important;
  }
`
export const StyledText = styled.div`
  width: 80%;
  display: inline-block;
  /* padding: 5px; Big Size*/
  text-align: 'center';

  h1 {
    font-size: 18px;
  }

  p {
    font-size: 12px;
  }

  @media screen and (max-width: 320px) {
    .list-sort-demo-text h1 {
      font-size: 14px;
      line-height: 28px;
    }
  }
`
export const StyledCircleWrapper = styled.div`
  width: 20%;
  display: inline-block;
  text-align: center;
  height: 40px; /* 50px Big Size */
  vertical-align: top;
`

export const StyledCircle = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 250px;
  font-size: 14px;
  line-height: 20px;
  color: #e9e9e9;
  text-align: center;
  border: 2px solid #e9e9e9;
  margin: 0 auto;
  margin-top: 8px; /* 13px Big Siza */
`

export const Wrap = styled.div`
  width: 400px;
  display: flex;
`
export const Extra = styled.div`
  min-width: 100px;
  text-align: center;
  /* padding: 1rem; */
  /* width: 20%; */
  display: inline-block;
  text-align: center;
  font-size: 30px;
  line-height: 50px;
  vertical-align: top;
`
export const Content = styled.div`
  padding-left: 1rem;
  flex: 1;
  h1 {
    line-height: 50px;

    font-size: 18px;
  }
`
