import { Alert, IAlert } from './alert'
import { Colors, IColors } from './colors'
import { ILoadingIndicator, LoadingIndicator } from './loadingIndicator'
import { INotification, Notification } from './notification'

export interface ITheme {
  alert: IAlert
  borderRadius: string
  colors: IColors
  list: {
    item: {
      left: {
        padding: string
      }
      description: {
        fontSize: string
        lineHeight: string
      }
      right: {
        padding: string
      }
      title: {
        fontSize: string
        fontWeight: number
      }
    }
  }
  loadingIndicator: ILoadingIndicator
  notification: INotification
  sizes: {
    container: string
    mdContainer: string
    xlContainer: string
  }
  spacing: {
    narrow: string
    normal: string
    wide: string
  }
}

export const theme: ITheme = {
  alert: Alert,
  borderRadius: '.25rem',
  colors: Colors,
  list: {
    item: {
      description: {
        fontSize: '1rem',
        lineHeight: '1.5rem'
      },
      left: {
        padding: '0.5rem'
      },
      right: {
        padding: '1rem'
      },
      title: {
        fontSize: '1rem',
        fontWeight: 600
      }
    }
  },
  loadingIndicator: LoadingIndicator,
  notification: Notification,
  sizes: {
    container: '100%',
    mdContainer: '48rem',
    xlContainer: '82rem'
  },
  spacing: {
    narrow: '0.5rem',
    normal: '1rem',
    wide: '2rem'
  }
}
