import * as React from 'react'

import { Button, Icon, notification, Avatar } from 'antd'
import * as ROUTES from '../../constants/routes'
import { history } from '../../pages/AppRouter'

const close = post => {
  history.push(
    `${ROUTES.REAL_ESTATES}/${post.realEstateID}/${post.rentalObjectID}/${post.assignmentID}`
  )
  notification.close(post.postID)
}
const openNotification = post => {
  notification.open({
    key: post.postID,
    message: `${post.authorEmail} ◦ ${post.dateCreated
      .toDate()
      .toLocaleString([], { hour: '2-digit', minute: '2-digit' })}`,
    description: (
      <div style={{ display: 'flex' }}>
        <div style={{ flex: 1, maxWidth: 50 }}>
          <Avatar icon="user" />
        </div>
        <div style={{ flex: 1 }}>
          <div style={{ fontWeight: 'bold' }}>{post.authorName}</div>
          <div>{post.comment}</div>
        </div>
        <div style={{ minWidth: 32 }}>
          <Button
            // onClick={() =>
            //   (window.location.href = `${ROUTES.REAL_ESTATES}/${post.realEstateID}/${post.rentalObjectID}/${post.assignmentID}`)
            // }
            onClick={() => close(post)}
            style={{ position: 'absolute', bottom: 16 }}
            shape="circle"
            icon="right"
          />
        </div>
      </div>
    ),
    icon: <Icon type="message" style={{ color: '#108ee9' }} />,
    duration: 0
  })
}

export default openNotification
